import React, { useContext, useEffect, useState } from "react"
import Banner from './sections/Banner'
import BreadCrumb from "./sections/BreadCrumb"
import { SectionTitle, OnlyTitleBanner, OnlySocialWithDate, ImageWithTextMasonary, PageLoader, GetSiteUrl, MetaData, checkIfImageExists } from "./sections/CommonSections";

import GlobalContext from './Core/PageData'
import { Link, useNavigate, useParams } from "react-router-dom"
import axios from "axios";

import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css';
import moment from "moment";

/* 
const customStyles = {
    content: {
        zIndex: '999999'
    },
}; */

const ImageGallery: React.FC<any> = ({ siteSlug, type = 'normal' }: any): any => {
    const { GlobalContent, setGlobalContent } = useContext(GlobalContext as any) as any
    //document.title = (type === 'normal') ? `Image Gallery${GlobalContent.pageTitle}` : `Social Image Gallery${GlobalContent.pageTitle}`;
    const navigate = useNavigate()
    let { ImageGallerySlug, newsSlug } = useParams();
    const [isPageLoad, setisPageLoad] = useState('no')
    const [BreadCrumbdata, setBreadCrumbdata] = useState({ data: [] as any[] });
    const [ImageGalleryData, setImageGalleryData] = useState({ data: [] as any[] });
    const [ImageGalleryContent, setImageGalleryContent] = useState({ data: [] as any[] });

    const [Lightimages, setLightimages] = useState({ data: [] as any[] });
    const [LightimagesCaption, setLightimagesCaption] = useState({ data: [] as any[] });
    const [LightimageTitle, setLightimageTitle] = useState({ data: [] as any[] });

    const [photoIndex, setphotoIndex] = useState(0)
    const [isOpen, setisOpen] = useState(false)

    const [NewsDetailsData, setNewsDetailsData] = useState({ data: [] as any[] })

    useEffect(() => {
        (async () => {
            setisPageLoad('no')
            setLightimages({ data: [] })
            setLightimagesCaption({ data: [] })
            setLightimageTitle({ data: [] })
            //setGlobalContent({ ...GlobalContent, IsMediaPlayershow: false })

            let APIURL = `${process.env.REACT_APP_API_URL}publication/image-gallery/slug/${ImageGallerySlug}`
            if (type === 'social-image-gallery') {
                APIURL = `${process.env.REACT_APP_API_URL}publication/social-image-gallery/slug/${ImageGallerySlug}`
            }
            axios.get(APIURL).then(async json => {

                if (json.data.data.length === 0 || json.data.data[0].status === 'inactive') {
                    navigate(`${process.env.PUBLIC_URL}/${process.env.REACT_APP_NEWS_SLUG}`, { replace: true })
                }
                document.title = json.data.data[0].name
                const d = moment(json.data.data[0].createdDate.split('T')[0])
                json.data.data[0]['date'] = `${d.format('DD')} ${d.format('MMMM')} ${d.format('YYYY')}`
                if (siteSlug !== undefined) {
                    var site = GlobalContent.AllSiteData.filter((e: any) => e.site_url === siteSlug)
                    json.data.data[0].siteAccess = site[0]._id
                } else {
                    var site = [] as any;
                    if (json.data.data[0].siteAccess !== '' && json.data.data[0].siteAccess !== undefined) {
                        site = GlobalContent.AllSiteData.filter((e: any) => e._id === json.data.data[0].siteAccess)
                    } else {
                        site = GlobalContent.AllSiteData.filter((e: any) => e.is_main === 'yes')
                    }
                }
                setGlobalContent({ ...GlobalContent, siteData: site[0], headerClass: '' })
                setImageGalleryContent({ data: json.data.data })
                let I: any[] = []
                let C: any[] = []
                let T: any[] = []
                json.data.data[0].image_json.forEach((Temp: any, i: any) => {
                    var imagess = ''
                    if (Temp?.image_thumb_2048_inside !== undefined && Temp?.image_thumb_2048_inside !== '' && Temp.lastModified !== undefined && Temp.lastModified !== 'undefined' && Temp.lastModified !== 'null') {
                        const date = new Date(parseInt(Temp.lastModified));
                        if (moment('2023-09-11').format('X') > moment(date).format('X')) {
                            imagess = Temp?.imageurl
                        } else {
                            imagess = Temp?.image_thumb_2048_inside
                        }
                    } else if (Temp?.image_thumb_2048_inside !== undefined && Temp?.image_thumb_2048_inside !== '' && (Temp.lastModified === undefined || Temp.lastModified === 'undefined' || Temp.lastModified === 'null')) {
                        if (moment('2023-09-11').format('X') > moment(json.data.data[0].updatedDate).format('X')) {
                            imagess = Temp?.imageurl
                        } else {
                            imagess = Temp?.image_thumb_2048_inside
                        }
                    } else {
                        imagess = Temp?.imageurl
                    }

                    I[i] = imagess
                    C[i] = (i + 1) + '/' + json.data.data[0].image_json.length + ((Temp.caption !== '' && Temp.caption !== undefined) ? ' :: ' + Temp.caption : '')
                    T[i] = json.data.data[0].name
                    /* I.push(Temp?.image_thumb_2048_inside !== undefined && Temp?.image_thumb_2048_inside !== '' ? ((moment('2023-09-06').format('X') > moment(Temp.lastModifiedDate.split('T')[0]).format('X')) ? Temp?.imageurl : Temp?.image_thumb_2048_inside) : Temp?.imageurl)
                    C.push((i + 1) + '/' + json.data.data[0].image_json.length + ((Temp.caption !== '' && Temp.caption !== undefined) ? ' :: ' + Temp.caption : ''))
                    T.push(json.data.data[0].name) */
                    /* setTimeout(() => {
                        var ____Lightimages = Lightimages.data
                        ____Lightimages[i] = imageS
                        setLightimages({ data: ____Lightimages })
                    }, 2000); */
                    json.data.data[0].image_json[i]['__index__'] = i
                    json.data.data[0].image_json[i]['caption'] = (json.data.data[0].image_json[i]['caption'] !== undefined && json.data.data[0].image_json[i]['caption'] !== 'undefined' && json.data.data[0].image_json[i]['caption'] !== '') ? json.data.data[0].image_json[i]['caption'] :  json.data.data[0].name
                })
                setImageGalleryData({ data: json.data.data[0].image_json })
                setLightimages({ data: I })
                setLightimagesCaption({ data: C })
                setLightimageTitle({ data: T })

                let BreadCrumbdata_ = [{
                    path: `${process.env.PUBLIC_URL}${GetSiteUrl(json.data.data[0].siteAccess, GlobalContent.AllSiteData)}/`,
                    linkName: 'Home',
                    status: '',
                }, {
                    path: `${process.env.PUBLIC_URL}${GetSiteUrl(json.data.data[0].siteAccess, GlobalContent.AllSiteData)}/${process.env.REACT_APP_NEWS_SLUG}`,
                    linkName: 'News',
                    status: '',
                }]
                if (newsSlug !== undefined && newsSlug !== null) {
                    await axios.get(`${process.env.REACT_APP_API_URL}news/slug/${newsSlug}`).then(json => {
                        setNewsDetailsData({ data: json.data.data })
                        if (json.data.data.length > 0) {
                            BreadCrumbdata_.push({
                                path: `${process.env.PUBLIC_URL}${GetSiteUrl(json.data.data[0].siteAccess, GlobalContent.AllSiteData)}/${process.env.REACT_APP_NEWS_SLUG}${json.data.data[0].slug}`,
                                linkName: 'Article'/* json.data.data[0].title */,
                                status: '',
                            })
                        }
                    })
                } else {
                    BreadCrumbdata_.push({
                        path: `${process.env.PUBLIC_URL}${GetSiteUrl(json.data.data[0].siteAccess, GlobalContent.AllSiteData)}/${process.env.REACT_APP_NEWS_SLUG}`,
                        linkName: 'Article Gallery',
                        status: '',
                    })
                }

                document.title = `${json.data.data[0].name}${GlobalContent.pageTitle}`

                /* if (window.innerWidth > 768) {
                    BreadCrumbdata_.push({
                        path: `${process.env.PUBLIC_URL}${GetSiteUrl(json.data.data[0].siteAccess, GlobalContent.AllSiteData)}/${process.env.REACT_APP_IMAGE_GALLERY_SLUG}${json.data.data[0].slug}`,
                        linkName: json.data.data[0].name,
                        status: '',
                    })
                } */
                setBreadCrumbdata({ data: BreadCrumbdata_ })
                setisPageLoad('yes')
            })
        })()
    }, [ImageGallerySlug, newsSlug]);

    const handleopen = (i: any) => {
        setisOpen(true)
        setphotoIndex(i)
    }
    return (
        <>
            {isPageLoad === 'yes' ? <>
                {/* <Banner BannerImage="Shreejibapa_1920.png" /> */}
                <MetaData keywords={`image, gallery, maninagar, swaminarayan, gadi, sansthan${(type === 'social-image-gallery') ? ', social, social image gallery' : ''}`} description={ImageGalleryContent?.data[0]?.name} />
                <section className="site-contain">
                    <div className="Flower-top d-none d-md-block">
                        <img src={process.env.PUBLIC_URL + '/img/Flower-top.png'} className="img-fluid lazyload" alt="Flower" title="Flower" />
                    </div>
                    {isOpen && (
                        <Lightbox
                            wrapperClassName='image-gallery-popup'
                            mainSrc={Lightimages.data[photoIndex]}
                            nextSrc={Lightimages.data[(photoIndex + 1) % Lightimages.data.length]}
                            prevSrc={Lightimages.data[(photoIndex + Lightimages.data.length - 1) % Lightimages.data.length]}
                            onCloseRequest={() => setisOpen(false)}
                            onMovePrevRequest={() => setphotoIndex((photoIndex + Lightimages.data.length - 1) % Lightimages.data.length)}
                            onMoveNextRequest={() => setphotoIndex((photoIndex + 1) % Lightimages.data.length)}
                            imageCaption={LightimagesCaption.data[photoIndex]}
                            imageTitle={LightimageTitle.data[photoIndex]}
                        /* reactModalStyle={customStyles} */
                        />
                    )}
                    <div className="container">
                        <BreadCrumb BreadCrumbdata={BreadCrumbdata.data} />
                        <div className="">
                            <SectionTitle title={`${(type === 'normal') ? 'Image Gallery' : 'Social Image Gallery'}`} />
                        </div>
                        {/* <div className="row mt-4 they-say-carousel">
                            <div className="col-md-6">
                                <SayingsImageAndText Image="imgresizer2 1.png" Text="" />
                            </div>
                            <div className="col-md-6 mt-3 mt-md-0">
                                <SayingsImageAndText Image="imgresizer (4) 1.png" Text="" />
                            </div>
                        </div> */}
                        <OnlyTitleBanner Text={ImageGalleryContent?.data[0]?.name} className="mt-5 mb-2" textAlign="text-center" />
                        <OnlySocialWithDate Date={``/* ImageGalleryContent?.data[0]?.date */} url={window.location.href} /* url={`${process.env.REACT_APP_SITE_URL}${BreadCrumbdata.data[BreadCrumbdata.data.length - 1].path}`} */ image="" title={ImageGalleryContent?.data[0]?.name} className="pb-2" Downloadable={(ImageGalleryContent?.data[0]?.skip_crop === 'yes') ? true : false} DownloadableFiles={ImageGalleryData?.data} />
                        <div className="mt-0">
                            <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 order-2 order-lg-1 they-say-carousel m-0">
                                <ImageWithTextMasonary Data={ImageGalleryData?.data} title={''} className="" handleopen={handleopen} Downloadable={(ImageGalleryContent?.data[0]?.skip_crop === 'yes') ? true : false} />
                            </div>
                        </div>
                    </div>
                </section>
            </> : (<PageLoader />)}
        </>
    )
}

export default ImageGallery