import React, { useContext, useEffect, useState } from "react";
// @ts-ignore
import ScrollAnimation from 'react-animate-on-scroll'
// @ts-ignore
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { HighLighttext, LazyImage, SectionTitle } from "./CommonSections";
import { Link } from "react-router-dom";
import axios from "axios";
import GlobalContext from '../Core/PageData'
import moment from "moment";
const options = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    responsive: [{
        breakpoint: 1200,
        settings: {
            slidesToShow: 3
        }
    }, {
        breakpoint: 1000,
        settings: {
            slidesToShow: 3
        }
    }, , {
        breakpoint: 991,
        settings: {
            slidesToShow: 2
        }
    }, {
        breakpoint: 600,
        settings: {
            slidesToShow: 1
        }
    }, {
        breakpoint: 0,
        settings: {
            slidesToShow: 1
        }
    }]
};

const News = ({ Data = {}, siteAccess = '', category = '', titleClass = '' }: any) => {
    const { GlobalContent, setGlobalContent } = useContext(GlobalContext as any) as any
    const [PopularNewsList, setPopularNewsList] = useState({ data: [] as any[] })
    var SiteSlug = ''
    var site = GlobalContent.AllSiteData.filter((e: any) => e._id === siteAccess)
    if (site.length > 0) {
        SiteSlug = site[0].site_url
    }
    useEffect(() => {
        let filters = [] as any

        filters.push(`recordPerPage=12`)
        filters.push(`sortField=publishOn`)
        filters.push(`sortType=desc`)

        if (siteAccess !== '') {
            filters.push(`siteAccess=${siteAccess}`)
        }
        if (category !== '') {
            filters.push(`category=${category}`)
        }

        axios.get(`${process.env.REACT_APP_API_URL}news/front?${filters.join('&')}`).then(json => {
            var newsA = [] as any
            json.data.data.data.forEach((news: any, k: any) => {
                newsA.push({ text: (news.title !== undefined) ? news.title : '', image: news.image, URL: `${process.env.PUBLIC_URL}/${news._slug ? news._slug : ''}`, publishOn: news.publishOn })
            })
            setPopularNewsList({ data: newsA })
        })
    }, [])

    return (
        <>
            {(PopularNewsList.data && PopularNewsList.data.length > 0) ?
                <section className="news pb-4">
                    <div className="container " >
                        <div className="row">
                            <SectionTitle title={`news`} type={titleClass} />
                            <div className="col-lg-12 pt-0 pt-lg-0 d-flex flex-column justify-content-center" >
                                <Slider className="news-carousel owl-nav-en" {...options} slidesToShow={(PopularNewsList.data.length > 3) ? 4 : PopularNewsList.data.length}>
                                    {PopularNewsList.data.map((Slide: any, i: any) => {
                                        //const d = new Date(Slide.publishOn)
                                        /* new Date(Slide.publishOn.split('T')[0]) */
                                        const d = moment(Slide.publishOn.split('T')[0])

                                        Slide['day'] = d.format('DD')
                                        Slide['month'] = d.format('MMM').toUpperCase()
                                        Slide['year'] = d.format('YYYY')

                                        Slide['postedBy'] = `${d.format('MMM').toUpperCase()} ${d.format('DD')}, ${d.format('YYYY')}`
                                        return (
                                            <>
                                                {/* <ScrollAnimation className="news-items" animateIn="zoomIn" animateOnce={true} delay={100} > */}
                                                <div className="news-items" key={`${i}-News`}>
                                                    <Link to={`${process.env.PUBLIC_URL}${Slide.URL}`} title={Slide.text} >
                                                        <div className="news-items-data">
                                                            <div className="position-relative">
                                                                {Slide.image !== '' && Slide.image !== undefined ?
                                                                    <LazyImage
                                                                        loadingSrc={`${process.env.PUBLIC_URL}/img/placeholder.png`}
                                                                        actualSrc={`${Slide.image}`}
                                                                        errorSrc={`${process.env.PUBLIC_URL}/img/placeholder.png`}
                                                                        width={`100%`}
                                                                        style={{ position: 'absolute', top: 0, left: 0, objectFit: 'cover', height: '100%', objectPosition: 'top' }}
                                                                        ContainerClass="border-radius-15px"
                                                                        imageWidth='370' imageHeight='255'
                                                                        title={Slide.text}
                                                                        alt={Slide.text}
                                                                    />
                                                                    : <div style={{ width: '100%', paddingBottom: 'calc(255 / 370 * 100%)', background: '#ffddbf', borderRadius: '15px' }}></div>}

                                                                <div className="site-date-pa d-flex flex-row justify-content-center">
                                                                    <span className="d-flex flex-column justify-content-center me-2 site-date-pa-day">{Slide.day}</span>
                                                                    <span className="d-flex flex-column justify-content-center">
                                                                        <span className="site-date-pa-month">{Slide.month}</span>
                                                                        <span className="site-date-pa-year">{Slide.year}</span>
                                                                    </span>
                                                                </div>
                                                            </div>
                                                            <div className="news-text">
                                                                {/* <span className="news-posted-by">Posted By <span className="news-time">Maninagar {Slide.postedBy}</span></span> */}
                                                                <p className="description mt-0 line-2-ellipsis">
                                                                    <HighLighttext searchWords={``} textToHighlight={Slide.text} />
                                                                </p>
                                                            </div>
                                                        </div>
                                                    </Link>
                                                </div>
                                                {/* </ScrollAnimation> */}
                                            </>
                                        )
                                    })}
                                </Slider>
                            </div>
                            <ScrollAnimation className="col-lg-12 text-center justify-content-center d-flex" animateIn="fadeIn" animateOnce={true} delay={100} >
                                <Link className="btn btn-primary mt-4" to={`${process.env.PUBLIC_URL}${(SiteSlug !== '') ? `/${SiteSlug}` : ``}/${process.env.REACT_APP_NEWS_SLUG}`} title="View More">View More</Link>
                            </ScrollAnimation>
                        </div>
                    </div>
                </section>
                : ''}
        </>
    )
}

export default News