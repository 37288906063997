import React, { useContext, useEffect, useState } from "react"
import Banner from './sections/Banner'
import BreadCrumb from "./sections/BreadCrumb"
import { SectionTitle, PageLoader, SayingsImageAndText, Counter, TabPanel, IMGDesc, Text100OR50, MetaData } from "./sections/CommonSections";

import GlobalContext from './Core/PageData'
import { Link, useNavigate, useParams } from "react-router-dom"
import axios from "axios";

import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css';
// @ts-ignore
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Sayings from "./sections/Sayings";
import News from "./sections/News";
// @ts-ignore
import Masonry, { ResponsiveMasonry } from "react-responsive-masonry"

const SliderOptions = {
    dots: true,
    arrows: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    draggable: true,
    autoplay: false,
    autoplaySpeed: 5000
};
const RecentSliderOptions = {
    dots: false,
    arrows: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    draggable: true,
    autoplay: true,
    autoplaySpeed: 5000,
    responsive: [{
        breakpoint: 1200,
        settings: {
            slidesToShow: 3
        }
    }, {
        breakpoint: 1000,
        settings: {
            slidesToShow: 3
        }
    }, , {
        breakpoint: 991,
        settings: {
            slidesToShow: 2
        }
    }, {
        breakpoint: 600,
        settings: {
            slidesToShow: 1
        }
    }, {
        breakpoint: 0,
        settings: {
            slidesToShow: 1
        }
    }]
};

const OurBand: React.FC<any> = ({ BandSlug }: any): any => {
    const { GlobalContent, setGlobalContent } = useContext(GlobalContext as any) as any
    document.title = `Pipe Band${GlobalContent.pageTitle}`;
    const navigate = useNavigate()
    //let { BandSlug } = useParams();
    const [isPageLoad, setisPageLoad] = useState('no')
    const [BreadCrumbdata, setBreadCrumbdata] = useState({ data: [] as any[] });

    const [AllPipeBand, setAllPipeBand] = useState({ data: [] as any[] });
    const [PipeBandData, setPipeBandData] = useState({ data: [] as any[] });
    const [UpcomingPerformance, setUpcomingPerformance] = useState({ data: [] as any[] });
    const [MemberType, setMemberType] = useState({ data: [] as any[] });

    const [SomeFacts, setSomeFacts] = useState({ data: [] as any[] });
    const [MembersData, setMembersData] = useState({ data: [] as any[] });
    const [TabData, setTabData] = useState({ tab_json: [{ cmsPageId: 'a', tabName: 'Uniform', desc: '' }, { cmsPageId: 'a', tabName: 'Repetoire', desc: '' }, { cmsPageId: 'a', tabName: 'Members', desc: '' }] as any[] });

    var NewsCategory = [
        { title: 'Bolton', id: '62e0cf26bd4256f61830d32b' },
        { title: 'London', id: '62e0cf26bd4256f61830d32a' },
        { title: 'Maninagar', id: '62e0cf26bd4256f61830d32d' },
        { title: 'Nairobi', id: '62e0cf26bd4256f61830d32c' },
        { title: 'USA', id: '62e0cf26bd4256f61830d329' },
        { title: 'Canada', id: '667d527c5ad3bbd146c0eb25' },
    ]
    useEffect(() => {
        (async () => {
            if (BandSlug === undefined || BandSlug === null) {
                navigate(`${process.env.PUBLIC_URL}/pipeband`, { replace: true })
            }
            setisPageLoad('no')
            setGlobalContent({ ...GlobalContent, IsMediaPlayershow: false })

            /* setSomeFacts({ data: [{ title: `Members`, count: `33`, desc: `Members` }, { title: `Members`, count: `33`, desc: `Members` }, { title: `Members`, count: `33`, desc: `Members` }, { title: `Members`, count: `33`, desc: `Members` }, { title: `Members`, count: `33`, desc: `Members5` }] }) */

            axios.get(`${process.env.REACT_APP_API_URL}pipeband/bands/all`).then(json => {
                setAllPipeBand({ data: json.data.data })
                var site = GlobalContent.AllSiteData.filter((e: any) => e.site_url === 'pipeband')
                setGlobalContent({ ...GlobalContent, siteData: site[0],headerClass:'' })
                return json.data.data
            }).then(async AllPipeBandTemp => {
                var BandId = ''
                BandId = AllPipeBandTemp.filter((e: any) => e.slug === BandSlug)[0]._id
                if (BandId !== '') {
                    let SomeFactsT = [] as any
                    var Performance = await axios.get(`${process.env.REACT_APP_API_URL}pipeband/performances/filter?sortField=createdDate&sortType=desc&page=1&recordPerPage=10&bands=${BandId}`).then(json => {
                        setUpcomingPerformance({ data: json.data.data.data })
                        return json.data.data
                    })

                    var MM = await axios.get(`${process.env.REACT_APP_API_URL}pipeband/member-type/all`).then(json => {
                        setMemberType({ data: json.data.data })
                        return json.data.data
                    })

                    var CounterApi = [] as any
                    CounterApi.push({ name: `Members`, api: `${process.env.REACT_APP_API_URL}pipeband/member/bands/${BandId}` })
                    Promise.all(CounterApi.map((endpoint: any) => axios.get(endpoint.api))).then((json) => {

                        json.forEach(async (dd: any, k: any) => {
                            /* console.log(dd.data.data, MM) */

                            if (CounterApi[k].name === 'Members') {
                                SomeFactsT.push({ title: CounterApi[k].name, count: dd.data.data.length, desc: CounterApi[k].name })

                                MM.forEach(async (memberTYpe: any, k: any) => {
                                    var count = dd.data.data.filter((E: any) => E.position === memberTYpe._id)
                                    if (count.length > 0) {
                                        SomeFactsT.push({ title: memberTYpe.name, count: count.length, desc: memberTYpe.name })
                                    }
                                })
                                if (Performance.total > 0) {
                                    SomeFactsT.push({ title: `Performance`, count: Performance.total, desc: `Performance` })
                                }

                                setMembersData({ data: dd.data.data })
                                TabData.tab_json[2].desc = <div className="ms-3 me-3">
                                    {/* <SectionTitle title={`Band Repetoire`} titleClassOrg={`mb-4`} type={`title-active-color`} /> */}
                                    <div className="row">
                                        {dd.data.data.length > 0 ? <>
                                            <div className="publication-track-table table-responsive">
                                                <table className="table">
                                                    <thead>
                                                        <tr>
                                                            <td>Member</td>
                                                            <td>Position</td>
                                                            <td>Joined</td>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {dd.data.data.map((mem: any, i: any) => {
                                                            return (
                                                                <tr>
                                                                    <td>{`${mem.fName} ${mem.lName}`}</td>
                                                                    <td>{(mem.position !== '' && mem.position !== undefined && mem.position !== 'undefined') ? MM.filter((e: any) => e._id === mem.position)[0].name : ''}</td>
                                                                    <td>{mem.joinedYear}</td>
                                                                </tr>
                                                            )
                                                        })}
                                                    </tbody>
                                                </table>
                                            </div>
                                        </> : ''}
                                    </div>
                                </div>
                            }
                        })
                        setSomeFacts({ data: SomeFactsT })
                    })

                    axios.get(`${process.env.REACT_APP_API_URL}pipeband/bands/${BandId}`).then(json => {
                        json.data.data[0]['sliderImages'] = []
                        if (json.data.data[0].image1 !== '') {
                            json.data.data[0]['sliderImages'].push({ image: json.data.data[0].image1, alt: json.data.data[0].image1_alt })
                        }
                        if (json.data.data[0].image2 !== '') {
                            json.data.data[0]['sliderImages'].push({ image: json.data.data[0].image2, alt: json.data.data[0].image2_alt })
                        }
                        if (json.data.data[0].image3 !== '') {
                            json.data.data[0]['sliderImages'].push({ image: json.data.data[0].image3, alt: json.data.data[0].image3_alt })
                        }
                        if (json.data.data[0].image4 !== '') {
                            json.data.data[0]['sliderImages'].push({ image: json.data.data[0].image4, alt: json.data.data[0].image4_alt })
                        }
                        if (json.data.data[0].video1 !== '') {
                            json.data.data[0]['sliderImages'].push({ image: json.data.data[0].video1, alt: '' })
                        }
                        if (json.data.data[0].video2 !== '') {
                            json.data.data[0]['sliderImages'].push({ image: json.data.data[0].video2, alt: '' })
                        }

                        setPipeBandData({ data: json.data.data })

                        TabData.tab_json[0].desc = <div className="ms-3 me-3">
                            <IMGDesc Data={{
                                dStyle: "",
                                desc: json.data.data[0].uniformDescription,
                                image: json.data.data[0].uniformImage,
                                title: "Pipeband Performance",
                                type: "img_cnt",
                            }} title={`Pipeband Performance`} className={'m-0'} titleClass={`title-active-color`} />
                        </div>
                        axios.get(`${process.env.REACT_APP_API_URL}pipeband/tunes-type/all`).then(json => {
                            return json.data.data
                        }).then(temp => {
                            axios.get(`${process.env.REACT_APP_API_URL}pipeband/tunes/bands/${BandId}`).then(json => {
                                json.data.data.forEach(async (dd: any, k: any) => {
                                    var Index = temp.findIndex((e: any) => e._id === dd.type)
                                    if (temp[Index]['tunes_play'] !== undefined) {
                                        temp[Index]['tunes_play'].push(dd)
                                    } else {
                                        temp[Index]['tunes_play'] = [dd]
                                    }
                                })
                                return temp
                            }).then(tempp => {
                                TabData.tab_json[1].desc = <div className="ms-3 me-3">
                                    <SectionTitle title={`Band Repetoire`} titleClassOrg={`mb-4`} type={`title-active-color`} />
                                    <div className="row">
                                        <ResponsiveMasonry columnsCountBreakPoints={{ 350: 1, 750: 2, 900: 3 }}>
                                            <Masonry gutter="25px">
                                                {tempp.length > 0 && tempp.map((tune: any, i: any) => {
                                                    return (
                                                        <>{
                                                            tune.tunes_play !== undefined && tune.tunes_play.length > 0 ? <div className="col-12">
                                                                <div className="border-with-bg-radius p-3 sidebar">
                                                                    <p className="title pb-3 font-size-16px">{tune.name}</p>
                                                                    <div className="sidebar-listing">
                                                                        {tune.tunes_play.map((tune1: any, i: any) => {
                                                                            return (<div className=" p-2 popular-news-details">
                                                                                <a className="d-flex flex-row justify-content-between" title={tune1.title}>
                                                                                    <span className="col font-size-14px">{tune1.title}</span>
                                                                                </a>
                                                                            </div>)
                                                                        })}
                                                                    </div>
                                                                </div>
                                                            </div> : ''
                                                        }</>
                                                    )
                                                })}
                                            </Masonry>
                                        </ResponsiveMasonry>
                                    </div>
                                </div>
                            })
                        })

                        let BreadCrumbdata_ = [
                            {
                                path: `${process.env.PUBLIC_URL}/pipeband`,
                                linkName: 'Home',
                                status: '',
                            }, {
                                path: `${process.env.PUBLIC_URL}/pipeband`,
                                linkName: 'Our Bands',
                                status: '',
                            }, {
                                path: `${process.env.PUBLIC_URL}/pipeband/ourbands/${BandSlug}`,
                                linkName: json.data.data[0].band,
                                status: '',
                            }]

                        setBreadCrumbdata({ data: BreadCrumbdata_ })
                        setisPageLoad('yes')
                    })
                } else {
                    navigate(`${process.env.PUBLIC_URL}/pipeband`, { replace: true })
                }
            })

        })()
    }, [BandSlug]);

    return (
        <>
            {isPageLoad === 'yes' ? <>
                {/* <Banner BannerImage="Shreejibapa_1920.png" /> */}
                <MetaData keywords={`band, maninagar, swaminarayan, gadi, sansthan`} description={document.title} />
                <section className="site-contain">
                    <div className="Flower-top d-none d-md-block">
                        <img src={process.env.PUBLIC_URL + '/img/Flower-top.png'} className="img-fluid lazyload" alt="Flower" title="Flower" />
                    </div>

                    <div className="container">
                        <BreadCrumb BreadCrumbdata={BreadCrumbdata.data} />
                        <div className="">
                            <SectionTitle title={`Shree Muktajeevan Swamibapa Pipe Band - ${PipeBandData.data[0].band}`} />
                        </div>

                        <div className="row mt-5 mb-4">
                            <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 cms-text-desc table-responsive" dangerouslySetInnerHTML={{ __html: PipeBandData.data[0].content }}></div>
                            <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                                <div className="sayings">
                                    <Slider className={`they-say-carousel owl-nav-en mt-0`} {...SliderOptions}>
                                        {PipeBandData.data[0].sliderImages.map((Slide: any, i: any) => {
                                            var Ia = Slide.image.split('.')
                                            var ImageType = Ia[Ia.length - 1]
                                            var isImage = (ImageType === 'jpg' || ImageType === 'png' || ImageType === 'jpeg') ? 'image' : 'video'
                                            return (<div className="banner-items h-100" key={`${i}-bannerSlider`}>
                                                <div style={{}}>
                                                    {isImage === 'image' ?
                                                        <SayingsImageAndText Image={`${Slide.image}`} Text={``} URL={``} imageWidth='370' imageHeight='200' />
                                                        : <div className="border-radius-15px overflow-auto" style={{ paddingBottom: `calc(200 / 370 * 100%)`, position: 'relative', width: '100%' }}><iframe src={`${Slide.image}`} style={{ position: 'absolute', top: 0, left: 0, objectFit: 'cover', height: '100%', width: '100%', objectPosition: 'top' }}></iframe></div>
                                                    }
                                                </div>
                                            </div>)
                                        })}
                                    </Slider>
                                </div>
                            </div>
                        </div>

                        {/* <div className="row mt-5 mb-4">
                            <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                                <News Data={{}} category={NewsCategory.filter((e: any) => e.title.toLowerCase() === BandSlug)[0].id} titleClass="" siteAccess={GlobalContent.siteData._id} />
                            </div>
                        </div> */}
                    </div>
                    {NewsCategory.filter((e: any) => e.title.toLowerCase() === BandSlug).length > 0 ?
                        <News Data={{}} category={NewsCategory.filter((e: any) => e.title.toLowerCase() === BandSlug)[0]?.id} titleClass="" siteAccess={GlobalContent.siteData._id} /> : ''}
                    <section className={`global-network pt-5 pb-5 ${NewsCategory.filter((e: any) => e.title.toLowerCase() === BandSlug).length === 0 ? `mt-5` : ``}`}>
                        <div className="container">
                            <div className="row">
                                <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                                    <Counter Data={SomeFacts.data} title={`Some Facts...`} className='' DataClass='' ContainerAlignClass={`justify-content-center`} />
                                </div>
                            </div>
                        </div>
                    </section>
                    <div className="container">
                        <div className="row mt-4 mb-4">
                            <div className="col-12 col-sm-12 col-md-9 col-lg-9 col-xl-9 tab-panel-container">
                                <TabPanel Data={TabData} Pagetype="element" />
                            </div>
                            <div className="col-12 col-sm-12 col-md-3 col-lg-3 col-xl-3 " style={{marginTop:'60px'}}>
                                {/* <SectionTitle title={`Contact`} type="title-active-color" />
                                <div>
                                    <p className="description"
                                        dangerouslySetInnerHTML={{
                                            __html: `Email : ${PipeBandData.data[0].email1}<br/>${PipeBandData.data[0].email2}<br/>${PipeBandData.data[0].contact1}<br/>${PipeBandData.data[0].telephone1}<br/>${PipeBandData.data[0].contact1}<br/>${PipeBandData.data[0].telephone1}`
                                        }}>
                                    </p>
                                </div> */}
                                <Text100OR50 Data={{
                                    dStyle: "sidebar",
                                    desc: `<p class="font-size-16px">Email : 
                                    <a href="mailto:${PipeBandData.data[0].email1}" title="${PipeBandData.data[0].email1}">${PipeBandData.data[0].email1}</a><br/>
                                    <a href="mailto:${PipeBandData.data[0].email2}" title="${PipeBandData.data[0].email2}">${PipeBandData.data[0].email2}</a><br/>
                                    ${PipeBandData.data[0].contact1}<br/>
                                    <a href="tel:${PipeBandData.data[0].telephone1}" title="${PipeBandData.data[0].telephone1}">${PipeBandData.data[0].telephone1}</a><br/>
                                    ${PipeBandData.data[0].contact2}<br/>
                                    <a href="tel:${PipeBandData.data[0].telephone2}" title="${PipeBandData.data[0].telephone2}">${PipeBandData.data[0].telephone2}</a></p>`,
                                    title: "Contact",
                                    type: "cnt_100",

                                }} title={`Contact`} className={`border-with-bg-radius p-3 mt-0`} titleClass={`title-active-color`} PlaceOfDesign={``} SectionType={`sidebar`} />
                            </div>
                        </div>
                    </div>
                </section>
            </> : (<PageLoader />)
            }
        </>
    )
}

export default OurBand