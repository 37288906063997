import React, { useContext, useEffect, useState } from "react"
import BreadCrumb from "./sections/BreadCrumb"
import { SectionTitle, LiveDarshanFilters, LiveDarshanSection, LiveDarshanSectionType2 } from "./sections/CommonSections";
import GlobalContext from './Core/PageData'
import { Link } from "react-router-dom"

const Darshan: React.FC = () => {
    const { GlobalContent, setGlobalContent } = useContext(GlobalContext as any) as any
    document.title = `Live Darshan${GlobalContent.pageTitle}`;
    const [BreadCrumbdata, setBreadCrumbdata] = useState({ data: [] as any[] });
    const [LiveDarshan, setLiveDarshan] = useState({ data: [] as any[] });
    const [LiveDarshanData, setLiveDarshanData] = useState({ data: [] as any[] });
    const [LordLiveDarshanData, setLordLiveDarshanData] = useState({ data: [] as any[] });
    const [DarshanTimings, setDarshanTimings] = useState({ data: [] as any[] });
    var sites = GlobalContent.AllSiteData.filter((e: any) => e.is_main === 'yes')
    useEffect(() => {
        setGlobalContent({ ...GlobalContent, siteData: sites[0], headerClass:'' })
        setGlobalContent({ ...GlobalContent, IsMediaPlayershow: false })
        setBreadCrumbdata({
            data: [
                {
                    path: `${process.env.PUBLIC_URL}`,
                    linkName: 'Home',
                    status: '',
                }, {
                    path: `${process.env.PUBLIC_URL}/darshan`,
                    linkName: 'Live Darshan',
                    status: '',
                }
            ]
        })
        setLiveDarshanData({
            data: [
                {
                    "image": "hk-rajbhog.png",
                    title: "HARIKRUSHNA MAHARAJ",
                    stream: 'https://stream.yr.com/live/kadi2.stream/playllist.m3u8'
                }, {
                    "image": "hk-rajbhog.png",
                    title: "LORD SWAMINARAYAN",
                    stream: 'https://stream.yr.com:443/live/maninagarsahajanand.stream/playlist.m3u8'
                }, {
                    "image": "hk-rajbhog.png",
                    title: "SAHAJANAND SWAMI",
                    stream: 'https://stream.yr.com/live/kadi2.stream/playllist.m3u8'
                }
            ]
        })
        setLordLiveDarshanData({
            data: [
                {
                    "image": "hk-rajbhog.png",
                    text: "HARIKRUSHNA MAHARAJ"
                }, {
                    "image": "hk-rajbhog.png",
                    text: "LORD SWAMINARAYAN"
                }, {
                    "image": "hk-rajbhog.png",
                    text: "SAHAJANAND SWAMI"
                }, {
                    "image": "hk-rajbhog.png",
                    text: "LORD SWAMINARAYAN"
                }, {
                    "image": "hk-rajbhog.png",
                    text: "SAHAJANAND SWAMI"
                }
            ]
        })
        setLiveDarshan({
            data: [
                {
                    name: 'Maninagar'
                }, {
                    name: 'Kadi'
                }, {
                    name: 'London'
                }, {
                    name: 'Bolton'
                }, {
                    name: 'New Jursey'
                }
            ]
        })
        setDarshanTimings({
            data:[
                {
                    time: '5.30 AM to 6.00 AM',
                    text: 'Mangala Aarti, Divine Darshan',
                },{
                    time: '5.30 AM to 6.00 AM',
                    text: 'Mangala Aarti, Divine Darshan',
                },{
                    time: '5.30 AM to 6.00 AM',
                    text: 'Mangala Aarti, Divine Darshan',
                },{
                    time: '5.30 AM to 6.00 AM',
                    text: 'Mangala Aarti, Divine Darshan',
                }
            ]
        })
    }, []);
    return (
        <>
            <section className="site-contain pb-5">
                <div className="container">
                    <BreadCrumb BreadCrumbdata={BreadCrumbdata.data} />
                    <SectionTitle title="Live Darshan" type="type-2" />
                    <LiveDarshanFilters FiltersCategoryData={LiveDarshan.data} title="" className="mt-5 mb-5" />
                    <LiveDarshanSection LiveDarshan={LiveDarshanData.data} title="" className="mt-5 mb-5" />
                    <LiveDarshanSectionType2 LiveDarshan={LordLiveDarshanData.data} DarshanTimingsData={DarshanTimings.data} title="LORD SWAMINARAYAN" className="mt-5 mb-5 border-with-bg-radius overflow-hidden" />
                </div>
            </section>

        </>
    )
}

export default Darshan