import React from "react"
import { Link } from 'react-router-dom'
// @ts-ignore
import ScrollAnimation from 'react-animate-on-scroll'
import { LimitDesc, PrintDesc, SectionTitle } from "./CommonSections";
import ReactTooltip from "react-tooltip";
const Guruparampara = ({ Data }: any) => {
    return (
        <>
            {(Data.image_json && Data.image_json.length > 0) ?
                <>
                    {Data.image_json.map((Slide: any, i: any) => {
                        return (
                            <ReactTooltip className="guruparampara-box-tooltip" id={`Guruparampara-popup-${i}`} effect="solid" clickable={true}>
                                <div className="d-flex flex-row bg-white border-radius-15px">
                                    <div className="p-3 col-6 d-flex flex-column justify-content-between" ><p className="guruparampara-box-tooltip-desc"><LimitDesc desc={Slide.s_desc} limit="400" /></p><span className="guruparampara-box-tooltip-view-more text-end"><Link to={`${process.env.PUBLIC_URL}${Slide.s_l_url}`} className="tooltip-view-more-a d-flex flex-row justify-content-start" title={Slide.s_title}><span className="tooltip-view-more-dot">OOO</span> View More</Link></span></div>
                                    <div className="col-6"><img src={Slide.imageurl} className="img-fluid w-100" alt={Slide.s_title} title={Slide.s_title} /></div>
                                </div>
                            </ReactTooltip>
                        )
                    })}
                    <section className="guruparampara">
                        <div className="container " >
                            <div className="row">
                                <SectionTitle title={Data.title} />
                            </div>
                            <div className="row">
                                {Data.image_json.map((Slide: any, i: any) => {
                                    return (
                                        <div className="col-lg-3 col-md-3 col-sm-6 col-6" key={`${i}-guruparampara`}>
                                            <ScrollAnimation className="guruparampara-box justify-content-center d-flex flex-row flex-wrap" animateIn="fadeIn" animateOnce={true} delay={100} >
                                                <div className="justify-content-center d-flex flex-row flex-wrap w-100">
                                                    {/* <div className="guruparampara-image justify-content-center d-flex" data-tip data-for={`Guruparampara-popup-${i}`} style={{ paddingBottom: 'calc(237 / 397 * 100%)' }}> */}
                                                        <Link className="guruparampara-image justify-content-center d-flex" data-tip data-for={`Guruparampara-popup-${i}`} style={{ paddingBottom: 'calc(237 / 397 * 100%)' }} to={`${process.env.PUBLIC_URL}${Slide.s_l_url}`} title={Slide.s_title}>
                                                            <img src={Slide.imageurl/* process.env.PUBLIC_URL + '/img/Mask Group.png' */} className="img-fluid lazyload w-80" alt={Slide.s_title} title={Slide.s_title} style={{ /* borderRadius: '50%', */ objectFit: 'cover', width: '100%', position: 'absolute', top: '0', left: '0', height: '100%' }} />
                                                        </Link>
                                                    {/* </div> */}
                                                    <Link to={`${process.env.PUBLIC_URL}${Slide.s_l_url}`} className="guruparampara-text description d-block text-center w-100" title={Slide.s_title}>
                                                        <PrintDesc desc={Slide.s_title} />
                                                        {/* ACHARYA SHREE JITENDRIYAPRIYADASJI SWAMIJI */}
                                                    </Link>
                                                </div>
                                            </ScrollAnimation>
                                        </div>
                                    )
                                })}
                            </div>
                        </div>
                    </section>
                </> : ''}
        </>
    )
}

export default Guruparampara