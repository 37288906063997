import { configureStore } from '@reduxjs/toolkit';
import { connect } from 'react-redux';
import playerReducer, { setPlayer } from './slices/player/playerSlice';
import playerListReducer, { setPlayerList } from './slices/playerList/playerListSlice';
import sessionReducer, { setSession } from './slices/session/sessionListSlice';


export const reduxConnect = (reducers = ['player', 'playerList','session']) => {
    const mapStateToProps = (state: { player: any; playerList: any; session: any}) => {
        const currentState = { player: '', playerList: '', session : '' };

        if (reducers.includes('player')) {
            currentState.player = state.player;
        }
        if (reducers.includes('playerList')) {
            currentState.playerList = state.playerList;
        }
        if (reducers.includes('session')) {
            currentState.session = state.session;
        }
        return currentState;
    };
    const propsFns = { setPlayer: setPlayer, setPlayerList: setPlayerList, setSession : setSession };

    if (reducers.includes('player')) {
        propsFns.setPlayer = setPlayer;
    }
    if (reducers.includes('playerList')) {
        propsFns.setPlayerList = setPlayerList;
    }
    if (reducers.includes('session')) {
        propsFns.setSession = setSession;
    }

    return connect(mapStateToProps, propsFns);
};

const reducer = {
    player: playerReducer,
    playerList: playerListReducer,
    session: sessionReducer
};
const store = configureStore({
    reducer: reducer,
    devTools: true,
});
export default store;
