import React, { useContext, useEffect, useState } from "react"
import { SectionTitle, PageLoader, LazyImage, MetaData } from "./sections/CommonSections";
import GlobalContext from './Core/PageData'
import axios from "axios";
import { useParams } from "react-router-dom";
import ScrollAnimation from "react-animate-on-scroll";
import { useFormik } from 'formik';
import * as Yup from 'yup'
import { reduxConnect } from "../redux/store";
import CacheManager from "../service/CacheManager";
import moment from "moment";
import { ToastContainer, toast, Slide } from 'react-toastify';



const Quiz = (props: any) => {
    let { pageSlug } = useParams()

    const { GlobalContent, setGlobalContent } = useContext(GlobalContext as any) as any
    const [isPageLoad, setisPageLoad] = useState('no')
    const [BreadCrumbdata, setBreadCrumbdata] = useState({ data: [] as any[] })
    const [Country, setCountry] = useState({ data: [] as any[] })
    const [Chapter, setChapter] = useState({ data: [] as any[] })
    const [TypeOfUser, setTypeOfUser] = useState('')
    const [UserId, setUserId] = useState('')
    const [Books, setBooks] = useState({ data: [] as any[] })
    const [PreviousBooks, setPreviousBooks] = useState({ data: [] as any[] })
    const [BookId, setBookId] = useState('')
    const [BookData, setBookData] = useState({ data: [] as any[] })
    const [IsStartQuiz, setIsStartQuiz] = useState('')
    const [IsPreviousQuiz, setIsPreviousQuiz] = useState('')
    /* const [Activityiframe, setActivityiframe] = useState('') */
    const [ChapterPdf, setChapterPdf] = useState('')
    const [CurrentActivity, setCurrentActivity] = useState({ data: [] as any[] })
    const [PreviousActivity, setPreviousActivity] = useState({ data: [] as any[], search: '' })
    const [CurrentGranthActivity, setCurrentGranthActivity] = useState({ data: [] as any[] })
    var sites = GlobalContent.AllSiteData.filter((e: any) => e.is_main === 'yes')
    const session = /* props.session */CacheManager.get('session')
    useEffect(() => {
        setisPageLoad('no')
        setGlobalContent({ ...GlobalContent, IsMediaPlayershow: false/* , siteAccess: '623beec8aeff82542fd87e55' */ })
        axios.get(`${process.env.REACT_APP_QUIZ_API_URL}user/country/all`).then(json => {
            setCountry({ data: json.data.countries_data })
            return json.data.countries_data
        }).then(Tempdata => {
            let BreadCrumbdata_ = [{
                path: `${process.env.PUBLIC_URL}/`,
                linkName: 'Home',
                status: '',
            }, {
                path: `${process.env.PUBLIC_URL}/quiz`,
                linkName: `Quiz`,
                status: '',
            }]
            document.title = `Quiz${GlobalContent.pageTitle}`;
            setBreadCrumbdata({ data: BreadCrumbdata_ })
            setGlobalContent({ ...GlobalContent, siteData: sites[0], headerClass: '' })
            setisPageLoad('yes')
        })
    }, [])
    useEffect(() => {
        axios.get(`${process.env.REACT_APP_QUIZ_API_URL}activity/book/`).then(async json => {
            const promises = [] as any;
            await json.data.data.map(async (e: any, index: any) => {
                const promise = axios.get(`${process.env.REACT_APP_QUIZ_API_URL}activity/chapter/${props.session.sessionData._id}/${e._id}`).then(async chapter_json => {
                    var aaaa = chapter_json.data.data.filter((e: any, ij : any) => {
                        if (e.status === 'active' && e.quiz_date !== '' && e.quiz_date !== undefined && e.quiz_date !== null && parseInt(moment(`${moment().format('YYYY-MM-DD')}T00:00:00`).format('x')) < parseInt(moment(e.quiz_date.split('T')[0] + 'T00:00:00').format('x'))) {
                            chapter_json.data.data[ij]['status2'] = 'upcoming'
                            return 'upcoming'
                        } else if (e.status === 'active' && e.quiz_date !== '' && e.quiz_date !== undefined && e.quiz_date !== null && parseInt(moment(`${moment().format('YYYY-MM-DD')}T00:00:00`).format('x')) === parseInt(moment(e.quiz_date.split('T')[0] + 'T00:00:00').format('x'))) {
                            chapter_json.data.data[ij]['status2'] = true
                            return true
                        } else {
                            chapter_json.data.data[ij]['status2'] = false
                            return false
                        }
                    })
                    json.data.data[index]['status'] = (aaaa.length > 0 && aaaa.filter((v:any) => (v.status2 === 'upcoming')).length == aaaa.length) ? 'upcoming' : ((aaaa.length > 0) ? true : false)
                    return (aaaa.length > 0) ? true : false
                })
                promises.push(promise);
            })
            Promise.all(promises).then(dsa => {
                setBooks({ data: json.data.data.filter((e: any) => e.status === true) })
                setPreviousBooks({ data: json.data.data.filter((e: any) => !e.status) })
            })
            return json.data.data
        })
    }, [props.session.IsSessionIn])

    useEffect(() => {
        if (IsStartQuiz === 'yes') {
            /* var CurrentActivityFindIndex = Chapter.data.findIndex((e: any) => e.current_activity === 1)
            var CurrentActivity = Chapter.data[CurrentActivityFindIndex + 1] */

            if (CurrentActivity.data.length > 0) {
                var UserActivity = {
                    "activity_id": CurrentActivity.data[0]._id,
                    "book_id": CurrentActivity.data[0].book_id,
                    "user_id": props.session.sessionData._id,
                    "status": "active"
                }
                setCurrentActivity({ data: [CurrentActivity.data[0]] })
                /* setActivityiframe(CurrentActivity.data[0].activity_iframe) */
                if (CurrentActivity.data[0].activity_iframe !== '' && CurrentActivity.data[0].activity_iframe !== undefined) {
                    if (IsPreviousQuiz === 'yes') {
                        axios.post(`${process.env.REACT_APP_QUIZ_API_URL}user-new-activity`, UserActivity).then(function (response) { })
                    } else {
                        axios.post(`${process.env.REACT_APP_QUIZ_API_URL}user-new-activity`, UserActivity).then(function (response) { })
                    }
                }
            } else {
                setIsStartQuiz('')
                setIsPreviousQuiz('')
                /* alert('There is no quiz or You answered all quiz!') */
                toast.error('You have already answered all questions Today. You should try again tomorrow for next quiz.', {
                    position: "bottom-center",
                    autoClose: 7000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: false,
                    progress: undefined,
                    theme: "colored",
                    transition: Slide
                });
            }
        }
    }, [IsStartQuiz])
    const handleCodeChange = (Code: any) => {
        formik.setFieldValue('countryCode', Code)
        handleMobileChange(formik.getFieldProps('contactNo').value, Code)
    }
    const handleMobileChange = (MobileNo: any, countryCode = '') => {
        formik.setFieldValue('contactNo', MobileNo)
        if (MobileNo !== '') {
            axios.get(`${process.env.REACT_APP_QUIZ_API_URL}user/contact/${(countryCode !== '') ? countryCode : formik.getFieldProps('countryCode').value}/${MobileNo}`).then(json => {
                if (json.data.status === 0) {
                    setTypeOfUser('post')
                    /* formik.setFieldValue('name', '') */
                    formik.setFieldValue('terms', false)
                } else {
                    setTypeOfUser('')
                    setUserId(json.data.data[0]._id)
                    /* formik.setFieldValue('name', json.data.data[0].name) */
                    formik.setFieldValue('terms', true)
                }
            }).catch(function (err) {
                setTypeOfUser('')
                /* formik.setFieldValue('name', '') */
                formik.setFieldValue('terms', false)
            })
        } else {
            setTypeOfUser('')
            /* formik.setFieldValue('name', '') */
            formik.setFieldValue('terms', false)
        }
    }
    const Logout = () => {
        setCurrentActivity({ data: [] })
        CacheManager.set({ key: "session", value: { sessionData: {}, IsSessionIn: 'no' } });
        props.setSession({ sessionData: {}, IsSessionIn: 'no' });
    }

    const findPos = (obj: any) => {
        var curtop = 0;
        if (obj.offsetParent) {
            do {
                curtop += obj.offsetTop;
            } while (obj = obj.offsetParent);
            return [curtop];
        }
    }
    const SelectPreviousQuizViewPdf = (Activity: any) => {
        axios.get(`${process.env.REACT_APP_API_URL}publication/book/${Activity.book_id}`).then(async json => {
            var PDF = json.data.data[0].book_file
            if(Activity.chapter_id !== undefined && Activity.chapter_id !== ''){
                PDF = await axios.get(`${process.env.REACT_APP_API_URL}publication/book-chapter-info/${Activity.book_id}/${Activity.chapter_id}/`).then(Chapterjson => {
                    if (Chapterjson.data.data.length > 0) {
                        return (Chapterjson.data.data[0].chapter_url !== '' && Chapterjson.data.data[0].chapter_url !== undefined && Chapterjson.data.data[0].chapter_url !== 'undefined') ? Chapterjson.data.data[0].chapter_url : json.data.data[0].book_file
                    }
                    return json.data.data[0].book_file
                })
            }
            setCurrentActivity({ data: [Activity] })
            if (Activity.show_pdf === 'active') {
                setChapterPdf(PDF)
            } else {
                StartQuiz()
            }
            setIsPreviousQuiz('yes')

        })
        window.scrollTo(0, 0);
    }
    const SelectBook = (BookId: any) => {

        axios.get(`${process.env.REACT_APP_QUIZ_API_URL}activity/chapter/${props.session.sessionData._id}/${BookId}`).then(async json => {
            setIsPreviousQuiz('')
            setChapter({ data: json.data.data })
            json.data.data = json.data.data.filter((e: any) => e.status === 'active')
            var CurrentActivityFindIndex = json.data.data.findIndex((e: any) => e.current_activity === 1)
            var CurrentActivity = json.data.data.filter((e: any) => e.quiz_date !== null && e.quiz_date !== undefined && e.quiz_date.split('T')[0] === moment().format('YYYY-MM-DD'))
            if (CurrentActivity !== undefined) {
                var CurrentDate = moment().format('YYYY-MM-DD')
                await axios.get(`${process.env.REACT_APP_QUIZ_API_URL}user-activity/${props.session.sessionData._id}/${BookId}`).then(async json => {
                    await axios.get(`${process.env.REACT_APP_QUIZ_API_URL}activity/chapter-list/${BookId}`).then(chapterJson => {
                        chapterJson.data.data = chapterJson.data.data.filter((e: any) => {
                            if (e.quiz_date === '' || e.quiz_date === undefined || e.quiz_date === null) {
                                return true
                            }
                            if (parseInt(moment(`${moment().format('YYYY-MM-DD')}T00:00:00`).format('x')) > parseInt(moment(e.quiz_date.split('T')[0] + 'T00:00:00').format('x'))) {
                                return true
                            }
                        })
                        chapterJson.data.data.map((e: any, i: any) => {
                            chapterJson.data.data[i]['quizFillStatus'] = (json.data.data.findIndex((e: any) => e.activity_id === chapterJson.data.data[i]['_id']) > -1) ? true : false
                        })
                        /* chapterJson.data.data = chapterJson.data.data.sort((a: any, b: any) => (a.createdDate > b.createdDate) ? 1 : -1) */
                        chapterJson.data.data = chapterJson.data.data.sort((a: any, b: any) => (a.quiz_date > b.quiz_date) ? 1 : -1)
                        setPreviousActivity({ ...PreviousActivity, data: chapterJson.data.data })
                    })
                    if (json.data.data.length > 0) {
                        if (json.data.data[json.data.data.length - 1].updatedDate === undefined) {
                            var CurrentActivityDate = moment(json.data.data[json.data.data.length - 1].createdDate).format('YYYY-MM-DD')
                        } else {
                            var CurrentActivityDate = moment(json.data.data[json.data.data.length - 1].updatedDate).format('YYYY-MM-DD')
                        }

                        /* if (CurrentActivityDate !== CurrentDate) {
                            setCurrentActivity({ data: CurrentActivity })
                        } else {
                            setCurrentActivity({ data: [] })
                        } */
                        setCurrentActivity({ data: CurrentActivity })
                    } else {
                        setCurrentActivity({ data: CurrentActivity })
                    }
                })
            } else {
                setCurrentActivity({ data: [] })
            }
            setCurrentGranthActivity({ data: [json.data.data[json.data.data.length - 1]] })
            setBookId(BookId)
            return { bookId: BookId, chapter: (CurrentActivity !== undefined) ? CurrentActivity : json.data.data }
        }).then(Data => {
            axios.get(`${process.env.REACT_APP_API_URL}publication/book/${Data.bookId}`).then(async json => {
                setBookData({ data: json.data.data })
                var PDF = json.data.data[0].book_file
                if(Data.chapter[0]?.chapter_data._id !== undefined && Data.chapter[0]?.chapter_data._id !== ''){
                    PDF = await axios.get(`${process.env.REACT_APP_API_URL}publication/book-chapter-info/${Data.bookId}/${Data.chapter[0]?.chapter_data._id}/`).then(Chapterjson => {
                        if (Chapterjson.data.data.length > 0) {
                            return (Chapterjson.data.data[0].chapter_url !== '' && Chapterjson.data.data[0].chapter_url !== undefined && Chapterjson.data.data[0].chapter_url !== 'undefined') ? Chapterjson.data.data[0].chapter_url : json.data.data[0].book_file
                        }
                        return json.data.data[0].book_file
                    })
                }
                if (Data.chapter[0]?.show_pdf === 'active') {
                    setChapterPdf(PDF)
                } else {
                    setChapterPdf('')
                }
            })
        })
    }
    const StartQuiz = () => {
        setIsStartQuiz('yes')
        window.scrollTo(0, 0);
    }

    const formik = useFormik({
        initialValues: {
            countryCode: (Country.data[0] !== undefined) ? Country.data[0].id : '',
            contactNo: '',
            /* name: '', */
            /* password: '', */
            terms: false
        },
        enableReinitialize: true,
        validationSchema: Yup.object({
            contactNo: Yup.string().required('Mobile No is required').min(9, 'Must be minimum 9 digits').max(10, 'Must be maximum 10 digits').matches(/^[0-9]+$/, "Must be only digits"),
            /* name: Yup.string().required('Name is required').matches(/^[aA-zZ\s]+$/, "Only alphabets are allowed for this field "), */
            /* password: Yup.string().required('Password is required'), */
            terms: Yup.boolean().oneOf([true], "You must accept the terms and conditions")
        }),
        onSubmit: (values: any) => {
            values['status'] = 'active'
            if (TypeOfUser === 'post') {
                delete values._id
                delete values.terms
                axios.post(`${process.env.REACT_APP_QUIZ_API_URL}user`, values).then(function (response) {
                    values['_id'] = response.data.user_data[0]._id
                    CacheManager.set({ key: "session", value: { sessionData: values, IsSessionIn: 'yes' } });
                    props.setSession({ sessionData: values, IsSessionIn: 'yes' });
                    setBookId('')
                    formik.resetForm()
                }).catch(function (err) {
                    return err.response.data
                })
            } else {
                /* axios.put(`${process.env.REACT_APP_QUIZ_API_URL}user/${UserId}`, values).then(function (response) { */
                //values['_id'] = UserId
                delete values.terms
                axios.post(`${process.env.REACT_APP_QUIZ_API_URL}user/login`, values).then(function (response) {
                    if (response.data.status === 1) {
                        values['_id'] = response.data.user_data[0]._id
                        CacheManager.set({ key: "session", value: { sessionData: values, IsSessionIn: 'yes' } });
                        props.setSession({ sessionData: values, IsSessionIn: 'yes' });
                        setBookId('')
                        formik.resetForm()
                    } else {
                        alert('Invalid Login credentials!');
                    }
                }).catch(function (err) {
                    return err.response.data
                })
            }
        }
    })
    return (
        <>
            {isPageLoad === 'yes' ? <>
                <ToastContainer />
                {/* <Banner BannerImage="Shreejibapa_1920.png" /> */}
                <MetaData keywords={`quiz, maninagar, swaminarayan, gadi, sansthan`} description={document.title} />
                <section className={`site-contain`}>
                    <div className="container">
                        {/* <BreadCrumb BreadCrumbdata={BreadCrumbdata.data} /> */}
                        <SectionTitle title={`Quiz`} type="" />
                    </div>

                    <div className="container quiz-container mt-5">
                        {props.session.IsSessionIn === 'yes' ? <div className="d-flex flex-column text-center">
                            <div className="text-center dark-active-color">Jay Shree Swaminarayan {props.session.sessionData.name}</div>
                            <div><span onClick={Logout} className="cursor-pointer" style={{ fontWeight: '700' }}>Logout</span></div>
                            {IsStartQuiz === 'yes' ? <>
                                <span className="cursor-pointer" onClick={() => {
                                    setIsStartQuiz('')
                                    setBookId('')
                                    setChapterPdf('')
                                    setIsPreviousQuiz('')
                                }} style={{ fontWeight: '700' }}>Back</span>
                                {CurrentActivity.data.length > 0 ? <>
                                    <SectionTitle title={CurrentActivity.data[0].activity_title} titleClassOrg={`mt-3 mb-4`} type={`title-active-color`} />
                                    {/* {CurrentActivity.data[0].activity_iframe !== '' && CurrentActivity.data[0].activity_iframe !== undefined ? <iframe src={CurrentActivity.data[0].activity_iframe} className="w-100 mt-3 border-with-bg-radius" style={{ height: '70vh' }}></iframe> : <QuizQuestions activity={CurrentActivity.data[0]} session={props.session} NextTimer={`` as any} />} */}
                                    {props.type === 'live' ?
                                        <>
                                            {CurrentActivity.data[0].activity_iframe !== '' && CurrentActivity.data[0].activity_iframe !== undefined ?
                                                <iframe src={CurrentActivity.data[0].activity_iframe} className="w-100 mt-3 border-with-bg-radius" style={{ height: '70vh' }}></iframe> :

                                                (CurrentActivity.data[0].quiz_json !== undefined && CurrentActivity.data[0].quiz_json.length > 0) ? <QuizQuestions activity={CurrentActivity.data[0]} session={props.session} NextTimer={`` as any} /> : <div className="text-center p-3">No Question Found!</div>}
                                        </> :

                                        (CurrentActivity.data[0].quiz_json !== undefined && CurrentActivity.data[0].quiz_json.length > 0) ? <QuizQuestions activity={CurrentActivity.data[0]} session={props.session} NextTimer={`` as any} /> : <div className="text-center p-3">No Question Found!</div>}
                                </> : ''}
                            </> :
                                <>
                                    <SectionTitle title={`Select Granth to start Quiz`} titleClassOrg={`mt-2 mb-0`} type={`title-active-color`} />
                                    <div className="row publication-data-container mt-2 mt-lg-2 justify-content-center">
                                        {Books.data && Books.data.length > 0 ?
                                            (
                                                <>
                                                    {BookId !== '' ?
                                                        <>
                                                            <span className="cursor-pointer d-flex flex-column" onClick={() => {
                                                                setBookId('')
                                                                setChapterPdf('')
                                                            }} style={{ fontWeight: '700' }}>Back</span>
                                                        </> : ''}
                                                    <p className="description font-family-gujarati text-center mt-2">ગ્રંથનું વાંચન કરવા માટે પુસ્તક ઉપર ક્લિક કરો અને વાંચન કર્યા બાદ પ્રશ્નોત્તરી માટે Start Quiz ઉપર ક્લિક કરો.</p>
                                                    {Books.data.map((data: any, i: any) =>
                                                        <BookView key={i} BookId={BookId} data={data} SelectBook={SelectBook} />
                                                    )}
                                                </>
                                            ) : ''}

                                        {PreviousBooks.data && PreviousBooks.data.length > 0 ?
                                            (
                                                <>
                                                    {BookId === '' ?
                                                        <SectionTitle title={`Previous Granth Quiz`} colClass='col-lg-12 mt-4 mb-3' /> : ''}
                                                    {PreviousBooks.data.map((data: any, i: any) =>
                                                        <BookView key={i} BookId={BookId} data={data} SelectBook={SelectBook} />
                                                    )}
                                                </>
                                            ) : ''}
                                    </div>
                                    {ChapterPdf !== '' && CurrentActivity.data.length > 0 ?
                                        <div className="d-flex flex-column mt-4" id="granthPdf">
                                            {/* <SectionTitle title={`${CurrentGranthActivity.data.length > 0 ? `Current Chapter Quiz: ${CurrentGranthActivity.data[0].activity_title}` : ''}`} titleClassOrg={`mt-3 mb-0`} type={`small-title`} />
                                            {CurrentActivity.data.length > 0 ? <>
                                                <SectionTitle title={`${CurrentActivity.data.length > 0 ? `You are on: ${CurrentActivity.data[0].activity_title}` : ''}`} titleClassOrg={`mt-3 mb-0`} type={`small-title`} />
                                            </> : ''} */}
                                            {CurrentActivity.data.length > 0 ? <>
                                                {/* <SectionTitle title={CurrentActivity.data[0].activity_title} titleClassOrg={`mt-3 mb-0`} type={`title-active-color`} /> */}
                                                <iframe className="mt-4 mb-4" style={{ width: '100%', height: '80vh' }} src={`${process.env.REACT_APP_SITE_URL}pdfjs/web/viewer.html?file=${ChapterPdf}`}></iframe>
                                            </> : ''}
                                        </div>
                                        : ''}
                                        
                                    {BookId !== '' && (ChapterPdf !== '' || CurrentActivity.data[0]?.show_pdf === undefined || CurrentActivity.data[0]?.show_pdf === 'inactive') && CurrentActivity.data.length > 0 ?
                                        <div className="d-flex flex-row justify-content-center mt-4">
                                            {/* <button className={`btn btn-primary ${BookId !== '' ? '' : 'disabled'}`} type="submit" onClick={StartQuiz}>Start Quiz</button> */}
                                            <button className={`btn btn-primary`} type="submit" onClick={StartQuiz}>Start Quiz</button>
                                        </div> : ''}
                                    {(() => {
                                        let PrevoiusRender = [] as any
                                        BookId !== '' && BookData.data.length > 0 && PreviousActivity.data.length > 0 && PreviousActivity.data.map((e: any) => {

                                            /* PrevoiusRender.push(<tr>
                                                <td className="text-start" valign="middle">{___[0]['activity_title']}</td>
                                                <td valign="middle"><button className={`btn btn-2`} style={{ padding: '7px', fontSize: '15px' }} type="submit" onClick={StartQuiz}>Start Quiz</button></td>
                                            </tr>) */
                                            if (PreviousActivity.search === '' || e.activity_title.match(new RegExp(PreviousActivity.search, "i"))) {
                                                PrevoiusRender.push(<div className={`col-6 col-sm-6 col-md-6 col-lg-6 col-xl-2 mt-3 mt-lg-3`}>
                                                    <div className="d-flex h-100 flex-column publication-data quiz-data">
                                                        {e.quizFillStatus ? <div className={`quiz-book-seleted active`} style={{ top: '0px', right: '0px' }} title="Completed"><svg fill="#000000" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 64 64" width="32px" height="32px"><path d="M32,6C17.641,6,6,17.641,6,32c0,14.359,11.641,26,26,26s26-11.641,26-26C58,17.641,46.359,6,32,6z M29,42L18,31l2-3l9,6 l13.957-12L46,25L29,42z" /></svg></div> : ''}
                                                        <p className="mt-3 mt-md-2 mb-2 active-color font-size-16px">{e.activity_title}</p>
                                                        {e.show_pdf === 'active' ?
                                                            <button className={`btn btn-2`} style={{ padding: '7px', fontSize: '15px', minWidth: 'auto' }} type="submit" onClick={() => SelectPreviousQuizViewPdf(e)}>View Pdf</button>
                                                            : <button className={`btn btn-primary`} style={{ padding: '7px', fontSize: '15px', minWidth: 'auto' }} type="submit" onClick={() => SelectPreviousQuizViewPdf(e)}>Start Quiz</button>}
                                                    </div>
                                                </div>)
                                            }
                                        })
                                        return (BookId !== '' && BookData.data.length > 0) ? <div className="w-100 d-block mt-5 pt-3 pb-3">
                                            <SectionTitle title={`Previous Quiz`} />
                                            <div className="d-flex flex-row justify-content-end mt-3">
                                                <div className="col-12 col-md-3">
                                                    <input type="text" className="form-control" placeholder="Search..." onChange={(e) => {
                                                        setPreviousActivity({ ...PreviousActivity, search: e.target.value })
                                                    }} />
                                                </div>
                                            </div>

                                            <div className="row publication-data-container mt-3 mt-lg-3 justify-content-center">
                                                {(PrevoiusRender.length > 0) ? PrevoiusRender : <div>No Quiz Found</div>}
                                            </div>
                                            {/* <table className="table">
                                                <thead>
                                                    <tr>
                                                        <th colSpan={2}>Title</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {PrevoiusRender}
                                                </tbody>
                                            </table> */}
                                        </div> : ''
                                    })()}
                                </>}

                        </div> :
                            <>
                                {/* <p className="description">With the inspiration of Shree Jitendriyapriyadasji Swamiji Maharaj, the sixth heir to the throne of Sri Swaminarayan, Gnanamahoddhi Acharya, these supreme books should be read daily for the purpose of establishing the glory and knowledge of Sri Swaminarayan Bhagwan, who is supreme in life and the glory and knowledge of Lord Shree Swaminarayan and the unique feeling about Him. And after reading this questioning has been presented for the purpose of attaining the highest devotion in the living being by means of easy and simple questioning.</p> */}
                                <p className="description font-family-gujarati">શ્રી સ્વામિનારાયણ ગાદીના ષષ્ઠ વારસદાર જ્ઞાનમહોદધિ આચાર્ય શ્રી જિતેન્દ્રિયપ્રિયદાસજી સ્વામીજી મહારાજની પ્રેરણાથી મુમુક્ષુઓને જીવનમાં સર્વોપરી સદાય પ્રગટ અને પ્રત્યક્ષ શ્રી સ્વામિનારાયણ ભગવાનનો જ મહિમા ને જ્ઞાન અને તેમને જ વિશે અનન્યભાવે પતિવ્રતાની ભક્તિ, ધર્મ, આસ્થા ને વિશ્વાસ સ્થિર કરવા હેતુસર આ સર્વોપરી ગ્રંથોનું નિત્ય વાંચન થાય અને વાંચન કર્યા બાદ સહજ અને સરળ પ્રશ્નોત્તરીના માધ્યમથી જીવાત્મામાં એક સર્વોત્તમ નિષ્ઠા દ્રઢ થાય એજ ધ્યેય, લક્ષ અને કેવળ શ્રી સ્વામિનારાયણબાપા સ્વામીબાપાની પ્રસન્નતા પ્રાપ્ત કરી શકાય તે માટે આ પ્રશ્નોત્તરીની રજુઆત કરવામાં આવી છે.</p>
                                <div className="d-flex flex-row justify-content-center">
                                    <form onSubmit={formik.handleSubmit} noValidate className="col-12 col-sm-12 col-md-12 col-lg-6 col-xl-4 justify-content-center">
                                        <div className="d-flex flex-column">
                                            <p className="text-center">Login / Signup</p>
                                            <div className="d-flex flex-row mb-4">
                                                <div className="col-4 col-md-3">
                                                    <select className="form-control with-bg border-radius-top-right border-radius-bottom-right" {...formik.getFieldProps('countryCode')} onChange={(e) => {
                                                        handleCodeChange(e.target.value)
                                                    }} style={{ textTransform: 'none' }}>
                                                        {Country.data && Country.data.length > 0 && Country.data.map((data: any, i: any) => (
                                                            <option key={i} value={data.country_code}>{data.country_code_display} {`(${data.country_name})`}</option>
                                                        ))}
                                                    </select>
                                                </div>
                                                <div className="col border-radius-top-left border-radius-bottom-left position-relative">
                                                    <input
                                                        type='text'
                                                        className='border-radius-top-left border-radius-bottom-left form-control h-100'
                                                        placeholder="Mobile No"
                                                        {...formik.getFieldProps('contactNo')}
                                                        onChange={(e) => handleMobileChange(e.target.value)}

                                                    />
                                                    {formik.touched.contactNo && formik.errors.contactNo ? <span className="text-danger font-size-14px" style={{ position: 'absolute' }}>{formik.errors.contactNo}</span> : ''}
                                                </div>
                                            </div>
                                            {/* <div className="d-flex flex-row mb-4 position-relative">
                                            <input
                                                type='text'
                                                className='form-control h-100'
                                                placeholder="Name"
                                                {...formik.getFieldProps('name')}
                                                disabled={(TypeOfUser === '') ? true : false}
                                            />
                                            {formik.touched.name && formik.errors.name ? <span className="text-danger font-size-14px" style={{ position: 'absolute', top: '100%' }}>{formik.errors.name}</span> : ''}
                                        </div> */}
                                            {/* <div className="d-flex flex-row mb-4 position-relative">
                                            <input
                                                type='password'
                                                className='form-control h-100'
                                                placeholder="Password"
                                                {...formik.getFieldProps('password')}
                                                /* disabled={(TypeOfUser === '') ? true : false} *
                                            />
                                            {formik.touched.password && formik.errors.password ? <span className="text-danger font-size-14px" style={{ position: 'absolute', top: '100%' }}>{formik.errors.password}</span> : ''}
                                        </div> */}
                                            <div className="custom02 mb-3">
                                                <div className="checkbox justify-content-between d-flex flex-column">
                                                    <div className="">
                                                        <input type="checkbox" id="terms" {...formik.getFieldProps('terms')} checked={formik.getFieldProps('terms').value} />
                                                        <label htmlFor="terms">I have read and agreed to the <a target={`_blank`} title="Terms & condition" href={`${process.env.PUBLIC_URL}/terms-conditions`}>Terms & conditions</a></label>
                                                    </div>
                                                </div>
                                                {formik.touched.terms && formik.errors.terms ? <span className="text-danger font-size-14px">{formik.errors.terms}</span> : ''}
                                            </div>
                                        </div>
                                        <div className="d-flex flex-row justify-content-center">
                                            <button className="btn btn-primary" type="submit">Submit</button>
                                        </div>
                                    </form>
                                </div>
                            </>}
                        {/* </Formik> */}
                    </div>
                </section>
            </> : <PageLoader />
            }
        </>
    )
}

const QuizQuestions = (props: any) => {

    let StartQuizSound = new Audio(`${process.env.PUBLIC_URL}/sound/startQuiz.mp3`)
    StartQuizSound.volume = 0.3
    let CorrectSound = new Audio(`${process.env.PUBLIC_URL}/sound/correct.mp3`)
    CorrectSound.volume = 0.3
    let IncorrectSound = new Audio(`${process.env.PUBLIC_URL}/sound/incorrect.mp3`)
    IncorrectSound.volume = 0.3

    const [QuizData, setQuizData] = useState({ start: false, startTime: '', endTime: '', timer: '', showSubmit: false, totalAttend: 0, totalRightAttend: 0, NextTimer: '' as any })
    const [QuizDataTemp, setQuizDataTemp] = useState({ showSubmitClass: 'QuizOver' })
    const [Questions, setQuestions] = useState({
        currentQuestion: 0,
        previous: false,
        next: true,
        readyForNextPrevQue: true,
        data: [
            {
                id: '1',
                question: 'First Question?',
                option: [{
                    name: 'A',
                    value: 'First'
                }, {
                    name: 'B',
                    value: 'Second Second Second Second Second Second'
                }, {
                    name: 'C',
                    value: 'Third'
                }, {
                    name: 'D',
                    value: 'Fourth'
                }],
                rightAnswer: 'B',
                userAnswer: ''
            }
        ] as any[]
    })

    useEffect(() => {
        var QuestionForActivity = [] as any;
        props.activity.quiz_json = props.activity.quiz_json.sort((a: any, b: any) => (a.position > b.position) ? 1 : -1)
        props.activity.quiz_json.map((e: any, index: any) => {
            var ValueLength = [e.s_a_answer.length, e.s_b_answer.length, e.s_c_answer.length, e.s_d_answer.length]
            var classValue = (Math.max(...ValueLength) > 30) ? 'col-12 col-md-6 mb-3 mb-md-3' : 'col-6 col-md-3 mb-3 mb-md-0'
            QuestionForActivity.push({
                id: `${index + 1}`,
                question: e.s_questions,
                option: [{
                    name: 'A',
                    value: e.s_a_answer,
                    classValue: classValue
                }, {
                    name: 'B',
                    value: e.s_b_answer,
                    classValue: classValue
                }, {
                    name: 'C',
                    value: e.s_c_answer,
                    classValue: classValue
                }, {
                    name: 'D',
                    value: e.s_d_answer,
                    classValue: classValue
                }],
                rightAnswer: e.s_answer.toUpperCase(),
                userAnswer: ''
            })
        })
        setQuestions({ ...Questions, data: QuestionForActivity })
    }, [props])
    useEffect(() => {
        if (Questions.currentQuestion === 0) {
            Questions.previous = false
            Questions.next = true
        } else if ((Questions.currentQuestion + 1) === Questions.data.length) {
            Questions.previous = true
            Questions.next = false
        } else {
            Questions.previous = true
            Questions.next = true
        }
    }, [Questions.currentQuestion])

    useEffect(() => {
        if (QuizData.start && !QuizData.showSubmit) {
            setQuizData({ ...QuizData, startTime: moment().format('YYYY-MM-DD HH:mm:ss') })
        }
        if (QuizData.showSubmit) {
            var ___ = { ...QuizData, endTime: moment().format('YYYY-MM-DD HH:mm:ss'), timer: moment.utc(1000 * count).format('mm:ss') }
            setQuizData(___)
            var UserActivity = {
                "activity_id": props.activity._id,
                "book_id": props.activity.book_id,
                "user_id": props.session.sessionData._id,
                "quiz_time": moment().format('YYYY-MM-DD HH:mm:ss'),
                "quiz_answer": { answer: Questions.data },
                "quiz_result": ___,
                "status": "active"
            }
            axios.post(`${process.env.REACT_APP_QUIZ_API_URL}user-new-activity`, UserActivity).then(function (response) { })

            setTimeout(() => {
                setQuizDataTemp({ ...QuizDataTemp, showSubmitClass: 'QuizOverStatus' })
            }, 6000);
        }
    }, [QuizData.start, QuizData.showSubmit])

    const CheckAnswerData = (currentQuestion = 0, GivenOptionName = 'A') => {
        Questions.data[currentQuestion].userAnswer = GivenOptionName
        var TotalUserAnswer = Questions.data.filter((e: any) => e.userAnswer !== '').length
        setQuestions({ ...Questions, data: Questions.data, readyForNextPrevQue: true })
        if (GivenOptionName === Questions.data[currentQuestion].rightAnswer) {
            CorrectSound.play()
        } else {
            IncorrectSound.play()
        }
        if (TotalUserAnswer === Questions.data.length) {
            setTimeout(() => {
                setQuizData({ ...QuizData, totalAttend: TotalUserAnswer, showSubmit: true, totalRightAttend: Questions.data.filter((e: any) => e.rightAnswer === e.userAnswer).length })
            }, 6000);
            return true
        }

        var NextIndex = Questions.data.findIndex((e: any) => e.userAnswer === '')
        ToggleQues(NextIndex, true)
    }

    const ToggleQues = (Index = 0, Timeout = false) => {
        if (Timeout) {
            QuizData.NextTimer = setTimeout(() => {
                var NextIndex = Questions.data.findIndex((e: any) => e.userAnswer === '')
                setQuestions({ ...Questions, currentQuestion: NextIndex, readyForNextPrevQue: true })
            }, 6000);
        } else {
            clearTimeout(QuizData.NextTimer)
            setQuestions({ ...Questions, currentQuestion: Index })
        }
    }

    const QuizDataStart = (status: any) => {
        StartQuizSound.play()
        setQuizData({ ...QuizData, start: status })
    }
    const [count, setCount] = useState(0);
    useEffect(() => {
        if (QuizData.start && !QuizData.showSubmit) {
            const interval = setInterval(() => {
                setCount(count + 1);
            }, 1000);
            return () => clearInterval(interval);
        }
    }, [count, QuizData.start, QuizData.showSubmit]);

    var OpColor = ['blue-option', 'red-option', 'orange-option', 'green-option']

    return (<div className="OptionQuizMainContainer border-with-bg-radius p-2 p-md-3 notranslate">

        {!QuizData.start ? <div className={`startQuiz`}>
            <div className="startQuizBtn d-flex flex-column gap-1 cursor-pointer align-items-center" onClick={() => {
                QuizDataStart(true)
            }}>
                {/* <svg fill="#fff" width="100px" height="80px" viewBox="0 0 20 21" xmlns="http://www.w3.org/2000/svg"><path id="primary" d="M17,12,5,21V3Z" /* style="fill: none; stroke: rgb(0, 0, 0); stroke-linecap: round; stroke-linejoin: round; stroke-width: 2;" *></path></svg> */}

                <svg width="70px" height="50px" viewBox="-3 0 28 28" version="1.1" xmlns="http://www.w3.org/2000/svg" >
                    <g stroke="none" strokeWidth="1" fill="#fff" fillRule="evenodd" >
                        <g transform="translate(-419.000000, -571.000000)" fill="#fff">
                            <path d="M440.415,583.554 L421.418,571.311 C420.291,570.704 419,570.767 419,572.946 L419,597.054 C419,599.046 420.385,599.36 421.418,598.689 L440.415,586.446 C441.197,585.647 441.197,584.353 440.415,583.554">
                            </path>
                        </g>
                    </g>
                </svg>

                <span className="color-white font-size-30px">START</span>
            </div>
        </div> : ''}

        {QuizData.showSubmit ? <div className={`QuizStatus`}>

            {(() => {
                let Render = [] as any
                if (QuizDataTemp.showSubmitClass === 'QuizOver') {
                    Render.push(<ScrollAnimation className={`d-flex flex-column gap-2 QuizStatusDetails justify-content-center align-items-center ${QuizDataTemp.showSubmitClass}`} animateIn="fadeIn" animateOnce={true} delay={10}>
                        <h1 className="active-color">Quiz Complete</h1>
                        <div className="pyro">
                            <div className="before"></div>
                            <div className="after"></div>
                        </div>
                    </ScrollAnimation>)
                } else if (QuizDataTemp.showSubmitClass === 'QuizOverStatus') {
                    Render.push(<ScrollAnimation className={`d-flex flex-column gap-2 QuizStatusDetails justify-content-center align-items-center ${QuizDataTemp.showSubmitClass}`} animateIn="fadeIn" animateOnce={true} delay={5000}>
                        <div className="font-size-30px QuizStatusDetailsTitle">Quiz Complete</div>
                        <div className="d-flex flex-row gap-2 QuizStatusDiv">
                            <div className="d-flex flex-column">
                                <span>Score</span>
                                <div>
                                    <span className="font-size-30px">{QuizData.totalRightAttend}</span> / {QuizData.totalAttend}
                                </div>
                            </div>
                            <div className="d-flex flex-column align-items-center justify-content-center bg-active-color" style={{ width: '1px' }}></div>
                            <div className="d-flex flex-column">
                                <span>Time</span>
                                <div className="font-size-30px">
                                    {QuizData.timer}
                                </div>
                            </div>
                        </div>
                        <div className="cursor-pointer btn btn-3 QuizStatusDiv" onClick={() => {
                            Questions.data.map((e: any, i: any) => {
                                Questions.data[i].userAnswer = ''
                            })
                            setQuestions({ ...Questions, data: Questions.data, currentQuestion: 0, readyForNextPrevQue: true })
                            setCount(0)
                            setQuizData({ ...QuizData, totalAttend: 0, showSubmit: false, timer: '', start: true, totalRightAttend: 0, })
                            setQuizDataTemp({ ...QuizDataTemp, showSubmitClass: 'QuizOver' })
                        }}>Start Again</div>
                    </ScrollAnimation>)
                }
                return (Render)
            })()}

        </div> : ''}

        <div className="d-flex flex-column gap-3">
            <div className="d-flex flex-row justify-content-between">
                <div className="d-flex flex-row gap-2 align-items-center">
                    <span className="font-size-30px QuizTimer">{moment.utc(1000 * count).format('mm:ss')}</span>
                </div>
                <div className="d-flex flex-row gap-2 align-items-center">
                    <svg width="32px" height="32px" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><g id="style=fill"><g id="check-circle"><path id="Subtract" fillRule="evenodd" clipRule="evenodd" d="M12 1.25C6.06294 1.25 1.25 6.06294 1.25 12C1.25 17.9371 6.06294 22.75 12 22.75C17.9371 22.75 22.75 17.9371 22.75 12C22.75 6.06294 17.9371 1.25 12 1.25ZM16.5303 10.0303C16.8232 9.73744 16.8232 9.26256 16.5303 8.96967C16.2374 8.67678 15.7626 8.67678 15.4697 8.96967L10.8434 13.5959C10.7458 13.6935 10.5875 13.6935 10.4899 13.5959L8.53033 11.6363C8.23744 11.3434 7.76256 11.3434 7.46967 11.6363C7.17678 11.9292 7.17678 12.4041 7.46967 12.697L9.42923 14.6566C10.1126 15.34 11.2207 15.34 11.9041 14.6566L16.5303 10.0303Z" fill="#000000" /></g></g></svg>

                    {/* <svg fill="#000000" height="20px" width="20px" version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 490 490"><polygon points="452.253,28.326 197.831,394.674 29.044,256.875 0,292.469 207.253,461.674 490,54.528 " /></svg> */}

                    <span className="font-size-30px QuizCorrectCount">{Questions.data.filter((e: any) => e.rightAnswer === e.userAnswer).length}</span>
                </div>
            </div>
            <div className="questionContainer d-flex flex-column gap-2">
                <div className="question" dangerouslySetInnerHTML={{ __html: `${Questions.data[Questions.currentQuestion]?.question}` }}></div>
                <div className="row justify-content-center p-2">
                    {Questions.data[Questions.currentQuestion].option.map((data: any, i: any) => {
                        var AlreadyGiven = Questions.data[Questions.currentQuestion]
                        let status = [] as any
                        if (AlreadyGiven.userAnswer !== '') {
                            if (AlreadyGiven.userAnswer === data.name && AlreadyGiven.rightAnswer === data.name) {
                                status.push(<div className="right option-status">
                                    <svg width="32px" height="32px" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><g id="style=fill"><g id="check-circle"><path id="Subtract" fillRule="evenodd" clipRule="evenodd" d="M12 1.25C6.06294 1.25 1.25 6.06294 1.25 12C1.25 17.9371 6.06294 22.75 12 22.75C17.9371 22.75 22.75 17.9371 22.75 12C22.75 6.06294 17.9371 1.25 12 1.25ZM16.5303 10.0303C16.8232 9.73744 16.8232 9.26256 16.5303 8.96967C16.2374 8.67678 15.7626 8.67678 15.4697 8.96967L10.8434 13.5959C10.7458 13.6935 10.5875 13.6935 10.4899 13.5959L8.53033 11.6363C8.23744 11.3434 7.76256 11.3434 7.46967 11.6363C7.17678 11.9292 7.17678 12.4041 7.46967 12.697L9.42923 14.6566C10.1126 15.34 11.2207 15.34 11.9041 14.6566L16.5303 10.0303Z" fill="#000000" /></g></g></svg>
                                </div>)
                            } else if (AlreadyGiven.userAnswer === data.name && AlreadyGiven.rightAnswer !== data.name) {
                                status.push(<div className="wrong option-status">
                                    <svg width="32px" height="32px" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                        <g id="style=fill">
                                            <g id="close-circle">
                                                <path id="Subtract" fillRule="evenodd" clipRule="evenodd" d="M1.25 12C1.25 6.06294 6.06294 1.25 12 1.25C17.9371 1.25 22.75 6.06294 22.75 12C22.75 17.9371 17.9371 22.75 12 22.75C6.06294 22.75 1.25 17.9371 1.25 12ZM8.46967 8.46967C8.76257 8.17678 9.23744 8.17678 9.53033 8.46967L12 10.9393L14.4697 8.46967C14.7626 8.17678 15.2374 8.17678 15.5303 8.46967C15.8232 8.76257 15.8232 9.23744 15.5303 9.53033L13.0606 12L15.5303 14.4697C15.8232 14.7626 15.8232 15.2374 15.5303 15.5303C15.2374 15.8232 14.7625 15.8232 14.4696 15.5303L12 13.0607L9.53033 15.5303C9.23743 15.8232 8.76256 15.8232 8.46967 15.5303C8.17678 15.2374 8.17678 14.7625 8.46967 14.4696L10.9393 12L8.46967 9.53033C8.17678 9.23743 8.17678 8.76256 8.46967 8.46967Z" fill="#000000" />
                                            </g>
                                        </g>
                                    </svg>
                                </div>)
                            } else if (AlreadyGiven.rightAnswer === data.name) {
                                status.push(<div className="right option-status">
                                    <svg width="32px" height="32px" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><g id="style=fill"><g id="check-circle"><path id="Subtract" fillRule="evenodd" clipRule="evenodd" d="M12 1.25C6.06294 1.25 1.25 6.06294 1.25 12C1.25 17.9371 6.06294 22.75 12 22.75C17.9371 22.75 22.75 17.9371 22.75 12C22.75 6.06294 17.9371 1.25 12 1.25ZM16.5303 10.0303C16.8232 9.73744 16.8232 9.26256 16.5303 8.96967C16.2374 8.67678 15.7626 8.67678 15.4697 8.96967L10.8434 13.5959C10.7458 13.6935 10.5875 13.6935 10.4899 13.5959L8.53033 11.6363C8.23744 11.3434 7.76256 11.3434 7.46967 11.6363C7.17678 11.9292 7.17678 12.4041 7.46967 12.697L9.42923 14.6566C10.1126 15.34 11.2207 15.34 11.9041 14.6566L16.5303 10.0303Z" fill="#000000" /></g></g></svg>
                                </div>)
                            }
                        }
                        // optionMain
                        return ((data.value !== '' && data.value !== undefined) ? <div className={`${data.classValue}`} /* animateIn="flipInY" */ /* animateOnce={true} */ /* delay={(i + 1) * 10} */>
                            <div className={`Options ${OpColor[i]} p-2 p-md-2 pb-5 pb-md-5 d-flex flex-column gap-1 gap-md-2 cursor-pointer h-100 ${AlreadyGiven.userAnswer === data.name ? 'pulse' : ''} ${AlreadyGiven.userAnswer !== '' && !((AlreadyGiven.userAnswer === data.name && AlreadyGiven.rightAnswer === data.name) || (AlreadyGiven.userAnswer === data.name && AlreadyGiven.rightAnswer !== data.name) || (AlreadyGiven.rightAnswer === data.name)) ? 'disabled' : ''}`} onClick={() => {
                                if (AlreadyGiven.userAnswer === '') {
                                    CheckAnswerData(Questions.currentQuestion, data.name)
                                }
                            }}>
                                <div className="optionName">{data.name}</div>
                                <div className="optionValue">{data.value}</div>
                                {status}
                            </div>
                        </div> : '')
                    })}
                </div>
            </div>

            <div className="d-flex flex-row justify-content-center">
                <div className="d-flex flex-row gap-2">
                    <div className={`nextPrev d-flex flex-row align-items-center cursor-pointer ${!Questions.previous || !Questions.readyForNextPrevQue ? 'disabled' : ''}`} onClick={() => {
                        if (Questions.previous && Questions.readyForNextPrevQue) {
                            ToggleQues((Questions.currentQuestion - 1), false)
                        }
                    }}>
                        <svg fill="#000000" width="30px" height="30px" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" style={{ transform: 'rotate(180deg)', fill: 'none', stroke: 'rgb(0, 0, 0)', strokeLinecap: 'round', strokeLinejoin: 'round', strokeWidth: 2 }}><path id="primary" d="M17,12,5,21V3Z" ></path></svg>
                    </div>
                    <div className="d-flex flex-row align-items-center font-size-30px color-black">{(Questions.currentQuestion + 1)} of {Questions.data.length}</div>
                    <div className={`nextPrev d-flex flex-row align-items-center cursor-pointer ${!Questions.next || !Questions.readyForNextPrevQue ? 'disabled' : ''}`} onClick={() => {
                        if (Questions.next && Questions.readyForNextPrevQue) {
                            ToggleQues((Questions.currentQuestion + 1), false)
                        }
                    }}>
                        <svg fill="#000000" width="30px" height="30px" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path id="primary" d="M17,12,5,21V3Z" style={{ fill: 'none', stroke: 'rgb(0, 0, 0)', strokeLinecap: 'round', strokeLinejoin: 'round', strokeWidth: 2 }}></path></svg>
                    </div>
                </div>
            </div>
        </div>
    </div>)
}

const BookView = (props: any) => {
    return (<div className={`col-6 col-sm-6 col-md-6 col-lg-6 col-xl-2 mt-3 mt-lg-2 ${props.BookId !== '' && props.BookId !== props.data._id ? 'd-none' : ''}`}>
        <div className="d-flex h-100 flex-column publication-data quiz-data" onClick={() => props.SelectBook(props.data._id)} /* style={{ opacity: `${props.BookId !== '' && props.BookId !== props.data._id ? '0' : '1'}` }} */ >
            <div className={`quiz-book-seleted ${props.BookId === props.data._id ? 'active' : ''}`}><svg fill="#000000" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 64 64" width="32px" height="32px"><path d="M32,6C17.641,6,6,17.641,6,32c0,14.359,11.641,26,26,26s26-11.641,26-26C58,17.641,46.359,6,32,6z M29,42L18,31l2-3l9,6 l13.957-12L46,25L29,42z" /></svg></div>
            <LazyImage
                loadingSrc={`${process.env.PUBLIC_URL}/img/placeholder.png`}
                actualSrc={`${props.data.banner_image}`}
                errorSrc={`${process.env.PUBLIC_URL}/img/placeholder.png`}
                width={`100%`}
                style={{ position: 'absolute', top: 0, left: 0 }}
                ContainerClass="img-fluid publication-image border-radius-15px"
                title={props.data.title}
                alt={props.data.title}
            />
            {/* <div className="publication-data-text mt-3 p-0">
                <span className="publication-title d-block" style={{ height: 'auto' }}>{props.data.title}</span> */}
            {/* <span className="publication-types" dangerouslySetInnerHTML={{ __html: `${props.data.categoryName[0][props.data.category]}` }}></span> */}
            {/* </div> */}
        </div>
    </div>)
}

export default reduxConnect(["session"])(Quiz);