import React, { useContext, useEffect, useState } from "react"
import BreadCrumb from "./sections/BreadCrumb"
import { useNavigate, Link } from 'react-router-dom'
import { SectionTitle, PageLoader, LazyImage, ImageWithText, PrintDesc, monthNamesShort, MetaData, checkBroadCast, getMyTimeBroadCast, NoDataFound } from "./sections/CommonSections";
import GlobalContext from './Core/PageData'
import { useParams } from "react-router-dom";
import Banner from './sections/Banner'
import axios from "axios";
// @ts-ignore
import ScrollAnimation from 'react-animate-on-scroll'
import moment from "moment";

const LiveBroadCast: React.FC<any> = ({ SiteSlug, BroadcastType }: any): any => {
    let { BroadcastSlug } = useParams()
    const navigate = useNavigate()
    const { GlobalContent, setGlobalContent } = useContext(GlobalContext as any) as any
    const [isPageLoad, setisPageLoad] = useState('no')
    const [BreadCrumbdata, setBreadCrumbdata] = useState({ data: [] as any[] })
    const [Broadcast, setBroadcast] = useState({ data: [] as any[] })
    const [BroadcastData, setBroadcastData] = useState({ data: [] as any[] })
    const [BroadType, setBroadType] = useState({ data: [] as any[] })
    const [AllBroadType, setAllBroadType] = useState({ data: [] as any[] })
    const [UpcomingBroadcast, setUpcomingBroadcast] = useState({ data: [] as any[] })
    const [Timezone, setTimezone] = useState({ data: [] as any[] })

    var sites = [] as any
    if(BroadcastType !== undefined){
        sites = GlobalContent.AllSiteData.filter((e: any) => e.is_main === 'yes')
    } else {
        navigate(`${process.env.PUBLIC_URL}/404`)
    }
    useEffect(() => {
        (async () => {
            var curDate = parseInt(moment().format('X'))
            axios.get(`${process.env.REACT_APP_API_URL}livebroadcast/type/all`).then(json => {
                setAllBroadType({ data: json.data.data })
                /* return { type: json.data.data } */
            }).then(() => {
                axios.get(`${process.env.REACT_APP_API_URL}global/time-zone/all`).then(json => {
                    setTimezone({ data: json.data.data })
                }).then(() => {

                    var siteId = '';
                    if (SiteSlug !== undefined) {
                        var IndexSite = GlobalContent.AllSiteData.findIndex((e: any) => e.site_url !== undefined && e.site_url === SiteSlug)
                        var PageSite = GlobalContent.AllSiteData[IndexSite]
                        siteId = GlobalContent.AllSiteData[IndexSite]._id
                    } else {
                        siteId = sites[0]._id
                    }
                    axios.get(`${process.env.REACT_APP_API_URL}livebroadcast/event/front?sortField=publishOn&sortType=desc&recordPerPage=20&siteAccess=${siteId}`).then(json => {
                        json.data.data.data = checkBroadCast(json.data.data.data, GlobalContent, json.data.data.date)
                        json.data.data.data = json.data.data.data.filter((e: any) => e.status === 'active' /* && e.show_event === 'active' */)
                        /* json.data.data.data.filter((e: any) => (curDate <= parseInt(moment(e.publishOn).format('X')))) */
                        json.data.data.data = json.data.data.data.sort((a: any, b: any) => (moment(a.publishOn).format('X') > moment(b.publishOn).format('X')) ? 1 : -1)
                        //json.data.data.data = json.data.data.data.slice(0, 5)
                        json.data.data.data.forEach((Data: any, j: any) => {
                            json.data.data.data[j]['ist'] = ''
                            json.data.data.data[j]['local'] = ''
                        })
                        setUpcomingBroadcast({ data: json.data.data.data })
                        return { upcoming: json.data.data.data }
                    }).then((returnData) => {
                        axios.get(`${process.env.REACT_APP_API_URL}livebroadcast/type/slug/${BroadcastType}`).then(json => {
                            setBroadType({ data: json.data.data })
                            return { type: json.data.data }
                        }).then(temp => {
                            if (SiteSlug !== undefined) {
                                var IndexSite = GlobalContent.AllSiteData.findIndex((e: any) => e.site_url !== undefined && e.site_url === SiteSlug)
                                var PageSite = GlobalContent.AllSiteData[IndexSite]
                                if (PageSite !== undefined) {
                                    setGlobalContent({ ...GlobalContent, /* siteAccess: Page?_id */ siteData: PageSite, headerClass: '' })
                                    if (BroadcastSlug === undefined) {
                                        axios.get(`${process.env.REACT_APP_API_URL}livebroadcast/event/all-site-type/${SiteSlug}/${BroadcastType}`).then(json => {
                                            json.data.data = checkBroadCast(json.data.data, GlobalContent, json.data.date)
                                            json.data.data = json.data.data.filter((e: any) => e.status === 'active' && e.broadcast === 'active')
                                            json.data.data = json.data.data.sort((a: any, b: any) => (a.publishOn < b.publishOn) ? 1 : -1)

                                            var MainBroadcast = json.data.data/* json.data.data.filter((e: any) => (curDate <= parseInt(moment(e.publishOn).format('X')))) */
                                            if (MainBroadcast.length > 0) {
                                                returnData.upcoming = returnData.upcoming.filter((e: any) => (e.name !== MainBroadcast[0].name))
                                            }
                                            setUpcomingBroadcast({ data: returnData.upcoming })
                                            setBroadcast({ data: MainBroadcast })
                                            return { bb: MainBroadcast, BroadType: temp }
                                        }).then(temp => {
                                            /* axios.get(`${process.env.REACT_APP_API_URL}livebroadcast/event/all-site-type/${Page?_id}/${temp.BroadType.type[0]._id}`).then(json => { */
                                            axios.get(`${process.env.REACT_APP_API_URL}livebroadcast/event/all-type/${BroadcastType}`).then(json => {
                                                json.data.data = json.data.data.filter((e: any) => e.status === 'active')
                                                json.data.data.sort((a: any, b: any) => (moment(a.publishOn).format('X') < moment(b.publishOn).format('X')) ? 1 : -1)

                                                json.data.data = json.data.data.slice(0, (temp.bb.length > 0) ? 4 : 12)
                                                setBroadcastData({ data: json.data.data })
                                            })
                                            setisPageLoad('yes')
                                        })
                                    } else {
                                        axios.get(`${process.env.REACT_APP_API_URL}livebroadcast/event/site-type/${SiteSlug/* Page?_id */}/${BroadcastType/* temp.type[0]._id */}/${BroadcastSlug}`).then(json => {
                                            if(json.data.data.length === 0){
                                                navigate(`${process.env.PUBLIC_URL}/404`)
                                            }
                                            json.data.data = checkBroadCast(json.data.data, GlobalContent, json.data.date)
                                            json.data.data = json.data.data.filter((e: any) => e.status === 'active' && e.broadcast === 'active')
                                            json.data.data = json.data.data.sort((a: any, b: any) => (a.publishOn < b.publishOn) ? 1 : -1)
                                            var MainBroadcast = json.data.data/* json.data.data.filter((e: any) => (curDate <= parseInt(moment(e.publishOn).format('X')))) */
                                            if (MainBroadcast.length > 0) {
                                                returnData.upcoming = returnData.upcoming.filter((e: any) => e.name !== MainBroadcast[0]?.name)
                                            }
                                            setUpcomingBroadcast({ data: returnData.upcoming })

                                            setBroadcast({ data: MainBroadcast })
                                            return { bb: MainBroadcast, BroadType: temp }
                                        }).then(temp => {
                                            if (temp?.bb[0]?.schedule !== undefined && temp?.bb[0]?.schedule !== '') {
                                                axios.get(`${process.env.REACT_APP_API_URL}livebroadcast/schedule/${temp?.bb[0]?.schedule}`).then(json => {
                                                    temp.bb[0]['__liveSchedule__'] = json.data.data
                                                    setBroadcast({ data: temp?.bb })
                                                })
                                            }
                                            axios.get(`${process.env.REACT_APP_API_URL}livebroadcast/event/all-type/${BroadcastType}`).then(json => {
                                                json.data.data = json.data.data.filter((e: any) => e.status === 'active')
                                                json.data.data.sort((a: any, b: any) => (moment(a.publishOn).format('X') < moment(b.publishOn).format('X')) ? 1 : -1)
                                                json.data.data = json.data.data.slice(0, (temp.bb.length > 0) ? 4 : 12)
                                                setBroadcastData({ data: json.data.data })
                                            })
                                            setisPageLoad('yes')
                                        })
                                    }
                                } else {
                                    setGlobalContent({ ...GlobalContent, siteData: sites[0], headerClass: '' })
                                    axios.get(`${process.env.REACT_APP_API_URL}livebroadcast/event/${BroadcastType !== undefined ? `slug/${BroadcastType}` : `all-type/${SiteSlug}`}`).then(json => {
                                        if(json.data.data.length === 0){
                                            navigate(`${process.env.PUBLIC_URL}/404`)
                                        }
                                        var MainBroadcast = [] as any
                                        if (BroadcastType === undefined) {
                                            json.data.data = checkBroadCast(json.data.data, GlobalContent, json.data.date)
                                            json.data.data = json.data.data.filter((e: any) => e.status === 'active' && e.broadcast === 'active')
                                            json.data.data = json.data.data.sort((a: any, b: any) => (a.publishOn < b.publishOn) ? 1 : -1)
                                            MainBroadcast = json.data.data/* json.data.data.filter((e: any) => (curDate <= parseInt(moment(e.publishOn).format('X')))) */
                                            if (MainBroadcast.length > 0) {
                                                returnData.upcoming = returnData.upcoming.filter((e: any) => e.name !== MainBroadcast[0].name)
                                            }
                                            setUpcomingBroadcast({ data: returnData.upcoming })
                                        }
                                        if (MainBroadcast.length === 0) {
                                            navigate(`${process.env.PUBLIC_URL}/404`)
                                        }
                                        setBroadcast({ data: MainBroadcast })
                                        return { bb: MainBroadcast, BroadType: temp }
                                    }).then(temp => {
                                        axios.get(`${process.env.REACT_APP_API_URL}livebroadcast/event/all-type/${SiteSlug}`).then(json => {
                                            json.data.data = json.data.data.filter((e: any) => e.status === 'active')
                                            json.data.data.sort((a: any, b: any) => (moment(a.publishOn).format('X') < moment(b.publishOn).format('X')) ? 1 : -1)
                                            json.data.data = json.data.data.slice(0, (temp.bb.length > 0) ? 4 : 12)
                                            setBroadcastData({ data: json.data.data })
                                        })
                                        setBreadCrumbdata({
                                            data: [{
                                                path: `${process.env.PUBLIC_URL}`,
                                                linkName: 'Home',
                                                status: '',
                                            }, {
                                                path: `${process.env.PUBLIC_URL}/live/${BroadcastType}`,
                                                linkName: 'live',
                                                status: '',
                                            }, {
                                                path: `${process.env.PUBLIC_URL}/live/${BroadcastType}`,
                                                linkName: temp.bb[0].name,
                                                status: '',
                                            }]
                                        })
                                        setisPageLoad('yes')
                                    })
                                }
                            } else {
                                setGlobalContent({ ...GlobalContent, siteData: sites[0], headerClass: '' })
                                axios.get(`${process.env.REACT_APP_API_URL}livebroadcast/event/${BroadcastSlug !== undefined ? `slug/${BroadcastSlug}` : `all-type/${BroadcastType}`}`).then(json => {
                                    if(json.data.data.length === 0){
                                        navigate(`${process.env.PUBLIC_URL}/404`)
                                    }
                                    var MainBroadcast = [] as any
                                    json.data.data = checkBroadCast(json.data.data, GlobalContent, json.data.date)
                                    json.data.data = json.data.data.filter((e: any) => e.status === 'active' && e.broadcast === 'active' && e.siteAccess.includes(siteId))
                                    if (BroadcastSlug === undefined) {
                                        /* 
                                        json.data.data = json.data.data.filter((e: any) => e.status === 'active' && e.broadcast === 'active') */
                                        json.data.data = json.data.data.sort((a: any, b: any) => (a.publishOn > b.publishOn) ? 1 : -1)
                                        MainBroadcast = json.data.data/* json.data.data.filter((e: any) => (curDate <= parseInt(moment(e.publishOn).format('X')))) */
                                        /* if(MainBroadcast.length > 0){
                                            returnData.upcoming = returnData.upcoming.filter((e: any) => e.name !== MainBroadcast[0].name)
                                        } */
                                        setUpcomingBroadcast({ data: returnData.upcoming })
                                    } else {
                                        /*
                                        if(json.data.data.length === 0){
                                            navigate(`${process.env.PUBLIC_URL}/404`)
                                        } */
                                        MainBroadcast = json.data.data
                                    }
                                    if (returnData.upcoming.filter((e: any) => e.status === 'active' && e.broadcast === 'active' && e.livePage === temp.type[0]?._id).length === 0) {
                                        MainBroadcast = []
                                    }
                                    setBroadcast({ data: MainBroadcast })
                                    return { bb: MainBroadcast, BroadType: temp }
                                }).then(async temp => {
                                    if (BroadcastType === 'katha') {
                                        var _PreviousData_ = [] as any
                                        const PreviousData = await fetch(`https://www.googleapis.com/youtube/v3/playlistItems?part=snippet,contentDetails&maxResults=12&playlistId=PLqKpGEY54C-1aCXSRRHRN_AZBFANjPf9e&key=AIzaSyAvgKluPp0op8MFMUspvC9R1jToA51gt3I`, { method: "GET", headers: {} }).then(response => response.json()).then(PlayList => {
                                            if (PlayList.items.length > 0) {
                                                PlayList.items.forEach(async (Video: any, k: any) => {
                                                    var Obj = {
                                                        publishOn: (Video.contentDetails.videoPublishedAt !== undefined) ? Video.contentDetails.videoPublishedAt : Video.snippet.publishedAt,
                                                        streamId: Video.snippet.resourceId.videoId,
                                                        text: Video.snippet.title,
                                                        name: Video.snippet.title,
                                                    }
                                                    _PreviousData_.push(Obj)
                                                })
                                            }
                                            return _PreviousData_
                                        })
                                        setBroadcastData({ data: PreviousData })
                                    } else {
                                        axios.get(`${process.env.REACT_APP_API_URL}livebroadcast/event/all-type/${BroadcastType}`).then(json => {
                                            json.data.data = json.data.data.filter((e: any) => e.status === 'active')
                                            json.data.data.sort((a: any, b: any) => (moment(a.publishOn).format('X') < moment(b.publishOn).format('X')) ? 1 : -1)
                                            json.data.data = json.data.data.slice(0, (temp.bb.length > 0) ? 4 : 12)
                                            setBroadcastData({ data: json.data.data })
                                        })
                                    }
                                    setBreadCrumbdata({
                                        data: [{
                                            path: `${process.env.PUBLIC_URL}`,
                                            linkName: 'Home',
                                            status: '',
                                        }, {
                                            path: `${process.env.PUBLIC_URL}/live/${BroadcastSlug}`,
                                            linkName: 'live',
                                            status: '',
                                        }, {
                                            path: `${process.env.PUBLIC_URL}/live/${BroadcastSlug}`,
                                            linkName: (temp.bb.length > 0) ? temp.bb[0].name : '',
                                            status: '',
                                        }]
                                    })
                                    setisPageLoad('yes')
                                })
                            }
                        })
                    })
                })
            })
        })()
    }, [SiteSlug, BroadcastType, BroadcastSlug])

    return (
        <>
            {isPageLoad === 'yes' ? <>
                {/* <Banner BannerImage="Shreejibapa_1920.png" /> */}
                <MetaData keywords={`live, broadcast, maninagar, swaminarayan, gadi, sansthan`} description={Broadcast.data.length > 0 ? Broadcast.data[0]?.name : 'Previous Broadcasts and Videos'} />
                <section className={`site-contain`}>
                    <div className="container">
                        {/* <BreadCrumb BreadCrumbdata={BreadCrumbdata.data} /> */}
                        <SectionTitle title={Broadcast.data.length > 0 ? Broadcast.data[0]?.name : BroadType.data[0]?.name/* 'Previous Broadcasts and Videos' */} type="" />
                    </div>
                    <ScrollAnimation className="" animateIn="fadeIn" animateOnce={true} delay={100} >
                        <div className="container broadcast-container mt-5">
                            <div className="row">
                                <div className="col-12 col-sm-12 col-md-12 col-lg-8 col-xl-9 order-1 order-lg-1" /* style={{ marginTop: '-1.5rem' }} */>
                                    {/* <p className="title pb-3">{Broadcast.data[0]?.name}</p> */}
                                    {/* <ImageWithText Data={data.references} ContainerAlignClass={``} title={``} className={`mt-5`} imageWidth="750" imageHeight="460" SectionType={`sidebar`} pageType={``} /> */}
                                    {Broadcast.data.length > 0 ?
                                        <div className="position-relative w-100" style={{ paddingBottom: `calc(422 / 750 * 100%)` }}>
                                            {Broadcast.data.length > 0 ? <>
                                                <iframe width="100%" className="border-radius-15px" src={`https://www.youtube.com/embed/${Broadcast.data[0]?.streamId}`} title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen style={{ position: 'absolute', top: 0, left: 0, objectFit: 'cover', height: '100%' }}></iframe>
                                            </> : <div></div>}
                                        </div> : <div>
                                            <NoDataFound style={{ height: 'auto' }} Text="There is no scheduled live broadcast currently!" />
                                        </div>}


                                    {/* <>
                                            {BroadcastData.data.length > 0 ?
                                                <div className="mt-0">
                                                    <PreviousBroadCast BroadCastdata={BroadcastData.data} title="" />
                                                </div> : ''
                                            }
                                        </> */}
                                </div>
                                <div className="col-12 col-sm-12 col-md-12 col-lg-4 col-xl-3 order-2 order-lg-2 pb-4 pb-lg-0">
                                    <div className="d-flex flex-column">
                                        <div className={`border-with-bg-radius p-3 sidebar mb-3 mt-3 mt-lg-0`}>
                                            <p className="title pb-3">Swaminarayan Gadi App</p>
                                            <div className="sidebar-listing">
                                                <div className="row mt-3 justify-content-center">
                                                    <div className="col-6 col-md-3 col-lg-6 col-xl-6">
                                                        <a className="" href="https://itunes.apple.com/us/app/swaminarayan-gadi/id1443539089?mt=8" target={`_blank`} title="app store"><img src={`${process.env.PUBLIC_URL}/img/app_store.png`} className="img-fluid w-100" title="app store" alt="app store" /></a>
                                                    </div>
                                                    <div className="col-6 col-md-3 col-lg-6 col-xl-6">
                                                        <a className="" href="https://play.google.com/store/apps/details?id=info.sgadi.shangardarshan" target={`_blank`} title="play store"><img src={`${process.env.PUBLIC_URL}/img/google-play-badge.png`} className="img-fluid w-100" title="play store" alt="play store" /></a>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        {Broadcast?.data?.length > 0 ? <>
                                            {Broadcast?.data[0]?.__liveSchedule__ !== undefined && Broadcast?.data[0]?.__liveSchedule__.length > 0 ? <>
                                                {Broadcast?.data[0]?.__liveSchedule__[0]?.schedule !== undefined && Broadcast?.data[0]?.__liveSchedule__[0]?.schedule !== '' ?
                                                    <>
                                                        <div className={`border-with-bg-radius p-3 sidebar mb-3`}>
                                                            <p className="title pb-3">{Broadcast?.data[0]?.__liveSchedule__[0]?.name}</p>
                                                            <div className="sidebar-listing font-size-14px" dangerouslySetInnerHTML={{ __html: Broadcast?.data[0]?.__liveSchedule__[0]?.schedule }}></div>
                                                        </div>
                                                    </> : ''}
                                            </> : ''}
                                        </> : ''}

                                        {UpcomingBroadcast.data.length > 0 ? <>
                                            <div className={`border-with-bg-radius p-3 sidebar`}>
                                                <p className="title pb-3">Upcoming Broadcasts</p>
                                                <div className="sidebar-listing">
                                                    <div className="upcoming-broadcast">
                                                        {AllBroadType.data.length > 0 && UpcomingBroadcast.data.length > 0 && UpcomingBroadcast.data.map((data: any, i: any) => {
                                                            /* if (Broadcast.data[0]?.name !== data.name) { */
                                                            var site = GlobalContent.AllSiteData.filter((e: any) => data.siteAccess.split(',').includes(e._id))[0]
                                                            var siteUrl = ''
                                                            if (GlobalContent.AllSiteData.length > 0 && site?.is_main !== 'yes') {
                                                                siteUrl = '/' + site?.site_url
                                                            }
                                                            var typeUrl = AllBroadType.data.filter((e: any) => e._id === data.livePage)[0]?.slug

                                                            const d = moment(data.publishOn.split('T')[0])
                                                            data['day'] = d.format('DD')
                                                            data['month'] = d.format('MMM')
                                                            data['year'] = d.format('YYYY')

                                                            const dT = data.startTime.split(':')
                                                            data['e_hour'] = dT[0]
                                                            data['e_minutes'] = dT[1]

                                                            const instD = moment(data.ist_startTime.split('T')[0])
                                                            data['ist_day'] = instD.format('DD')
                                                            data['ist_month'] = instD.format('MMM')
                                                            data['ist_year'] = instD.format('YYYY')

                                                            const instDT = data.ist_startTime.split('T')[1].split(':')
                                                            data['ist_hour'] = instDT[0]
                                                            data['ist_minutes'] = instDT[1]
                                                            data['event_times'] = ''
                                                            data['__myTime__'] = ''
                                                            data = getMyTimeBroadCast(data, GlobalContent)
                                                            return (
                                                                <div className="d-flex flex-row mt-3" key={`${i}-UpcomingBroadcast`}>
                                                                    <div className="col-1 font-size-18px grey-color">{i + 1}</div>
                                                                    <div className="col-11 d-flex flex-column text-wrap">
                                                                        <h5 className="text-wrap font-size-18px"><Link to={`${process.env.PUBLIC_URL}${siteUrl}/${typeUrl}/${data._slug}`} title={data.name}>{data.name}</Link></h5>
                                                                        <p className="m-0 font-size-14px">{site?.name}</p>
                                                                        <p className="m-0 font-size-14px active-color">Event Time: {`${data.day} ${data.month} ${data.year} ${data.e_hour}:${data.e_minutes} ${data.event_times}`}</p>
                                                                        {/* {data.ist_startTime ? <p className="m-0 font-size-14px active-color">Your Time: {`${data.ist_day} ${data.ist_month} ${data.ist_year} ${data.ist_hour}:${data.ist_minutes} IST`}</p> : ''} */}
                                                                        {data['__myTime__'] ? <p className="m-0 font-size-14px active-color">{data['__myTime__']}</p> : ''}
                                                                        {data.broadcast === 'active' ? <div><Link to={`${process.env.PUBLIC_URL}${siteUrl}/${typeUrl}/${data._slug}`} className="btn btn-red mt-2" title="Live Now">Live Now</Link></div> : <div><Link to={`${process.env.PUBLIC_URL}${siteUrl}/${typeUrl}/${data._slug}`} className="btn btn-grey mt-2 disabled" title="Live Now">Live Now</Link></div>}
                                                                    </div>
                                                                </div>
                                                            )
                                                            /* } */
                                                        })}
                                                    </div>
                                                </div>
                                            </div>
                                        </> : ''}
                                    </div>
                                </div>
                            </div>
                            {(BroadcastType === 'katha' && SiteSlug === undefined && BroadcastData.data.length > 0) || (SiteSlug === undefined && BroadcastData.data.length > 0 && Broadcast.data.length) || (SiteSlug !== undefined && BroadcastData.data.length > 0 && Broadcast.data.length > 0) ?
                                <div className="mt-0 mt-lg-5">
                                    <PreviousBroadCast BroadCastdata={BroadcastData.data} title="Previous Broadcasts and Videos" />
                                </div> : ''
                            }
                        </div>
                    </ScrollAnimation>
                </section>
            </> : <PageLoader />
            }
        </>
    )
}

const PreviousBroadCast: React.FC<any> = ({ BroadCastdata, title = '', type = 'type-2' }: any) => {
    return (<>
        {BroadCastdata.length > 0 ? <>{title ?
            <a href="https://www.youtube.com/user/SwaminarayanGadi" title="Swaminarayan Gadi" className="mb-4 d-block" target={`_blank`}><SectionTitle title={title} /* type="title-active-color" */ type={type} /></a>
            : ``}
            <div className={`row image-with-text-container`} style={{ gap: '15px 0' }}>
                {BroadCastdata.map((data: any, i: any) => {
                    const d = moment(data.publishOn.split('T')[0])
                    data['day'] = d.format('DD')
                    data['month'] = d.format('MMM')
                    data['year'] = d.format('YYYY')
                    return (
                        <div className={`col-12 col-sm-12 col-md-12 col-lg-3`} key={`${i}-BroadcastData`}>
                            <a href={`https://www.youtube.com/watch?v=${data.streamId}`} target="_blank" className="image-with-text-single" title={data.text}>
                                <div className="image-with-text-image border-radius-15px">
                                    <LazyImage
                                        loadingSrc={`${process.env.PUBLIC_URL}/img/placeholder.png`}
                                        actualSrc={`https://img.youtube.com/vi/${data.streamId}/hqdefault.jpg`}
                                        errorSrc={`${process.env.PUBLIC_URL}/img/placeholder.png`}
                                        width={`100%`}
                                        imageWidth={750} imageHeight={420}
                                        style={{ position: 'absolute', top: 0, left: 0, objectFit: 'cover', height: '100%', objectPosition: 'center' }}
                                        title={data.text}
                                        alt={data.text}
                                    />
                                    <img src={`${process.env.PUBLIC_URL}/icons/youtube-hover.svg`} className="w-auto youtube-icon" alt="youtube" title="youtube" />
                                </div>
                                <div className="d-flex flex-row mt-2">
                                    <div className="me-3 d-flex flex-column text-center">
                                        <b className="active-color font-size-24px font-weight-bold line-height">{data.day}</b>
                                        <b className="black-color font-size-13px font-weight-bold">{data.month}</b>
                                        <b className="black-color" style={{ fontSize: '10px' }}>{data.year}</b>
                                        {/* <b className="black-color font-size-16px font-weight-bold">{data.month}</b>
                                        <b className="black-color font-size-13px">{data.year}</b> */}
                                    </div>
                                    <p className="image-text mb-0 text-start"><PrintDesc desc={data.name} /></p>
                                </div>
                            </a>
                        </div>)
                })}
            </div>
        </> : ''}
    </>)
}

export { PreviousBroadCast, LiveBroadCast }