class CacheManager {
  /**
   * @param {string} key
   * @param {boolean} decrypt
   * @return {string | object}
   */

  static get = (key : any) => {
    let result = "";
    result = window.localStorage.getItem(key) || "";
    try {
      result = JSON.parse(result);
    } catch (e) {}
    return result;
  };

  /**
   * @param key
   * @param value
   * @param cacheSetting
   */

  static set = ({ key , value } : any) => {
    value = value || "";
    if (typeof value === "object") {
      value = JSON.stringify(value);
    }
    window.localStorage.setItem(key, value);
  };
}

export default CacheManager;
