import React, { useContext, useEffect, useRef, useState } from "react"
import BreadCrumb from "./sections/BreadCrumb"
import { useNavigate } from 'react-router-dom'
import { SectionTitle, PageLoader, MetaData, Text100OR50, ImageTextRow, SayingsImageAndText, Popup } from "./sections/CommonSections";
import GlobalContext from './Core/PageData'
import axios from "axios";
import { useParams } from "react-router-dom";
// @ts-ignore
import { Helmet } from "react-helmet";

declare global {
    interface Window {
        create_tithely_widget: any
    }
}
const NorthamericaDonations: React.FC = (props: any) => {
    let { pageSlug } = useParams()
    const navigate = useNavigate()
    const { GlobalContent, setGlobalContent } = useContext(GlobalContext as any) as any
    const [isPageLoad, setisPageLoad] = useState('no')
    const [BreadCrumbdata, setBreadCrumbdata] = useState({ data: [] as any[] })
    const [Country, setCountry] = useState({ data: [] as any[] })
    var sites = GlobalContent.AllSiteData.filter((e: any) => e.name.includes('North America'))
    const [PopUpShow, setPopUpShow] = useState(false);
    const myRef1 = useRef<HTMLAnchorElement>(null)
    const myRef2 = useRef<HTMLAnchorElement>(null)
    const myRef3 = useRef<HTMLAnchorElement>(null)
    useEffect(() => {
        setisPageLoad('no')
        setGlobalContent({ ...GlobalContent, IsMediaPlayershow: false/* , siteAccess: '623beec8aeff82542fd87e55' */ })
        axios.get(`${process.env.REACT_APP_QUIZ_API_URL}user/country/all`).then(json => {
            setCountry({ data: json.data.countries_data })
            return json.data.countries_data
        }).then(Tempdata => {
            let BreadCrumbdata_ = [{
                path: `${process.env.PUBLIC_URL}/`,
                linkName: 'Home',
                status: '',
            }, {
                path: `${process.env.PUBLIC_URL}/donations`,
                linkName: `Donate`,
                status: '',
            }]
            document.title = `Donate${GlobalContent.pageTitle}`;
            setBreadCrumbdata({ data: BreadCrumbdata_ })
            setGlobalContent({ ...GlobalContent, siteData: sites[0], headerClass: '' })
            setisPageLoad('yes')
        })
    }, [])
    const handleopen = () => {
        /* window.create_tithely_widget() */
        /* setTimeout(() => {
            create_tithely_widget()
        }, 500);
        
        setTimeout(() => {
            create_tithely_widget()
        }, 1000); */
    }
    const onClose = () => {
        setPopUpShow(false)
    }
    const openSubscribe = () => {
        setPopUpShow(true)
    }
    return (
        <>
            <Helmet>
                <script src="https://tithe.ly/widget/v3/give.js?3"></script>
                <script src="https://app.breezechms.com/js/give.js"></script>
                <style>
                    {`.modal-backdrop.show{opacity:0.8}`}
                </style>
            </Helmet>
            {isPageLoad === 'yes' ? <>
                {/* <Helmet>
                    <script>var tw = create_tithely_widget();</script>
                </Helmet> */}
                <MetaData keywords={`Donations, maninagar, swaminarayan, gadi, sansthan`} description={document.title} />
                <section className={`site-contain`}>
                    <div className="container">
                        <BreadCrumb BreadCrumbdata={BreadCrumbdata.data} />
                        <SectionTitle title={`Donate to Maninagar Shree Swaminarayan Gadi Sansthan North America`} type="" />
                    </div>
                    <div className={`container mt-5`} key={``}><ImageTextRow Data={{ image: process.env.PUBLIC_URL + '/img/donations-banner.jpeg', desc: `` }} TypeOfContent={``} /></div>
                    <div className="container quiz-container mt-5">
                        <Text100OR50 Data={{
                            type: 'cnt_100',
                            title: '',
                            desc: `Transaction fees are lower for payment through bank account (as compared to credit card).<br/><br/><b>Please click on the location or event you would like to donate to.</b>`,
                            class: '',
                            titleClass: 'title-active-color',
                            PlaceOfDesign: 'content',
                            align: 'justify'
                        }} title={''} className={''} titleClass={`title-active-color`} PlaceOfDesign={`content`} TypeOfContent={''} />
                    </div>

                    <div className="container mt-2">
                        <div className="row gap-4 gap-md-0">
                            

                            <div className="col-12 col-md-4 d-flex flex-column gap-2">
                                <a className="" /* ref={myRef1} */ /* data-church-id="455162" */ style={{ cursor: 'pointer' }} title="Donate to New Jersey" /* onClick={() => { window.create_tithely_widget() }} */ onClick={openSubscribe}><SayingsImageAndText Image={`${process.env.PUBLIC_URL}/img/new-jersey.jpeg`} Text="" URL={``} /* handleopen={() => {
                                    setTimeout(() => {
                                        myRef1.current?.click()
                                    }, 10);
                                }} */ /></a>
                                <a className=" text-center" /* ref={myRef1} */ /* data-church-id="455162" */ style={{ cursor: 'pointer' }} title="Donate to New Jersey" onClick={openSubscribe} /* onClick={() => { window.create_tithely_widget() }} */>Click here to donate to New Jersey</a>
                                <div className="border-with-bg-radius border-radius-0px">
                                    <div className="table-responsive">
                                        <table className="table m-0">
                                            <thead className="bg-active-color">
                                                <tr><th colSpan={2} className="color-white">Check</th></tr>
                                            </thead>
                                            <tbody>
                                                <tr><th>Payable to</th><td valign="middle">SSSSM USA</td></tr>
                                                <tr><th>Mailing Address</th><td valign="middle">Shree Swaminarayan Temple, New Jersey<br />200 Penhorn Ave.<br />Secaucus, NJ 07094</td></tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12 col-md-4 d-flex flex-column gap-2">
                                <a className="tithely-give-btn" ref={myRef2} data-church-id="2377530" style={{ cursor: 'pointer' }} title="Donate to Delaware" onClick={() => { window.create_tithely_widget() }}><SayingsImageAndText Image={`${process.env.PUBLIC_URL}/img/delaware.jpeg`} Text="" URL={``} handleopen={() => {
                                    setTimeout(() => {
                                        myRef2.current?.click()
                                    }, 10)
                                }} /></a>
                                <a className="tithely-give-btn text-center" ref={myRef2} data-church-id="2377530" style={{ cursor: 'pointer' }} title="Donate to Delaware" onClick={() => { window.create_tithely_widget() }}>Click here to donate to Delaware</a>
                                <div className="border-with-bg-radius border-radius-0px">
                                    <div className="table-responsive">
                                        <table className="table m-0">
                                            <thead className="bg-active-color">
                                                <tr><th colSpan={2} className="color-white">Check</th></tr>
                                            </thead>
                                            <tbody>
                                                <tr><th>Payable to</th><td valign="middle">SSSSM Mid-Atlantic</td></tr>
                                                <tr><th>Mailing Address</th><td valign="middle">Shree Swaminarayan Temple, Delaware<br />180 School Bell Road<br />Bear, DE 19701</td></tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12 col-md-4 d-flex flex-column gap-2">
                                <a className="tithely-give-btn" ref={myRef3} data-church-id="792707" style={{ cursor: 'pointer' }} title="Donate to Florida" onClick={() => { window.create_tithely_widget() }}><SayingsImageAndText Image={`${process.env.PUBLIC_URL}/img/ocala.jpeg`} Text="" URL={``} handleopen={() => {
                                    setTimeout(() => {
                                        myRef3.current?.click()
                                    }, 10)
                                }} /></a>
                                <a className="tithely-give-btn text-center" ref={myRef3} data-church-id="792707" style={{ cursor: 'pointer' }} title="Donate to Florida" onClick={() => { window.create_tithely_widget() }}>Click here to donate to Florida</a>
                                <div className="border-with-bg-radius border-radius-0px">
                                    <div className="table-responsive">
                                        <table className="table m-0">
                                            <thead className="bg-active-color">
                                                <tr><th colSpan={2} className="color-white">Check</th></tr>
                                            </thead>
                                            <tbody>
                                                <tr><th>Payable to</th><td valign="middle">SSSSM Ocala</td></tr>
                                                <tr><th>Mailing Address</th><td valign="middle">Shree Swaminarayan Temple, Florida<br />14245 SW 16th Ave.<br />Ocala, Florida 34473</td></tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <Popup show={PopUpShow} Body={<iframe className="w-100" style={{height:'80vh'}} src="https://ssssmusa.breezechms.com/give/online"></iframe>} Header="Donate to New Jersey" onClose={onClose} />
                </section>
            </> : <PageLoader />
            }
        </>
    )
}

export default NorthamericaDonations