import 'bootstrap/dist/js/bootstrap.bundle.js';
import 'bootstrap/dist/css/bootstrap.min.css';
import './assets/vendor/icofont/icofont.min.css';
import './assets/vendor/boxicons/css/boxicons.min.css';
import './assets/vendor/venobox/venobox.css';
import './assets/css/style.css';
import './assets/css/animate.css';
import "./assets/css/playlistView.css";

import React, { useContext, useEffect, useState } from 'react'
import { BrowserRouter as Router, Navigate, Route, Routes, useLocation, useNavigate, useParams, BrowserRouter } from 'react-router-dom'
import Layout from './components/Layout'
import News from './components/News'
import Publication from './components/Publication'
import PublicationListing from './components/PublicationListing'
import PublicationDetails from './components/PublicationDetails'
import NewsDetails from './components/NewsDetails'
import ImageGallery from "./components/ImageGallery"
import Download from "./components/Download"
import Mandir from "./components/Mandir"
import Darshan from "./components/Darshan"
import CMS from "./components/CMS"
import Quiz from "./components/Quiz"
import Calendar from "./components/Calendar"
import AudioManage from "./components/AudioPlayer/UI/AudioManage";
import CmsMainFile from './components/CMS_Main_File';
import EventDetails from './components/EventDetails';
import { LiveBroadCast } from './components/LiveBroadCast';
import axios from "axios";
import OurBand from './components/OurBand';
import UpcomingEvents from './components/Upcoming';
import PageNotFound from './components/PageNotFound';
import Donations from './components/Donations';
import moment from "moment";
import DonationThankyou from './components/DonationThankyou';
import CalendarIcs from './components/CalendarIcs';
import DarshanFullView from './components/DarshanFullView';
import NorthamericaDonations from './components/NorthamericaDonations';
import LondonDonations from './components/LondonDonations';
import { Search } from './components/Search';

//window.folder = '';
/* function App() { */

export default function App()  {
  /* const [GlobalContent, setGlobalContent] = useState({ IsMediaPlayershow: true });
  const value = {
    GlobalContent,
    setGlobalContent,
  }*/

  const [typeOfPage, settypeOfPage] = useState('CMS')
  const [slugs, setslugs] = useState(window.location.pathname.split('/').filter(Boolean))
  const [AllSites, setAllSites] = useState({ data: [] as any[] })
  const [AllBroadTypes, setAllBroadTypes] = useState({ data: [] as any[] })
  const [Pipebands, setPipebands] = useState({ data: [] as any[] })
  useEffect(() => {
    if (slugs[0] !== 'darshan-full') {
      axios.get(`${process.env.REACT_APP_API_URL}global/site/all`).then(json => {
        if (json.data.data.length > 0) {
          setAllSites({ data: json.data.data })
        }
      })
      axios.get(`${process.env.REACT_APP_API_URL}livebroadcast/type/all`).then(json => {
        if (json.data.data.length > 0) {
          setAllBroadTypes({ data: json.data.data })
        }
      })
      axios.get(`${process.env.REACT_APP_API_URL}pipeband/bands/all`).then(json => {
        if (json.data.data.length > 0) {
          setPipebands({ data: json.data.data })
        }
      })
      /* if (slugs.length === 1) {
        axios.get(`${process.env.REACT_APP_API_URL}livebroadcast/type/slug/${slugs[0]}`).then(json => {
          if (json.data.data.length > 0) {
            settypeOfPage('LiveBroadCast')
          }
        })
      } else if (slugs.length === 2) {
        axios.get(`${process.env.REACT_APP_API_URL}livebroadcast/type/slug/${slugs[0]}`).then(json => {
          if (json.data.data.length > 0) {
            settypeOfPage('LiveBroadCast_1')
          }
        })
        axios.get(`${process.env.REACT_APP_API_URL}livebroadcast/type/slug/${slugs[1]}`).then(json => {
          if (json.data.data.length > 0) {
            settypeOfPage('LiveBroadCast_2')
          }
        })
      } */
    }
  }, [slugs])
  return (
    <BrowserRouter>
      {/* <GlobalContext.Provider value={value}> */}
      {/* <Header /> */}
      <Routes>
        <Route path={`${process.env.PUBLIC_URL}/calendar/ics/currentMonth`} element={<CalendarIcs type="currentMonth" />} />
        <Route path={`${process.env.PUBLIC_URL}/calendar/ics/currentYear`} element={<CalendarIcs type="currentYear" />} />
        <Route path={`${process.env.PUBLIC_URL}/calendar/ics/all`} element={<CalendarIcs type="all" />} />

        <Route path={`${process.env.PUBLIC_URL}/darshan-full`} element={<DarshanFullView />} />
        <Route element={<Layout />}>
          <Route path={`${process.env.PUBLIC_URL}`} element={<CMS />} />

          <Route path={`${process.env.PUBLIC_URL}/search`} element={<Search />} />
          <Route path={`${process.env.PUBLIC_URL}/${process.env.REACT_APP_PUBLICATION_SLUG}`} element={<Publication />} />
          <Route path={`${process.env.PUBLIC_URL}/${process.env.REACT_APP_PUBLICATION_SLUG}:publicationSlug`} element={<PublicationListing From="main" />} />
          <Route path={`${process.env.PUBLIC_URL}/${process.env.REACT_APP_PUBLICATION_SLUG}:publicationSlug/:publicationSubCategorySlug`} element={<PublicationListing From="main" />} />

          <Route path={`${process.env.PUBLIC_URL}/${process.env.REACT_APP_PUBLICATION_SLUG}:publicationSlug/:publicationSubCategorySlug/:publicationLanguageSlug/:publicationSingleSlug`} element={<PublicationDetails />} />
          <Route path={`${process.env.PUBLIC_URL}/${process.env.REACT_APP_PUBLICATION_SLUG}:publicationSlug/:publicationSubCategorySlug/:publicationLanguageSlug/:publicationSingleSlug/:trackSlug`} element={<PublicationDetails />} />
          <Route path={`${process.env.PUBLIC_URL}/${process.env.REACT_APP_PUBLICATION_SLUG}:publicationSlug/:publicationSubCategorySlug/:publicationLanguageSlug/:publicationSingleSlug/:SectionSlug/:ChapterSlug`} element={<PublicationDetails />} />

          {/* This 3 URL Need to remove in future */}
          <Route path={`${process.env.PUBLIC_URL}/${process.env.REACT_APP_PUBLICATION_SLUG}:publicationSlug/:publicationSubCategorySlug/:publicationSingleSlug`} element={<PublicationDetails />} />
          <Route path={`${process.env.PUBLIC_URL}/${process.env.REACT_APP_PUBLICATION_SLUG}:publicationSlug/:publicationSubCategorySlug/:publicationSingleSlug/:trackSlug`} element={<PublicationDetails />} />
          <Route path={`${process.env.PUBLIC_URL}/${process.env.REACT_APP_PUBLICATION_SLUG}:publicationSlug/:publicationSubCategorySlug/:publicationSingleSlug/:SectionSlug/:ChapterSlug`} element={<PublicationDetails />} />

          <Route path={`${process.env.PUBLIC_URL}/${process.env.REACT_APP_NEWS_SLUG}`} element={<News From="main" />} />
          <Route path={`${process.env.PUBLIC_URL}/${process.env.REACT_APP_NEWS_SLUG}category/:newsCategorySlug`} element={<News From="main" />} />
          <Route path={`${process.env.PUBLIC_URL}/${process.env.REACT_APP_NEWS_SLUG}:YearSlug/:newsDetailsSlug`} element={<NewsDetails />} />
          <Route path={`${process.env.PUBLIC_URL}/:locationSlug/${process.env.REACT_APP_NEWS_SLUG}:YearSlug/:newsDetailsSlug`} element={<NewsDetails />} />
          <Route path={`${process.env.PUBLIC_URL}/${process.env.REACT_APP_NEWS_SLUG}:newsDetailsSlug`} element={<NewsDetails />} />

          <Route path={`${process.env.PUBLIC_URL}/:newsSlug/${process.env.REACT_APP_IMAGE_GALLERY_SLUG}:yearSlug/:ImageGallerySlug`} element={<ImageGallery />} />

          <Route path={`${process.env.PUBLIC_URL}/:siteSlug/:newsSlug/${process.env.REACT_APP_IMAGE_GALLERY_SLUG}:yearSlug/:ImageGallerySlug`} element={<ImageGallery />} />

          <Route path={`${process.env.PUBLIC_URL}/${process.env.REACT_APP_IMAGE_GALLERY_SLUG}:ImageGallerySlug`} element={<ImageGallery />} />
          <Route path={`${process.env.PUBLIC_URL}/${process.env.REACT_APP_SOCIAL_IMAGE_GALLERY_SLUG}:ImageGallerySlug`} element={<ImageGallery type="social-image-gallery" />} />

          <Route path={`${process.env.PUBLIC_URL}/:siteSlug/${process.env.REACT_APP_NEWS_SLUG}`} element={<News From="main" />} />
          <Route path={`${process.env.PUBLIC_URL}/:siteSlug/${process.env.REACT_APP_NEWS_SLUG}category/:newsCategorySlug`} element={<News From="main" />} />
          <Route path={`${process.env.PUBLIC_URL}/:siteSlug/${process.env.REACT_APP_NEWS_SLUG}:newsDetailsSlug`} element={<NewsDetails />} />

          <Route path={`${process.env.PUBLIC_URL}/event-details/:eventDetailsSlug`} element={<EventDetails />} />
          <Route path={`${process.env.PUBLIC_URL}/events/:eventDateSlug`} element={<EventDetails />} />
          <Route path={`${process.env.PUBLIC_URL}/events/:eventDateSlug/:LangSlug`} element={<EventDetails />} />

          <Route path={`${process.env.PUBLIC_URL}/download`} element={<Download />} />
          <Route path={`${process.env.PUBLIC_URL}/download/:downloadType`} element={<Download />} />
          <Route path={`${process.env.PUBLIC_URL}/download/:categoryType/:downloadType`} element={<Download />} />
          <Route path={`${process.env.PUBLIC_URL}/${process.env.REACT_APP_MANDIR_SLUG}`} element={<Mandir />} />
          <Route path={`${process.env.PUBLIC_URL}/${process.env.REACT_APP_MANDIR_SLUG}:regionSlug`} element={<Mandir />} />
          <Route path={`${process.env.PUBLIC_URL}/darshan_test`} element={<Darshan />} />
          <Route path={`${process.env.PUBLIC_URL}/cms`} element={<CmsMainFile />} />
          <Route path={`${process.env.PUBLIC_URL}/calendar`} element={<Calendar language="English" />} />
          <Route path={`${process.env.PUBLIC_URL}/calendar/gujarati`} element={<Calendar language="Gujarati" />} />
          <Route path={`${process.env.PUBLIC_URL}/calendar/english`} element={<Calendar language="English" />} />
          <Route path={`${process.env.PUBLIC_URL}/calendar/gujarati/:yearSlug/:monthSlug`} element={<Calendar language="Gujarati" />} />
          <Route path={`${process.env.PUBLIC_URL}/calendar/english/:yearSlug/:monthSlug`} element={<Calendar language="English" />} />
          <Route path={`${process.env.PUBLIC_URL}/calendar/:yearSlug/:monthSlug`} element={<Calendar />} />

          <Route path={`${process.env.PUBLIC_URL}/:SiteSlug/calendar`} element={<Calendar language="English" />} />
          <Route path={`${process.env.PUBLIC_URL}/:SiteSlug/calendar/gujarati`} element={<Calendar language="Gujarati" />} />
          <Route path={`${process.env.PUBLIC_URL}/:SiteSlug/calendar/english`} element={<Calendar language="English" />} />
          <Route path={`${process.env.PUBLIC_URL}/:SiteSlug/calendar/gujarati/:yearSlug/:monthSlug`} element={<Calendar language="Gujarati" />} />
          <Route path={`${process.env.PUBLIC_URL}/:SiteSlug/calendar/english/:yearSlug/:monthSlug`} element={<Calendar language="English" />} />
          <Route path={`${process.env.PUBLIC_URL}/:SiteSlug/calendar/:yearSlug/:monthSlug`} element={<Calendar />} />


          <Route path={`${process.env.PUBLIC_URL}/manage`} element={<AudioManage />} />
          <Route path={`${process.env.PUBLIC_URL}/quiz`} element={<Quiz type="live" />} />
          <Route path={`${process.env.PUBLIC_URL}/quizTest`} element={<Quiz type="test" />} />
          <Route path={`${process.env.PUBLIC_URL}/donations`} element={<Donations />} />
          <Route path={`${process.env.PUBLIC_URL}/northamerica/donations`} element={<NorthamericaDonations />} />
          <Route path={`${process.env.PUBLIC_URL}/london/donations`} element={<LondonDonations />} />

          <Route path={`${process.env.PUBLIC_URL}/:SiteSlug/:BroadcastType/:BroadcastSlug`} element={<LiveBroadCast />} />
          {/* <Route path={`${process.env.PUBLIC_URL}/:BroadcastType/:BroadcastSlug`} element={<LiveBroadCast />} /> */}
          {/* {slugs.length === 2 && typeOfPage.split('_')[0] === 'LiveBroadCast' ? <Route path={`${process.env.PUBLIC_URL}${typeOfPage === 'LiveBroadCast_1' ? `/:BroadcastType/:BroadcastSlug` : `/:SiteSlug/:BroadcastType`}`} element={<LiveBroadCast />} /> : <Route path={`${process.env.PUBLIC_URL}/:siteSlug/:pageSlug`} element={<CMS />} />} */}
          {/* <Route path={`${process.env.PUBLIC_URL}/:pageSlug`} element={<CMS />} /> */}
          {/* {slugs.length === 1 && typeOfPage === 'LiveBroadCast' ? <Route path={`${process.env.PUBLIC_URL}/:BroadcastType`} element={<LiveBroadCast />} />
              : <Route  path={`${process.env.PUBLIC_URL}/:pageSlug`} element={<CMS />}
              />} */}
          <Route path={`${process.env.PUBLIC_URL}/pipeband/upcomingevents`} element={<UpcomingEvents />} />

          {Pipebands.data && Pipebands.data !== undefined && Pipebands.data.map((data: any, i: any) => {
            return (<Route path={`${process.env.PUBLIC_URL}/pipeband/${data.slug}`} element={<OurBand BandSlug={data.slug} />} />)
          })}


          {AllSites.data && AllSites.data !== undefined && AllSites.data.map((data: any, i: any) => {
            return (
              AllBroadTypes.data && AllBroadTypes.data !== undefined && AllBroadTypes.data.map((Broaddata: any, i: any) => {
                return (<>
                  {/* <Route path={`${process.env.PUBLIC_URL}/${data.site_url}`} element={<Navigate to={`${process.env.PUBLIC_URL}/${data.site_url}/home`} />} /> */}

                  <Route path={`${process.env.PUBLIC_URL}/${data.site_url}/${Broaddata.slug}`} element={<LiveBroadCast BroadcastType={Broaddata.slug} SiteSlug={data.site_url} />} />
                  <Route path={`${process.env.PUBLIC_URL}/${data.site_url}/${Broaddata.slug}/:BroadcastSlug`} element={<LiveBroadCast BroadcastType={Broaddata.slug} SiteSlug={data.site_url} />} />
                </>)
              }))
          })}

          {AllBroadTypes.data && AllBroadTypes.data !== undefined && AllBroadTypes.data.map((Broaddata: any, i: any) => {
            return (<>
              <Route path={`${process.env.PUBLIC_URL}/${Broaddata.slug}`} element={<LiveBroadCast BroadcastType={Broaddata.slug} />} />
              <Route path={`${process.env.PUBLIC_URL}/${Broaddata.slug}/:BroadcastSlug`} element={<LiveBroadCast BroadcastType={Broaddata.slug} />} />
            </>)
          })}

          {AllSites.data && AllSites.data !== undefined && AllSites.data.map((data: any, i: any) => {
            if (data.site_url !== '' && data.site_url !== undefined && data.site_url !== 'undefined') {
              return (<>
                <Route path={`${process.env.PUBLIC_URL}/${data.site_url}/:pageSlug`} element={<CMS SiteSlug={data.site_url} />} />
                <Route path={`${process.env.PUBLIC_URL}/${data.site_url}`} element={<CMS SiteSlug={data.site_url} />} />

                {/* <Route path={`${process.env.PUBLIC_URL}/${data.site_url}/:newsSlug/${process.env.REACT_APP_IMAGE_GALLERY_SLUG}:yearSlug/:ImageGallerySlug`} element={<ImageGallery siteSlug={data.site_url} />} /> */}

                <Route path={`${process.env.PUBLIC_URL}/${data.site_url}/${process.env.REACT_APP_IMAGE_GALLERY_SLUG}:ImageGallerySlug`} element={<ImageGallery siteSlug={data.site_url} />} />
                <Route path={`${process.env.PUBLIC_URL}/${data.site_url}/:newsSlug/${process.env.REACT_APP_IMAGE_GALLERY_SLUG}:ImageGallerySlug`} element={<ImageGallery siteSlug={data.site_url} />} />

                <Route path={`${process.env.PUBLIC_URL}/${data.site_url}/${process.env.REACT_APP_SOCIAL_IMAGE_GALLERY_SLUG}:ImageGallerySlug`} element={<ImageGallery type="social-image-gallery" siteSlug={data.site_url} />} />
              </>)
            }
          })}
          {/* <Route path={`${process.env.PUBLIC_URL}/:newsSlug/${process.env.REACT_APP_IMAGE_GALLERY_SLUG}:ImageGallerySlug`} element={<ImageGallery />} /> */}

          <Route path={`${process.env.PUBLIC_URL}/:locationSlug/${process.env.REACT_APP_IMAGE_GALLERY_SLUG}:newsSlug/:YearSlug/:ImageGallerySlug`} element={<ImageGallery />} />

          {AllSites.data && AllSites.data !== undefined && AllSites.data.length > 0 ? <Route path={`${process.env.PUBLIC_URL}/:pageSlug`} element={<CMS SiteSlug='' />} /> : ''}
          {/* Pipebands.data
            AllSites.data
            AllBroadTypes.data */}
          <Route path={`${process.env.PUBLIC_URL}/404`} element={<PageNotFound />} />
          <Route path={`${process.env.PUBLIC_URL}/thank-you-donation`} element={<DonationThankyou />} />
          <Route path={`${process.env.PUBLIC_URL}/cancelled-donation`} element={<DonationThankyou type="Cancelled" />} />
          <Route path={`${process.env.PUBLIC_URL}/london/thank-you-donation`} element={<DonationThankyou site="Kingsbury" />} />
          <Route path={`${process.env.PUBLIC_URL}/london/cancelled-donation`} element={<DonationThankyou site="Kingsbury" type="Cancelled" />} />

          {/* <Route path="*" element={<Navigate to={`${process.env.PUBLIC_URL}/404`} />} /> */}
        </Route>
      </Routes>
      {/* <Footer /> */}
      {/* {(GlobalContent.IsMediaPlayershow === true) ? (
            <div style={{ background: 'red', height: '100px', position: 'fixed', width: '100%', zIndex: '4', bottom: '0px' }}>
            </div>
          ) : <></>} */}
      {/* </GlobalContext.Provider> */}
    </BrowserRouter>
  );
}
