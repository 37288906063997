import React, { useContext, useEffect, useState } from "react"
import BreadCrumb from "./sections/BreadCrumb"
import { SectionTitle, MandirsBox, PageLoader, RegionIcons, MetaData } from "./sections/CommonSections";
import GlobalNetwork from './sections/GlobalNetwork';
import GlobalContext from './Core/PageData'
import { Link, useParams } from "react-router-dom"
import axios from "axios";

const Mandir: React.FC = () => {
    let { regionSlug } = useParams();
    const { GlobalContent, setGlobalContent } = useContext(GlobalContext as any) as any
    document.title = `Mandirs${GlobalContent.pageTitle}`;
    const [BreadCrumbdata, setBreadCrumbdata] = useState({ data: [] as any[] });
    const [MandirData, setMandirData] = useState({ data: [] as any[] });
    const [MandirRegion, setMandirRegion] = useState({ image_json: [] as any[] });
    const [MandirNearData, setMandirNearData] = useState({ data: [] as any[] });
    const [isPageLoad, setisPageLoad] = useState('no')
    const [FilterForm, setFilterForm] = useState({ data: [{ search: "" }] } as any)
    var sites = GlobalContent.AllSiteData.filter((e: any) => e.is_main === 'yes')

    useEffect(() => {
        setisPageLoad('no')

        setBreadCrumbdata({
            data: [
                {
                    path: `${process.env.PUBLIC_URL}`,
                    linkName: 'Home',
                    status: '',
                }/* , {
                    path: `${process.env.PUBLIC_URL}/mandir`,
                    linkName: 'Mandirs',
                    status: '',
                } */, {
                    path: `${process.env.PUBLIC_URL}/mandir`,
                    linkName: 'Mandirs & Mandals',
                    status: '',
                }
            ]
        })

        axios.get(`${process.env.REACT_APP_API_URL}mandir/region/all`).then(json => {
            var RegionM = [] as any

            json.data.data = json.data.data.filter((e: any) => e.status === 'active')
            json.data.data.forEach((Data: any, j: any) => {
                var icon = '' as any
                if (RegionIcons !== undefined) {
                    icon = RegionIcons.find((ell: { name: string; }) => ell.name === Data.name)?.icon
                }
                RegionM.push({
                    imageurl: `${process.env.PUBLIC_URL}/img/${icon}`,
                    s_i_alt: Data.name,
                    s_title: Data.name,
                    s_l_url: Data.slug
                });
            })
            return RegionM
        }).then(MandirRegion => {
            setMandirRegion({ image_json: MandirRegion })
        })
        GetMandirData()
    }, [regionSlug]);

    const GetMandirData = (MyFilters = {} as any, typeOfLoad = 'first') => {
        let filters = [] as any

        setGlobalContent({ ...GlobalContent, siteData: sites[0], headerClass:'' })

        filters.push(`recordPerPage=500`)
        if (typeOfLoad === 'loadmore') {
            /* filters.push(`page=${PaginationData.nextpage}`)
            setisPageLoadMore('no') */
        } else {
            /* setisPageLoad('no') */
        }
        if (MyFilters.data !== undefined) {
            if (MyFilters.data[0].search) {
                filters.push(`search=${MyFilters.data[0].search}`)
            }
        }

        axios.get(`${process.env.REACT_APP_API_URL}mandir/filter?${filters.join('&')}`).then(json => {
            json.data.data.data = json.data.data.data.filter((e: any) => e.status === 'active' && e.type === 'mandir')
            setMandirData({ data: json.data.data.data })
            return json.data.data.data
        }).then(MandirDataTemp => {
            const promises = [] as any;
            if (regionSlug !== undefined) {
                var pp = axios.get(`${process.env.REACT_APP_API_URL}mandir/region/slug/${regionSlug}`).then(json => {
                    return json.data.data[0]._id
                })
                promises.push(pp);
            } else {
                promises.push('');
            }
            Promise.all(promises).then(dsa => {
                if (dsa[0] !== '') {
                    var newData = MandirDataTemp.filter((ell: { region: string; }) => ell.region === dsa[0]) as any
                    setMandirData({ data: newData })
                } else {

                }
                setisPageLoad('yes')
            })
        })
    }
    const ChangeFilters = (_id: any, type: any, status = 'add') => {
        const promises = [] as any
        let dd = {} as any
        if (type === 'search') {
            dd = { data: [{ ...FilterForm.data[0], search: _id }] }
            setFilterForm({ data: [{ ...FilterForm.data[0], search: _id }] })
            promises.push(dd)
        }
        Promise.all(promises).then(pp => {
            GetMandirData(pp[0])
        })
    }
    return (
        <>
            {isPageLoad === 'yes' ? <>
                <MetaData keywords={`mandir, maninagar, swaminarayan, gadi, sansthan`} description={document.title} />
                <section className="site-contain pb-5">
                    <div className="container">
                        <BreadCrumb BreadCrumbdata={BreadCrumbdata.data} />
                        <SectionTitle title="Shree Swaminarayan Gadi Sansthan Mandirs & Mandals" type="type-2" />
                    </div>
                </section>
                <GlobalNetwork className="pt-5 pb-5" Data={MandirRegion} ShowWordWide='no' activeRegion={regionSlug} />
                <section className="site-contain pt-5">
                    <div className="container">
                        <div className="d-flex flex-row pt-0">
                            {/* <div className="col-auto">
                                    <select className="form-control with-bg border-radius-top-right border-radius-bottom-right">
                                        <option>All Mandir</option>
                                        <option>Audio</option>
                                        <option>Video</option>
                                    </select>
                                </div> border-radius-top-left border-radius-bottom-left */}
                            <input className="form-control " type={`text`} onChange={(e) => ChangeFilters(e.target.value, 'search')} placeholder="Search Mandir..." name="search" />
                        </div>
                        {MandirData.data && MandirData.data.length > 0 ? <>
                            {/* <div className="map border-with-bg-radius">
                                <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2597.04490483431!2d72.60973757015341!3d22.995272424552706!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x395e85e0e78cefed%3A0xf7a37d3e2a4bb802!2sShree%20Swaminarayan%20Mandir%20Maninagar!5e0!3m2!1sen!2sin!4v1655175156899!5m2!1sen!2sin" width="100%" height="100%" allowFullScreen loading="lazy" referrerPolicy="no-referrer-when-downgrade"></iframe>
                            </div> */}
                            <MandirsBox className="mandir-container pt-5" MandirData={MandirData.data} title="" searchText={FilterForm.data[0].search} />
                            {/* <div className="d-flex flex-row justify-content-center mt-4 mb-4">
                                <button className="btn btn-primary">LOAD MORE</button>
                            </div> */}
                            <MandirsBox className="d-flex flex-column mandir-container mandir-nearme pt-5" MandirData={MandirNearData.data} title="MANDIRS NEAR YOU" />
                        </> : <div className="d-flex flex-row justify-content-center p-5">No data found!</div>}
                    </div>
                </section>
            </> : <PageLoader />}
        </>
    )
}

export default Mandir