import React, { useContext, useEffect, useRef, useState } from "react"
import { SectionTitle, ImageTextRow, Text100OR50, IMGDesc, TabPanel, PageLoader, ImgImg, ModulePlusApiName, ImageWithText, monthNames, DayNamesShort, monthNamesShort, LazyImage, PrintDesc, DownloadSections, Buttons, Counter, ImageWithTextMasonary, LiveDarshanSection, LiveDarshanSectionType2, GetSiteUrl, PopularNews, GetGujAndEngDateSameDate, GetPublicationUrl } from "./sections/CommonSections";
import axios from "axios";
import BannerSlider from "./sections/BannerSlider";
import LiveDarshan from "./sections/LiveDarshan";
import LiveBroadcast from "./sections/LiveBroadcast";
import { Aboutus, AboutUsBlocks } from "./sections/About";
import Sayings from "./sections/Sayings";
import Guruparampara from "./sections/Guruparampara";
import GlobalNetwork from "./sections/GlobalNetwork";
import News from './sections/News';
import Calender from "./sections/Calender";
import OurWebsites from "./sections/OurWebsites";
import Darshan from "./Darshan"
import ScrollAnimation from "react-animate-on-scroll";
/* import { TransitionGroup, CSSTransition } from "react-transition-group" */
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css';
import GlobalContext from './Core/PageData'
import moment from "moment";
import { PreviousBroadCast } from "./LiveBroadCast";

import { useFormik } from 'formik';
import * as Yup from 'yup'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


const ValidSchema = Yup.object().shape({
    fName: Yup.string().required('First Name is required').matches(/^[aA-zZ\s]+$/, "Only alphabets are allowed for this field "),
    mName: Yup.string().required('Middle Name is required').matches(/^[aA-zZ\s]+$/, "Only alphabets are allowed for this field "),
    lName: Yup.string().required('Last Name is required').matches(/^[aA-zZ\s]+$/, "Only alphabets are allowed for this field "),
    city: Yup.string().required('Village is required'),
    postCode: Yup.string().required('Postcode is required'),
    phoneNo: Yup.string().required('Moile No is required').min(9, 'Must be minimum 9 digits').max(10, 'Must be maximum 10 digits').matches(/^[0-9]+$/, "Must be only digits"),
})

const CMSContent: React.FC<any> = ({ cmsPageId = '', ContainerClass = 'container', firstBlockClass = '', TypeOfContent = '', NewsData = {}, SiteSlug = '', newsDetailsSlug = '', siteAccess = '', pageSlug = '', locationSlug = '', YearSlug = '', setisPageLoadFromMain }: any) => {
    //document.title = `${GlobalContent.pageTitle}`;

    const { GlobalContent, setGlobalContent } = useContext(GlobalContext as any) as any
    const [isPageLoad, setisPageLoad] = useState('no')
    const [CmsData, setCmsData] = useState({ data: [] as any[] });
    const [IsLikeHomePage, setIsLikeHomePage] = useState('no');

    const [Lightimages, setLightimages] = useState({ data: [] as any[] });
    const [LightimagesCaption, setLightimagesCaption] = useState({ data: [] as any[] });
    const [LightimageTitle, setLightimageTitle] = useState({ data: [] as any[] });
    const [DarshanData, setDarshanData] = useState({ data: [] as any[] });
    const [photoIndex, setphotoIndex] = useState(0)
    const [isOpen, setisOpen] = useState(false)
    const [AllPipeBand, setAllPipeBand] = useState({ data: [] as any[] });
    let renderBanner = [] as any
    let renderMainPage = [] as any
    let renderSidebar = [] as any
    useEffect(() => {
        setisPageLoad('no')
        /* if (setisPageLoadFromMain !== undefined && setisPageLoadFromMain !== 'undefined' && setisPageLoadFromMain !== '') {
            setisPageLoadFromMain('no')
        } */

        setLightimages({ data: [] })
        setLightimagesCaption({ data: [] })
        setLightimageTitle({ data: [] })

        let I: any[] = [] as any
        let C: any[] = [] as any
        let T: any[] = [] as any
        let _New_Index_ = 0

        /* axios.get(`${process.env.REACT_APP_API_URL}cms/cms-page/slug/${pageSlug}`).then(json => {
            setPageData({ data: json.data.data })
            return json.data.data
        }).then(cmsPage => { */

        if (cmsPageId !== '') {
            axios.get(`${process.env.REACT_APP_API_URL}cms/cms-page-content/level1/${cmsPageId}`).then(async json => {
                const promises = [] as any;
                /* json.data.data.push({
                    type: 'pipeband_performance', status: 'active', tab_json: [
                        {
                            tabName: "All",
                            cmsPageId: "62e6bc6a252eea3e41f06d33",
                            pageLink: ""
                        }
                    ]
                }) */
                json.data.data = json.data.data.filter((e: any) => e.status === 'active')

                if (SiteSlug === 'pipeband' && pageSlug === 'home') {
                    var Pipeband = await axios.get(`${process.env.REACT_APP_API_URL}pipeband/bands/all`).then(json => {
                        /* setAllPipeBand({ data: json.data.data }) */
                        var APipeband = [] as any
                        json.data.data.forEach((ContentData: any, i: any) => {
                            APipeband.push({
                                imageurl: ContentData.image1,
                                s_l_title: ContentData.band,
                                s_l_url: `/pipeband/${ContentData.slug}`,
                                s_title: ContentData.band,
                                s_i_alt: ContentData.band,
                                s_desc: "",
                                s_video_url: ""
                            })
                        })
                        return APipeband
                    })

                    json.data.data.splice(3, 0, {
                        align: "center",
                        dStyle: "normal",
                        image_json: Pipeband,
                        pageType: "cms",
                        status: "active",
                        tSlider: "4_column_image_only",
                        title: "Our Bands",
                        type: "imgRepeat",
                    })
                }

                /* json.data.data.push({status:'active',type:'tModule',tagType:'imageGallery',referenceLink:'630aedec7a40c3b623bf48b3'}) */
                await json.data.data.forEach((ContentData: any, i: any) => {
                    if (ContentData.status === 'active') {
                        if (ContentData.type === 'tModule') {
                            if (ContentData.tagType !== '') {
                                json.data.data[i]['references'] = []
                                var DownloadName = ''
                                if (ContentData.tagType === 'dWallpaper') {
                                    DownloadName = 'wallpaper'
                                } else if (ContentData.tagType === 'dRingtone') {
                                    DownloadName = 'ringtone'
                                } else if (ContentData.tagType === 'dArtwork') {
                                    DownloadName = 'artwork'
                                    json.data.data[i]['downloadType'] = []
                                } else if (ContentData.tagType === 'dImages') {
                                    DownloadName = 'images'
                                    json.data.data[i]['downloadType'] = []
                                } /* else if (ContentData.tagType === 'sdds') {
                                    json.data.data[i]['downloadType'] = []
                                } */
                                if (DownloadName !== '') {
                                    axios.get(`${process.env.REACT_APP_API_URL}download/${DownloadName}-type/all/`).then(Typejson => {
                                        json.data.data[i]['downloadType'] = Typejson.data.data;
                                    })
                                    json.data.data[i]['downloadTypeName'] = DownloadName;
                                }
                                const References = ContentData.referenceLink.split(',')
                                References.forEach((ReferencesID: any, j: any) => {
                                    if (ReferencesID !== '') {

                                        var APIURL = `${process.env.REACT_APP_API_URL}${ModulePlusApiName[0][ContentData.tagType].apiUrl}${ReferencesID}`
                                        const promise = axios.get(APIURL).then(async Referencesjson => {
                                            let Image = ''
                                            let Title = ''
                                            let Type = ContentData.tagType
                                            var siteLink = ''
                                            if (Referencesjson.data.data.length > 0) {
                                                if (Referencesjson.data.data[0].siteAccess !== undefined) {
                                                    siteLink = GetSiteUrl(Referencesjson.data.data[0].siteAccess, GlobalContent.AllSiteData)
                                                }
                                            }
                                            if (ContentData.tagType === 'imageGallery') {
                                                if (SiteSlug !== undefined && SiteSlug !== '') {
                                                    siteLink = (GlobalContent.AllSiteData.filter((e: any) => e.site_url === SiteSlug)[0]?.site_url !== undefined) ? `/${GlobalContent.AllSiteData.filter((e: any) => e.site_url === SiteSlug)[0]?.site_url}` : ''
                                                }
                                                if (newsDetailsSlug !== '' && newsDetailsSlug !== undefined) {
                                                    siteLink += `/${newsDetailsSlug}`
                                                }
                                            }

                                            if (ContentData.tagType === 'pBook' || ContentData.tagType === 'pVideo' || ContentData.tagType === 'pAudio') {
                                                siteLink = ''
                                            }
                                            let URL = `${process.env.PUBLIC_URL}${siteLink}/${ModulePlusApiName[0][ContentData.tagType].siteUrl}`
                                            let bookPdf = ''
                                            if (Referencesjson.data.data.length > 0 && Referencesjson.data.data[0].status === 'active') {
                                                if (ContentData.tagType === 'imageGallery') {
                                                    if (Referencesjson.data.data[0].publish === 'active') {
                                                        /* if (Referencesjson.data.data[0].image_json.length > 0) { */
                                                        if (Referencesjson.data.data[0].image_json.length > 0) {
                                                            var __tt__ = Referencesjson.data.data[0].image_json.find((ell: { image_feature: string; }) => ell.image_feature === 'yes' || ell.image_feature === '1')
                                                            if (__tt__ !== undefined) {
                                                                const __date__ = new Date(parseInt(__tt__.lastModified));
                                                                if (__tt__.image_thumb_508_inside !== undefined) {
                                                                    if (moment('2023-03-14').format('X') >= moment(__date__).format('X')) {
                                                                        Image = __tt__.imageurl
                                                                    } else {
                                                                        Image = __tt__.image_thumb_508_inside
                                                                    }
                                                                } else {
                                                                    Image = __tt__.imageurl
                                                                }
                                                            } else {
                                                                var _tt_ = Referencesjson.data.data[0].image_json[0]
                                                                const _date_ = new Date(parseInt(_tt_.lastModified));
                                                                if (_tt_.image_thumb_508_inside !== undefined) {
                                                                    if (moment('2023-03-14').format('X') >= moment(_date_).format('X')) {
                                                                        Image = _tt_.imageurl
                                                                    } else {
                                                                        Image = _tt_.image_thumb_508_inside
                                                                    }
                                                                } else {
                                                                    Image = _tt_.imageurl
                                                                }
                                                            }
                                                        }

                                                        Title = Referencesjson.data.data[0].name
                                                        /* Image = (Referencesjson.data.data[0].image_json.length > 0) 
                                                            ?   
                                                                (Referencesjson.data.data[0].image_json.find((ell: { image_feature: string; }) => ell.image_feature === 'yes' || ell.image_feature === '1') !== undefined) ? 
                                                                Referencesjson.data.data[0].image_json.find((ell: { image_feature: string; }) => ell.image_feature === 'yes' || ell.image_feature === '1').imageurl : 
                                                                Referencesjson.data.data[0].image_json[0].imageurl 
                                                            : '' */

                                                        //URL += `${Referencesjson.data.data[0].slug}`
                                                        //URL = `${process.env.PUBLIC_URL}/${locationSlug}/${process.env.REACT_APP_IMAGE_GALLERY_SLUG}${newsDetailsSlug}/${YearSlug}/${Referencesjson.data.data[0].slug}`

                                                        var site = GlobalContent.AllSiteData.filter((e: any) => e._id === Referencesjson.data.data[0].siteAccess.split(',')[0])
                                                        var SiteSlugIG = ''
                                                        if (site.length > 0) {
                                                            SiteSlugIG = (site[0].is_main === 'yes') ? '' : '/' + site[0].site_url
                                                        }
                                                        if (newsDetailsSlug === undefined || newsDetailsSlug === 'undefined' || newsDetailsSlug === null || newsDetailsSlug === '') {
                                                            URL = `${process.env.PUBLIC_URL}${SiteSlugIG}/${process.env.REACT_APP_IMAGE_GALLERY_SLUG}${Referencesjson.data.data[0].slug}`
                                                        } else {
                                                            //URL = `${process.env.PUBLIC_URL}${locationSlug ? `/${locationSlug}` : ''}/${YearSlug}/${newsDetailsSlug}/${process.env.REACT_APP_IMAGE_GALLERY_SLUG}${Referencesjson.data.data[0].slug}`

                                                            URL = `${process.env.PUBLIC_URL}${locationSlug ? `/${locationSlug}` : ''}/${newsDetailsSlug}/${process.env.REACT_APP_IMAGE_GALLERY_SLUG}${YearSlug}/${Referencesjson.data.data[0].slug}`
                                                        }
                                                        /* } */

                                                    } else {
                                                        return false;
                                                    }
                                                } else if (ContentData.tagType === 'pBook') {
                                                    Title = Referencesjson.data.data[0].title
                                                    Image = Referencesjson.data.data[0].banner_image
                                                    URL = GetPublicationUrl(Referencesjson.data.data[0], 'books')/* `${Referencesjson.data.data[0].categorySlug[0][Referencesjson.data.data[0].category]}/${Referencesjson.data.data[0].slug}` */
                                                    bookPdf = Referencesjson.data.data[0].book_file
                                                } else if (ContentData.tagType === 'pVideo') {

                                                    /* await axios.get(`${process.env.REACT_APP_API_URL}publication/video-album/${Referencesjson.data.data[0].album}`).then(Album => {
                                                        Image = Album.data.data[0].banner_image
                                                        URL += `${Album.data.data[0].categorySlug[0][Album.data.data[0].category]}/${Album.data.data[0].slug}`
                                                    }) */
                                                    if (Referencesjson.data.data[0].video_file !== '' && Referencesjson.data.data[0].video_file !== undefined && Referencesjson.data.data[0].video_file !== 'undefined') {
                                                        Title = Referencesjson.data.data[0].title
                                                        Image = (Referencesjson.data.data[0].banner_image !== '' && Referencesjson.data.data[0].banner_image !== undefined) ? Referencesjson.data.data[0].banner_image : ``
                                                        URL = Referencesjson.data.data[0].video_file
                                                        Type = 'LocalVideo'
                                                    } else {
                                                        Title = Referencesjson.data.data[0].title
                                                        Image = `https://img.youtube.com/vi/${Referencesjson.data.data[0].video_url}/hqdefault.jpg`
                                                        URL = `https://www.youtube.com/watch?v=${Referencesjson.data.data[0].video_url}`
                                                    }

                                                } else if (ContentData.tagType === 'pAudio') {

                                                    await axios.get(`${process.env.REACT_APP_API_URL}publication/audio-album/${Referencesjson.data.data[0].album}`).then(Album => {
                                                        Image = Album.data.data[0].banner_image
                                                        URL = GetPublicationUrl(Album.data.data[0], 'audio')/* `${Album.data.data[0].categorySlug[0][Album.data.data[0].category]}/${Album.data.data[0].slug}` */
                                                    })
                                                    Title = Referencesjson.data.data[0].title
                                                    /* Image = Referencesjson.data.data[0].banner_image
                                                    URL = `https://www.youtube.com/watch?v=${Referencesjson.data.data[0].video_url}` */

                                                } else if (ContentData.tagType === 'news') {

                                                    if (ContentData.dStyle === "tablist") {
                                                        var tabName = '';
                                                        if (Referencesjson.data.data[0].tag_title !== undefined && Referencesjson.data.data[0].tag_title !== 'undefined' && Referencesjson.data.data[0].tag_title !== '') {
                                                            tabName = Referencesjson.data.data[0].tag_title
                                                        } else if (Referencesjson.data.data[0].sort_title !== undefined && Referencesjson.data.data[0].sort_title !== 'undefined' && Referencesjson.data.data[0].sort_title !== '') {
                                                            tabName = Referencesjson.data.data[0].sort_title
                                                        } else if (Referencesjson.data.data[0].title !== undefined && Referencesjson.data.data[0].title !== 'undefined' && Referencesjson.data.data[0].title !== '') {
                                                            tabName = Referencesjson.data.data[0].title
                                                        }
                                                        return json.data.data[i]['tab_json'][j] = { tabName: tabName, tabTitle: (Referencesjson.data.data[0]?.sort_title !== undefined) ? Referencesjson.data.data[0]?.sort_title : Referencesjson.data.data[0].title, cmsPageId: Referencesjson.data.data[0]._id }
                                                    } else {
                                                        Title = (Referencesjson.data.data[0].title !== undefined) ? Referencesjson.data.data[0].title : ''
                                                        Image = Referencesjson.data.data[0].image
                                                        URL = `${process.env.PUBLIC_URL}/${Referencesjson.data.data[0]._slug ? Referencesjson.data.data[0]._slug : ''}`
                                                    }
                                                } else if (ContentData.tagType === 'pGvijay') {
                                                    Title = (Referencesjson.data.data[0].title !== undefined) ? Referencesjson.data.data[0].title : ''
                                                    Image = Referencesjson.data.data[0].banner_image
                                                    URL = `${Referencesjson.data.data[0].pdf_file}`
                                                } else if (ContentData.tagType === 'event') {
                                                    Title = (Referencesjson.data.data[0].title !== undefined) ? Referencesjson.data.data[0].title : ''
                                                    Image = Referencesjson.data.data[0].image
                                                    URL += `${Referencesjson.data.data[0].slug}`
                                                } else if (ContentData.tagType === 'dWallpaper' || ContentData.tagType === 'dRingtone' || ContentData.tagType === 'dArtwork' || ContentData.tagType === 'dImages') {
                                                    return json.data.data[i]['references'].push(Referencesjson.data.data[0])
                                                }
                                                /* return json.data.data[i]['references'].push({
                                                    image: Image,
                                                    text: Title,
                                                    URL: URL,
                                                    type: ContentData.tagType,
                                                    publishOn: (Referencesjson.data.data[0].publishOn !== undefined) ? Referencesjson.data.data[0].publishOn : ''
                                                }) */
                                                if (ContentData.tagType === 'imageGallery') {
                                                    /* let I: any[] = []
                                                    let C: any[] = []
                                                    let T: any[] = [] */
                                                    if (ContentData.dStyle === 'tab') {

                                                        Referencesjson.data.data[0].image_json.forEach((Temp: any, i: any) => {
                                                            const date111 = new Date(parseInt(Temp.lastModified));
                                                            /* I.push(Temp?.image_thumb_2048_inside !== undefined && Temp?.image_thumb_2048_inside !== '' && Temp.lastModified !== undefined && Temp.lastModified !== 'undefined' ? ((moment('2023-09-11').format('X') > moment(date111).format('X')) ? Temp?.imageurl : Temp?.image_thumb_2048_inside) : Temp?.imageurl)
                                                            C.push((i + 1) + '/' + Referencesjson.data.data[0].image_json.length + ' :: ' + Temp.caption)
                                                            T.push(Referencesjson.data.data[0].name) */
                                                            _New_Index_ = _New_Index_ + 1
                                                            var klkl = _New_Index_
                                                            I[klkl] = (Temp?.image_thumb_2048_inside !== undefined && Temp?.image_thumb_2048_inside !== '' && Temp.lastModified !== undefined && Temp.lastModified !== 'undefined' ? ((moment('2023-09-11').format('X') > moment(date111).format('X')) ? Temp?.imageurl : Temp?.image_thumb_2048_inside) : Temp?.imageurl)
                                                            C[klkl] = ((i + 1) + '/' + Referencesjson.data.data[0].image_json.length + ' :: ' + Temp.caption)
                                                            T[klkl] = (Referencesjson.data.data[0].name)
                                                            Referencesjson.data.data[0].image_json[i]['__index__'] = klkl
                                                        })

                                                        setLightimages({ data: I })
                                                        setLightimagesCaption({ data: C })
                                                        setLightimageTitle({ data: T })
                                                        var TabNameImageGalleryTab = Referencesjson.data.data[0].name
                                                        if (ContentData.pageType === 'news') {
                                                            if (Referencesjson.data.data[0].news_tag_title !== undefined && Referencesjson.data.data[0].news_tag_title !== 'undefined' && Referencesjson.data.data[0].news_tag_title !== '') {
                                                                TabNameImageGalleryTab = Referencesjson.data.data[0].news_tag_title
                                                            }
                                                        }
                                                        return json.data.data[i]['tab_json'][j] = { tabName: TabNameImageGalleryTab, image_json: Referencesjson.data.data[0].image_json, cmsPageId: 'image' }
                                                    } else if (ContentData.dStyle === 'page') {
                                                        Referencesjson.data.data[0].image_json.forEach((Temp: any, i: any) => {
                                                            const date111 = new Date(parseInt(Temp.lastModified));
                                                            /* I.push(Temp?.image_thumb_2048_inside !== undefined && Temp?.image_thumb_2048_inside !== '' && Temp.lastModified !== undefined && Temp.lastModified !== 'undefined' ? ((moment('2023-09-11').format('X') > moment(date111).format('X')) ? Temp?.imageurl : Temp?.image_thumb_2048_inside) : Temp?.imageurl)
                                                            C.push((i + 1) + '/' + Referencesjson.data.data[0].image_json.length + ' :: ' + Temp.caption)
                                                            T.push(Referencesjson.data.data[0].name) */
                                                            _New_Index_ = _New_Index_ + 1
                                                            var klkl = _New_Index_/* ReferencesID+''+i as any */
                                                            I[klkl] = (Temp?.image_thumb_2048_inside !== undefined && Temp?.image_thumb_2048_inside !== '' && Temp.lastModified !== undefined && Temp.lastModified !== 'undefined' ? ((moment('2023-09-11').format('X') > moment(date111).format('X')) ? Temp?.imageurl : Temp?.image_thumb_2048_inside) : Temp?.imageurl)
                                                            C[klkl] = ((i + 1) + '/' + Referencesjson.data.data[0].image_json.length + ' :: ' + Temp.caption)
                                                            T[klkl] = (Referencesjson.data.data[0].name)

                                                            Referencesjson.data.data[0].image_json[i]['__index__'] = klkl
                                                        })
                                                        setLightimages({ data: I })
                                                        setLightimagesCaption({ data: C })
                                                        setLightimageTitle({ data: T })

                                                        return json.data.data[i]['references'] = json.data.data[i]['references'].concat(Referencesjson.data.data[0].image_json)
                                                    } else if (ContentData.dStyle === 'normal') {
                                                        return json.data.data[i]['references'][j] = {
                                                            image: Image,
                                                            text: Title,
                                                            URL: URL,
                                                            type: ContentData.tagType,
                                                            publishOn: (Referencesjson.data.data[0].publishOn !== undefined) ? Referencesjson.data.data[0].publishOn : ''
                                                        }
                                                    } else {
                                                        return json.data.data[i]['references'][j] = {
                                                            image: Image,
                                                            text: Title,
                                                            URL: URL,
                                                            type: ContentData.tagType,
                                                            publishOn: (Referencesjson.data.data[0].publishOn !== undefined) ? Referencesjson.data.data[0].publishOn : ''
                                                        }
                                                    }
                                                } else {
                                                    return json.data.data[i]['references'][j] = {
                                                        image: Image,
                                                        text: Title,
                                                        URL: URL,
                                                        type: Type,
                                                        publishOn: (Referencesjson.data.data[0].publishOn !== undefined) ? Referencesjson.data.data[0].publishOn : '',
                                                        bookPdf: bookPdf
                                                    }
                                                }
                                            }

                                            if (Referencesjson.data.data.length > 0 && Referencesjson.data.data[0].status === 'inactive') {
                                                if (ContentData.tagType === 'pBook') {
                                                    Title = Referencesjson.data.data[0].title
                                                    Image = Referencesjson.data.data[0].banner_image
                                                    URL += `${Referencesjson.data.data[0].categorySlug[0][Referencesjson.data.data[0].category]}/${Referencesjson.data.data[0].slug}`
                                                    bookPdf = Referencesjson.data.data[0].book_file

                                                    return json.data.data[i]['references'][j] = {
                                                        image: Image,
                                                        text: Title,
                                                        URL: URL,
                                                        type: ContentData.tagType,
                                                        publishOn: (Referencesjson.data.data[0].publishOn !== undefined) ? Referencesjson.data.data[0].publishOn : '',
                                                        bookPdf: bookPdf
                                                    }
                                                } else if (ContentData.tagType === 'news') {

                                                    if (ContentData.dStyle === "tablist") {
                                                        var tabName = '';
                                                        if (Referencesjson.data.data[0].tag_title !== undefined && Referencesjson.data.data[0].tag_title !== 'undefined' && Referencesjson.data.data[0].tag_title !== '') {
                                                            tabName = Referencesjson.data.data[0].tag_title
                                                        } else if (Referencesjson.data.data[0].sort_title !== undefined && Referencesjson.data.data[0].sort_title !== 'undefined' && Referencesjson.data.data[0].sort_title !== '') {
                                                            tabName = Referencesjson.data.data[0].sort_title
                                                        } else if (Referencesjson.data.data[0].title !== undefined && Referencesjson.data.data[0].title !== 'undefined' && Referencesjson.data.data[0].title !== '') {
                                                            tabName = Referencesjson.data.data[0].title
                                                        }
                                                        return json.data.data[i]['tab_json'][j] = { tabName: tabName, tabTitle: (Referencesjson.data.data[0]?.sort_title !== undefined) ? Referencesjson.data.data[0]?.sort_title : Referencesjson.data.data[0].title, cmsPageId: Referencesjson.data.data[0]._id }
                                                    }
                                                }
                                            }
                                        })
                                        promises.push(promise);
                                    }
                                })

                                if (ContentData.referenceLink === '' && ContentData.tagType === 'news') {
                                    if (ContentData.category !== '' && ContentData.category !== undefined && ContentData.category !== 'undefined') {
                                        /* (async () => { */
                                        json.data.data[i]['tab_json'] = []
                                        json.data.data[i]['tab_json'][0] = { tabName: 'All', id: '', cmsPageId: 'cmsPageId' }
                                        const promise = axios.get(`${process.env.REACT_APP_API_URL}news/category/level1/${ContentData.category}`).then(NewsCategory => {
                                            //json.data.data[i]['tab_json'] = NewsCategory.data.data
                                            NewsCategory.data.data.forEach(async (Temp: any, k: any) => {
                                                return json.data.data[i]['tab_json'][k + 1] = { tabName: Temp.name, id: Temp._id, cmsPageId: 'cmsPageId' }
                                                let aa = await axios.get(`${process.env.REACT_APP_API_URL}news/front?sortField=publishOn&sortType=desc&recordPerPage=1000&category=${Temp._id}`).then(NewsJson => {
                                                    /* return json.data.data[i]/* ['tab_json'][k] */
                                                    return { tabName: Temp.name, references: [] }
                                                }).then(temp => {
                                                    return json.data.data[i]['tab_json'][k] = { tabName: Temp.name, references: temp }
                                                })
                                                return aa
                                                //promises.push(promise);
                                            })
                                            //return json.data.data[i]
                                        })
                                        promises.push(promise);
                                        /* })() */

                                        var sites = GlobalContent.AllSiteData.filter((e: any) => e.is_main === 'yes' && e._id === siteAccess)
                                        var ID = ''
                                        if (sites.length === 0) {
                                            ID = `&siteAccess=${siteAccess}`
                                        }

                                        const promise1 = axios.get(`${process.env.REACT_APP_API_URL}news/front?sortField=publishOn&sortType=desc&recordPerPage=1000&category=${ContentData.category}${ID}`).then(NewsJson => {
                                            json.data.data[i]['references'] = NewsJson.data.data.data
                                        })
                                        promises.push(promise1);
                                    }
                                }
                            }
                        } else if (ContentData.type === 'imgRepeat') {
                            if (ContentData.tSlider === 'timeline') {
                                ContentData.image_json.forEach((ReferencesID: any, j: any) => {
                                    /* json.data.data[i]['references'] = [] */
                                    if (ReferencesID.s_date !== '' && ReferencesID.s_date !== undefined && ReferencesID.s_date !== 'undefined') {
                                        const promise1 = axios.get(`${process.env.REACT_APP_API_URL}calendar/event/${ReferencesID.s_date}/${ReferencesID.s_date}`).then(Eventjson => {

                                            let ___ = Eventjson.data.data.calender.filter((ell: { englishDate: string; }) => ell.englishDate === ReferencesID.s_date)
                                            let Tithi = GetGujAndEngDateSameDate(___)

                                            if (Eventjson.data.data.calender.length > 0) {
                                                Eventjson.data.data.calender[0]['month'] = moment(ReferencesID.s_date).format('MMM').toUpperCase()
                                                Eventjson.data.data.calender[0]['date'] = moment(ReferencesID.s_date).format('DD')
                                                Eventjson.data.data.calender[0]['year'] = moment(ReferencesID.s_date).format('YYYY')
                                                Eventjson.data.data.calender[0]['day'] = moment(ReferencesID.s_date).format('dddd')
                                            } else {
                                                Eventjson.data.data.calender = [{ month: moment(ReferencesID.s_date).format('MMM'), date: moment(ReferencesID.s_date).format('DD'), year: moment(ReferencesID.s_date).format('YYYY'), day: moment(ReferencesID.s_date).format('dddd') }]
                                            }

                                            Eventjson.data.data.calender[0]['indianDate'] = Tithi.english
                                            return json.data.data[i]['image_json'][j]['dates'] = Eventjson.data.data.calender[0]
                                        })
                                        promises.push(promise1);
                                    }
                                })
                            }
                        } else if (ContentData.type === 'schedule') {
                            /* if (ContentData.tSlider === 'timeline') { */
                            if (ContentData.schedule_json !== undefined) {
                                ContentData.schedule_json.forEach((ReferencesID: any, j: any) => {
                                    /* json.data.data[i]['references'] = [] */
                                    if (ReferencesID.sc_date !== '' && ReferencesID.sc_date !== undefined && ReferencesID.sc_date !== 'undefined') {
                                        const promise1 = axios.get(`${process.env.REACT_APP_API_URL}calendar/event/${ReferencesID.sc_date}/${ReferencesID.sc_date}`).then(Eventjson => {
                                            let ___ = Eventjson.data.data.calender.filter((ell: { englishDate: string; }) => ell.englishDate === ReferencesID.sc_date)
                                            let Tithi = GetGujAndEngDateSameDate(___)
                                            Eventjson.data.data.calender[0]['month'] = moment(ReferencesID.sc_date).format('MMM').toUpperCase()
                                            Eventjson.data.data.calender[0]['date'] = moment(ReferencesID.sc_date).format('DD')
                                            Eventjson.data.data.calender[0]['year'] = moment(ReferencesID.sc_date).format('YYYY')
                                            Eventjson.data.data.calender[0]['day'] = moment(ReferencesID.sc_date).format('ddd').toUpperCase()
                                            Eventjson.data.data.calender[0]['dayF'] = moment(ReferencesID.sc_date).format('dddd')
                                            Eventjson.data.data.calender[0]['indianDate'] = Tithi.english
                                            return json.data.data[i]['schedule_json'][j]['dates'] = Eventjson.data.data.calender[0]
                                        })
                                        promises.push(promise1);
                                    }
                                })
                            }
                            /* } */
                        } else if (ContentData.type === 'youtube_channel') {
                            if (ContentData.channel_id !== '') {
                                json.data.data[i]['references'] = []
                                var Channels = ContentData.channel_id.split(',')

                                const promisesY = [] as any;

                                Channels.forEach((channel: any, k: any) => {
                                    const promise = fetch(`https://www.googleapis.com/youtube/v3/playlistItems?part=snippet&maxResults=50&playlistId=${channel}&key=AIzaSyAvgKluPp0op8MFMUspvC9R1jToA51gt3I`, { method: "GET", headers: {} }).then(response => response.json()).then(PlayList => {
                                        if (PlayList.items.length > 0) {
                                            PlayList.items.forEach(async (Video: any, k: any) => {
                                                var Obj = {
                                                    publishOn: Video.snippet.publishedAt,
                                                    streamId: Video.snippet.resourceId.videoId,
                                                    text: Video.snippet.title,
                                                    name: Video.snippet.title,
                                                }
                                                json.data.data[i]['references'].push(Obj)
                                            })
                                        }
                                        return json.data.data[i]['references']
                                        //json.data.data[i]['references'].push(Referencesjson.data.data[0])
                                    })
                                    promises.push(promise)
                                })

                                /* Promise.all(promisesY).then(dsa => {
                                    json.data.data[i]['references'] = json.data.data[i]['references'].sort((a: any, b: any) => (a.publishOn < b.publishOn) ? 1 : -1)
                                }) */
                            }
                        }
                    }
                })
                Promise.all(promises).then(dsa => {
                    if (cmsPageId === '62f42bbf669082d78ead1820') {
                        json.data.data.push({ type: 'tForm', formName: 'RegistratonF', status: 'active', title: 'form' })
                    }
                    setCmsData({ data: json.data.data })

                    if (json.data.data[0] !== undefined && json.data.data[0].type === 'imgRepeat' && json.data.data[0].tSlider === 'full') {
                        setIsLikeHomePage('yes')
                    } else {
                        if (json.data.data.length > 0) {
                            /* if (json.data.data[0].image_json !== undefined && json.data.data[0].image_json.length === 1) {
                                setIsLikeHomePage('no')
                            } else { */
                            //setIsLikeHomePage('yes')
                            /* } */
                        }
                    }
                    setisPageLoad('yes')
                    if (setisPageLoadFromMain !== undefined && setisPageLoadFromMain !== 'undefined' && setisPageLoadFromMain !== '') {
                        setisPageLoadFromMain('yes')
                    }
                })
            })
        }
        /* }) */
    }, [cmsPageId]);

    const [audioSrc, setaudioSrc] = useState('');
    const myRef = useRef<HTMLAudioElement>(null);

    const startAudio = (src: any, i: any, dI: any) => {
        setaudioSrc(src)
        setTimeout(() => {
            myRef.current?.play();
            let __ = [...CmsData.data]
            let index = -1
            __.forEach((ringtone: any, ij: any) => {
                if (ringtone.references !== undefined) {
                    index = ringtone.references.findIndex((ell: { audioStatus: boolean; }) => ell.audioStatus === true)
                    if (index > -1) {
                        __[ij]['references'][index]['audioStatus'] = false
                    }
                }
            })
            __[dI]['references'][i]['audioStatus'] = true
            setCmsData({ data: __ })
        }, 1);
    }

    const pauseAudio = (src: any, i: any, dI: any) => {
        myRef.current?.pause();
        let __ = [...CmsData.data]
        __[dI]['references'][i]['audioStatus'] = false
        setCmsData({ data: __ })
    }

    const onEndedRingtone = () => {
        let __ = [...CmsData.data]
        let index = -1
        __.forEach((ringtone: any, ij: any) => {
            if (ringtone.references !== undefined) {
                index = ringtone.references.findIndex((ell: { audioStatus: boolean; }) => ell.audioStatus === true)
                if (index > -1) {
                    __[ij]['references'][index]['audioStatus'] = false
                }
            }
        })
        setCmsData({ data: __ })
    }

    const handleDownload = async (url: string, filename: any, _id: any, i: any, CurrentDownloadType: any) => {
        axios.put(`${process.env.REACT_APP_API_URL}download/${CurrentDownloadType}/count/${_id}`, {}).then(async function (response) {
            /* let _a_ = [...Downloads.data]
            _a_[i]['no_of_download'] = (_a_[i]['no_of_download'] !== undefined) ? _a_[i]['no_of_download'] + 1 : 1
            setDownloads({ data: _a_ }) */
            let a = document.createElement('a');
            await fetch(url, { method: "GET", headers: {} }).then(async response => await response.blob()).then(async blob => {
                let blobUrl = await window.URL.createObjectURL(blob);
                a.href = blobUrl;
                a.download = url.replace(/^.*[\\\/]/, '');
                document.body.appendChild(a);
                a.click();
                await window.URL.revokeObjectURL(blobUrl);
                a.remove();
            })
        })
    }

    const HandleChangeDarshan = (DarshanData: any) => {
        setDarshanData({ data: DarshanData })
    }

    const currentKey = `${Date.now()}-${cmsPageId}`;
    const timeout = { enter: 300, exit: 200 };

    const handleopen = (i: any) => {
        setisOpen(true)
        setphotoIndex(i)
    }

    const formik = useFormik({
        initialValues: {
            fName: "",
            mName: "",
            lName: "",
            city: "",
            address: "",
            postCode: "",
            phoneNo: "",
            displayOrder: 0
        },
        enableReinitialize: true,
        validationSchema: ValidSchema,
        onSubmit: (values: any) => {
            axios.post(`${process.env.REACT_APP_API_URL}form/registration`, values).then(function (response) {
                formik.resetForm()
                toast.success("Your data is saved!", {
                    position: toast.POSITION.TOP_CENTER
                });
            }).catch(function (err) {
                return err.response.data
            })
        }
    })

    return (
        <>
            <audio ref={myRef} src={audioSrc} onEnded={onEndedRingtone} />
            <ToastContainer />
            <style>
                {`.main-content .container {padding-left : 0; padding-right:0}
                .main-content .container{width:100%;}
                .main-content-sidebar .container {padding-left : 0; padding-right:0}`}
            </style>
            {isPageLoad === 'yes' ? <>
                {/* <TransitionGroup component="main" className="page-main">
                    <CSSTransition
                        key={currentKey}
                        timeout={timeout}
                        classNames="fade"
                        appear
                    > */}
                {isOpen && (
                    <Lightbox
                        wrapperClassName='image-gallery-popup'
                        mainSrc={Lightimages.data[photoIndex]}
                        nextSrc={Lightimages.data[(photoIndex + 1) % Lightimages.data.length]}
                        prevSrc={Lightimages.data[(photoIndex + Lightimages.data.length - 1) % Lightimages.data.length]}
                        onCloseRequest={() => setisOpen(false)}
                        onMovePrevRequest={() => setphotoIndex((photoIndex + Lightimages.data.length - 1) % Lightimages.data.length)}
                        onMoveNextRequest={() => setphotoIndex((photoIndex + 1) % Lightimages.data.length)}
                        imageCaption={LightimagesCaption.data[photoIndex]}
                        imageTitle={LightimageTitle.data[photoIndex]}
                    />
                )}
                {/* {JSON.stringify(CmsData)} */}
                {CmsData.data && CmsData.data.length > 0 ?
                    <>
                        {CmsData.data.map((data: any, i: any) => {
                            if (data.status === 'active') {
                                let SectionInElement
                                var Class = ''
                                let SectionType = ''
                                if (i === 0) {
                                    Class += ` ${firstBlockClass}`
                                }

                                if (TypeOfContent === 'tab' && i === 0) {
                                    Class += ` mt-0`
                                } else {

                                    if (data.pageType === 'news') {
                                        Class += ` mt-4`
                                    } else {
                                        Class += ` mt-5`
                                    }

                                }

                                //ContainerClass += Class
                                data['ContainerClass'] = `${ContainerClass} ${Class}`
                                data['cmsContentKey'] = `${i}-cmsContent`

                                if (data.align === 'center') {
                                    data['ContainerAlignClass'] = 'justify-content-center'
                                } else if (data.align === 'flex-start') {
                                    data['ContainerAlignClass'] = 'justify-content-start'
                                } else if (data.align === 'flex-end') {
                                    data['ContainerAlignClass'] = 'justify-content-end'
                                } else if (data.align === 'space-between') {
                                    data['ContainerAlignClass'] = 'justify-content-between'
                                } else if (data.align === 'space-around') {
                                    data['ContainerAlignClass'] = 'justify-content-around'
                                }

                                if (data.dStyle === 'sidebar') {
                                    SectionType = 'sidebar'
                                } else {
                                    SectionType = 'main'
                                }

                                if (data.pageType === 'news') {
                                    SectionType = 'main'
                                }
                                if (data.type === 'imgRepeat') {
                                    if (data.image_json !== undefined) {
                                        data.image_json = data.image_json.filter((e: any) => e?.imageStatus !== "inactive" || e?.imageStatus === undefined)
                                    }
                                    if (data.tSlider === 'full') {
                                        /* if (IsLikeHomePage === 'no') {
                                            SectionType = 'banner'
                                            SectionInElement = <div className={`${ContainerClass} ${i + 1 === CmsData.data.length ? 'mb-5' : ''}`} key={data.cmsContentKey}><ImageTextRow Data={{ image: data.image_json[0].imageurl, desc: data.image_json[0].s_header }} /></div>
                                        } else { */
                                        SectionType = 'banner'
                                        SectionInElement = <BannerSlider Data={data} siteAccess={siteAccess} pageSlug={pageSlug} />
                                        /* } */
                                    } else if (data.tSlider === 'right_leaf') {
                                        SectionInElement = <LiveDarshan Data={data} />
                                    } else if (data.tSlider === '4_column_box') {
                                        SectionInElement = <AboutUsBlocks Data={data} />
                                    } else if (data.tSlider === '4_column_image_only' || data.tSlider === 'image_gallery') {
                                        SectionInElement = <Sayings Data={data} />
                                    } else if (data.tSlider === '4_column_popup') {
                                        SectionInElement = <Guruparampara Data={data} />
                                    } else if (data.tSlider === '5_column_box') {
                                        SectionInElement = <GlobalNetwork Data={data} />
                                    } else if (data.tSlider === 'timeline') {
                                        SectionInElement = <div className={`${data.ContainerClass} ${i + 1 === CmsData.data.length ? 'mb-5' : ''}`} key={data.cmsContentKey}>
                                            <div className="itinerary-section mt-5">
                                                <SectionTitle title={data.title} titleClassOrg={`mb-4`} type={`title-active-color`} />
                                                <div className="timeline">
                                                    {/* <div className="line text-muted"></div> */}
                                                    <div aria-multiselectable="true" className="panel-group" id="accordion" role="tablist">
                                                        {data.image_json.map((Timeline: any, i: any) => {
                                                            let Dates
                                                            if (Timeline.s_date !== '' && Timeline.s_date !== undefined && Timeline.s_date !== 'undefined') {
                                                                if (data.dStyle === 'year') {
                                                                    Dates = <div className="day-text-month" style={{ minWidth: '120px' }}>{Timeline.dates.year}</div>
                                                                } else if (data.dStyle === 'month_year') {
                                                                    Dates = <div className="day-text-month" style={{ minWidth: '120px' }}>{Timeline.dates.month} {Timeline.dates.year}</div>
                                                                } else if (data.dStyle === 'month_day') {
                                                                    Dates = <><div className="day-text-month" style={{ minWidth: '120px' }}>{Timeline.dates.month}</div>
                                                                        <div className="day-text-day">{Timeline.dates.date}</div>
                                                                    </>
                                                                } else if (data.dStyle === 'month') {
                                                                    Dates = <><div className="day-text-month" style={{ minWidth: '120px' }}>{Timeline.dates.month}</div>
                                                                    </>
                                                                } else {
                                                                    Dates = <>
                                                                        {Timeline?.dates?.day !== undefined ? <div className="day-text-month">{Timeline?.dates?.day}</div> : ''}
                                                                        <div className="day-text-day d-flex flex-row flex-md-column align-items-center gap-2 gap-md-0">
                                                                            <span>{Timeline?.dates?.date}</span>
                                                                            {Timeline?.dates !== undefined ? <span className="d-block active-color font-weight-600 font-size-16px line-height mb-0 mb-md-2 d-flex flex-column flex-md-row gap-1"><span>{Timeline?.dates?.month}</span><span>{Timeline?.dates?.year}</span></span> : <><span>{Timeline?.dates?.month}</span><span>{Timeline?.dates?.year}</span></> }
                                                                        </div>
                                                                        
                                                                        <div className="day-text-gujarati">
                                                                            {Timeline?.dates !== undefined ? <span className="d-block">Samvat {Timeline?.dates?.indianYear}</span> : ''}
                                                                            {Timeline?.dates !== undefined ? <span className="d-block">{Timeline?.dates?.indianDate}</span> : ''}
                                                                        </div>
                                                                    </>
                                                                }
                                                            }
                                                            return (
                                                                (Timeline.s_date !== '' && Timeline.s_date !== undefined && Timeline.s_date !== 'undefined') ?
                                                                    <div className={`panel-group-contain default`} key={`${i}-TimeLine`}>
                                                                        <div className="panel panel-default">
                                                                            <div className="day-text d-flex flex-column">
                                                                                {Dates}
                                                                            </div>
                                                                            {Timeline.s_title !== '' && Timeline.s_title !== 'undefined' && Timeline.s_title !== undefined ?
                                                                                <div className="panel-heading" id="iti_heading0" role="tab">
                                                                                    <h4 className="panel-title">
                                                                                        {Timeline.s_l_url !== '' && Timeline.s_l_url !== undefined && Timeline.s_l_url !== 'undefined' ? <a href={Timeline.s_l_url} title={Timeline.s_title}>{Timeline.s_title}</a> : Timeline.s_title}
                                                                                    </h4>
                                                                                </div> : ''}

                                                                            <div className="panel-collapse collapse in show">
                                                                                <div className="panel-body">
                                                                                    <div className={`row ${(Timeline.s_title !== '' && Timeline.s_title !== 'undefined' && Timeline.s_title !== undefined) ? `mt-3` : ``}`}>
                                                                                        {(Timeline.imageurl !== '') ? <div className="col-12 col-sm-12 col-md-6 col-lg-4 col-xl-4">
                                                                                            <div className="border-radius-15px">
                                                                                                <LazyImage
                                                                                                    loadingSrc={`${process.env.PUBLIC_URL}/img/placeholder.png`}
                                                                                                    actualSrc={`${Timeline.imageurl}`}
                                                                                                    errorSrc={`${process.env.PUBLIC_URL}/img/placeholder.png`}
                                                                                                    width={`100%`}
                                                                                                    imageWidth={`750`} imageHeight={`460`}
                                                                                                    imageClass="border-radius-15px"
                                                                                                    ContainerClass="border-radius-15px"
                                                                                                    style={{ position: 'absolute', top: 0, left: 0, objectFit: 'cover', height: '100%', objectPosition: 'top' }}
                                                                                                    title={Timeline.s_title}
                                                                                                    alt={Timeline.s_title}
                                                                                                />
                                                                                            </div>
                                                                                        </div> : ''}
                                                                                        {(Timeline.s_video_url !== '' && Timeline.s_video_url !== 'undefined' && Timeline.s_video_url !== undefined) ? <div className="col-12 col-sm-12 col-md-6 col-lg-4 col-xl-4 position-relative mt-3 mt-md-0" >
                                                                                            <div className="position-relative w-100" style={{ paddingBottom: `calc(460 / 750 * 100%)` }}>
                                                                                                <iframe width="100%" className="border-radius-15px" src={Timeline.s_video_url} title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen style={{ position: 'absolute', top: 0, left: 0, objectFit: 'cover', height: '100%' }}></iframe>
                                                                                            </div>
                                                                                        </div> : ''}

                                                                                        <div className={`${(Timeline.imageurl !== '' && Timeline.s_video_url !== '' && Timeline.imageurl !== 'undefined' && Timeline.s_video_url !== 'undefined' && Timeline.imageurl !== undefined && Timeline.s_video_url !== undefined) ? 'col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 mt-3' : 'col-12 col-sm-12 col-md-12 col-lg-8 col-xl-8 mt-3 mt-lg-0'}`}>
                                                                                            <p className="description " dangerouslySetInnerHTML={{
                                                                                                __html: Timeline.s_desc
                                                                                            }}></p>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    : ''
                                                            )
                                                        })}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                    } else if (data.tSlider === 'slider_quotes') {
                                        SectionInElement = <LiveBroadcast Data={data} />
                                    }
                                } else if (data.type === 'schedule') {
                                    /* if (data.tSlider === 'timeline') { */
                                    SectionInElement = <div className={`${data.ContainerClass} ${i + 1 === CmsData.data.length ? 'mb-5' : ''}`} key={data.cmsContentKey}>
                                        <div className="itinerary-section mt-5">
                                            <SectionTitle title={data.title} titleClassOrg={`mb-4`} type={`title-active-color`} />
                                            <div className="timeline">
                                                {/* <div className="line text-muted"></div> */}
                                                <div aria-multiselectable="true" className="panel-group" id="accordion" role="tablist">
                                                    {data.schedule_json !== undefined && data.schedule_json.map((Timeline: any, i: any) => {

                                                        let Dates
                                                        if (data.dStyle === 'year') {
                                                            Dates = <div className="day-text-month">{Timeline?.dates?.year}</div>
                                                        } else {
                                                            Dates = <>
                                                                {/* <div className="day-text-month">{Timeline?.dates?.month} {Timeline?.dates?.year}</div>
                                                                <div className="day-text-day">{Timeline?.dates?.date}</div>
                                                                <div className="day-text-gujarati">
                                                                    {Timeline?.dates?.day !== undefined ? <span className="d-block active-color font-weight-600">{Timeline?.dates?.day}</span> : ''}
                                                                    {Timeline?.dates !== undefined ? <span className="d-block">Samvat {Timeline?.dates?.indianYear}</span> : ''}
                                                                    {Timeline?.dates !== undefined ? <span className="d-block">{Timeline?.dates?.indianDate}</span> : ''}
                                                                </div> */}
                                                                <div className="day-text-month">{Timeline?.dates?.dayF}</div>

                                                                <div className="day-text-day d-flex flex-row flex-md-column align-items-center gap-2 gap-md-0">
                                                                    <span>{Timeline?.dates?.date}</span>
                                                                    {Timeline?.dates !== undefined ? <span className="d-block active-color font-weight-600 font-size-16px line-height mb-0 mb-md-2 d-flex flex-column flex-md-row gap-1"><span>{Timeline?.dates?.month}</span><span>{Timeline?.dates?.year}</span></span> : <><span>{Timeline?.dates?.month}</span><span>{Timeline?.dates?.year}</span></> }
                                                                </div>
                                                                
                                                                <div className="day-text-gujarati">
                                                                    {Timeline?.dates !== undefined ? <span className="d-block">Samvat {Timeline?.dates?.indianYear}</span> : ''}
                                                                    {Timeline?.dates !== undefined ? <span className="d-block">{Timeline?.dates?.indianDate}</span> : ''}
                                                                </div>
                                                            </>
                                                        }
                                                        return (
                                                            (Timeline.sc_date !== '' && Timeline.sc_date !== undefined && Timeline.sc_date !== 'undefined') ?
                                                                <div className={`panel-group-contain default`} key={`${i}-TimeLine`}>
                                                                    <div className="panel panel-default">
                                                                        <div className="day-text d-flex flex-column">
                                                                            {Dates}
                                                                        </div>
                                                                        {Timeline.title !== '' && Timeline.title !== 'undefined' && Timeline.title !== undefined ?
                                                                            <div className="panel-heading" id="iti_heading0" role="tab">
                                                                                <h4 className="panel-title">
                                                                                    {Timeline.link !== '' && Timeline.link !== undefined && Timeline.link !== 'undefined' ? <a href={Timeline.link} title={Timeline.title}>{Timeline.title}</a> : Timeline.title}
                                                                                </h4>
                                                                            </div> : ''}

                                                                        {Timeline.desc !== '' && Timeline.desc !== undefined && Timeline.desc !== 'undefined' ? <div className="panel-collapse collapse in show">
                                                                            <div className="panel-body">
                                                                                <div className={`row ${(Timeline.title !== '' && Timeline.title !== 'undefined' && Timeline.title !== undefined) ? `mt-3` : ``}`}>
                                                                                    <div className={`col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12`}>
                                                                                        <p className="description " dangerouslySetInnerHTML={{
                                                                                            __html: Timeline.desc
                                                                                        }}></p>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div> : ''}
                                                                    </div>
                                                                </div>
                                                                : ''
                                                        )
                                                    })}
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    /* } */
                                } else if (data.type === 'bannerImageText') {
                                    if (i === 0) {
                                        SectionType = 'banner'
                                    }
                                    SectionInElement = <div className={`${data.ContainerClass} ${i + 1 === CmsData.data.length ? 'mb-5' : ''}`} key={data.cmsContentKey}><ImageTextRow Data={{ image: data.image, desc: data.desc }} TypeOfContent={TypeOfContent} /></div>
                                } else if (data.type === 'cnt_p50' || data.type === 'cnt_100') {
                                    if (data.dStyle === 'bgImage' && data.type === 'cnt_p50') {
                                        SectionInElement = <Aboutus Data={data} />
                                    } else if (data.dStyle === 'bgImage' && data.type === 'cnt_100') {
                                        SectionInElement = <section className="aboutus pb-2 pt-4" key={data.cmsContentKey}>
                                            {/* <div className="Flower-top d-none d-md-block">
                                                    <img src={process.env.PUBLIC_URL + '/img/Flower_Light.svg'} className="img-fluid lazyload" alt="Flower_Light" title="Flower_Light" />
                                                </div> */}
                                            <div className={`${data.ContainerClass}`} >
                                                <div className="row">
                                                    <SectionTitle title={data.title} titleClassOrg={`mb-4`} type={`title-active-color`} />
                                                    <div className="col-lg-12 text-start" /* animateIn="fadeIn" animateOnce={true} delay={100} */ >
                                                        <p className="description about-desc" style={{ textIndent: '0' }}>
                                                            <PrintDesc desc={data.desc} />
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                        </section>
                                    } else {
                                        // white-bg-with-borders
                                        // title-with-bg-active-color
                                        let className = ''
                                        if (data.dStyle === 'bgTitleBorder') {
                                            className = 'white-bg-with-borders'
                                        } else if (data.dStyle === 'sidebar') {
                                            className = 'border-with-bg-radius p-3'
                                        }
                                        SectionInElement = <div className={`${data.ContainerClass} ${i + 1 === CmsData.data.length ? 'mb-5' : ''}`} key={data.cmsContentKey}><Text100OR50 Data={data} title={data.title} className={className} titleClass={(data.dStyle === 'bgTitleBorder') ? `title-with-bg-active-color` : `title-active-color`/* data.titleClass */} PlaceOfDesign={data.PlaceOfDesign} SectionType={SectionType} /></div>
                                    }
                                } else if (data.type === 'img_cnt' || data.type === 'cnt_img') {
                                    SectionInElement = <div className={`${data.ContainerClass} ${i + 1 === CmsData.data.length ? 'mb-5' : ''}`} key={data.cmsContentKey}><IMGDesc Data={data} title={data.title} className={(SectionType === 'sidebar') ? 'border-with-bg-radius p-3' : ''} titleClass={`title-active-color`/* data.titleClass */} PlaceOfDesign={data.PlaceOfDesign} SectionType={SectionType} /></div>
                                } else if (data.type === 'img_img') {
                                    if (data.pageType !== 'news' || i !== 0) {
                                        SectionInElement = <div className={`${data.ContainerClass} ${i + 1 === CmsData.data.length ? 'mb-5' : ''}`} key={data.cmsContentKey}><ImgImg Data={data} title={data.title} className={data.class} titleClass={`title-active-color`/* data.titleClass */} /></div>
                                    }
                                } else if (data.type === 'tab_panel') {
                                    SectionInElement = <div className={`${data.ContainerClass} ${i + 1 === CmsData.data.length ? 'mb-5' : ''}`} key={data.cmsContentKey}><TabPanel Data={data} setisPageLoadFromMain={setisPageLoadFromMain} /></div>
                                } else if (data.type === 'shlok') {
                                    SectionInElement = <div className={`${data.ContainerClass} ${i + 1 === CmsData.data.length ? 'mb-5' : ''}`} key={data.cmsContentKey}><Text100OR50 Data={{
                                        type: 'cnt_p50',
                                        sectionType: data.type,
                                        title: data.title,
                                        desc: data.shlok,
                                        rDesc: data.shlokEnglish,
                                        class: 'white-bg-with-design-and-shadow',
                                        titleClass: 'small-title',
                                        align: data.align
                                    }} title={data.title} className={`white-bg-with-design-and-shadow`} titleClass={`small-title`} PlaceOfDesign={undefined} SectionType={SectionType} /></div>
                                } else if (data.type === 'quotes') {
                                    if (data.image !== '' && data.image !== undefined) {
                                        SectionInElement = <LiveBroadcast Data={data} />
                                    } else {
                                        SectionInElement = <div className={`${data.ContainerClass} ${i + 1 === CmsData.data.length ? 'mb-5' : ''}`} key={data.cmsContentKey}><Text100OR50 Data={{
                                            type: 'cnt_100',
                                            title: data.title,
                                            desc: `<div class='cms-text'>
                                                <svg width="44" height="48" viewBox="0 0 44 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M17.3438 0.820312V2.5C14.6875 4.11458 12.8776 5.61198 11.9141 6.99219C11.2109 8.00781 10.8594 9.20573 10.8594 10.5859C10.8594 11.4193 11.0156 12.0312 11.3281 12.4219C11.6146 12.8125 11.9531 13.0078 12.3438 13.0078C12.6042 13.0078 12.9688 12.9167 13.4375 12.7344C14.1406 12.5 14.7135 12.3828 15.1562 12.3828C16.1979 12.3828 17.1224 12.7734 17.9297 13.5547C18.737 14.3359 19.1406 15.3125 19.1406 16.4844C19.1406 17.8125 18.737 18.8411 17.9297 19.5703C16.8099 20.5599 15.5078 21.0547 14.0234 21.0547C12.2526 21.0547 10.6771 20.3125 9.29688 18.8281C7.91667 17.3438 7.22656 15.5078 7.22656 13.3203C7.22656 10.2474 8.3724 7.44792 10.6641 4.92188C12.2005 3.25521 14.4271 1.88802 17.3438 0.820312Z" fill="#F86800"/>
                                                <path d="M34.3438 0.820312V2.5C31.6875 4.11458 29.8776 5.61198 28.9141 6.99219C28.2109 8.00781 27.8594 9.20573 27.8594 10.5859C27.8594 11.4193 28.0156 12.0312 28.3281 12.4219C28.6146 12.8125 28.9531 13.0078 29.3438 13.0078C29.6042 13.0078 29.9688 12.9167 30.4375 12.7344C31.1406 12.5 31.7135 12.3828 32.1562 12.3828C33.1979 12.3828 34.1224 12.7734 34.9297 13.5547C35.737 14.3359 36.1406 15.3125 36.1406 16.4844C36.1406 17.8125 35.737 18.8411 34.9297 19.5703C33.8099 20.5599 32.5078 21.0547 31.0234 21.0547C29.2526 21.0547 27.6771 20.3125 26.2969 18.8281C24.9167 17.3438 24.2266 15.5078 24.2266 13.3203C24.2266 10.2474 25.3724 7.44792 27.6641 4.92188C29.2005 3.25521 31.4271 1.88802 34.3438 0.820312Z" fill="#F86800"/>
                                                </svg>
                                                ${data.quotes.replace(/(<([^>]+)>)/ig, '')}
                                            </div>${(data.author !== undefined) ? `<p class='quote-by'>${data.author}</p>` : ''}`,
                                            class: (data.title.trim() !== '') ? 'no-bg-with-design-2' : 'white-bg-with-design-and-shadow-quote',
                                            titleClass: 'title-active-color',
                                            PlaceOfDesign: 'content',
                                            align: data.align
                                        }} title={data.title} className={(data.title.trim() !== '') ? 'no-bg-with-design-2' : 'white-bg-with-design-and-shadow-quote'} titleClass={`title-active-color`} PlaceOfDesign={`content`} TypeOfContent={TypeOfContent} /></div>
                                    }
                                } else if (data.type === 'tModule') {
                                    if (IsLikeHomePage === 'yes' && data.tagType === 'news') {
                                        SectionInElement = <News Data={data} siteAccess={siteAccess} />
                                    } else if (data.tagType === 'calender') {
                                        SectionInElement = <Calender siteAccess={siteAccess} />
                                    } else if (data.tagType === 'website') {
                                        SectionInElement = <OurWebsites siteAccess={siteAccess} data={data} />
                                    } else if (data.tagType === 'dWallpaper' || data.tagType === 'dRingtone' || data.tagType === 'dArtwork' || data.tagType === 'dImages') {
                                        if (data.references.length > 0) {
                                            SectionInElement = <div className={`${data.ContainerClass} ${i + 1 === CmsData.data.length ? 'mb-5' : ''} t-module`} key={data.cmsContentKey}>
                                                <SectionTitle title={data.title} titleClassOrg={`mb-4`} type={`title-active-color`} />
                                                <DownloadSections DownloadSectionsData={data.references} DownloadType={data.downloadType} CurrentDownloadSubType={``} CurrentDownloadType={data.downloadTypeName} title="" className={`${SectionType === 'sidebar' ? 'border-with-bg-radius p-3' : 'mt-4 mb-0'}`} startAudio={startAudio} pauseAudio={pauseAudio} DownloadIndex={i} SectionType={SectionType} handleDownload={handleDownload} />
                                            </div>
                                        }
                                    } else {
                                        if (data.tagType === 'news' && data.referenceLink === '') {
                                            SectionInElement = <div className={`${data.ContainerClass} tab-panel-container pipeband_performance ${i + 1 === CmsData.data.length ? 'mb-5' : ''}`} key={data.cmsContentKey}>
                                                <TabPanel Data={data} Pagetype='pipeband_performance' RefData={data.references} setisPageLoadFromMain={setisPageLoadFromMain} />
                                            </div>
                                        } else if (data.dStyle === 'page' && data.tagType === 'imageGallery') {
                                            if (data.references.length > 0) {
                                                SectionInElement = <div className={`${data.ContainerClass} ${i + 1 === CmsData.data.length ? 'mb-5' : ''} mt-5 t-module `} key={data.cmsContentKey}><ImageWithTextMasonary Data={data.references} title="" className="" handleopen={handleopen} /></div>
                                            }
                                        } else if (data.dStyle === 'tab' && data.tagType === 'imageGallery') {
                                            SectionInElement = <div className={`${data.ContainerClass} ${i + 1 === CmsData.data.length ? 'mb-5' : ''} mt-5 t-module tab-panel-container`} key={data.cmsContentKey}><TabPanel Data={data} Pagetype='imageGallery' titleClass={`title-active-color`} handleopen={handleopen} setisPageLoadFromMain={setisPageLoadFromMain} /></div>
                                        } else if (data.dStyle === "tablist" && data.tagType === 'news') {
                                            SectionInElement = <div className={`${data.ContainerClass} ${i + 1 === CmsData.data.length ? 'mb-5' : ''} mt-5 t-module tab-panel-container`} key={data.cmsContentKey}><TabPanel Data={data} Pagetype={data.tagType} titleClass={`title-active-color`} handleopen={handleopen} setisPageLoadFromMain={setisPageLoadFromMain} /></div>
                                        } else {
                                            if (data.tagType === 'pBook' && data.dStyle === 'pdf' && /* data.references.length === 1 && */ data.references[0]?.bookPdf !== '') {
                                                if (data.references[0]?.bookPdf !== undefined) {
                                                    SectionInElement = <div className={`${data.ContainerClass} ${i + 1 === CmsData.data.length ? 'mb-5' : ''} t-module`} key={data.cmsContentKey}><iframe className="mt-4 mb-4" style={{ width: '100%', height: '80vh' }} src={`${process.env.REACT_APP_SITE_URL}pdfjs/web/viewer.html?file=${data.references[0]?.bookPdf}`}></iframe></div>
                                                }
                                            } else if (data.references.length > 0) {
                                                if ((data.tagType === 'imageGallery' || data.tagType === 'pVideo') && data.pageType === 'news') {
                                                    SectionType = 'sidebar'
                                                    if (data.title === '' || data.title === undefined || data.title === 'undefined') {
                                                        if (data.tagType === 'imageGallery') {
                                                            data.title = 'Image Gallery'
                                                        } else if (data.tagType === 'pVideo') {
                                                            data.title = 'Video Gallery'
                                                        }
                                                    }
                                                }
                                                SectionInElement = <div className={`${data.ContainerClass} ${i + 1 === CmsData.data.length ? 'mb-5' : ''} t-module`} key={data.cmsContentKey}><ImageWithText Data={data.references} ContainerAlignClass={data.align} title={data.title} className={`${(SectionType === 'sidebar') ? `border-with-bg-radius p-3 mt-4 mb-0` : `mt-5`}`} imageWidth="750" imageHeight="460" SectionType={SectionType} pageType={data.pageType} objectPosition={(data.tagType === 'pVideo') ? 'center' : 'top'} /></div>
                                            }
                                        }
                                    }
                                } else if (data.type === 'tabPanel') {
                                    SectionInElement = <div className={`${(data.tab_json.length > 1) ? data.ContainerClass + ' ' : ''}tab-panel-container ${data.tab_json.length > 1 && i + 1 === CmsData.data.length ? 'mb-5' : ''}`} key={data.cmsContentKey}>
                                        <TabPanel Data={data} Pagetype='cms' titleClass={`title-active-color`} className={(data.tab_json.length > 1) ? 'mt-4 mb-4' : 'mt-4'} setisPageLoadFromMain={setisPageLoadFromMain} />
                                    </div>
                                } else if (data.type === 'button') {
                                    SectionInElement = <div className={`${data.ContainerClass} button-container ${i + 1 === CmsData.data.length ? 'mb-5' : ''}`} key={data.cmsContentKey}>
                                        <Buttons Data={data.button_json} title={data.title} className='' DataClass='' ContainerAlignClass={data['ContainerAlignClass']} />
                                    </div>
                                } else if (data.type === 'facts') {
                                    SectionInElement = <div className={`${data.ContainerClass} mt-5 counter-container ${i + 1 === CmsData.data.length ? 'mb-5' : ''}`} key={data.cmsContentKey}>
                                        <Counter Data={data.facts_json} title={data.title} className='' DataClass='' ContainerAlignClass={data['ContainerAlignClass']} />
                                    </div>
                                } else if (data.type === 'live_darshan') {
                                    // mt-5 mb-5 m-4
                                    var is = data.live_json.filter((e: any) => e.title === 'Lord Swaminarayan')
                                    var title = data.live_json[0].title
                                    var desc = data.live_json[0].desc
                                    var images = data.live_json[0].images
                                    if (is.length > 0) {
                                        title = is[0].title
                                        desc = is[0].desc
                                        images = is[0].images
                                    }
                                    /* if(TypeOfContent === 'tab') */
                                    SectionInElement = <>
                                        <div className={(TypeOfContent === 'tab') ? `` : `${data.ContainerClass} mt-5 ${i + 1 === CmsData.data.length ? 'mb-5' : ''}`} key={data.cmsContentKey}>
                                            <LiveDarshanSection LiveDarshan={data.live_json} title="" className={(TypeOfContent === 'tab') ? `ms-1 me-1 ms-md-4 me-md-4` : ''} HandleChangeDarshan={HandleChangeDarshan} />
                                            <LiveDarshanSectionType2 LiveDarshan={DarshanData.data.length > 0 ? DarshanData.data[0].images : images} DarshanTimingsData={(DarshanData.data.length > 0) ? DarshanData.data[0].desc : desc} title={`${(DarshanData.data.length > 0) ? DarshanData.data[0].title : title}`} className={`mt-3${(TypeOfContent === 'tab') ? ` mb-3 m-1 mt-md-5 mb-md-5 m-md-4` : ''} border-with-bg-radius overflow-hidden`} />
                                        </div>
                                    </>
                                } else if (data.type === 'pipeband_performance') {
                                    SectionInElement = <div className={`${data.ContainerClass} pipeband_performance ${i + 1 === CmsData.data.length ? 'mb-5' : ''}`} key={data.cmsContentKey}>
                                        <TabPanel Data={data} Pagetype='pipeband_performance' setisPageLoadFromMain={setisPageLoadFromMain} />
                                    </div>
                                } else if (data.type === 'youtube_channel') {
                                    if (data.references.length > 0) {
                                        data.references.sort((a: any, b: any) => (parseInt(moment(a.publishOn).format('x')) < parseInt(moment(b.publishOn).format('x'))) ? 1 : -1)
                                        SectionInElement = <div className={`${data.ContainerClass} ${i + 1 === CmsData.data.length ? 'mb-5' : ''}`} key={data.cmsContentKey}>
                                            <PreviousBroadCast BroadCastdata={data.references} title={data.title} />
                                        </div>
                                    }
                                } else if (data.type === 'tForm') {
                                    if (data.formName === 'RegistratonF') {
                                        SectionInElement = <div className={`${data.ContainerClass} ${i + 1 === CmsData.data.length ? 'mb-5' : ''}`} key={data.cmsContentKey}>
                                            <div className="form-section mt-5">
                                                <SectionTitle title={data.title} titleClassOrg={`mb-4`} type={`title-active-color`} />
                                                <form onSubmit={formik.handleSubmit} noValidate className="justify-content-center">
                                                    <div className="row justify-content-center">
                                                        <div className={`col-md-4 mb-3`}>
                                                            <div className="d-flex flex-column position-relative">
                                                                <label htmlFor="fName" className="required">First Name</label>
                                                                <input id="fName" className="form-control" type={`text`} {...formik.getFieldProps('fName')} placeholder='First Name' />
                                                                {formik.touched.fName && formik.errors.fName ? <span className="text-danger">{formik.errors.fName}</span> : ''}
                                                            </div>
                                                        </div>

                                                        <div className={`col-md-4 mb-3`}>
                                                            <div className="d-flex flex-column form-group">
                                                                <label htmlFor="mName" className="required">Middle Name</label>
                                                                <input id="mName" className="form-control" type={`text`} {...formik.getFieldProps('mName')} placeholder='Middle Name' />
                                                                {formik.touched.mName && formik.errors.mName ? <span className="text-danger">{formik.errors.mName}</span> : ''}
                                                            </div>
                                                        </div>

                                                        <div className={`col-md-4 mb-3`}>
                                                            <div className="d-flex flex-column form-group">
                                                                <label htmlFor="lName" className="required">Last Name</label>
                                                                <input id="lName" className="form-control" type={`text`} {...formik.getFieldProps('lName')} placeholder='Last Name' />
                                                                {formik.touched.lName && formik.errors.lName ? <span className="text-danger">{formik.errors.lName}</span> : ''}
                                                            </div>
                                                        </div>

                                                        <div className={`col-md-4 mb-3`}>
                                                            <div className="d-flex flex-column form-group">
                                                                <label htmlFor="city" className="required">Village</label>
                                                                <input id="city" className="form-control" type={`text`} {...formik.getFieldProps('city')} placeholder='Village' />
                                                                {formik.touched.city && formik.errors.city ? <span className="text-danger">{formik.errors.city}</span> : ''}
                                                            </div>
                                                        </div>

                                                        {/*  <div className={`col-md-4 mb-3`}>
                                                            <div className="d-flex flex-column form-group">
                                                                <label htmlFor="address">Address</label>
                                                                <input id="address" className="form-control" type={`text`} {...formik.getFieldProps('address')} placeholder='Address' />
                                                                {formik.touched.address && formik.errors.address ? <span className="text-danger">{formik.errors.address}</span> : ''}
                                                            </div>
                                                        </div> */}

                                                        <div className={`col-md-4 mb-3`}>
                                                            <div className="d-flex flex-column form-group">
                                                                <label htmlFor="postCode" className="required">Post Code</label>
                                                                <input id="postCode" className="form-control" type={`text`} {...formik.getFieldProps('postCode')} placeholder='Post Code' />
                                                                {formik.touched.postCode && formik.errors.postCode ? <span className="text-danger">{formik.errors.postCode}</span> : ''}
                                                            </div>
                                                        </div>

                                                        <div className={`col-md-4 mb-3`}>
                                                            <div className="d-flex flex-column form-group">
                                                                <label htmlFor="phoneNo" className="required">Mobile No.</label>
                                                                <input id="phoneNo" className="form-control" type={`text`} {...formik.getFieldProps('phoneNo')} placeholder='Mobile No.' />
                                                                {formik.touched.phoneNo && formik.errors.phoneNo ? <span className="text-danger">{formik.errors.phoneNo}</span> : ''}
                                                            </div>
                                                        </div>


                                                        <div className="d-flex flex-row justify-content-center mt-4">
                                                            <button className="btn btn-primary" type="submit">Submit</button>
                                                        </div>
                                                    </div>
                                                </form>
                                            </div>
                                        </div>
                                    }
                                }

                                if (SectionType === 'banner') {
                                    renderBanner.push(SectionInElement)
                                } else if (SectionType === 'main') {
                                    renderMainPage.push(SectionInElement)
                                } else if (SectionType === 'sidebar') {
                                    renderSidebar.push(SectionInElement)
                                }
                            }
                        })
                        }
                    </> : ''
                }
                {renderBanner}
                {/* <ScrollAnimation animateIn="fadeIn" animateOnce={true} delay={100} > */}
                {(() => {

                    if (Object.keys(NewsData).length > 0) {
                        renderSidebar.push(<PopularNews title={NewsData.title} NewsData={NewsData.data} className="mt-4" />)
                    }
                    if (renderSidebar.length > 0) {
                        return (
                            <div className="container">
                                <div className={`row ${Object.keys(NewsData).length === 0 ? `mt-4` : ``}`}>
                                    <div className="col-12 col-sm-12 col-md-12 col-lg-8 col-xl-9 order-1 order-lg-1 main-content">
                                        {renderMainPage}
                                    </div>
                                    <div className="col-12 col-sm-12 col-md-12 col-lg-4 col-xl-3 order-2 order-lg-2 pb-4 pb-lg-0 main-content-sidebar">
                                        {renderSidebar}
                                    </div>
                                </div>
                            </div>)
                    } else {
                        return (<>{renderMainPage}{renderSidebar}</>)
                    }
                })()}
                {/* </ScrollAnimation> */}

                {/* </CSSTransition>
                </TransitionGroup> */}
            </> : <div className="loading_page"></div>}
        </>
    )
}

export default CMSContent