import React, { useContext, useEffect, useRef, useState } from "react"
import Banner from './sections/Banner'
import BreadCrumb from "./sections/BreadCrumb"
import { SectionTitle, PopularNews, SayingsImageAndText, PageLoader, LimitDesc, NewsListBlock, OnlyTitleBanner, NoDataFound, MetaData } from "./sections/CommonSections";

import GlobalContext from './Core/PageData'
import { Link, useNavigate, useParams } from "react-router-dom"
import axios, { CancelTokenSource } from "axios";
import moment from "moment";
var cancelToken: CancelTokenSource;
const News: React.FC<any> = ({ From, searchString }: any): any => {
    let { siteSlug, newsCategorySlug } = useParams();
    const { GlobalContent, setGlobalContent } = useContext(GlobalContext as any) as any
    if (From === 'main') {
        document.title = `News${GlobalContent.pageTitle}`;
    } else {
        document.title = `Search: ${searchString}${GlobalContent.pageTitle}`;
    }
    const navigate = useNavigate()
    const [isPageLoad, setisPageLoad] = useState('no')
    const [isPageLoadMore, setisPageLoadMore] = useState('no')
    const [BreadCrumbdata, setBreadCrumbdata] = useState({ data: [] as any[] })
    const [NewsList, setNewsList] = useState({ data: [] as any[] })
    const [PopularNewsList, setPopularNewsList] = useState({ data: [] as any[] })
    const [MostUpcoming, setMostUpcoming] = useState({ data: [] as any[] })
    const [Newsyears, setNewsyears] = useState({ data: [] as any[] })
    const [NewsCategory, setNewsCategory] = useState({ data: [] as any[] })
    const [PaginationData, setPaginationData] = useState({ currentpage: '', nextpage: '', perpage: '', remainingCount: '', total: '', totalpages: '' })
    const [FilterForm, setFilterForm] = useState({ data: [{ category: [], year: [], search: (searchString !== undefined) ? searchString : '', columnOrder: 'publishOn:desc' }] } as any)
    const query = new URLSearchParams(window.location.search)
    const c = query.get('c');
    const [NewsCategoryQuery, setNewsCategoryQuery] = useState((newsCategorySlug !== undefined) ? newsCategorySlug : c)
    const myRef = useRef<HTMLButtonElement>(null);
    const isMounted = useRef(true)
    useEffect(() => {
        isMounted.current = true;
        (async () => {
            setisPageLoad('no')
            setGlobalContent({ ...GlobalContent, IsMediaPlayershow: false })
            setBreadCrumbdata({
                data: [
                    {
                        path: `${process.env.PUBLIC_URL}/`,
                        linkName: 'Home',
                        status: '',
                    }, {
                        path: `${process.env.PUBLIC_URL}/${process.env.REACT_APP_NEWS_SLUG}`,
                        linkName: 'News',
                        status: '',
                    }
                ]
            })
            axios.get(`${process.env.REACT_APP_API_URL}news/category/all`).then(json => {
                json.data.data.sort((a: any, b: any) => (a.name > b.name) ? 1 : -1)
                var NewsCat = json.data.data.filter((e: any) => e.parent_id === '0')
                NewsCat.map((e: any, i: any) => {
                    NewsCat[i]['child'] = json.data.data.filter((e: any) => e.parent_id === e._id)
                })

                /* json.data.data.map((e:any,i:any) => {
                    var filters = []
                    filters.push(`category=${e._id}`)
                    if(siteSlug !== undefined){
                        var site_id = GlobalContent.AllSiteData.filter((e: any) => e.site_url === siteSlug)[0]._id
                        filters.push(`siteAccess=${site_id}`)
                    }
                    axios.get(`${process.env.REACT_APP_API_URL}news/front?${filters.join('&')}`).then((ff : any) => {
                        json.data.data[i]['total'] = ff.data.data.total
                    })
                }) */

                if (isMounted.current) {
                    setNewsCategory({ data: json.data.data })
                }
                return json.data.data
            }).then(async temp => {
                if (From === 'main') {
                    await axios.get(`${process.env.REACT_APP_API_URL}news/front?sortField=noOfView&sortType=desc&recordPerPage=5`).then(json => {
                        if (isMounted.current) {
                            setPopularNewsList({ data: json.data.data.data })
                        }
                    })
                }
                var NewsCSlug = (newsCategorySlug !== undefined) ? newsCategorySlug : c
                if (NewsCSlug !== null) {
                    var CatId = temp.filter((e: any) => e.slug === NewsCSlug)[0]?._id
                    var FilterForm_ = { data: [{ ...FilterForm.data[0], category: [CatId] }] }
                    setFilterForm(FilterForm_)
                    changeType('first', FilterForm_)
                } else {
                    changeType('first', FilterForm)
                }
            })

            var yy = [] as any
            for (let yearPrint = parseInt(moment().format('YYYY')) as any; yearPrint >= 1999; yearPrint--) {
                yy.push(yearPrint)
            }
            setNewsyears({ data: yy })
        })();
        return (() => {
            isMounted.current = false;
        })
    }, [/* siteSlug, newsCategorySlug */newsCategorySlug])

    useEffect(() => {
        /* isMounted.current = true;
        (async () => { */
        let filters = [] as any
        filters.push(`recordPerPage=${(window.innerWidth < 768) ? '1' : '2'}`)
        filters.push(`recordOffser=0`)
        var site_id = ''
        var sites = [] as any
        if (siteSlug !== undefined) {
            sites = GlobalContent.AllSiteData.filter((e: any) => e.site_url === siteSlug)
            /* if (sites.length > 0) {
                site_id = GlobalContent.AllSiteData.filter((e: any) => e.site_url === siteSlug)[0]._id
                filters.push(`siteAccess=${site_id}`)
            } */
        } else {
            sites = GlobalContent.AllSiteData.filter((e: any) => e.is_main === 'yes')
        }
        if (sites.length > 0) {
            site_id = sites[0]?._id/* GlobalContent.AllSiteData.filter((e: any) => e.site_url === siteSlug)[0]?._id */
            filters.push(`siteAccess=${site_id}`)
        }
        if (FilterForm.data !== undefined) {
            if (FilterForm.data[0].category[0]) {
                filters.push(`category=${FilterForm.data[0].category[0]}`)
            }
            if (FilterForm.data[0].year[0]) {
                filters.push(`year=${FilterForm.data[0].year[0]}`)
            }
            if (FilterForm.data[0].search) {
                filters.push(`search=${FilterForm.data[0].search}`)
            }
            if (FilterForm.data[0].columnOrder) {
                filters.push(`sortField=${FilterForm.data[0].columnOrder.split(':')[0]}`)
                filters.push(`sortType=${FilterForm.data[0].columnOrder.split(':')[1]}`)
            }
        }

        if (From === 'main') {
            axios.get(`${process.env.REACT_APP_API_URL}news/front?${filters.join('&')}`).then(json => {
                setMostUpcoming({ data: json.data.data.data })
            })
        }
        /* })();
        return (() => {
            isMounted.current = false;
        }) */
    }, [FilterForm])
    /* useEffect(() => {
        if (NewsCategory.data.length > 0) {
            if (NewsCategoryQuery !== null) {
                var CatId = NewsCategory.data.filter((e: any) => e.slug === NewsCategoryQuery)[0]._id
                setFilterForm({ data: [{ ...FilterForm.data[0], category: [CatId] }] })

                changeType('first', FilterForm)
            }
        }
    }, [NewsCategory]) */
    const handleScrollNews = (e: any, FilterForm: any) => {
        const target = e.target
        if ((target.scrollHeight - target.scrollTop) === target.clientHeight) {
            changeType('loadmore', FilterForm)
        }
    }
    useEffect(() => {
        window.addEventListener('scroll', () => {
            const windowHeight = "innerHeight" in window ? window.innerHeight : document.documentElement.offsetHeight;
            const body = document.body;
            const html = document.documentElement;
            const docHeight = Math.max(body.scrollHeight, body.offsetHeight, html.clientHeight, html.scrollHeight, html.offsetHeight);
            const windowBottom = (windowHeight + ((window.innerWidth < 992) ? 900 : 400)) + window.pageYOffset;
            if (windowBottom >= docHeight) {
                if (!myRef.current?.disabled) {
                    myRef.current?.click()
                }
            }
        });
    }, []);

    const resetFilters = () => {
        setFilterForm({ data: [{ category: [], year: [], search: '', columnOrder: 'publishOn:desc' }] })
        changeType('first', { data: [{ category: [], year: [], search: '', columnOrder: 'publishOn:desc' }] })
    }
    const changeType = (typeOfLoad = 'first', MyFilters = {} as any) => {
        /* isMounted.current = true; */
        let _isMounted_ = true;
        /* (async () => { */
        if (typeof cancelToken != typeof undefined) {
            cancelToken.cancel("Operation canceled due to new request.")
        }
        cancelToken = axios.CancelToken.source()
        let filters = [] as any
        filters.push(`recordPerPage=12`)
        if (From === 'main') {
            filters.push(`recordOffser=${(window.innerWidth < 768) ? '1' : '2'}`)
        }
        if (typeOfLoad === 'loadmore') {
            filters.push(`page=${PaginationData.nextpage}`)
            setisPageLoadMore('no')
            /* setisPageLoadMore('processing') */
            /* isPageLoadMore = 'processing' */
        } else {
            /* setisPageLoad('no') */
        }
        var site_id = ''
        var sites = [] as any
        if (siteSlug !== undefined) {
            sites = GlobalContent.AllSiteData.filter((e: any) => e.site_url === siteSlug)
            /* if (sites.length > 0) {
                site_id = GlobalContent.AllSiteData.filter((e: any) => e.site_url === siteSlug)[0]._id
                filters.push(`siteAccess=${site_id}`)
            } */
        } else {
            sites = GlobalContent.AllSiteData.filter((e: any) => e.is_main === 'yes')
        }
        if (sites.length > 0) {
            site_id = sites[0]?._id/* GlobalContent.AllSiteData.filter((e: any) => e.site_url === siteSlug)[0]?._id */
            filters.push(`siteAccess=${site_id}`)
        }

        if (MyFilters.data !== undefined) {
            if (MyFilters.data[0].category[0]) {
                filters.push(`category=${MyFilters.data[0].category[0]}`)
            }
            if (MyFilters.data[0].year[0]) {
                filters.push(`year=${MyFilters.data[0].year[0]}`)
            }
            if (MyFilters.data[0].search) {
                filters.push(`search=${MyFilters.data[0].search}`)
            }
            if (MyFilters.data[0].columnOrder) {
                filters.push(`sortField=${MyFilters.data[0].columnOrder.split(':')[0]}`)
                filters.push(`sortType=${MyFilters.data[0].columnOrder.split(':')[1]}`)
            }
        }

        axios.get(`${process.env.REACT_APP_API_URL}news/front?${filters.join('&')}`/* , { cancelToken: cancelToken.token } */).then(json => {

            if (_isMounted_) {
                setPaginationData({ currentpage: json.data.data.currentpage, nextpage: json.data.data.nextpage, perpage: json.data.data.perpage, remainingCount: json.data.data.remainingCount, total: json.data.data.total, totalpages: json.data.data.totalpages })
            }
            if (typeOfLoad === 'loadmore') {
                let dwnTemp = [...NewsList.data]
                dwnTemp = dwnTemp.concat(json.data.data.data)
                setNewsList({ data: dwnTemp })
            } else {
                if (_isMounted_) {
                    setNewsList({ data: json.data.data.data })
                }
            }

            if (_isMounted_) {
                setisPageLoad('yes')
                setisPageLoadMore('yes')
            }
            /* setisPageLoadMore('ready') */
            if (sites.length > 0) {
                if (typeOfLoad === 'first') {
                    setGlobalContent({ ...GlobalContent, siteData: sites[0], headerClass: '', search: { ...GlobalContent.search, News: json.data.data.total } })
                } else {
                setGlobalContent({ ...GlobalContent, siteData: sites[0], headerClass: '' })
            }
            }
        }).catch((err) => {
            if (axios.isCancel(err)) {
                //console.log('successfully aborted');
            } else {
                //console.log('successfully aborted1');
            }
        })
        /* })();
        return (() => {
            isMounted.current = false;
        }) */
        /* return () => {
            _isMounted_ = false;
        }     */
    }

    useEffect(() => {
        ChangeFilters(searchString, 'search')
    }, [searchString])

    const ChangeFilters = (_id: any, type: any, status = 'add') => {
        const promises = [] as any;
        let dd = {} as any
        if (type === 'category') {
            if (_id !== undefined && _id !== '') {
                var CatUrl = NewsCategory.data.filter((e: any) => e._id === _id)[0].slug
                var SiteU = siteSlug !== null && siteSlug !== undefined ? `${siteSlug}/` : ``
                if (From === 'main') {
                    navigate(`${process.env.PUBLIC_URL}/${SiteU}${process.env.REACT_APP_NEWS_SLUG}category/${CatUrl}`, { replace: true })
                }
            } else if (From === 'main') {
                if (siteSlug !== undefined) {
                    navigate(`${process.env.PUBLIC_URL}/${siteSlug}/${process.env.REACT_APP_NEWS_SLUG}`, { replace: true })
                } else {
                    navigate(`${process.env.PUBLIC_URL}/${process.env.REACT_APP_NEWS_SLUG}`, { replace: true })
                }
            }

            dd = { data: [{ ...FilterForm.data[0], category: [_id] }] }
            setFilterForm({ data: [{ ...FilterForm.data[0], category: [_id] }] })
            promises.push(dd)
        } else if (type === 'year') {
            dd = { data: [{ ...FilterForm.data[0], year: [_id] }] }
            setFilterForm({ data: [{ ...FilterForm.data[0], year: [_id] }] })
            promises.push(dd)
        } else if (type === 'search') {
            dd = { data: [{ ...FilterForm.data[0], search: _id }] }
            setFilterForm({ data: [{ ...FilterForm.data[0], search: _id }] })
            promises.push(dd)
        } else if (type === 'columnOrder') {
            dd = { data: [{ ...FilterForm.data[0], columnOrder: _id }] }
            setFilterForm({ data: [{ ...FilterForm.data[0], columnOrder: _id }] })
            promises.push(dd)
        }
        Promise.all(promises).then(pp => {
            changeType('first', pp[0])
        })
    }

    return (
        <>
            <style>
                {`.site-contain{overflow:unset}`}
            </style>
            {isPageLoad === 'yes' ? <>
                {From === 'main' ? <MetaData keywords={`news, maninagar, swaminarayan, gadi, sansthan`} description={`Latest news from Maninagar Shree Swaminarayan Gadi Sansthan`} /> : ''}
                {/* <Banner BannerImage="Shreejibapa_1920.png" /> */}
                <section className={`${From === 'main' ? 'site-contain' : ''}`}>
                    {From === 'main' ? <div className="Flower-top d-none d-md-block">
                        <img src={process.env.PUBLIC_URL + '/img/Flower-top.png'} className="img-fluid lazyload" alt="Flower" title="Flower" />
                    </div> : ''}
                    <div className={`${(From === 'main') ? 'container' : ''}`}>
                        {From === 'main' ?
                            <><BreadCrumb BreadCrumbdata={BreadCrumbdata.data} />
                                <div className="">
                                    <SectionTitle title={`News`} />
                                </div></>
                            : ''}

                        {(MostUpcoming.data.length > 0) ?
                            <div className="row mt-4 they-say-carousel news-image-text">
                                <div className="col-md-6">
                                    <SayingsImageAndText Image={(moment('2023-03-14').format('X') > moment(MostUpcoming.data[0]['createdDate']?.split('T')[0]).format('X')) ? MostUpcoming.data[0].image : MostUpcoming.data[0].image_thumb_774_inside} Text={MostUpcoming.data[0]['title']} URL={`${process.env.PUBLIC_URL}/${MostUpcoming.data[0]['_slug']}`} textLength="-1" imageWidth='360' imageHeight='180' dateDisplay='true' data={MostUpcoming.data[0]} />
                                </div>
                                {MostUpcoming.data[1] !== undefined ?
                                    <div className="col-md-6 mt-3 mt-md-0 d-none d-md-block">
                                        <SayingsImageAndText Image={(moment('2023-03-14').format('X') > moment(MostUpcoming.data[1]['createdDate']?.split('T')[0]).format('X')) ? MostUpcoming.data[1].image : MostUpcoming.data[1].image_thumb_774_inside} Text={MostUpcoming.data[1]['title']} URL={`${process.env.PUBLIC_URL}/${MostUpcoming.data[1]['_slug']}`} textLength="-1" imageWidth='360' imageHeight='180' dateDisplay='true' data={MostUpcoming.data[1]} />
                                    </div> : ''}
                            </div>
                            : ''}

                        <div className={`d-flex row ${(From === 'main') ? 'mt-5' : ''} flex-wrap`}>
                            {(From === 'main') ? <>
                                <div className="col-auto">
                                    <a className="btn btn-6 h-100 d-flex flex-column justify-content-center pt-0 pb-0" onClick={resetFilters} title="Reset"><span>Reset</span></a>
                                </div>
                                <div className={`col-auto col-md-4`}>
                                    <div className="d-flex flex-row">
                                        <input className="form-control" type={`text`} value={FilterForm.data[0].search} placeholder='Search News' name="search" onChange={(e) => ChangeFilters(e.target.value, 'search')} />
                                    </div>
                                </div>
                            </> : ''}
                            <div className="col-12 col-md-1 mt-3 mt-md-0">
                                <select className="form-control" defaultValue={FilterForm.data[0].year[0]} onChange={(e) => ChangeFilters(e.target.value, 'year')}>
                                    <option value="">Year</option>
                                    {Newsyears.data && Newsyears.data.length > 0 && Newsyears.data.map((data: any, i: any) => (
                                        <option key={`${i}-Newsyears`} value={data} /* selected={(FilterForm.data[0].year.findIndex((ell: any) => ell === data + '') > -1) ? true : false} */>{data}</option>
                                    ))}
                                </select>
                            </div>
                            <div className="col-12 col-md-2 mt-3 mt-md-0">
                                <select className="form-control" defaultValue={FilterForm.data[0].category[0]} onChange={(e) => ChangeFilters(e.target.value, 'category')}>
                                    <option value="">Category</option>
                                    {NewsCategory.data && NewsCategory.data.length > 0 && NewsCategory.data.map((data: any, i: any) => (
                                        <option key={`${i}-NewsCategory`} value={data._id} /* selected={(FilterForm.data[0].category.findIndex((ell: any) => ell === data._id) > -1) ? true : false} */>{data.name}</option>
                                    ))}
                                </select>
                            </div>
                            <div className="col-12 col-md-3 mt-3 mt-md-0">
                                <select className="form-control" defaultValue={FilterForm.data[0].columnOrder} onChange={(e) => ChangeFilters(e.target.value, 'columnOrder')}>
                                    <option value="">Sort By</option>
                                    <option key={`Published-Date-Decending-NewsCategory`} value={`publishOn:desc`} /* selected={(FilterForm.data[0].columnOrder === 'publishOn:desc') ? true : false} */>Published Date Decending</option>
                                    <option key={`Published-Date-Ascending-NewsCategory`} value={`publishOn:asc`} /* selected={(FilterForm.data[0].columnOrder === 'publishOn:asc') ? true : false} */>Published Date Ascending</option>

                                    <option key={`Event-Date-Decending-NewsCategory`} value={`eventDate:desc`} /* selected={(FilterForm.data[0].columnOrder === 'eventDate:desc') ? true : false} */>Event Date Decending</option>
                                    <option key={`Event-Date-Ascending-NewsCategory`} value={`eventDate:asc`} /* selected={(FilterForm.data[0].columnOrder === 'eventDate:asc') ? true : false} */>Event Date Ascending</option>

                                </select>
                            </div>
                            <div className="col-auto col-md-2 d-flex flex-column justify-content-center d-none">
                                <div className="font-size-18px black-color font-family-futura-bt text-capitalize">{PaginationData.total} Items</div>
                            </div>
                        </div>

                        <div className="row mt-5">
                            <div className={`col-12 col-sm-12 col-md-12 ${(From === 'main') ? 'col-lg-8 col-xl-9' : ''} order-1 order-lg-1`}>
                                <div className="row news news-list" onScroll={(e) => handleScrollNews(e, FilterForm)}>
                                    {NewsList.data && NewsList.data.length > 0 ? <>
                                        {NewsList.data && NewsList.data.length > 0 && NewsList.data.map((data: any, i: any) => {
                                            if (1 === 1/* i > 1 */) {
                                                return (
                                                    <NewsListBlock data={data} key={`${i}-${data._id}-NewsListBlock`} searchText={FilterForm.data[0].search} type={From} />
                                                )
                                            }
                                        })}
                                    </> : <NoDataFound Text={(From === 'main') ? 'No data found!' : 'There are no results for your search query.<br/>Please refine your search.'} />}
                                </div>
                                {/* <div className="text-center active-color mt-3 font-size-15">
                                    {(isPageLoadMore === 'processing') ? 'Loading...' : ''}
                                </div> */}
                                {PaginationData.nextpage !== '' && PaginationData.nextpage !== null && PaginationData.nextpage !== undefined ?
                                    <div className="d-flex flex-row justify-content-center mt-4">
                                        <button ref={myRef} className={`btn btn-primary ${(isPageLoadMore === 'yes') ? '' : 'disabled'}`} disabled={(isPageLoadMore === 'yes') ? false : true} style={{ opacity: '0', height: 0, width: 0, padding: 0 }} onClick={() => changeType('loadmore', FilterForm)}>{(isPageLoadMore === 'yes') ? 'Load More' : 'Loading...'}</button>
                                        {(isPageLoadMore === 'yes') ? '' : <span className="text-center active-color font-size-18px font-weight-bold">Loading...</span>}
                                    </div>
                                    : ''}
                            </div>
                            {From === 'main' ? <div className="col-12 col-sm-12 col-md-12 col-lg-4 col-xl-3 order-2 order-lg-2 pb-4 pb-lg-0 mt-4 mt-lg-0">
                                <PopularNews title="Popular news" NewsData={PopularNewsList.data} className="sticky-top news-listing-popular-sidebar" />
                            </div> : ''}
                        </div>
                    </div>
                </section>

            </> : (<PageLoader />)}
        </>
    )
}

export default News