import CacheManager from "../../../service/CacheManager";
import { createSlice } from "@reduxjs/toolkit";
let initialList = CacheManager.get("list") ? CacheManager.get("list") : [];
let initialCurrent = CacheManager.get("current")
  ? CacheManager.get("current")
  : -1;
export const counterSlice = createSlice({
  name: "playerlist",
  initialState: {
    list: initialList,
    current: initialCurrent,
  },
  reducers: {
    setPlayerList: (state: { [x: string]: any; }, action: { payload: { [x: string]: any; }; }) => {
      Object.keys(action.payload).forEach((key) => {
        state[key] = action.payload[key];
      });
    },
  },
  extraReducers: {},
});

export const { setPlayerList } = counterSlice.actions;

export default counterSlice.reducer;
