import React, { useContext, useEffect, useState } from "react";
import GlobalContext from './Core/PageData'
import { Link, useNavigate } from "react-router-dom";
import ScrollAnimation from "react-animate-on-scroll";
import { SectionTitle } from "./sections/CommonSections";
const PageNotFound: React.FC = () => {
    const { GlobalContent, setGlobalContent } = useContext(GlobalContext as any) as any
    document.title = `404 ${GlobalContent.pageTitle}`;
    var sites = GlobalContent.AllSiteData.filter((e: any) => e.is_main === 'yes')
    useEffect(() => {
        setGlobalContent({ ...GlobalContent, siteData: sites[0], headerClass:'' })
    }, [])

    return (
        <div className="site-contain">
            <ScrollAnimation className="mform-section pt-5 pb-5 container" animateIn="fadeIn" animateOnce={true} delay={400}>
                <div className="notfoundrow mb-100 d-flex justify-content-center align-items-center" style={{ height: '55vh' }}>
                    <div className="justify-content-center align-items-center">
                        <h1 className="dark-active-color" title={`404`} style={{ fontSize: '50px' }}>{`404`}</h1>
                        <h3 style={{ fontSize: '24px' }}>Opps! Page not found</h3>
                        <p className="description">We're sorry, the page you requested cannot be found. <br />You can go back to</p>
                        <Link to={`${process.env.PUBLIC_URL}/`} className="btn btn-primary" title="Homepage">Homepage</Link>
                    </div>
                </div>
            </ScrollAnimation>
        </div>
    )
}

export default PageNotFound