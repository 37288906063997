import React, { useContext, useEffect, useRef, useState } from "react"
import Banner from './sections/Banner'
import BreadCrumb from "./sections/BreadCrumb"
import { SectionTitle, PopularNews, SayingsImageAndText, PageLoader, LimitDesc, NewsListBlock, OnlyTitleBanner, NoDataFound, MetaData, HighLighttext, GetCmsPopupSlug, PrintDesc, GetGujAndEngDateSameDate } from "./sections/CommonSections";

import GlobalContext from './Core/PageData'
import { Link, useLocation, useNavigate, useParams } from "react-router-dom"
import axios, { CancelTokenSource } from "axios";
import moment from "moment";
import News from "./News";
import PublicationListing from "./PublicationListing";
var cancelToken: CancelTokenSource;
const Search = () => {

    const { GlobalContent, setGlobalContent } = useContext(GlobalContext as any) as any

    const navigate = useNavigate()
    const [isPageLoad, setisPageLoad] = useState('yes')
    const [PagesData, setPagesData] = useState({ pages: [], isPageLoadMore: 'no', BtnRef: useRef<HTMLButtonElement>(null) }) as any
    const [EventData, setEventData] = useState({ event: [], cmsPage: [], isPageLoadMore: 'no', BtnRef: useRef<HTMLButtonElement>(null) }) as any
    const [Calendar, setCalendar] = useState({ data: [] }) as any
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const searchString = searchParams.get('q');
    const [searchStringS, setsearchStringS] = useState(searchString) as any
    document.title = `Search: ${searchString}${GlobalContent.pageTitle}`;
    useEffect(() => {
        setsearchStringS(searchString)
    }, [searchString])

    useEffect(() => {
        navigate(`${process.env.PUBLIC_URL}/search?q=${searchStringS}`)
    }, [searchStringS])
    const TabSearch = [
        { "tabName": "News", "id": "1" },
        { "tabName": "Audio", "id": "2" },
        { "tabName": "Books", "id": "3" },
        { "tabName": "Pages", "id": "4" },
        { "tabName": "Events", "id": "5" }
    ];
    const [TabId, setTabId] = useState(`1`);
    const onchangeTab = (tab: string) => {
        setTabId(tab)
    }
    useEffect(() => {
        var sites = GlobalContent.AllSiteData.filter((e: any) => e.is_main === 'yes')
        setGlobalContent({ ...GlobalContent, siteData: sites[0], headerClass: '' })
        axios.get(`${process.env.REACT_APP_API_URL}calendar/all`).then(json => {
            setCalendar({ data: json.data.data })
        })
    }, [])

    const changeType = (typeOfLoad = 'first', MyFilters = {} as any, TabId: any) => {
        var filters = []
        if (MyFilters.search) {
            filters.push(`search=${MyFilters.search}`)
        }
        filters.push(`recordPerPage=12`)

        if (TabId === '4') {

            if (typeOfLoad === 'loadmore') {
                filters.push(`page=${PagesData.pages.nextpage}`)
                setPagesData({ ...PagesData, isPageLoadMore: 'no' })
            }

            axios.get(`${process.env.REACT_APP_API_URL}cms/cms-page/front?${filters.join('&')}`).then(json => {
                var pageData = []
                if (typeOfLoad === 'loadmore') {
                    pageData = PagesData.pages.data
                    pageData = pageData.concat(json.data.data.data)
                } else {
                    pageData = json.data.data.data
                }
                setPagesData({ ...PagesData, pages: { ...json.data.data, data: pageData }, isPageLoadMore: 'yes' })
            }).catch((err) => {
                if (axios.isCancel(err)) {
                    //console.log('successfully aborted');
                } else {
                    //console.log('successfully aborted1');
                }
            })
        } else if (TabId === '5') {
            if (typeOfLoad === 'loadmore') {
                filters.push(`page=${EventData.event.nextpage}`)
                setEventData({ ...EventData, isPageLoadMore: 'no' })
            }
            axios.get(`${process.env.REACT_APP_API_URL}event/front?${filters.join('&')}&sortField=startDate&sortType=desc`).then(json => {
                return json.data.data
            }).then(async (Event) => {

                var eventData = Event
                if (typeOfLoad === 'loadmore') {
                    /* eventData = EventData.event.data
                    eventData = eventData.concat(Event.data) */
                    eventData.data = EventData.event.data.concat(Event.data)

                } else {
                    eventData = Event
                }

                var CMSSID = [] as any
                Event.data.map((e: any) => {
                    if (e.cmspage !== undefined && e.cmspage !== '' && e.cmspage !== 'undefined' && !CMSSID.includes(e.cmspage)) {
                        CMSSID.push(e.cmspage)
                    }
                })
                if (CMSSID.length > 0) {
                    var CmsPage = await axios.get(`${process.env.REACT_APP_API_URL}cms/cms-page/ids/${CMSSID.join(',')}`).then(json => {
                        return json.data.data
                    })
                    setEventData({ ...EventData, cmsPage: EventData.cmsPage.concat(CmsPage), event: eventData, isPageLoadMore: 'yes' })
                } else {
                    setEventData({ ...EventData, event: eventData, isPageLoadMore: 'yes' })
                }
            })
        }
    }

    useEffect(() => {
        if (TabId === '4') {
            changeType('first', { search: searchStringS }, TabId)
        } else if (TabId === '5') {
            changeType('first', { search: searchStringS }, TabId)
        }
    }, [TabId, searchStringS])

    useEffect(() => {
        window.addEventListener('scroll', () => {
            const windowHeight = "innerHeight" in window ? window.innerHeight : document.documentElement.offsetHeight;
            const body = document.body;
            const html = document.documentElement;
            const docHeight = Math.max(body.scrollHeight, body.offsetHeight, html.clientHeight, html.scrollHeight, html.offsetHeight);
            const windowBottom = (windowHeight + ((window.innerWidth < 992) ? 900 : 400)) + window.pageYOffset;
            if (windowBottom >= docHeight) {
                if (!PagesData?.BtnRef.current?.disabled) {
                    PagesData?.BtnRef.current?.click()
                }
                if (!EventData?.BtnRef.current?.disabled) {
                    EventData?.BtnRef.current?.click()
                }
            }
        });
    }, []);

    return (
        <>
            <style>
                {`
                .tab-content{overflow:hidden}
                .news-search section{ padding: 15px }
                .publication-search section{ padding: 15px; }
                .publication-search .container{padding:0;width:100%}
                .publication-search .Flower-top { display:none !important}
                .publication-search .main-title-outer{display:none !important}
                .publication-search .publications{margin-top: 0px;}
                `}
            </style>

            <MetaData keywords={`maninagar, swaminarayan, gadi, sansthan`} description={`Maninagar Shree Swaminarayan Gadi Sansthan`} />

            <section className="site-contain">
                <div className="Flower-top d-none d-md-block">
                    <img src={process.env.PUBLIC_URL + '/img/Flower-top.png'} className="img-fluid lazyload" alt="Flower" title="Flower" />
                </div>
                <div className="container" style={{ position: 'relative', }}>
                    <div className="">
                        <SectionTitle title={`Search: ${searchStringS}`} type="type-2" />
                    </div>

                    <div className="mt-5">
                        <div className="d-flex flex-row">
                            <input className="form-control" type={`search`} value={searchStringS} name="search" onChange={(e) => setsearchStringS(e.target.value)} placeholder="Search" />
                            {/* <button type="button" className="btn btn-primary border-radius-top-left border-radius-bottom-left">Search</button> */}
                        </div>
                        {isPageLoad === 'yes' ? <div className="content tab-panel-container mt-3">
                            {TabSearch && TabSearch.length > 1 ? <ul className="nav nav-tabs site-tabs custom-scrollbar">
                                {TabSearch.map((tab_data: any, i: any) => {
                                    return (<li className={`col-auto ${(TabId === tab_data.id) ? 'active' : ''}`} onClick={() => onchangeTab(`${tab_data.id}`)} key={`${i}-TabNav`}>{tab_data.tabName}{/*  {(GlobalContent.search[tab_data.tabName] !== '') ? `(${GlobalContent.search[tab_data.tabName]})` : ''} */}</li>)
                                })}
                            </ul> : ``}
                            <div className={`${TabSearch.length > 1 ? `tab-content` : ``}`}>

                                {TabSearch && TabSearch.length > 0 && TabSearch.map((tab_data: any, i: any) => {
                                    let RenderTab
                                    if (tab_data.tabName === 'News') {
                                        RenderTab = <div className="tab-content-inner news-search"><News From="search" searchString={searchStringS} /></div>
                                    } else if (tab_data.tabName === 'Audio') {
                                        RenderTab = <div className="tab-content-inner publication-search"><PublicationListing From="search" publicationSlug="audio" searchString={searchStringS} /></div>
                                    } else if (tab_data.tabName === 'Books') {
                                        RenderTab = <div className="tab-content-inner publication-search"><PublicationListing From="search" publicationSlug="books" searchString={searchStringS} /></div>
                                    } else if (tab_data.tabName === 'Pages') {
                                        RenderTab = <div className="tab-content-inner p-3">
                                            <PagesDataView PagesData={PagesData.pages?.data} searchString={searchStringS} />

                                            {PagesData?.pages?.nextpage !== '' && PagesData?.pages?.nextpage !== null && PagesData?.pages?.nextpage !== undefined ?
                                                <div className="d-flex flex-row justify-content-center mt-4">
                                                    <button ref={PagesData?.BtnRef} className={`btn btn-primary ${(PagesData.isPageLoadMore === 'yes') ? '' : 'disabled'}`} disabled={(PagesData.isPageLoadMore === 'yes') ? false : true} style={{ opacity: '0', height: 0, width: 0, padding: 0 }} onClick={() => changeType('loadmore', { search: searchStringS }, TabId)}>{(PagesData.isPageLoadMore === 'yes') ? 'Load More' : 'Loading...'}</button>
                                                    {(PagesData.isPageLoadMore === 'yes') ? '' : <span className="text-center active-color font-size-18px font-weight-bold">Loading...</span>}
                                                </div>
                                                : ''}
                                        </div>
                                    } else if (tab_data.tabName === 'Events') {
                                        RenderTab = <div className="tab-content-inner p-3">
                                            <EventsDataView PagesData={EventData} searchString={searchStringS} Calendar={Calendar.data} />

                                            {EventData?.event?.nextpage !== '' && EventData?.event?.nextpage !== null && EventData?.event?.nextpage !== undefined ?
                                                <div className="d-flex flex-row justify-content-center mt-4">
                                                    <button ref={EventData?.BtnRef} className={`btn btn-primary ${(EventData.isPageLoadMore === 'yes') ? '' : 'disabled'}`} disabled={(EventData.isPageLoadMore === 'yes') ? false : true} style={{ opacity: '0', height: 0, width: 0, padding: 0 }} onClick={() => changeType('loadmore', { search: searchStringS }, TabId)}>{(EventData.isPageLoadMore === 'yes') ? 'Load More' : 'Loading...'}</button>
                                                    {(EventData.isPageLoadMore === 'yes') ? '' : <span className="text-center active-color font-size-18px font-weight-bold">Loading...</span>}
                                                </div>
                                                : ''}
                                        </div>
                                    }
                                    return (
                                        (TabId === tab_data.id) ? <div className={`tab-pane fade ${(TabId === tab_data.id) ? 'show active' : ''}`} key={`${i}-TabContent`}>{RenderTab}</div> : ''
                                    )
                                })}
                            </div>
                        </div> : <PageLoader />}

                    </div>
                </div>
            </section>
        </>
    )
}

const PagesDataView = (props: any) => {
    const { GlobalContent, setGlobalContent } = useContext(GlobalContext as any) as any
    return (
        <div className="publication-data-container m-0 search-cms-data-container">
            <div className="d-flex flex-column gap-2 publication-data">
                {props.PagesData !== undefined && props.PagesData.length > 0 && props.PagesData.map((e: any) => {
                    var cmsSlug = GetCmsPopupSlug(e._id, [e], GlobalContent.AllSiteData)
                    return (
                        <>
                            <div className="publication-data-text d-flex flex-column gap-1 pb-3 pb-md-2">
                                <svg className="publication-icon" width="24" height="22" xmlns="http://www.w3.org/2000/svg"><path d="M6 4H5a1 1 0 1 1 0-2h11V1a1 1 0 0 0-1-1H4a2 2 0 0 0-2 2v16c0 1.1.9 2 2 2h12a2 2 0 0 0 2-2V5a1 1 0 0 0-1-1h-7v8l-2-2-2 2V4z" /></svg>
                                <Link to={`${process.env.PUBLIC_URL}${cmsSlug[0].slug}`} className="publication-title line-2-ellipsis" title={e.title}><HighLighttext searchWords={props.searchString} textToHighlight={e.title} /></Link>
                                {/* <span className="publication-types m-0" dangerouslySetInnerHTML={{ __html: `Page &#183; ${e.title}` }}></span> */}
                                {e.metaDescription !== '' && e.metaDescription !== undefined ? <div className="publication-types m-0">Page &#183; <PrintDesc desc={`${e.metaDescription}`} isHtml={false} /></div> : ''}
                            </div>
                        </>
                    )
                })}

                {props.PagesData !== undefined && props.PagesData.length === 0 ? <NoDataFound Text={'There are no results for your search query.<br/>Please refine your search.'} /> : ''}
            </div>
        </div>
    )
}

const EventsDataView = (props: any) => {
    const { GlobalContent, setGlobalContent } = useContext(GlobalContext as any) as any
    return (
        <div className="publication-data-container m-0 search-cms-data-container" key="EventsDataView">
            <div className="d-flex flex-column gap-2 publication-data">
                {props.PagesData !== undefined && props.PagesData.event.data !== undefined && props.PagesData.event.data.length > 0 && props.PagesData.event.data.map((e: any) => {

                    let ___ = props.Calendar.filter((ell: { englishDate: string; }) => ell.englishDate === moment(e.startDate).format('YYYY-MM-DD'))
                    let Tithi = GetGujAndEngDateSameDate(___)
                    console.log(Tithi)
                    var cmsSlug = GetCmsPopupSlug(e.cmspage, props.PagesData.cmsPage, GlobalContent.AllSiteData)
                    return (
                        <div className="publication-data-text d-flex flex-column gap-1 pb-2">
                            <svg className="publication-icon" width="24" height="22" version="1.1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 452.986 452.986">
                                <g>
                                    <path d="M404.344,0H48.642C21.894,0,0,21.873,0,48.664v355.681c0,26.726,21.894,48.642,48.642,48.642 h355.702c26.726,0,48.642-21.916,48.642-48.642V48.664C452.986,21.873,431.07,0,404.344,0z M148.429,33.629h156.043v40.337 H148.429V33.629z M410.902,406.372H42.041v-293.88h368.86V406.372z" />
                                    <rect x="79.273" y="246.23" width="48.642" height="48.664" />
                                    <rect x="79.273" y="323.26" width="48.642" height="48.642" />
                                    <rect x="160.853" y="169.223" width="48.621" height="48.642" />
                                    <rect x="160.853" y="246.23" width="48.621" height="48.664" />
                                    <rect x="160.853" y="323.26" width="48.621" height="48.642" />
                                    <rect x="242.369" y="169.223" width="48.664" height="48.642" />
                                    <rect x="242.369" y="246.23" width="48.664" height="48.664" />
                                    <rect x="242.369" y="323.26" width="48.664" height="48.642" />
                                    <rect x="323.907" y="169.223" width="48.664" height="48.642" />
                                    <rect x="323.907" y="246.23" width="48.664" height="48.664" />
                                </g>
                            </svg>
                            <span className="publication-title line-2-ellipsis" title={e.title}><HighLighttext searchWords={props.searchString} textToHighlight={e.title} /></span>
                            <span className="publication-types m-0" dangerouslySetInnerHTML={{ __html: `${moment(e.startDate).format('MMMM D, YYYY - dddd')} &#183; <span class="font-family-gujarati">${Tithi.gujarati}, ${Tithi.gujaratiIndianYear} - ${Tithi.gujaratiDay}</span>` }}></span>
                            <span className="publication-types m-0" dangerouslySetInnerHTML={{ __html: `${e.desc}` }}></span>

                            {cmsSlug.map((e: any) => {
                                return (<>{e.name !== undefined ? <div className="d-flex flex-row gap-1 event-page-links">
                                    <Link to={`${process.env.PUBLIC_URL}${e.slug}`} className="font-size-14px m-0 event-page-link d-flex flex-row gap-1" style={{ textDecoration: 'underline' }} title={e.name}>
                                        <span>{e.name}</span>
                                        <svg width="20" height="20" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M9.16488 17.6505C8.92513 17.8743 8.73958 18.0241 8.54996 18.1336C7.62175 18.6695 6.47816 18.6695 5.54996 18.1336C5.20791 17.9361 4.87912 17.6073 4.22153 16.9498C3.56394 16.2922 3.23514 15.9634 3.03767 15.6213C2.50177 14.6931 2.50177 13.5495 3.03767 12.6213C3.23514 12.2793 3.56394 11.9505 4.22153 11.2929L7.04996 8.46448C7.70755 7.80689 8.03634 7.47809 8.37838 7.28062C9.30659 6.74472 10.4502 6.74472 11.3784 7.28061C11.7204 7.47809 12.0492 7.80689 12.7068 8.46448C13.3644 9.12207 13.6932 9.45086 13.8907 9.7929C14.4266 10.7211 14.4266 11.8647 13.8907 12.7929C13.7812 12.9825 13.6314 13.1681 13.4075 13.4078M10.5919 10.5922C10.368 10.8319 10.2182 11.0175 10.1087 11.2071C9.57284 12.1353 9.57284 13.2789 10.1087 14.2071C10.3062 14.5492 10.635 14.878 11.2926 15.5355C11.9502 16.1931 12.279 16.5219 12.621 16.7194C13.5492 17.2553 14.6928 17.2553 15.621 16.7194C15.9631 16.5219 16.2919 16.1931 16.9495 15.5355L19.7779 12.7071C20.4355 12.0495 20.7643 11.7207 20.9617 11.3787C21.4976 10.4505 21.4976 9.30689 20.9617 8.37869C20.7643 8.03665 20.4355 7.70785 19.7779 7.05026C19.1203 6.39267 18.7915 6.06388 18.4495 5.8664C17.5212 5.3305 16.3777 5.3305 15.4495 5.8664C15.2598 5.97588 15.0743 6.12571 14.8345 6.34955" stroke="#000000" stroke-width="2" stroke-linecap="round" />
                                        </svg>
                                    </Link>
                                </div> : ''}</>)
                            })}
                        </div>
                    )
                })}
                {props.PagesData !== undefined && props.PagesData.event.data !== undefined && props.PagesData.event.data.length === 0 ? <NoDataFound Text={'There are no results for your search query.<br/>Please refine your search.'} /> : ''}
            </div>
        </div>
    )
}

export { Search }