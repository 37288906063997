import axios from 'axios';
import moment from 'moment';
import React, { useContext, useEffect, useRef, useState, useMemo } from 'react';
import { Link, useNavigate } from "react-router-dom";
import { TransitionGroup, CSSTransition } from "react-transition-group"
import GlobalContext from './Core/PageData'
import { GetGujAndEngDateSameDate } from './sections/CommonSections';
import { useFormik } from 'formik';
import * as Yup from 'yup'

const Header: React.FC<any> = ({ className }: any): any => {
    const refH = useRef<HTMLElement>(null)
    const refSearchBox = useRef<HTMLInputElement>(null)
    const navigate = useNavigate();
    const { GlobalContent, setGlobalContent } = useContext(GlobalContext as any) as any
    const [PrimaryMenu, setPrimaryMenu] = useState({ data: [{ menu_name: '&nbsp;', menu_type: 'primary' }] as any[] });
    const [PrimaryMegaMenuOpen, setPrimaryMegaMenuOpen] = useState({ data: [] as any[] });
    const [SecondaryMenu, setSecondaryMenu] = useState({ data: [{ menu_name: '&nbsp;', menu_type: 'secondary' }] as any[] });
    const [SecondaryMegaMenuOpen, setSecondaryMegaMenuOpen] = useState({ data: [] as any[] });
    const [CurrentDateTithi, setCurrentDateTithi] = useState('');
    const [HeaderSearch, setHeaderSearch] = useState('');
    const [Inspirator, setInspirator] = useState('');
    const bg = `${process.env.PUBLIC_URL}/img/Jeevanpran-Swamibapa-Ashirwad_Part_2%201.png`;
    const timeout = { enter: 300, exit: 200 };
    var img = new Image();
    img.onload = function (e: any) {
        /* alert(e.path[0].width + 'x' + e.path[0].height); */
    }
    img.src = 'https://www.google.com/intl/en_ALL/images/logo.gif';

    useEffect(() => {
        /* axios.get(`${process.env.REACT_APP_API_URL}menu/front-menu/level0`).then(async json => { */
        if (GlobalContent.siteData?._id !== '' && GlobalContent.siteData?._id !== undefined && GlobalContent.siteData?._id !== 'undefined') {
            axios.get(`${process.env.REACT_APP_API_URL}menu/front-menu/front/${(GlobalContent.siteData.parent_site !== '' && GlobalContent.siteData.parent_site !== undefined && GlobalContent.siteData.parent_site !== 'undefined') ? GlobalContent.siteData.parent_site : GlobalContent.siteData?._id}`).then(async json => {
                let primaryMenu = [] as any
                let secondaryMenu = [] as any
                let primaryMenuO = [] as any
                let secondaryMenuO = [] as any
                const promises_ = [] as any;

                if (json.data.data.length === 0) {
                    json.data.data = [{ menu_name: '&nbsp;', menu_type: 'primary', menu_link: GlobalContent.siteData.site_url }, { menu_name: '&nbsp;', menu_type: 'secondary' }]
                    //json.data.data = [{menu_name:'Home', menu_type: 'primary', menu_link: GlobalContent.siteData.site_url }, {menu_name:'&nbsp;', menu_type: 'secondary' }]
                } else {
                    json.data.data = json.data.data.filter((e: any) => e.status === 'active')
                    /* json.data.data.sort((a: any, b: any) => (a.displayOrder > b.displayOrder) ? 1 : -1) */
                    json.data.data.sort((a: any, b: any) => (a.displayOrder - b.displayOrder))

                    var AllMenu = json.data.data
                    json.data.data = json.data.data.filter((e: any) => e.parent_id === '0' || e.parent_id === '')
                    await json.data.data.forEach(async (menu: any, j: any) => {
                        var child = [] as any
                        const promises = [] as any;
                        var level1json = AllMenu.filter((e: any) => e.parent_id === menu._id)
                        var childTemp = [] as any
                        json.data.data[j]['child'] = level1json
                        if (level1json.length > 0) {
                            await level1json.forEach(async (menu: any, K: any) => {
                                childTemp.push({ 'menu_name': menu.menu_name, 'menu_link': menu.menu_link, 'child': [] })
                                var level2json = AllMenu.filter((e: any) => e.parent_id === menu._id)
                                json.data.data[j]['child'][K]['child'] = level2json
                                json.data.data[j]['child'][K]['isopen'] = 0
                            })
                        }
                        json.data.data[j]['child'] = level1json
                        return childTemp
                    })
                }

                return json.data.data
            }).then(async Menu => {
                let primaryMenu = [] as any
                let secondaryMenu = [] as any
                let primaryMenuO = [] as any
                let secondaryMenuO = [] as any
                await Menu.forEach(async (menuData: any, j: any) => {
                    if (menuData.menu_type === 'primary') {
                        primaryMenu.push(menuData)
                        primaryMenuO.push(0)
                    } else if (menuData.menu_type === 'secondary') {
                        secondaryMenu.push(menuData)
                        secondaryMenuO.push(0)
                    }
                })
                if (primaryMenu.length === 0) {
                    primaryMenu.push({ menu_name: '&nbsp;' })
                }
                if (secondaryMenu.length === 0) {
                    secondaryMenu.push({ menu_name: '&nbsp;' })
                }
                /* setPrimaryMenu({ data: primaryMenu })
                setPrimaryMegaMenuOpen({ data: primaryMenuO })
                setSecondaryMenu({ data: secondaryMenu })
                setSecondaryMegaMenuOpen({ data: secondaryMenuO }) */
                return { primaryMenu: primaryMenu, primaryMenuO: primaryMenuO, secondaryMenu: secondaryMenu, secondaryMenuO: secondaryMenuO }
            }).then(async __data__ => {
                axios.get(`${process.env.REACT_APP_API_URL}calendar/event/${moment().format('YYYY-MM-DD')}/${moment().format('YYYY-MM-DD')}`).then(Eventjson => {
                    let ___ = Eventjson.data.data.calender.filter((ell: { englishDate: string; }) => ell.englishDate === moment().format('YYYY-MM-DD'))
                    let Tithi = GetGujAndEngDateSameDate(___)
                    setCurrentDateTithi(`${moment().format('dddd DD MMMM YYYY')}${(Eventjson.data.data.calender.length > 0 && Tithi.english !== '' && Eventjson.data.data.calender[0]?.indianYear !== '') ? ` | ${Tithi.english}, SAMVAT ${Eventjson.data.data.calender[0]?.indianYear}`.toUpperCase() : ''}`)

                    var mh = GlobalContent.AllSiteData.filter((e: any) => e.is_main === 'yes')[0]
                    setInspirator(`${mh.name} | ${mh.acharya_maharaj_name}`.toUpperCase())

                    setPrimaryMenu({ data: __data__.primaryMenu })
                    setPrimaryMegaMenuOpen({ data: __data__.primaryMenuO })
                    setSecondaryMenu({ data: __data__.secondaryMenu })
                    setSecondaryMegaMenuOpen({ data: __data__.secondaryMenuO })
                })
            })
        }
    }, [GlobalContent.siteData])

    const handleopenMenu = async (i: any, type = 'primary', status = 1, Classes = '') => {
        let aa = [] as any
        if (type === 'primary') {
            aa = [...PrimaryMenu.data]
        } else {
            aa = [...SecondaryMenu.data]
        }
        if (i.length === 1) {
            await aa.forEach((el: any, k: any) => {
                aa[k]['isopen'] = 0;
                el.child.forEach((el: any, kl: any) => {
                    aa[k]['child'][kl]['isopen'] = 0;
                })
            })
        } else {
            await aa.forEach((el: any, k: any) => {
                el.child.forEach((el: any, kl: any) => {
                    aa[k]['child'][kl]['isopen'] = 0;
                })
            })
        }
        if (type === 'primary') {
            setPrimaryMenu({ data: aa })
        } else {
            setSecondaryMenu({ data: aa })
        }
        setTimeout(() => {
            if (type === 'primary') {
                aa = [...PrimaryMenu.data]
            } else {
                aa = [...SecondaryMenu.data]
            }
            if (i.length === 1) {
                aa[i[0]]['isopen'] = status;
                if (aa[i[0]]['child'].length > 0) {
                    aa[i[0]]['child'][0]['isopen'] = status;
                }
            } else {
                /* aa[i[0]]['isopen'] = status; */
                if (aa[i[0]]['child'].length > 0) {
                    aa[i[0]]['child'][i[1]]['isopen'] = status;
                }
            }

            if (type === 'primary') {
                setPrimaryMenu({ data: aa })
            } else {
                setSecondaryMenu({ data: aa })
            }

            setTimeout(() => {
                if (Classes !== '' && status === 1 && aa[i[0]]['isInViewport'] === undefined) {
                    let box = document.querySelector(`.${Classes}`) as any;
                    //const box = document.getElementsByClassName(`${Classes}`) as any;
                    if (box !== null) {
                        const rect = box.getBoundingClientRect() as any;
                        const isInViewport = rect.top >= 0 &&
                            rect.left >= 0 &&
                            rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
                            rect.right <= (window.innerWidth || document.documentElement.clientWidth);

                        aa[i[0]]['isInViewport'] = isInViewport;
                        /* box[0].style.left = (isInViewport) ? 0 : "auto";
                        document.getElementsByClassName(`${Classes}`)[0] */
                    }

                    if (type === 'primary') {
                        setPrimaryMenu({ data: aa })
                    } else {
                        setSecondaryMenu({ data: aa })
                    }
                }
            }, 0);
        }, 10);
    }

    let mainSite = GlobalContent.siteData
    if (GlobalContent.siteData?.main_footer !== undefined && GlobalContent.siteData?.main_footer === 'yes') {
        mainSite = GlobalContent.AllSiteData.filter((e: any) => e.is_main === 'yes')[0]

    }

    var parent = GlobalContent.AllSiteData.filter((e: any) => e._id === mainSite?.parent_site)
    if (parent.length > 0) {
        mainSite['site_url'] = parent[0].site_url
    }
    const ValidSchema = Yup.object().shape({
        searchText: Yup.string().required('Please enter text.')
    })

    const formik = useFormik({
        initialValues: {
            searchText: ''
        },
        enableReinitialize: true,
        validationSchema: ValidSchema,
        onSubmit: (values: any) => {
            setHeaderSearch('')
            /* navigate(`${process.env.PUBLIC_URL}/search?q=${values.searchText}`) */
            window.open(`${process.env.PUBLIC_URL}/search?q=${values.searchText}`, "_blank")
            formik.resetForm()
        }
    })
    return (
        <>
            <style>
                {`body {padding-top: ${refH.current?.clientHeight}px !important;}
                .news-listing-popular-sidebar{ top: calc(${(refH.current?.clientHeight)}px + 10px) }`}
            </style>
            <header id="header" className={`fixed-top ${className} ${(PrimaryMenu.data.length > 0 || SecondaryMenu.data.length > 0) ? '' : 'd-none'}`} ref={refH}>
                <div className='d-flex d-lg-none justify-content-between ms-3 me-3 gap-2'>
                    <div className="mobile-nav-overly"></div>
                    <div className="" >
                        {/* <Link to={`${process.env.PUBLIC_URL}/${(mainSite !== undefined && mainSite.site_url !== undefined) ? mainSite.site_url : ``}`}> */}
                        {/* {(mainSite.footer_logo !== '') ?
                                <img src={mainSite.footer_logo} className="img-fluid mobile-logo" alt={(GlobalContent.siteData !== undefined && GlobalContent.siteData.name !== undefined) ? GlobalContent.siteData.name+' Logo' : ``} title={(GlobalContent.siteData !== undefined && GlobalContent.siteData.name !== undefined) ? GlobalContent.siteData.name+' Logo' : ``} />
                                : <img src={process.env.PUBLIC_URL + '/favicon.png'} className="img-fluid mobile-logo" alt={(GlobalContent.siteData !== undefined && GlobalContent.siteData.name !== undefined) ? GlobalContent.siteData.name+' Logo' : ``} title={(GlobalContent.siteData !== undefined && GlobalContent.siteData.name !== undefined) ? GlobalContent.siteData.name+' Logo' : ``} />} */}
                        {/* <img src={process.env.PUBLIC_URL + '/favicon.png'} className="img-fluid mobile-logo" alt={(GlobalContent.siteData !== undefined && GlobalContent.siteData.name !== undefined) ? GlobalContent.siteData.name+' Logo' : ``} title={(GlobalContent.siteData !== undefined && GlobalContent.siteData.name !== undefined) ? GlobalContent.siteData.name+' Logo' : ``} />
                        </Link> */}
                        <Link to={`${process.env.PUBLIC_URL}/${mainSite?.parent_site_url !== undefined ? mainSite?.parent_site_url : ``}${(GlobalContent.siteData !== undefined && GlobalContent.siteData.site_url !== undefined) ? GlobalContent.siteData.site_url : ``}`} title={(GlobalContent.siteData !== undefined && GlobalContent.siteData.name !== undefined) ? GlobalContent.siteData.name + ' Logo' : ``}>
                            <img src={(GlobalContent.siteData?.site_logo !== '' && GlobalContent.siteData?.site_logo !== undefined && GlobalContent.siteData?.site_logo !== 'undefined') ? GlobalContent.siteData?.site_logo : process.env.PUBLIC_URL + '/img/sgadi-logo.png'} alt={(GlobalContent.siteData !== undefined && GlobalContent.siteData.name !== undefined) ? GlobalContent.siteData.name + ' Logo' : ``} title={(GlobalContent.siteData !== undefined && GlobalContent.siteData.name !== undefined) ? GlobalContent.siteData.name + ' Logo' : ``} style={{ height: '55px', objectFit: 'contain' }} className="img-fluid" />
                        </Link>
                    </div>
                    <div className='d-flex flex-row gap-1' style={{ gap: '0px' }}>
                        <button type="button" className="mobile-search-toggle d-lg-none p-0" onClick={(e) => {
                            setHeaderSearch('active')
                            setTimeout(() => {
                                refSearchBox.current?.focus();
                            }, 10);

                        }} style={{ position: 'relative', top: 'unset', right: 'unset' }}>{/* <i className="icofont-search"></i> */}
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 50 50" width="24px" height="24px" fill='#fff'><path d="M 21 3 C 11.601563 3 4 10.601563 4 20 C 4 29.398438 11.601563 37 21 37 C 24.355469 37 27.460938 36.015625 30.09375 34.34375 L 42.375 46.625 L 46.625 42.375 L 34.5 30.28125 C 36.679688 27.421875 38 23.878906 38 20 C 38 10.601563 30.398438 3 21 3 Z M 21 7 C 28.199219 7 34 12.800781 34 20 C 34 27.199219 28.199219 33 21 33 C 13.800781 33 8 27.199219 8 20 C 8 12.800781 13.800781 7 21 7 Z" /></svg></button>
                        {(GlobalContent.siteData.is_main === 'yes') ? <button type="button" className="mobile-donate-toggle d-lg-none p-0" style={{ position: 'relative', top: 'unset', right: 'unset', left: 'unset' }} >
                            <Link to={`${process.env.PUBLIC_URL}donate`} title='Donate' >
                                <svg height="28px" viewBox="0 0 24 24" style={{ fill: 'transparent', stroke: '#fff', strokeWidth: '1.5px' }} width="28px" xmlns="http://www.w3.org/2000/svg"><path d="M17.726 13.02 14 16H9v-1h4.065a.5.5 0 0 0 .416-.777l-.888-1.332A1.995 1.995 0 0 0 10.93 12H3a1 1 0 0 0-1 1v6a2 2 0 0 0 2 2h9.639a3 3 0 0 0 2.258-1.024L22 13l-1.452-.484a2.998 2.998 0 0 0-2.822.504zm1.532-5.63c.451-.465.73-1.108.73-1.818s-.279-1.353-.73-1.818A2.447 2.447 0 0 0 17.494 3S16.25 2.997 15 4.286C13.75 2.997 12.506 3 12.506 3a2.45 2.45 0 0 0-1.764.753c-.451.466-.73 1.108-.73 1.818s.279 1.354.73 1.818L15 12l4.258-4.61z" /></svg>
                            </Link>
                        </button> : ''}
                        <button type="button" className="mobile-nav-toggle d-lg-none" style={{ position: 'relative', top: 'unset', left: 'unset' }}>{/* <i className="icofont-navigation-menu"></i> */}
                            <svg className='menu' xmlns="http://www.w3.org/2000/svg" viewBox="0 0 50 50" width="25px" height="25px" fill='#fff'><path d="M 5 8 A 2.0002 2.0002 0 1 0 5 12 L 45 12 A 2.0002 2.0002 0 1 0 45 8 L 5 8 z M 5 23 A 2.0002 2.0002 0 1 0 5 27 L 45 27 A 2.0002 2.0002 0 1 0 45 23 L 5 23 z M 5 38 A 2.0002 2.0002 0 1 0 5 42 L 45 42 A 2.0002 2.0002 0 1 0 45 38 L 5 38 z" /></svg>

                            <svg className='close' style={{ display: 'none' }} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 50 50" width="25px" height="25px" fill='#fff'><path d="M 9.15625 6.3125 L 6.3125 9.15625 L 22.15625 25 L 6.21875 40.96875 L 9.03125 43.78125 L 25 27.84375 L 40.9375 43.78125 L 43.78125 40.9375 L 27.84375 25 L 43.6875 9.15625 L 40.84375 6.3125 L 25 22.15625 Z" /></svg>
                        </button>
                    </div>

                    <TransitionGroup component="nav" className="mobile-nav">
                        <CSSTransition
                            key={`mobile-nav`}
                            timeout={timeout}
                            classNames="transLeftToRight"
                            appear
                        >
                            <ul className="d-block justify-content-between d-lg-flex">
                                {PrimaryMenu.data && PrimaryMenu.data.length > 0 && PrimaryMenu.data.map((data: any, i: any) => {
                                    /* if(data.menu_name === '{inspirator}'){
                                        menuElement = <Link to={`${process.env.PUBLIC_URL}/${data.menu_link}`} className="inspirator-text" >{Inspirator}</Link>
                                    } else if(data.menu_name === '{tithi}'){
                                        menuElement = <span className='inspirator-text'>{CurrentDateTithi}</span>
                                    } */
                                    let MainMenuLink = data.menu_link
                                    if (data.child !== undefined && data.child.length > 0) {
                                        MainMenuLink = data.child[0].menu_link
                                    }

                                    var Parent = { nameEle: [], Expand: [] } as any
                                    if (data.child !== undefined && data.child.length > 0) {
                                        if (data.menu_link === '/') {
                                            Parent.nameEle.push(<span className='sub-menu-nav btn-expand'>{data.menu_name}</span>)
                                        } else {
                                            Parent.nameEle.push(<Link to={`${process.env.PUBLIC_URL}/${data.menu_link}`} className="d-flex flex-row justify-content-between me-5" title={data.menu_name} >{data.menu_name}</Link>)
                                            Parent.Expand.push(<span className='btn-expand'></span>)
                                        }
                                    } else {
                                        Parent.nameEle.push(<Link to={`${process.env.PUBLIC_URL}/${MainMenuLink}`} className="d-flex flex-row justify-content-between me-5" title={data.menu_name} >{data.menu_name}</Link>)
                                    }
                                    return (
                                        <> {data.menu_name !== '{inspirator}' && data.menu_name !== '{tithi}' ? <li className='position-relative' key={`${data._id}-mobile-primary`}>
                                            {Parent.nameEle}
                                            {(data.child !== undefined && data.child.length > 0) ?
                                                <>{Parent.Expand}
                                                    <ul className="d-none justify-content-between mobile-nav-inner">
                                                        {data.child && data.child.map((dataChild: any, i: any) =>
                                                        (
                                                            <li className='position-relative' key={`${dataChild._id}-mobile-child-primary`}>

                                                                <Link to={`${process.env.PUBLIC_URL}/${dataChild.menu_link}`} className="mega-menu-link color-grey me-5" style={{ fontSize: '14px', padding: '10px 20px 10px 30px' }} title={dataChild.menu_name}>{dataChild.menu_name}</Link>

                                                                {/* {(dataChild.child !== undefined && dataChild.child.length > 0) ? <span className='sub-menu-nav btn-expand' style={{ fontSize: '14px', padding: '10px 20px 10px 30px' }}>{dataChild.menu_name}</span> : <Link to={`${process.env.PUBLIC_URL}/${dataChild.menu_link}`} className="mega-menu-link color-grey me-5" style={{ fontSize: '14px', padding: '10px 20px 10px 30px' }} >{dataChild.menu_name} </Link>} */}

                                                                {(dataChild.child !== undefined && dataChild.child.length > 0) ?
                                                                    <><span className='btn-expand'></span>
                                                                        <ul className="d-none justify-content-between mobile-nav-inner">
                                                                            {dataChild.child && dataChild.child.map((dataChildC: any, i: any) => (
                                                                                <li key={`${dataChildC._id}-mobile-child-primaryinnerMegaMenu4`}><Link to={`${process.env.PUBLIC_URL}/${dataChildC.menu_link}`} className="mega-menu-link color-grey ps-5" style={{ fontSize: '13px', padding: '10px 20px' }} title={dataChildC.menu_name}>{dataChildC.menu_name}</Link></li>
                                                                            ))}
                                                                        </ul>
                                                                    </> : ''}
                                                            </li>
                                                        ))}
                                                    </ul></> : ''}
                                        </li> : ''}</>
                                    )
                                })}
                                {SecondaryMenu.data && SecondaryMenu.data.length > 0 && SecondaryMenu.data.map((data: any, i: any) => {
                                    let MainMenuLink = data.menu_link
                                    if (data.child !== undefined && data.child.length > 0) {
                                        MainMenuLink = data.child[0].menu_link
                                    }
                                    var Parent = { nameEle: [], Expand: [] } as any
                                    if (data.child !== undefined && data.child.length > 0) {
                                        if (data.menu_link === '/') {
                                            Parent.nameEle.push(<span className='sub-menu-nav btn-expand'>{data.menu_name}</span>)
                                        } else {
                                            Parent.nameEle.push(<Link to={`${process.env.PUBLIC_URL}/${data.menu_link}`} className="d-flex flex-row justify-content-between me-5" title={data.menu_name} >{data.menu_name}</Link>)
                                            Parent.Expand.push(<span className='btn-expand'></span>)
                                        }
                                    } else {
                                        Parent.nameEle.push(<Link to={`${process.env.PUBLIC_URL}/${MainMenuLink}`} className="d-flex flex-row justify-content-between me-5" title={data.menu_name}>{data.menu_name}</Link>)
                                    }
                                    return (
                                        <li className='position-relative' key={`${i.toString()}-mobile-secondary`}>

                                            {Parent.nameEle}
                                            {(data.child !== undefined && data.child.length > 0) ?
                                                <>{Parent.Expand}<ul className="d-none justify-content-between mobile-nav-inner">
                                                    {data.child && data.child.map((dataChild: any, i: any) =>
                                                    (
                                                        <li className='position-relative' key={`${dataChild._id}-mobile-child-secondary`}>

                                                            <Link to={`${process.env.PUBLIC_URL}/${dataChild.menu_link}`} className="mega-menu-link color-grey me-5" style={{ fontSize: '14px', padding: '10px 20px 10px 30px' }} title={dataChild.menu_name}>{dataChild.menu_name}</Link>

                                                            {/* {(dataChild.child !== undefined && dataChild.child.length > 0) ? <span className='sub-menu-nav btn-expand' style={{ fontSize: '14px', padding: '10px 20px 10px 30px' }}>{dataChild.menu_name}</span> : <Link to={`${process.env.PUBLIC_URL}/${dataChild.menu_link}`} className="mega-menu-link color-grey me-5" style={{ fontSize: '14px', padding: '10px 20px 10px 30px' }} >{dataChild.menu_name} </Link>} */}

                                                            {(dataChild.child !== undefined && dataChild.child.length > 0) ?
                                                                <><span className='btn-expand'></span><ul className="d-none justify-content-between mobile-nav-inner">
                                                                    {dataChild.child && dataChild.child.map((dataChildC: any, i: any) => (
                                                                        <li key={`${dataChildC._id}-mobile-child-primaryinnerMegaMenu3`}><Link to={`${process.env.PUBLIC_URL}/${dataChildC.menu_link}`} className="mega-menu-link color-grey ps-5" style={{ fontSize: '13px', padding: '10px 20px' }} title={dataChildC.menu_name}>{dataChildC.menu_name}</Link></li>
                                                                    ))}
                                                                </ul></> : ''}
                                                        </li>
                                                    ))}
                                                </ul></> : ''}
                                        </li>
                                    )
                                })}
                                {/* {(GlobalContent.siteData.is_main === 'yes') ? <li className='position-relative' key={`donate-mobile-secondary`}>
                                    <Link to={`${process.env.PUBLIC_URL}donate`} className="d-flex flex-row justify-content-between me-5" title={`Donate`}>{`Donate`}</Link>
                                </li> : ''} */}
                                {(PrimaryMenu.data[0].menu_name === "{inspirator}") ? <li className='position-relative'><Link to={`${process.env.PUBLIC_URL}/`} className="d-flex flex-row justify-content-between me-5" title="Go to Main Site">Go to Main Site</Link></li> : ''}
                            </ul>
                        </CSSTransition>
                    </TransitionGroup>
                </div>
                <div className="container">
                    <div className={`d-none d-lg-flex sgadi-logo ${(PrimaryMenu.data[0].menu_name === "{inspirator}") ? 'justify-content-end pb-2' : 'justify-content-center'} flex-column`} style={{ zIndex: '0', /* transition: '0.2s all', */ paddingTop: (PrimaryMenu.data[0].menu_name === "{inspirator}") ? '33px' : '0px', height: (PrimaryMenu.data[0].menu_name === "{inspirator}") ? 'auto' : 'calc(100% + 12px)' }}>
                        <Link to={`${process.env.PUBLIC_URL}/${mainSite?.parent_site_url !== undefined ? mainSite?.parent_site_url : ``}${(GlobalContent.siteData !== undefined && GlobalContent.siteData?.site_url !== undefined) ? GlobalContent.siteData?.site_url : ``}`} title='Logo'><img src={(GlobalContent.siteData?.site_logo !== '' && GlobalContent.siteData?.site_logo !== undefined && GlobalContent.siteData?.site_logo !== 'undefined') ? GlobalContent.siteData?.site_logo : process.env.PUBLIC_URL + '/img/sgadi-logo.png'} alt={(GlobalContent.siteData !== undefined && GlobalContent.siteData.name !== undefined) ? GlobalContent.siteData.name + ' Logo' : ``} title={(GlobalContent.siteData !== undefined && GlobalContent.siteData.name !== undefined) ? GlobalContent.siteData.name + ' Logo' : ``} className="img-fluid" /></Link>
                    </div>
                    <nav className="nav-menu d-none d-lg-flex justify-content-end" style={{ transition: '0.3s height ease 0.3s', height: `${(PrimaryMenu.data[0].menu_name === "{inspirator}") ? '30px' : 'auto'}` }}>
                        <ul className={`d-flex justify-content-between position-relative ${(PrimaryMenu.data[0].menu_name === "{inspirator}") ? 'col-12' : 'col-8 width-header-desktop ps-5 ps-md-4 ps-lg-4 ps-xl-4'}`}>
                            {PrimaryMenu.data && PrimaryMenu.data.length > 0 && PrimaryMenu.data.map((data: any, i: any) => {
                                let isMegaMenu = 'no'
                                let MainMenuLink = data.menu_link
                                if (data.child !== undefined && data.child.length > 0) {
                                    MainMenuLink = data.child[0].menu_link
                                    data.child.forEach((dataChild: any, k: any) => {
                                        if (dataChild.child !== undefined && dataChild.child.length > 0) {
                                            dataChild.child.forEach((dataChildC: any, k: any) => {
                                                isMegaMenu = 'yes'
                                            })
                                        }
                                    })
                                }
                                // dropdown-toggle

                                //let menuElement = (data.child !== undefined && data.child.length > 0) ? <span data-toggle="dropdown" className='sub-menu-nav' dangerouslySetInnerHTML={{ __html: `${data.menu_name}` }}></span> : <Link to={`${process.env.PUBLIC_URL}/${MainMenuLink}`} data-toggle="dropdown" dangerouslySetInnerHTML={{ __html: `${data.menu_name}` }}></Link>
                                let menuElement = (data.child !== undefined && data.child.length > 0) ? <Link to={`${process.env.PUBLIC_URL}/${data.menu_link}`} title={data.menu_name} data-toggle="dropdown" dangerouslySetInnerHTML={{ __html: `${data.menu_name}` }}></Link> : <Link to={`${process.env.PUBLIC_URL}/${MainMenuLink}`} title={data.menu_name} data-toggle="dropdown" dangerouslySetInnerHTML={{ __html: `${data.menu_name}` }}></Link>
                                if (data.menu_name === '{inspirator}') {
                                    menuElement = <Link to={`${process.env.PUBLIC_URL}/${data.menu_link}`} className="inspirator-text text-uppercase" title={Inspirator} >{Inspirator}</Link>
                                } else if (data.menu_name === '{tithi}') {
                                    menuElement = <span className='inspirator-text text-uppercase'>{CurrentDateTithi}</span>
                                }
                                var Classes = `PrimaryMenu-dropdown-menu-${i}`;

                                return (
                                    <li className='dropdown is-mega-menu d-flex justify-content-center flex-column' onMouseEnter={() => handleopenMenu([i], 'primary', 1, Classes)} onMouseLeave={() => handleopenMenu([i], 'primary', 0, Classes)} style={{ position: `${isMegaMenu === 'yes' ? `initial` : `relative`}` }} key={`${data._id}-mobile-PrimaryMenu2`}>

                                        {menuElement}
                                        {(data.child !== undefined && data.child.length > 0) ?
                                            <ul /* component="ul" */ className={`${Classes} dropdown-menu mega-menu ${isMegaMenu === 'yes' ? `ms-5` : `min-h-2`}`} style={{ display: (data.isopen === 1) ? 'flex' : 'none', minWidth: 'fit-content', left: (data?.isInViewport === true || data?.isInViewport === undefined) ? 0 : "auto" }} >
                                                {/* <CSSTransition
                                                    key={i.toString()}
                                                    timeout={timeout}
                                                    classNames="fade"
                                                    appear
                                                > */}
                                                <li className="w3_megamenu-content withdesc d-flex flex-row w-100">
                                                    <div className={`${isMegaMenu === 'yes' ? `col-3` : ``}`}>
                                                        <div className={`${isMegaMenu === 'yes' ? `mega-menu-inner ` : ``}d-flex flex-column`}>
                                                            {data.child && data.child.length > 0 && data.child.map((dataChild: any, j: any) => (
                                                                <Link to={`${process.env.PUBLIC_URL}/${dataChild.menu_link}`} className="mega-menu-link " onMouseEnter={() => handleopenMenu([i, j], 'primary', 1)} /* onMouseLeave={() => handleopenMenu([i, j], 'primary', 0)} */ key={`${dataChild._id}-mobile-child-PrimaryMenu2`} title={dataChild.menu_name}>{dataChild.menu_name}</Link>
                                                            ))}
                                                        </div>
                                                    </div>
                                                    {isMegaMenu === 'yes' && data.child && data.child.length > 0 ? <div className="col-9">
                                                        {data.child.map((dataChild: any, i: any) => (
                                                            <div className={`mega-menu-inner-2 ${(dataChild.isopen === 1) ? 'd-flex' : 'd-none'} h-100`} style={{ backgroundImage: `url(${dataChild.image})`, backgroundRepeat: 'no-repeat', backgroundSize: 'cover', backgroundPosition: 'center center', transition: '0.2s all' }} key={`${dataChild._id}-mobile-child-PrimaryMenuMega`}>
                                                                <div className='mega-menu-inner d-flex flex-column grey-color-05-opacity-bg-2 col-4'>
                                                                    {
                                                                        dataChild.child && dataChild.child.length > 0 && dataChild.child.map((dataChildC: any, i: any) =>
                                                                            (<Link to={`${process.env.PUBLIC_URL}/${dataChildC.menu_link}`} className="mega-menu-link color-white" key={`${dataChildC._id}-mobile-child-primaryinnerMegaMenu2`} title={dataChildC.menu_name}>{dataChildC.menu_name}</Link>))
                                                                    }
                                                                </div>
                                                            </div>
                                                        ))}
                                                    </div> : ''}
                                                </li>
                                                {/* </CSSTransition> */}
                                            </ul>
                                            : ''}
                                    </li>
                                )
                            })}
                        </ul>
                        {/* <div className='col-1' style={{ width: '6%' }}></div> */}
                    </nav>
                </div>

                <div id="topbar" className="d-none d-lg-flex align-items-center">
                    <div className="container d-flex justify-content-between">
                        <div className="contact-info d-flex justify-content-end col-12">
                            <div className={`d-flex justify-content-between m-0 position-relative ${(PrimaryMenu.data[0].menu_name === "{inspirator}") ? 'col-auto gap-40' : 'col-8 width-header-desktop ps-5 ps-md-4 ps-lg-4 ps-xl-4'}`}>
                                {SecondaryMenu.data && SecondaryMenu.data.length > 0 && SecondaryMenu.data.map((data: any, i: any) => {
                                    let isMegaMenu = 'no'
                                    let MainMenuLink = data.menu_link
                                    if (data.child !== undefined && data.child.length > 0) {
                                        MainMenuLink = data.child[0].menu_link
                                        data.child.forEach((dataChild: any, k: any) => {
                                            if (dataChild.child !== undefined && dataChild.child.length > 0) {
                                                dataChild.child.forEach((dataChildC: any, k: any) => {
                                                    isMegaMenu = 'yes'
                                                })
                                            }
                                        })
                                    }
                                    var Classes = `SecondaryMenu-dropdown-menu-${i}`;
                                    return (
                                        <span className='dropdown is-mega-menu' onMouseEnter={() => handleopenMenu([i], 'secondary', 1, Classes)} onMouseLeave={() => handleopenMenu([i], 'secondary', 0, Classes)} style={{ position: `${isMegaMenu === 'yes' ? `initial` : `relative`}`, display: 'flex', whiteSpace: 'nowrap' }} key={`${data._id}-mobile-child-SecondaryMenu2`}>

                                            {/* <Link to={`${process.env.PUBLIC_URL}/${MainMenuLink}`} data-toggle="dropdown" className={(data.child !== undefined && data.child.length > 0) ? '' : ''} dangerouslySetInnerHTML={{ __html: `${data.menu_name}` }}></Link> */}

                                            {/* {(data.child !== undefined && data.child.length > 0) ? <span data-toggle="dropdown" className='sub-menu-nav' dangerouslySetInnerHTML={{ __html: `${data.menu_name}` }}></span> : <Link to={`${process.env.PUBLIC_URL}/${MainMenuLink}`} data-toggle="dropdown" className={(data.child !== undefined && data.child.length > 0) ? '' : ''} dangerouslySetInnerHTML={{ __html: `${data.menu_name}` }}></Link>} */}

                                            {(data.child !== undefined && data.child.length > 0) ? <Link to={`${process.env.PUBLIC_URL}/${data.menu_link}`} data-toggle="dropdown" title={data.menu_name} className={`${(data.child !== undefined && data.child.length > 0) ? '' : ''} ${(data.isopen === 1) ? 'active' : ''} `} dangerouslySetInnerHTML={{ __html: `${data.menu_name}` }}></Link> : <Link to={`${process.env.PUBLIC_URL}/${MainMenuLink}`} data-toggle="dropdown" title={data.menu_name} className={`${(data.child !== undefined && data.child.length > 0) ? '' : ''} ${(data.isopen === 1) ? 'active' : ''}`} dangerouslySetInnerHTML={{ __html: `${data.menu_name}` }}></Link>}

                                            {(data.child !== undefined && data.child.length > 0) ?
                                                <ul /* component="ul" */ className={`${Classes} dropdown-menu mega-menu ${isMegaMenu === 'yes' ? `ms-5` : `min-h-2`}`} style={{ display: (data.isopen === 1) ? 'flex' : 'none', minWidth: 'fit-content', left: (data?.isInViewport === true || data?.isInViewport === undefined) ? 0 : "auto" }} >
                                                    {/* <CSSTransition
                                                        key={`${i.toString()}-secondary`}
                                                        timeout={timeout}
                                                        classNames="fade"
                                                        appear
                                                    > */}

                                                    <li className="w3_megamenu-content withdesc d-flex flex-row w-100">
                                                        <div className={`${isMegaMenu === 'yes' ? `col-3` : ``}`}>
                                                            <div className={`${isMegaMenu === 'yes' ? `mega-menu-inner ` : ``}d-flex flex-column`}>
                                                                {data.child && data.child.length > 0 && data.child.map((dataChild: any, j: any) => (
                                                                    <Link to={`${process.env.PUBLIC_URL}/${dataChild.menu_link}`} title={dataChild.menu_name} className="mega-menu-link " onMouseEnter={() => handleopenMenu([i, j], 'secondary', 1)} /* onMouseLeave={() => handleopenMenu([i, j], 'secondary', 0)} */ key={`${dataChild._id}-mobile-child-SecondaryMegaMenu2`}>{dataChild.menu_name}</Link>
                                                                ))}
                                                            </div>
                                                        </div>
                                                        {isMegaMenu === 'yes' && data.child && data.child.length > 0 ? <div className="col-9">
                                                            {data.child && data.child.length > 0 && data.child.map((dataChild: any, i: any) => (
                                                                <div className={`mega-menu-inner-2 ${(dataChild.isopen === 1) ? 'd-flex' : 'd-none'} h-100`} style={{ backgroundImage: `url(${dataChild.image})`, backgroundRepeat: 'no-repeat', backgroundSize: 'cover', backgroundPosition: 'center center', transition: '0.2s all' }} key={`${dataChild._id}-mobile-child-SecondaryMegaMenu3`}>
                                                                    <div className='mega-menu-inner d-flex flex-column grey-color-05-opacity-bg-2 col-4'>
                                                                        {
                                                                            dataChild.child && dataChild.child.length > 0 && dataChild.child.map((dataChildC: any, i: any) =>
                                                                                (<Link to={`${process.env.PUBLIC_URL}/${dataChildC.menu_link}`} title={dataChild.menu_name} className="mega-menu-link color-white" key={`${dataChildC._id}-mobile-child-SecondaryinnerMegaMenu2`}>{dataChildC.menu_name}</Link>))
                                                                        }
                                                                    </div>
                                                                </div>
                                                            ))}
                                                        </div> : ''}
                                                    </li>
                                                    {/* </CSSTransition> */}
                                                </ul>
                                                : ''}
                                        </span>
                                    )
                                })}
                                {/* <div className='d-flex justify-content-end' >
                                    
                                </div> */}
                                <div className='d-flex justify-content-end gap-2' >
                                    {/* {(GlobalContent.siteData.is_main === 'yes') ? <div className='d-flex justify-content-center flex-column position-relative'>
                                        <Link to={`${process.env.PUBLIC_URL}donate`} className="topbar-search-box" title='Donate' style={{ paddingLeft: '6.5px', paddingRight: '6.5px' }}>
                                            {/* <svg height="18" viewBox="0 0 24 24" width="18" xmlns="http://www.w3.org/2000/svg"><path d="M17.726 13.02 14 16H9v-1h4.065a.5.5 0 0 0 .416-.777l-.888-1.332A1.995 1.995 0 0 0 10.93 12H3a1 1 0 0 0-1 1v6a2 2 0 0 0 2 2h9.639a3 3 0 0 0 2.258-1.024L22 13l-1.452-.484a2.998 2.998 0 0 0-2.822.504zm1.532-5.63c.451-.465.73-1.108.73-1.818s-.279-1.353-.73-1.818A2.447 2.447 0 0 0 17.494 3S16.25 2.997 15 4.286C13.75 2.997 12.506 3 12.506 3a2.45 2.45 0 0 0-1.764.753c-.451.466-.73 1.108-.73 1.818s.279 1.354.73 1.818L15 12l4.258-4.61z" /></svg> *
                                            Donate
                                        </Link>
                                    </div> : ''} */}
                                    <div className='d-flex justify-content-center flex-column position-relative'>
                                        <span className="topbar-search-box " onClick={(e) => setHeaderSearch('active')}><i className="icofont-search"></i></span>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </header>
            {HeaderSearch === 'active' ?
                <div className={`d-flex flex-column align-items-start align-items-md-center justify-content-start justify-content-md-center search-box pt-5 pt-md-0 ${HeaderSearch}`}>
                    <div className='d-flex h-auto col-12 col-md-6 px-3'>
                        <div className="d-flex flex-column w-100 position-relative" style={{ zIndex: '2' }}>
                            <form onSubmit={formik.handleSubmit} noValidate className="d-flex flex-row w-100">
                                <input className="form-control border-radius-top-right border-radius-bottom-right" type={`text`} placeholder="Search..." {...formik.getFieldProps('searchText')} autoFocus ref={refSearchBox} />
                                <button type="submit" className="btn btn-primary border-radius-top-left border-radius-bottom-left">Search</button>
                            </form>
                            {formik.touched.searchText && formik.errors.searchText ? <span className="text-danger font-size-14px" style={{ position: 'absolute', top: '100%' }}>{formik.errors.searchText}</span> : ''}
                            <button className="close modal-close col-auto" style={{ position: 'absolute', top: '-100%', right: '0' }} onClick={(e) => {
                                setHeaderSearch('')
                            }}>
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className={`modal-backdrop fade`} style={{ zIndex: '1' }} onClick={(e) => {
                            setHeaderSearch('')
                        }}></div>
                    </div>
                </div> : ''}
        </>
    )
}

export default Header