import React, { useContext, useEffect, useRef, useState } from "react";
import Banner from './sections/Banner'
import { useParams, Link, useNavigate } from 'react-router-dom'
import GlobalContext from './Core/PageData'
import ReactPaginate from 'react-paginate'
import { SectionTitle, LimitDesc, PageLoader, LazyImage, HighLighttext, MetaData, NoDataFound, GetPublicationUrl } from "./sections/CommonSections"
import axios, { CancelTokenSource } from 'axios'
// @ts-ignore
import ScrollAnimation from 'react-animate-on-scroll'
// @ts-ignore
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/opacity.css';
import { InlineShareButtons } from 'sharethis-reactjs';
import moment from "moment";

var cancelToken: CancelTokenSource;

const Publication: React.FC<any> = (props: any) => {
    let { publicationSlug, publicationSubCategorySlug } = useParams();
    if (publicationSlug === undefined) {
        publicationSlug = props.publicationSlug
    }
    var From = props.From
    const { GlobalContent, setGlobalContent } = useContext(GlobalContext as any) as any
    if (From === 'main') {
        document.title = `Publication Listing${GlobalContent.pageTitle}`;
    } else {
        document.title = `Search: ${props?.searchString}${GlobalContent.pageTitle}`;
    }
    /* useEffect(() => {
        setGlobalContent({ ...GlobalContent, IsMediaPlayershow: false })
    }, []); */
    const navigate = useNavigate();
    const [isPageLoad, setisPageLoad] = useState('no')
    const [publication, setpublication] = useState({ data: [] as any[] })
    const [publicationData, setpublicationData] = useState({ data: [] as any[] })
    const [MainCategory, setMainCategory] = useState({ data: [] as any[] })
    const [SubCategory, setSubCategory] = useState({ data: [] as any[] })
    const [Language, setLanguage] = useState({ data: [] as any[] })
    const [Artist, setArtist] = useState({ data: [] as any[] })
    const [FilterForm, setFilterForm] = useState({ data: [{ main_category: "", search: (props?.searchString !== undefined) ? props?.searchString : "", sub_category: [], language: [] as any, artist: [] as any, time_by: "desc", year: "", typeOfLoad: 'first' }] } as any)
    const [FilterFormArtist, setFilterFormArtist] = useState({ data: [] as any[] })
    const [PaginationData, setPaginationData] = useState({ currentpage: '', nextpage: '', perpage: '', remainingCount: '', total: '', totalpages: '' })
    const [isPageLoadMore, setisPageLoadMore] = useState('no')
    const [Years, setYears] = useState({ data: [] as any[] })
    var sites = GlobalContent.AllSiteData.filter((e: any) => e.is_main === 'yes')
    const abortController = useRef<HTMLInputElement>(null);
    const myRef = useRef<HTMLButtonElement>(null);
    const isMounted = useRef(true)
    useEffect(() => {
        isMounted.current = true;
        (async () => {
            //setGlobalContent({ ...GlobalContent, siteData: sites[0], headerClass: '' })
            setisPageLoad('no')
            setpublicationData({ data: [] })

            /* if(publicationSlug !== undefined || publicationSubCategorySlug !== undefined){ */
            const LanguageDetails = await axios.get(`${process.env.REACT_APP_API_URL}global/language/all/`).then(json => {
                if (isMounted.current) {
                    setLanguage({ data: json.data.data })
                }
                /* Language = json.data.data */
                return json.data.data
            }).catch(function (err) {
                return []
            })
            var yy = []
            for (let yearPrint = parseInt(moment().format('YYYY')); yearPrint >= 1999; yearPrint--) {
                yy.push(yearPrint)
            }
            if (isMounted.current) { setYears({ data: yy }) }

                /* await */ axios.get(`${process.env.REACT_APP_API_URL}publication/type/slug/${publicationSlug}`).then(json => {
                if (json.data.data.length === 0) {
                    navigate(`${process.env.PUBLIC_URL}/${process.env.REACT_APP_PUBLICATION_SLUG}`)
                }
                if (isMounted.current) { setpublication({ data: json.data.data }) }
                return json.data.data
            }).then((_publication_) => {
                if (isMounted.current) { setFilterForm({ data: [{ ...FilterForm.data[0], main_category: "", search: (props?.searchString !== undefined) ? props?.searchString : "", sub_category: [], language: [] as any, artist: [] as any, time_by: "", year: "", }] }) }
                axios.get(`${process.env.REACT_APP_API_URL}publication/type/all/`).then(json => {
                    json.data.data = json.data.data.filter((e: any) => e.status === 'active')
                    if (isMounted.current) { setMainCategory({ data: json.data.data }) }
                    return json.data.data
                }).then((publicationData) => {
                    let PubName = _publication_[0]['name'].toLowerCase()
                    if (PubName === 'books') {
                        PubName = 'book'
                    } else if (PubName === 'shree ghanshyam vijay') {
                        PubName = 'gvijay'
                    }

                    axios.get(`${process.env.REACT_APP_API_URL}global/artist/all/`).then(async json => {
                        var ArtistTemp = [] as any
                        await json.data.data.forEach(async (artist: any, j: any) => {
                            var typesArray = artist.type.split(',')
                            var IsPush = 0
                            artist.typeName.forEach((typesValue: any, j: any) => {
                                if (PubName === 'book') {
                                    if (typesValue[typesArray[j]] === 'Author') {
                                        IsPush = 1
                                    }
                                } else {
                                    IsPush = 1
                                }
                            })
                            if (IsPush === 1) {
                                ArtistTemp.push(artist)
                            }
                        })
                        if (isMounted.current) { setArtist({ data: ArtistTemp }) }
                    })
                    /* } */

                    axios.get(`${process.env.REACT_APP_API_URL}publication/${PubName}-category/all/`).then(json => {
                        if (PubName === 'gvijay') {
                            json.data.data.filter((e: any) => e.status === 'active')
                            json.data.data = json.data.data.sort((a: any, b: any) => (a.name < b.name) ? 1 : -1)
                        }
                        if (isMounted.current) { setSubCategory({ data: json.data.data }) }
                        return { subCat: json.data.data }
                    }).then((temp: any) => {
                        if (isMounted.current) { GetPublicationData(_publication_[0]['name'], temp.subCat, { data: [{ ...FilterForm.data[0], main_category: "", search: (props?.searchString !== undefined) ? props?.searchString : "", sub_category: [], language: [] as any, artist: [] as any, time_by: "", year: "", }] }) }
                    })
                })
            })
            /* } */
        })();
        return (() => {
            isMounted.current = false;
        })
    }, [publicationSlug, publicationSubCategorySlug]);

    const GetPublicationData = (publiactionName: any, SubCat: any, MyFilters = {} as any, typeOfLoad = 'first') => {
        if (typeof cancelToken != typeof undefined) {
            cancelToken.cancel("Operation canceled due to new request.")
        }
        cancelToken = axios.CancelToken.source()

        let filters = [] as any
        filters.push(`recordPerPage=12`)
        if (publicationSubCategorySlug !== undefined && publicationSubCategorySlug !== '' && SubCat.length > 0) {
            let SubCategoryId = SubCat.find((ell: { slug: boolean; }) => ell.slug === publicationSubCategorySlug as any)?._id
            filters.push(`category=${SubCategoryId}`)
        }

        if (typeOfLoad === 'loadmore') {
            filters.push(`page=${PaginationData.nextpage}`)
            setisPageLoadMore('no')
        } else {
            /* setisPageLoad('no') */
        }
        if (MyFilters.data !== undefined) {

            // https://sgapi.swaminarayangadi.com/api/v1/publication/book/front?recordPerPage=12&category=&sortField=publishOn&sortType=desc&search=swami%20bapa
            // https://sgapi.swaminarayangadi.com/api/v1/publication/book/front?recordPerPage=12&sortField=publishOn&sortType=desc
            if (MyFilters.data[0].sub_category.length > 0 && SubCat.length > 0) {
                filters.push(`category=${MyFilters.data[0].sub_category[0]}`)
            }

            if (MyFilters.data[0].artist.length > 0) {
                filters.push(`artist=${MyFilters.data[0].artist[0]._id}`)
            }

            if (MyFilters.data[0].language.length > 0) {
                filters.push(`language=${MyFilters.data[0].language[0]._id}`)
            }

            if (MyFilters.data[0].time_by) {
                if (MyFilters.data[0].time_by === 'AZ' || MyFilters.data[0].time_by === 'ZA') {
                    filters.push(`sortField=title`)
                    filters.push(`sortType=${(MyFilters.data[0].time_by === 'AZ') ? `asc` : `desc`}`)
                } else {
                    filters.push(`sortField=publishOn`)
                    filters.push(`sortType=${MyFilters.data[0].time_by}`)
                }
            } else {
                filters.push(`sortField=publishOn`)
                filters.push(`sortType=desc`)
            }
            if (MyFilters.data[0].year) {
                filters.push(`year=${MyFilters.data[0].year}`)
            }
            if (MyFilters.data[0].search) {
                filters.push(`search=${MyFilters.data[0].search}`)
            }
        } else {
            filters.push(`sortField=publishOn`)
            filters.push(`sortType=desc`)
        }
        let Setlink = ''
        if (publiactionName !== undefined) {
            if (publiactionName === 'Audio') {
                Setlink = `${process.env.REACT_APP_API_URL}publication/audio-album/front`
            } else if (publiactionName === 'Video') {
                Setlink = `${process.env.REACT_APP_API_URL}publication/video-album/front`
            } else if (publiactionName === 'Books') {
                Setlink = `${process.env.REACT_APP_API_URL}publication/book/front`
            } else if (publiactionName.toLowerCase() === 'shree ghanshyam vijay') {
                Setlink = `${process.env.REACT_APP_API_URL}publication/gvijay/front`
            }
            axios.get(`${Setlink}?${filters.join('&')}`, { cancelToken: cancelToken.token }).then(json => {
                if (typeOfLoad === 'loadmore') {
                    let dwnTemp = [...publicationData.data]
                    dwnTemp = dwnTemp.concat(json.data.data.data)
                    setpublicationData({ data: dwnTemp })
                } else {
                    setpublicationData({ data: json.data.data.data })
                }


                if (typeOfLoad === 'first') {
                    setGlobalContent({ ...GlobalContent, search: { ...GlobalContent.search, [publiactionName]: json.data.data.total } })
                }
                setisPageLoad('yes')
                setisPageLoadMore('yes')
                setGlobalContent({ ...GlobalContent, siteData: sites[0], headerClass: '' })
                setPaginationData({ currentpage: json.data.data.currentpage, nextpage: json.data.data.nextpage, perpage: json.data.data.perpage, remainingCount: json.data.data.remainingCount, total: json.data.data.total, totalpages: json.data.data.totalpages })
                return { currentpage: json.data.data.currentpage, nextpage: json.data.data.nextpage, perpage: json.data.data.perpage, remainingCount: json.data.data.remainingCount, total: json.data.data.total, totalpages: json.data.data.totalpages }
            }).catch((err) => {
                if (axios.isCancel(err)) {
                    //console.log('successfully aborted');
                } else {
                }
            })
        }
    }


    useEffect(() => {
        window.addEventListener('scroll', () => {
            const windowHeight = "innerHeight" in window ? window.innerHeight : document.documentElement.offsetHeight;
            const body = document.body;
            const html = document.documentElement;
            const docHeight = Math.max(body.scrollHeight, body.offsetHeight, html.clientHeight, html.scrollHeight, html.offsetHeight);
            const windowBottom = (windowHeight + ((window.innerWidth < 992) ? 900 : 400)) + window.pageYOffset;
            if (windowBottom >= docHeight) {
                if (!myRef.current?.disabled) {
                    myRef.current?.click()
                }
            }
        });
    }, []);

    useEffect(() => {
        ChangeFilters(props?.searchString, 'search')
    }, [props?.searchString])

    const ChangeFilters = (_id: any, type: any, status = 'add') => {

        const promises = [] as any;
        let dd = {} as any
        if (type === 'main_category') {
            let MainCategoryFilters = MainCategory.data.find((ell: { _id: string; }) => ell._id === _id)
            navigate(`${process.env.PUBLIC_URL}/${process.env.REACT_APP_PUBLICATION_SLUG}${MainCategoryFilters.slug}`)
        } else if (type === 'sub_category') {
            if (From === 'main') {
                let SubCategoryFilters = SubCategory.data.find((ell: { _id: string; }) => ell._id === _id)
                navigate(`${process.env.PUBLIC_URL}/${process.env.REACT_APP_PUBLICATION_SLUG}${publicationSlug}/${(SubCategoryFilters !== undefined) ? SubCategoryFilters.slug : ''}`)
            } else {
                dd = { data: [{ ...FilterForm.data[0], sub_category: [_id] }] }
                setFilterForm({ data: [{ ...FilterForm.data[0], sub_category: [_id] }] })
                promises.push(dd)
            }
        } else if (type === 'artist' || type === 'language') {
            let GetFilterTempData = [] as any;
            if (_id !== '') {
                if (status === 'add') {
                    /* GetFilterTempData = (type === 'artist') ? [...FilterForm.artist] : [...FilterForm.language] */
                    let checkTempData = GetFilterTempData.findIndex((ell: { _id: string; }) => ell._id === _id)
                    if (checkTempData === -1) {
                        let TempFilters = (type === 'artist') ? Artist.data.find((ell: { _id: string; }) => ell._id === _id) as any : Language.data.find((ell: { _id: string; }) => ell._id === _id) as any
                        GetFilterTempData.push(TempFilters)
                        /* promises.push('1') */
                    }
                } else if (status === 'remove') {
                    GetFilterTempData = (type === 'artist') ? [...FilterForm.data[0].artist] : [...FilterForm.data[0].language]
                    GetFilterTempData = GetFilterTempData.filter(function (item: any) { return item['_id'] !== _id })
                    /* promises.push('1') */
                }
            } else {
                GetFilterTempData = (type === 'artist') ? [] : []
            }

            if (type === 'artist') {
                dd = { data: [{ ...FilterForm.data[0], artist: GetFilterTempData }] }
                setFilterForm({ data: [{ ...FilterForm.data[0], artist: GetFilterTempData }] })
            } else {
                dd = { data: [{ ...FilterForm.data[0], language: GetFilterTempData }] }
                setFilterForm({ data: [{ ...FilterForm.data[0], language: GetFilterTempData }] })
            }
            promises.push(dd)
        } else if (type === 'time_by') {
            dd = { data: [{ ...FilterForm.data[0], time_by: _id }] }
            setFilterForm({ data: [{ ...FilterForm.data[0], time_by: _id }] })
            promises.push(dd)
        } else if (type === 'year') {
            dd = { data: [{ ...FilterForm.data[0], year: _id }] }
            setFilterForm({ data: [{ ...FilterForm.data[0], year: _id }] })
            promises.push(dd)
        } else if (type === 'search') {
            dd = { data: [{ ...FilterForm.data[0], search: _id }] }
            setFilterForm({ data: [{ ...FilterForm.data[0], search: _id }] })
            promises.push(dd)
        }
        Promise.all(promises).then(pp => {
            GetPublicationData(publication.data[0]?.name, SubCategory.data, pp[0])
        })
    }

    return (
        <>
            {isPageLoad === 'yes' ? <>
                {/* <Banner BannerImage="Shreejibapa_1920.png" /> */}
                <MetaData keywords={`Publications, maninagar, swaminarayan, gadi, sansthan, ${publication.data[0]['name']}`} description={`Publications from Maninagar Shree Swaminarayan Gadi Sansthan`} />
                <section className="publication-contain">
                    <div className="Flower-top d-none d-md-block" style={{ zIndex: 0 }}>
                        <img src={process.env.PUBLIC_URL + '/img/Flower-top.png'} className="img-fluid lazyload" alt="Flower" title="Flower" />
                    </div>
                    <div className="container" style={{ zIndex: 1, position: 'relative' }}>
                        <div className="row">
                            <SectionTitle title="Publications" />
                            <div className="col-lg-12 pt-0 pt-lg-0 d-flex flex-column justify-content-center publications" >
                                <ScrollAnimation className="row publications-search" animateIn="fadeIn" animateOnce={true} delay={100} >
                                    {/* {JSON.stringify(FilterForm)} */}
                                    {From === 'main' ? <div className={`col-12 col-sm-12 ${publication.data[0]['name'].toLowerCase() === 'shree ghanshyam vijay' || publication.data[0]['name'].toLowerCase() === 'video' ? 'col-md-9 col-lg-9 col-xl-9' : 'col-md-6 col-lg-6 col-xl-6'}`}>
                                        <div className="d-flex flex-row">
                                            <div className={`${publication.data[0]['name'].toLowerCase() === 'shree ghanshyam vijay' && window.innerWidth < 768 ? 'col-12' : 'col-4'}`}>
                                                <select className={`form-control with-bg ${publication.data[0]['name'].toLowerCase() === 'shree ghanshyam vijay' && window.innerWidth < 768 ? '' : 'border-radius-top-right border-radius-bottom-right'}`} name="main_category" onChange={(e) => ChangeFilters(e.target.value, 'main_category')}>
                                                    {MainCategory.data && MainCategory.data.length > 0 && MainCategory.data.map((data: any, i: any) =>
                                                    (
                                                        <><option value={data._id} selected={publicationSlug === data.slug}>{data.name}</option></>
                                                    ))}
                                                </select>
                                            </div>
                                            {publication.data[0]['name'].toLowerCase() === 'shree ghanshyam vijay' && window.innerWidth < 768 ? '' : <input className="form-control border-radius-top-left border-radius-bottom-left" type={`text`} value={FilterForm.data[0].search} name="search" onChange={(e) => ChangeFilters(e.target.value, 'search')} placeholder="Search" />}
                                        </div>
                                    </div> : ''}
                                    <div className={`${(publication.data[0]['name'].toLowerCase() === 'shree ghanshyam vijay') ? 'col-12' : 'col-6'} col-sm-12 col-md-6 col-lg-3 col-xl-3 mt-3 mt-md-0 mt-lg-0`}>
                                        <div className="d-flex flex-row">
                                            {publication.data[0]['name'].toLowerCase() === 'shree ghanshyam vijay' && window.innerWidth < 768 ? <div className="col-8">
                                                <input className="form-control border-radius-top-right border-radius-bottom-right" type={`text`} name="search" onChange={(e) => ChangeFilters(e.target.value, 'search')} placeholder="Search" />
                                            </div> : ''}
                                            <select className={`form-control ${publication.data[0]['name'].toLowerCase() === 'shree ghanshyam vijay' && window.innerWidth < 768 ? 'border-radius-top-left border-radius-bottom-left' : ''}`} name="sub_category" onChange={(e) => ChangeFilters(e.target.value, 'sub_category')}>
                                                <option value="">{publication.data[0]['name'].toLowerCase() !== 'shree ghanshyam vijay' ? 'Sub Category' : 'Year'}</option>
                                                {SubCategory.data && SubCategory.data.length > 0 && SubCategory.data.map((data: any, i: any) =>
                                                (
                                                    <><option value={data._id} selected={publicationSubCategorySlug === data.slug}>{data.name}</option></>
                                                ))}
                                            </select>
                                        </div>
                                    </div>
                                    {publication.data[0]['name'].toLowerCase() !== 'shree ghanshyam vijay' && publication.data[0]['name'].toLowerCase() !== 'video' ?
                                        <>
                                            <div className="col-6 col-sm-12 col-md-6 col-lg-3 col-xl-3 mt-3 mt-lg-0">
                                                <div className="d-flex flex-row">
                                                    <select className="form-control" name="language" onChange={(e) => ChangeFilters(e.target.value, 'language')}>
                                                        <option value="">Language</option>
                                                        {Language.data && Language.data.length > 0 && Language.data.map((data: any, i: any) =>
                                                        (
                                                            <><option value={data._id} selected={(FilterForm.data[0].language.findIndex((ell: { _id: string; }) => ell._id === data._id) > -1)}>{data.name}</option></>
                                                        ))}
                                                    </select>
                                                </div>
                                            </div>
                                        </> : ''}
                                    {publication.data[0]['name'].toLowerCase() !== 'shree ghanshyam vijay' && publication.data[0]['name'].toLowerCase() !== 'video' ?
                                        <>
                                            <div className={`col-12 col-sm-12 col-md-6 col-lg-4 ${From === 'main' ? 'col-xl-5 mt-3 mt-lg-4' : 'col-xl-6'} `}>
                                                <div className="d-flex flex-row">
                                                    <select className="form-control" name="artist" onChange={(e) => ChangeFilters(e.target.value, 'artist')}>
                                                        <option value="">{`${(publication.data[0]['name'].toLowerCase() === 'audio') ? 'Artist' : 'Author'}`}</option>
                                                        {Artist.data && Artist.data.length > 0 && Artist.data.sort((a: any, b: any) => (a.title > b.title) ? 1 : -1) && Artist.data.map((data: any, i: any) =>
                                                        (
                                                            <>
                                                                <option value={data._id} selected={(FilterForm.data[0].artist.findIndex((ell: { _id: string; }) => ell._id === data._id) > -1)}>{data.title}</option>
                                                            </>
                                                        ))}
                                                    </select>
                                                </div>
                                            </div>
                                        </> : ''}
                                    {publication.data[0]['name'].toLowerCase() !== 'shree ghanshyam vijay' ?
                                        <>
                                            <div className="col-12 col-sm-12 col-md-12 col-lg-2 col-xl-1 mt-3 mt-lg-4 font-family-mulish d-flex flex-column justify-content-center">
                                                <div className={`text-start ${(publication.data[0]['name'].toLowerCase() === 'video') ? '' : 'text-lg-end'} font-size-18px color-black`} style={{ fontWeight: '500' }}>Sort By</div>
                                            </div>
                                            <div className="col-6 col-sm-12 col-md-6 col-lg-3 col-xl-3 mt-3 mt-lg-4">
                                                <div className="d-flex flex-row">
                                                    <select className="form-control" name="time_by" onChange={(e) => ChangeFilters(e.target.value, 'time_by')}>
                                                        <option value="desc">Newest</option>
                                                        <option value="asc">Oldest</option>
                                                        <option value="AZ">A...Z</option>
                                                        <option value="ZA">Z...A</option>
                                                    </select>
                                                </div>
                                            </div>
                                            <div className="col-6 col-sm-12 col-md-6 col-lg-3 col-xl-3 mt-3 mt-lg-4">
                                                <div className="d-flex flex-row">
                                                    <select className="form-control" name="year" onChange={(e) => ChangeFilters(e.target.value, 'year')}>
                                                        <option value="">Year</option>
                                                        {Years.data && Years.data.length > 0 && Years.data.map((data: any, i: any) => (
                                                            <option value={data} selected={(FilterForm.data[0].year === data + '') ? true : false}>{data}</option>
                                                        ))}
                                                    </select>
                                                </div>
                                            </div>
                                        </>
                                        : ''}
                                </ScrollAnimation>

                                {(FilterForm.data[0].language.length > 0 || FilterForm.data[0].artist.length) ?
                                    <ScrollAnimation className="d-flex search-show mt-3 mt-lg-4" animateIn="fadeIn" animateOnce={true} delay={100} >
                                        {/* <div className="d-flex search-show-inner">
                                            <span className="filter-name">SUB-CATEGORY</span>
                                            <span className="filter-name filter-name-single">Ashirvads <span className="filter-remove">X</span></span>
                                        </div> */}
                                        {/* <div className="d-flex search-show-inner">
                                            <span className="filter-name">Language</span>
                                            <span className="filter-name filter-name-single">Gujarati <span className="filter-remove">X</span></span>
                                            <span className="filter-name filter-name-single">Hindi <span className="filter-remove">X</span></span>
                                        </div> */}
                                        {FilterForm.data[0].language && FilterForm.data[0].language.length > 0 ?
                                            (
                                                <>
                                                    <div className="d-flex search-show-inner">
                                                        <span className="filter-name">Language</span>
                                                        {FilterForm.data[0].language.map((data: any, i: any) =>
                                                            <span className="filter-name filter-name-single">{data.name}<span className="filter-remove" onClick={() => ChangeFilters(data._id, 'language', 'remove')}>&times;</span></span>
                                                        )}
                                                    </div>
                                                </>
                                            ) : ''}
                                        {FilterForm.data[0].artist && FilterForm.data[0].artist.length > 0 ?
                                            (
                                                <>
                                                    <div className="d-flex search-show-inner">
                                                        <span className="filter-name">{`${publication.data[0]['name'].toLowerCase() === 'books' ? 'Author' : 'Artist'}`}</span>
                                                        {FilterForm.data[0].artist.map((data: any, i: any) =>
                                                            <span className="filter-name filter-name-single">{data.title}<span className="filter-remove" onClick={() => ChangeFilters(data._id, 'artist', 'remove')}>&times;</span></span>
                                                        )}
                                                    </div>
                                                </>
                                            ) : ''}
                                    </ScrollAnimation>
                                    : ''}

                                <div className="row publication-data-container mt-3 mt-lg-4 ">
                                    {publicationData.data && publicationData.data.length > 0 ?
                                        (
                                            <>
                                                {publicationData.data.map((data: any, i: any) =>
                                                    <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-4 mt-4 mt-lg-4">
                                                        {(() => {
                                                            if (1 === 1) {
                                                                return ('')
                                                            }
                                                        })()}
                                                        <ScrollAnimation className="d-flex h-100 flex-column publication-data" animateIn="fadeIn" offset={0} animateOnce={true} delay={10} >

                                                            {(data.type === '1') && (<>
                                                                <div className="d-flex flex-row">
                                                                    <div className="col-5">
                                                                        <img className="img-fluid publication-image" src={`${data.banner_image}`} alt={data.banner_image} title={data.title} />
                                                                    </div>
                                                                    <div className="col-7">
                                                                        <div className="publication-data-text d-flex flex-column">

                                                                            <a className="publication-title line-2-ellipsis" title={data.title}>{data.title}</a>
                                                                            <span className="publication-types">{`${data.categoryName[0][data.category]}`}</span>
                                                                            <div className="">
                                                                                <a className="btn btn-3 d-flex flex-row align-items-center" title="Listen">
                                                                                    <svg className="music-icon" width="14" height="14" viewBox="0 0 14 14" fill="black" xmlns="http://www.w3.org/2000/svg">
                                                                                        <path d="M6.99986 0.531378C5.36161 0.535367 3.79146 1.18799 2.63326 2.34641C1.47485 3.50482 0.822349 5.07488 0.818359 6.71301V10.1439C0.908991 11.9878 2.36001 13.4691 4.12825 13.4691H4.12813C4.37859 13.4691 4.58155 13.266 4.58155 13.0157V6.92438C4.58155 6.67392 4.37859 6.47096 4.12813 6.47096C3.20123 6.4752 2.32069 6.87702 1.70994 7.57426V6.71281C1.70994 5.31181 2.26647 3.96826 3.25708 2.97749C4.24768 1.98689 5.59136 1.43036 6.9924 1.43036C8.39343 1.43036 9.73695 1.98689 10.7277 2.97749C11.7185 3.96823 12.275 5.31178 12.275 6.71281V7.57426C11.6642 6.87698 10.7837 6.47518 9.85666 6.47096C9.6062 6.47096 9.40325 6.67392 9.40325 6.92438V13.0157C9.40325 13.266 9.60621 13.4691 9.85666 13.4691C11.6251 13.4691 13.0759 12.003 13.1666 10.1439V6.71301C13.1626 5.07737 12.512 3.50939 11.3569 2.35152C10.2017 1.19361 8.6355 0.539229 6.99987 0.53125L6.99986 0.531378ZM3.67465 7.4234V12.517C2.55613 12.2902 1.70977 11.2323 1.70977 9.9778C1.7099 8.72326 2.57135 7.65025 3.67465 7.42349V7.4234ZM10.3251 12.517V7.4234C11.4436 7.65017 12.2899 8.7081 12.2899 9.96261C12.2899 11.217 11.4285 12.2902 10.3251 12.5169V12.517Z" />
                                                                                    </svg>&nbsp;
                                                                                    Listen
                                                                                </a>&nbsp;&nbsp;
                                                                                <a><img src={`${process.env.PUBLIC_URL}/icons/facebook-circled.png`} alt="facebook" title="facebook" /></a>&nbsp;
                                                                                <a><img src={`${process.env.PUBLIC_URL}/icons/twitter.png`} alt="twitter" title="twitter" /></a>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="d-flex flex-row">
                                                                    <div className="col-12">
                                                                        <p className="publication-desc"><LimitDesc desc={data.desc} /></p>
                                                                    </div>
                                                                </div>
                                                            </>)}

                                                            {('2' === '2') && (<>
                                                                <div className="d-flex flex-row flex-wrap">

                                                                    <div className="col-12">
                                                                        <div className="publication-data-text d-flex flex-column">
                                                                            {(publication.data[0].name === 'Audio' || publication.data[0].name === 'Video') ? <>
                                                                                <svg className="publication-icon" width="24" height="22" viewBox="0 0 24 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                                    <path d="M22.5802 1.80567C22.5799 1.57981 22.4785 1.3658 22.304 1.22242C22.1295 1.07905 21.8999 1.02129 21.6784 1.06513L8.83104 3.58423V3.58402C8.47699 3.65384 8.22184 3.96405 8.22141 4.32477V14.6301C7.44556 14.0901 6.52157 13.8042 5.57636 13.8112C3.28391 13.8139 1.41992 15.4134 1.41992 17.3808C1.41992 19.3481 3.28391 20.9502 5.57636 20.9502C7.8688 20.9502 9.73279 19.3481 9.73279 17.3808V8.73296L21.0684 6.51115V14.6303C20.2926 14.0903 19.3684 13.8044 18.4234 13.8114C16.1309 13.8114 14.2669 15.4111 14.2669 17.3808C14.2669 19.3508 16.1309 20.9505 18.4234 20.9505C20.7156 20.9505 22.5798 19.3483 22.5798 17.381L22.5802 1.80567ZM5.57643 19.4392C4.11782 19.4392 2.93138 18.5173 2.93138 17.3811C2.93138 16.245 4.11782 15.3231 5.57643 15.3231C7.03505 15.3231 8.22149 16.2475 8.22149 17.3811C8.22149 18.5146 7.03505 19.4392 5.57643 19.4392ZM9.73287 7.18386V4.9546L21.0685 2.73279V4.9546L9.73287 7.18386ZM18.4238 19.4392C16.9652 19.4392 15.7788 18.5173 15.7788 17.3811C15.7788 16.245 16.9652 15.3231 18.4238 15.3231C19.8822 15.3231 21.0689 16.2475 21.0689 17.3811C21.0689 18.5146 19.8822 19.4392 18.4238 19.4392Z" fill="#F83600" stroke="#F83600" />
                                                                                </svg>
                                                                            </> : <><svg className="publication-icon" width="24" height="22" xmlns="http://www.w3.org/2000/svg"><path d="M6 4H5a1 1 0 1 1 0-2h11V1a1 1 0 0 0-1-1H4a2 2 0 0 0-2 2v16c0 1.1.9 2 2 2h12a2 2 0 0 0 2-2V5a1 1 0 0 0-1-1h-7v8l-2-2-2 2V4z" /></svg>{/* <svg className="publication-icon" width="24" height="22" viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg"><path d="M29,23H3a1,1,0,0,1-1-1V7A1,1,0,0,1,3,6H29a1,1,0,0,1,1,1V22A1,1,0,0,1,29,23ZM4,21H28V8H4Z" /><path d="M21,26H11a1,1,0,0,1,0-2H21a1,1,0,0,1,0,2Z" /><path d="M13,19a1,1,0,0,1-.53-.15A1,1,0,0,1,12,18V12a1,1,0,0,1,1.45-.89l6,3a1,1,0,0,1,0,1.78l-6,3A1,1,0,0,1,13,19Zm1-5.38v2.76L16.76,15Z" /></svg> */}</>}

                                                                            {(publication.data[0].name === 'Shree Ghanshyam Vijay') ? <a href={data.pdf_file} className="publication-title line-2-ellipsis" target={`_blank`} title={data.title}>
                                                                                <HighLighttext searchWords={FilterForm.data[0].search} textToHighlight={data.title} />{/* {data.title} */}</a> :
                                                                                <Link to={GetPublicationUrl(data, publicationSlug)} className="publication-title line-2-ellipsis" title={data.title}><HighLighttext searchWords={FilterForm.data[0].search} textToHighlight={data.title} /></Link>}

                                                                            <span className="publication-types" dangerouslySetInnerHTML={{ __html: `${publication.data[0].name} &#183; ${data.categoryName[0][data.category]}` }}></span>
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-12 col-sm-5 col-md-5 col-lg-5 col-xl-5">
                                                                        {(publication.data[0].name === 'Shree Ghanshyam Vijay') ? <a href={data.pdf_file} target={`_blank`} title={data.title}>
                                                                            <LazyImage
                                                                                loadingSrc={`${process.env.PUBLIC_URL}/img/placeholder.png`}
                                                                                actualSrc={`${data.banner_image}`}
                                                                                errorSrc={`${process.env.PUBLIC_URL}/img/placeholder.png`}
                                                                                width={`100%`}
                                                                                style={{ position: 'absolute', top: 0, left: 0 }}
                                                                                ContainerClass="img-fluid publication-image"
                                                                                title={data.title}
                                                                                alt={data.title}
                                                                            />
                                                                        </a> :
                                                                            <Link to={GetPublicationUrl(data, publicationSlug)} title={data.title}>
                                                                                <LazyImage
                                                                                    loadingSrc={`${process.env.PUBLIC_URL}/img/placeholder.png`}
                                                                                    actualSrc={`${data.banner_image}`}
                                                                                    errorSrc={`${process.env.PUBLIC_URL}/img/placeholder.png`}
                                                                                    width={`100%`}
                                                                                    style={{ position: 'absolute', top: 0, left: 0 }}
                                                                                    ContainerClass="img-fluid publication-image"
                                                                                    title={data.title}
                                                                                    alt={data.title}
                                                                                />
                                                                            </Link>}
                                                                    </div>
                                                                    <div className="col-12 col-sm-7 col-md-7 col-lg-7 col-xl-7 ps-0 ps-sm-3 ps-md-3 pt-3 pt-sm-0">
                                                                        <div className="d-flex flex-row flex-wrap" style={{ gap: '4px' }}>
                                                                            {(publication.data[0].name === 'Shree Ghanshyam Vijay') ? <a href={data.pdf_file} target={`_blank`} className="btn btn-3 d-flex align-items-center" title={`Download ${data.title}`}>
                                                                                <svg style={{ width: '12px' }} className="music-icon" width="17" height="16" viewBox="0 0 17 16" fill="black" xmlns="http://www.w3.org/2000/svg">
                                                                                    <path d="M16.0304 11.0726V14.3478C16.0304 14.413 16.0203 14.4736 16.0051 14.5295C15.9345 14.8509 15.6266 15.0932 15.2632 15.0932H0.762216C0.373538 15.0932 0.0504874 14.8183 0.0101582 14.469C5.33615e-08 14.427 0 14.3898 0 14.3432V11.068C0 10.6812 0.343216 10.3691 0.757046 10.3691C0.964042 10.3691 1.15572 10.4483 1.29211 10.5742C1.42833 10.6999 1.51426 10.877 1.51426 11.0681V13.6912H14.5215V11.0681C14.5215 10.6814 14.8647 10.3693 15.2785 10.3693C15.4855 10.3693 15.6772 10.4485 15.8136 10.5744C15.9448 10.7044 16.0306 10.8815 16.0306 11.0725L16.0304 11.0726Z" />
                                                                                    <path d="M12.3813 7.79282L8.66141 11.2266C8.65641 11.236 8.64626 11.2406 8.64126 11.2452C8.50504 11.3709 8.32836 11.4502 8.15168 11.4735C8.13653 11.4735 8.12137 11.4781 8.10622 11.4781C8.07591 11.4827 8.0456 11.4827 8.0153 11.4827L7.92954 11.4781C7.91438 11.4781 7.89923 11.4735 7.88407 11.4735C7.70239 11.4501 7.5307 11.3709 7.3945 11.2452C7.3895 11.2406 7.37934 11.2312 7.37435 11.2266L3.65443 7.79282C3.48274 7.63434 3.39697 7.42481 3.39697 7.21513C3.39697 7.00545 3.48273 6.7958 3.65443 6.63744C3.99764 6.32063 4.55783 6.32063 4.90621 6.63744L7.14218 8.70141V1.13955C7.14218 0.692238 7.54085 0.324219 8.02545 0.324219C8.26775 0.324219 8.48975 0.417373 8.65128 0.561869C8.81281 0.710977 8.90874 0.911271 8.90874 1.13955V8.70142L11.1447 6.63745C11.4879 6.32064 12.0481 6.32064 12.3965 6.63745C12.7245 6.95903 12.7245 7.47612 12.3813 7.79294V7.79282Z" />
                                                                                </svg>&nbsp;
                                                                                Download
                                                                            </a> :
                                                                                <>
                                                                                    <div className="d-flex flex-row" style={{ gap: '4px' }}>
                                                                                        <Link to={GetPublicationUrl(data, publicationSlug)} className="btn btn-3 d-flex flex-row align-items-center" title={data.title}>
                                                                                            {(publication.data[0].name === 'Audio' || publication.data[0].name === 'Video') ? <>
                                                                                                <svg className="music-icon" width="14" height="14" viewBox="0 0 14 14" fill="black" xmlns="http://www.w3.org/2000/svg">
                                                                                                    <path d="M6.99986 0.531378C5.36161 0.535367 3.79146 1.18799 2.63326 2.34641C1.47485 3.50482 0.822349 5.07488 0.818359 6.71301V10.1439C0.908991 11.9878 2.36001 13.4691 4.12825 13.4691H4.12813C4.37859 13.4691 4.58155 13.266 4.58155 13.0157V6.92438C4.58155 6.67392 4.37859 6.47096 4.12813 6.47096C3.20123 6.4752 2.32069 6.87702 1.70994 7.57426V6.71281C1.70994 5.31181 2.26647 3.96826 3.25708 2.97749C4.24768 1.98689 5.59136 1.43036 6.9924 1.43036C8.39343 1.43036 9.73695 1.98689 10.7277 2.97749C11.7185 3.96823 12.275 5.31178 12.275 6.71281V7.57426C11.6642 6.87698 10.7837 6.47518 9.85666 6.47096C9.6062 6.47096 9.40325 6.67392 9.40325 6.92438V13.0157C9.40325 13.266 9.60621 13.4691 9.85666 13.4691C11.6251 13.4691 13.0759 12.003 13.1666 10.1439V6.71301C13.1626 5.07737 12.512 3.50939 11.3569 2.35152C10.2017 1.19361 8.6355 0.539229 6.99987 0.53125L6.99986 0.531378ZM3.67465 7.4234V12.517C2.55613 12.2902 1.70977 11.2323 1.70977 9.9778C1.7099 8.72326 2.57135 7.65025 3.67465 7.42349V7.4234ZM10.3251 12.517V7.4234C11.4436 7.65017 12.2899 8.7081 12.2899 9.96261C12.2899 11.217 11.4285 12.2902 10.3251 12.5169V12.517Z" />
                                                                                                </svg>&nbsp;
                                                                                                Listen
                                                                                            </> : <>
                                                                                                Read
                                                                                            </>
                                                                                            }
                                                                                        </Link>
                                                                                        {(publication.data[0].name === 'Books' && data.book_file !== '' && data.book_file !== undefined && data.book_file !== 'undefined') ? <a href={data.book_file} target="_blank" className="btn btn-3 d-flex flex-row align-items-center" download={`download`} title={data.title}>Download</a> : ''}
                                                                                    </div>
                                                                                </>}
                                                                            {/* <a><img src={`${process.env.PUBLIC_URL}/icons/facebook-circled.png`} alt="" /></a>&nbsp;
                                                                            <a><img src={`${process.env.PUBLIC_URL}/icons/twitter.png`} alt="" /></a> */} 
                                                                            <div className="d-flex flex-column justify-content-center" style={{ zIndex: 0 }}>
                                                                                <InlineShareButtons
                                                                                    config={{
                                                                                        alignment: 'center',  // alignment of buttons (left, center, right)
                                                                                        color: 'social',      // set the color of buttons (social, white)
                                                                                        enabled: true,        // show/hide buttons (true, false)
                                                                                        font_size: 14,        // font size for the buttons
                                                                                        labels: null,        // button labels (cta, counts, null)
                                                                                        language: 'en',       // which language to use (see LANGUAGES)
                                                                                        networks: [           // which networks to include (see SHARING NETWORKS)
                                                                                            /* 'whatsapp',
                                                                                            'linkedin',
                                                                                            'messenger', */
                                                                                            'facebook',
                                                                                            'twitter'
                                                                                        ],
                                                                                        padding: 12,          // padding within buttons (INTEGER)
                                                                                        radius: 4,            // the corner radius on each button (INTEGER)
                                                                                        show_total: false,
                                                                                        size: 30,             // the size of each button (INTEGER)

                                                                                        // OPTIONAL PARAMETERS
                                                                                        url: GetPublicationUrl(data, publicationSlug), // (defaults to current url)
                                                                                        image: data.banner_image,  // (defaults to og:image or twitter:image)
                                                                                        description: 'custom text',       // (defaults to og:description or twitter:description)
                                                                                        title: data.title,            // (defaults to og:title or twitter:title)
                                                                                        message: 'custom email text',     // (only for email sharing)
                                                                                        subject: 'custom email subject',  // (only for email sharing)
                                                                                        username: 'custom twitter handle' // (only for twitter sharing)
                                                                                    }}
                                                                                />
                                                                            </div>
                                                                        </div>
                                                                        <p className="publication-desc">
                                                                            <HighLighttext
                                                                                searchWords={FilterForm.data[0].search}
                                                                                textToHighlight={data.desc}
                                                                                Limit={250} />
                                                                            {/* <LimitDesc desc={data.desc} limit="250" /> */}
                                                                        </p>
                                                                    </div>

                                                                    {data.Datas__ !== undefined && data.Datas__.length > 0 ? <>
                                                                        <div className="col-12 found-in-search">
                                                                            <p className="mt-3 mb-1 found-in-search-title">Found Track</p>
                                                                            {data.Datas__.map((Audio: any, i: any) =>
                                                                                <>
                                                                                    {i < 5 ?
                                                                                        <span className="found-in-search-text d-flex flex-row"><span className="dot" dangerouslySetInnerHTML={{ __html: `&#183;` }}></span> <HighLighttext searchWords={FilterForm.data[0].search} textToHighlight={Audio.title} /></span> : ''}
                                                                                </>
                                                                            )}
                                                                        </div>
                                                                    </> : ''}
                                                                    {/* <div className="col-12 found-in-search">
                                                                        <p className="mt-3 mb-1 found-in-search-title">Found Track</p>
                                                                        <span className="found-in-search-text d-flex flex-row"><span className="dot" dangerouslySetInnerHTML={{ __html: `&#183;` }}></span> <HighLighttext searchWords={FilterForm.data[0].search} textToHighlight={data.title} /></span>
                                                                    </div> */}
                                                                </div>
                                                            </>)}
                                                        </ScrollAnimation>
                                                    </div>
                                                )}
                                                {PaginationData.nextpage !== '' && PaginationData.nextpage !== null && PaginationData.nextpage !== undefined ?
                                                    <div className="d-flex flex-row justify-content-center mt-5">
                                                        <button ref={myRef} className={`btn btn-primary ${(isPageLoadMore === 'yes') ? '' : 'disabled'}`} disabled={(isPageLoadMore === 'yes') ? false : true} style={{ opacity: '0', height: 0, width: 0, padding: 0 }} onClick={() => GetPublicationData(publication.data[0]['name'], SubCategory.data, FilterForm, 'loadmore')}>{(isPageLoadMore === 'yes') ? 'Load More' : 'Loading...'}</button>
                                                        {(isPageLoadMore === 'yes') ? '' : <span className="text-center active-color font-size-18px font-weight-bold">Loading...</span>}
                                                    </div>
                                                    : ''}
                                            </>
                                        ) : <NoDataFound Text={(From === 'main') ? 'There is currently no publication for this selection' : 'There are no results for your search query.<br/>Please refine your search.'} />}
                                </div>

                                {/* <div className="d-flex flex-row justify-content-center mt-md-5 mt-4">
                                    <ReactPaginate
                                        breakLabel="..."
                                        nextLabel="Next"
                                        onPageChange={handlePageClick}
                                        pageRangeDisplayed={3}
                                        pageCount={15}
                                        previousLabel="Previous"
                                        className="pagination pagination-s"
                                        previousClassName="page-item"
                                        nextClassName="page-item"
                                        previousLinkClassName="page-link"
                                        nextLinkClassName="page-link"
                                        pageClassName="page-item"
                                        pageLinkClassName="page-link"
                                        breakClassName="page-item"
                                        breakLinkClassName="page-link"
                                        activeClassName="active"
                                    * renderOnZeroPageCount={null} *
                                    />
                                </div> */}

                            </div>
                        </div>
                    </div>
                </section>
            </> : (<PageLoader />)}
        </>
    )
}

export default Publication