import axios from "axios";
import React, { useEffect, useState } from "react"
// @ts-ignore
import ScrollAnimation from 'react-animate-on-scroll'
import { Link } from "react-router-dom";
import { IsUrlIntOrExt, PrintDesc } from "./CommonSections"
// @ts-ignore
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const options = {
    dots: true,
    arrows: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    draggable: false,
    autoplay: true,
    autoplaySpeed: 5000
};

const Live_broadcast = ({ Data }: any) => {
    const [Quote, setQuote] = useState({ data: [] as any[] });
    useEffect(() => {
        axios.get(`${process.env.REACT_APP_API_URL}quote/all`).then(json => {
            setQuote({ data: [json.data.data[Math.floor(Math.random() * json.data.data.length)]] })
        })
    }, [])

    var SingleImage = [] as any
    if (Data.image !== undefined) {
        var string_check = IsUrlIntOrExt(Data.referenceLink)
        var image = <img src={Data.image} className="img-fluid lazyload" alt={Data.title} title={Data.title} />
        SingleImage.push (string_check.type === 'internal' ?
            <Link to={`${process.env.PUBLIC_URL}${Data.referenceLink}`} title={Data.title}>
                {image}
            </Link> :
            <a href={`${Data.referenceLink}`} title={Data.title} target="_blank">{image}</a>)
    }

    return (
        <>
            {Quote.data.length > 0 ?
                <section className="live-broadcast pb-0 pt-0">
                    <ScrollAnimation className="d-flex flex-column flex-lg-row" animateIn="fadeIn" animateOnce={true} delay={100} >
                        {Data.image_json.length > 0 || Data.image !== undefined ? <div className="col-lg-6 text-center justify-content-center live-broadcast-box">
                            {Data.image_json.length > 0 ? <Slider className={`w-100 quote_board_slider`} {...options} key={`live-liveDarshan`}>
                                {Data.image_json.map((images: any, i: any) => {

                                    var string_check = IsUrlIntOrExt(images.s_l_url)
                                    var image = <img src={images.imageurl} className="img-fluid lazyload" alt={images.s_i_alt} title={images.s_i_alt} />
                                    return (string_check.type === 'internal' ?
                                        <Link to={`${process.env.PUBLIC_URL}${images.s_l_url}`} title={images.s_i_alt}>
                                            {image}
                                        </Link> :
                                        <a href={`${images.s_l_url}`} title={images.s_i_alt} target="_blank">{image}</a>)
                                })}
                            </Slider> : ''}
                            {Data.image !== undefined ? SingleImage : ''}
                        </div> : ''}
                        <div className={`d-flex flex-column justify-content-center mt-3 mt-lg-0 pt-4 pt-md-4 pt-lg-0 pb-4 pb-md-4 pb-lg-0 col-lg-6 text-center live-broadcast-box`}>
                            <div className="live-broadcast-quote" >
                                <span className="d-block title">{/* &ldquo;  */}<PrintDesc desc={Quote.data[0].desc} /></span>
                                <p className="sub-title">{Quote.data[0].author}</p>
                            </div>
                        </div>
                    </ScrollAnimation>
                </section>
                : ''}
        </>
    )
}

export default Live_broadcast