import React, { useContext, useEffect, useState } from "react"
import Banner from './sections/Banner'
import BreadCrumb from "./sections/BreadCrumb"
import { PageLoader, SectionTitle, monthNames, SayingsImageAndText, monthNamesShort, PrintDesc, MetaData } from "./sections/CommonSections";
import GlobalContext from './Core/PageData'
import { Link, useParams } from "react-router-dom"
import axios from "axios";
import ScrollAnimation from "react-animate-on-scroll";
// @ts-ignore
import Slider from "react-slick";
import { PreviousBroadCast } from "./LiveBroadCast";
import moment from 'moment';
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css';
import CMSContent from "./CMSContent";

const options = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 5,
    slidesToScroll: 1,
    responsive: [{
        breakpoint: 1200,
        settings: {
            slidesToShow: 3
        }
    }, {
        breakpoint: 1000,
        settings: {
            slidesToShow: 3
        }
    }, , {
        breakpoint: 991,
        settings: {
            slidesToShow: 2
        }
    }, {
        breakpoint: 600,
        settings: {
            slidesToShow: 1
        }
    }, {
        breakpoint: 0,
        settings: {
            slidesToShow: 1
        }
    }]

};

const EventDetails: React.FC = () => {
    let { eventDetailsSlug, eventDateSlug, LangSlug } = useParams();
    const { GlobalContent, setGlobalContent } = useContext(GlobalContext as any) as any
    document.title = `Event${GlobalContent.pageTitle}`;
    const [isPageLoad, setisPageLoad] = useState('no')
    const [BreadCrumbdata, setBreadCrumbdata] = useState({ data: [] as any[] })
    const [EventDetailsData, setEventDetailsData] = useState({ data: [] as any[] })
    const [CalendarEventDetailsData, setCalendarEventDetailsData] = useState({ data: [{ event: [], Datedata: [], CurDate: '', NextDate: '', PrevDate: '', NextDateT: '', PrevDateT: '' }] as any[] })
    const [CalendarEventGalleryData, setCalendarEventGalleryData] = useState({ data: [] as any[] })
    const [BroadcastData, setBroadcastData] = useState({ data: [] as any[] })
    var sites = GlobalContent.AllSiteData.filter((e: any) => e.is_main === 'yes')

    const [Lightimages, setLightimages] = useState({ data: [] as any[] });
    /* const [LightimagesCaption, setLightimagesCaption] = useState({ data: [] as any[] });
    const [LightimageTitle, setLightimageTitle] = useState({ data: [] as any[] }); */
    const [photoIndex, setphotoIndex] = useState(0)
    const [imageIndex, setimageIndex] = useState(0)

    const [isOpen, setisOpen] = useState(false)

    useEffect(() => {
        setisPageLoad('no')
        setLightimages({ data: [] })
        setGlobalContent({ ...GlobalContent, IsMediaPlayershow: false })

        if (eventDetailsSlug !== undefined) {
            axios.get(`${process.env.REACT_APP_API_URL}event/slug/${eventDetailsSlug}`).then(json => {
                setEventDetailsData({ data: json.data.data })
                //document.title = `${json.data.data[0].title}${GlobalContent.pageTitle}`;
                setisPageLoad('yes')
                return { EventDetails: json.data.data[0] }
            }).then(EventDetailsTemp => {
                setGlobalContent({ ...GlobalContent, siteData: sites[0], headerClass:'' })
                let BreadCrumbdata_ = [
                    {
                        path: `${process.env.PUBLIC_URL}/`,
                        linkName: 'Home',
                        status: '',
                    }, {
                        path: `${process.env.PUBLIC_URL}/calendar`,
                        linkName: 'Calendar',
                        status: '',
                    }, {
                        path: `${process.env.PUBLIC_URL}/event-details/${eventDetailsSlug}`,
                        linkName: EventDetailsTemp.EventDetails.title,
                        status: '',
                    }]

                setBreadCrumbdata({ data: BreadCrumbdata_ })
                document.title = `${EventDetailsTemp.EventDetails.title}${GlobalContent.pageTitle}`
            });
        } else {
            axios.get(`${process.env.REACT_APP_API_URL}calendar/event/${eventDateSlug}/${eventDateSlug}`).then(async json => {

                let CurDateObj = moment(eventDateSlug)
                let CurDate = `${(GlobalContent.isMobile) ? CurDateObj.format("MMM")/* monthNamesShort[CurDateObj.getMonth()] */ : CurDateObj.format("MMMM")} ${CurDateObj.format("D")}, ${CurDateObj.format('YYYY')}`

                let TempObj = moment(eventDateSlug).add(1, 'days')
                let NextDate = '' as any
                NextDate = `${(GlobalContent.isMobile) ? TempObj.format("MMM")/* monthNamesShort[TempObj.getMonth()] */ : TempObj.format("MMMM")} ${TempObj.format("D")}`
                let NextDateT = TempObj.format("YYYY-MM-DD")

                TempObj = moment(eventDateSlug).subtract(1, 'days')
                let PrevDate = '' as any
                PrevDate = `${TempObj.format("D")} ${(GlobalContent.isMobile) ? TempObj.format("MMM")/* monthNamesShort[TempObj.getMonth()] */ : TempObj.format("MMMM")}`
                let PrevDateT = TempObj.format("YYYY-MM-DD")

                const promisesEvent = [] as any;
                const EventIds = [] as any;

                const NewsIds = [] as any;
                json.data.data.event.forEach((eventD: any, j: any) => {
                    var date1Temp = moment(eventD.startDate.split('T')[0]);
                    var date1 = moment(eventD.startDate.split('T')[0]);
                    var date2 = moment(eventD.endDate.split('T')[0]);
                    var Difference_In_Time = parseInt(date2.format('x')) - parseInt(date1.format('x'));
                    var Difference_In_Days = Difference_In_Time / (1000 * 3600 * 24);

                    if (!Number.isNaN(Difference_In_Days)) {
                        for (var i = 0; i <= Difference_In_Days; i++) {
                            var DD = (i === 0) ? date1Temp.format('YYYY-MM-DD') : date1Temp.add(1, 'days').format('YYYY-MM-DD')
                            if (/* window.innerWidth < 768 ||  */DD === eventDateSlug) {
                                if (eventD.days_cnt[i] !== undefined) {
                                    eventD.title = `${eventD.title.trim()}${(eventD.days_cnt.length > 1) ? ` - Day ${i + 1}` : ''}`

                                    eventD['images_data'] = []
                                    /* if (eventD.image !== '' && eventD.image !== undefined && eventD.image !== 'undefined') {
                                        eventD['images_data'].push({ image: eventD.image, text: eventD.title })
                                    } */
                                    eventD.days_cnt.forEach((days_cnt: any, j: any) => {
                                        if (days_cnt.image !== '' && days_cnt.image !== undefined && days_cnt.image !== 'undefined') {
                                            eventD['images_data'].push({ image: days_cnt.imageurl, text: eventD.title })
                                        }
                                    })
                                    if (eventD['desc'] !== undefined && eventD['desc'] === '') {
                                        eventD['desc'] = (eventD.days_cnt[i].desc !== '' && eventD.days_cnt[i].desc !== undefined && eventD.days_cnt[i].desc !== 'undefined') ? eventD.days_cnt[i].desc : ''
                                    }

                                    promisesEvent.push(eventD)
                                    EventIds.push(eventD._id)

                                }
                            }
                        }
                    }
                })
                let EventGallery = [] as any
                if (EventIds.length > 0) {
                    await axios.get(`${process.env.REACT_APP_API_URL}news/event-wise/${eventDateSlug}/${EventIds.join(',')}`).then(json => {
                        json.data.data.forEach((news: any, j: any) => {
                            NewsIds.push(news._id)
                        })
                        return NewsIds
                    }).then(temp => {
                        let ImageGallery = [] as any
                        var newsIds = temp.join(',')
                        if (newsIds !== '') {
                            axios.get(`${process.env.REACT_APP_API_URL}cms/cms-page-content/news/image-gallery/${temp.join(',')}`).then(json => {
                                json.data.data.forEach((Content: any, j: any) => {
                                    ImageGallery = ImageGallery.concat(Content.referenceLink.split(','))
                                })
                                return ImageGallery
                            }).then(iTemp => {
                                axios.get(`${process.env.REACT_APP_API_URL}publication/image-gallery/ids/${iTemp.join(',')}`).then(json => {

                                    let GalleryTemp = [] as any
                                    json.data.data.forEach((Content: any, j: any) => {
                                        json.data.data[j]['image'] = (Content.image_json.length > 0) ? (Content.image_json.find((ell: { image_feature: string; }) => ell.image_feature === 'yes' || ell.image_feature === '1') !== undefined) ? Content.image_json.find((ell: { image_feature: string; }) => ell.image_feature === 'yes' || ell.image_feature === '1').imageurl : Content.image_json[0].imageurl : ''
                                        GalleryTemp[j] = { name: Content.name, images: Content.image_json }
                                    })
                                    setLightimages({ data: GalleryTemp })
                                    setCalendarEventGalleryData({ data: json.data.data })
                                })
                            })
                        }
                    })
                }

                setCalendarEventGalleryData({ data: EventGallery })

                setCalendarEventDetailsData({ data: [{ event: promisesEvent, yearly: json.data.data['yearly-event'], Datedata: json.data.data.calender, CurDate: CurDate, NextDate: NextDate, PrevDate: PrevDate, NextDateT: NextDateT, PrevDateT: PrevDateT }] })

                /* let GalleryTemp = [] as any
                json.data.data.event.forEach((eventD: any, j: any) => {
                    if (eventD.image !== '' && eventD.image !== undefined && eventD.image !== 'undefined') {
                        GalleryTemp.push({ image: eventD.image, text: eventD.title })
                    }
                    eventD.days_cnt.forEach((days_cnt: any, j: any) => {
                        if (days_cnt.image !== '' && days_cnt.image !== undefined && days_cnt.image !== 'undefined') {
                            GalleryTemp.push({ image: days_cnt.imageurl, text: eventD.title })
                        }
                    })
                })
                setCalendarEventGalleryData({ data: GalleryTemp })
                setLightimages({ data: GalleryTemp }) */

                return { EventDetails: json.data.data }
            }).then(EventDetailsTemp => {
                setGlobalContent({ ...GlobalContent, siteData: sites[0], headerClass:'' })
                let BreadCrumbdata_ = [
                    {
                        path: `${process.env.PUBLIC_URL}/`,
                        linkName: 'Home',
                        status: '',
                    }, {
                        path: `${process.env.PUBLIC_URL}/calendar`,
                        linkName: 'Calendar',
                        status: '',
                    }]

                var EventIds = [] as any
                EventDetailsTemp.EventDetails.event.forEach((data: any, j: any) => {
                    EventIds.push(`${process.env.REACT_APP_API_URL}livebroadcast/event/calander/${data._id}`)
                })
                EventIds = EventIds.filter(Boolean)
                if (EventIds.length > 0) {
                    Promise.all(EventIds.map((endpoint: any) => axios.get(endpoint))).then((BroadcastJson) => {
                        var Res = [] as any
                        BroadcastJson.forEach(async (dd: any, k: any) => {
                            if (dd.data.data.length > 0) {
                                Res.push(dd.data.data[0])
                            }
                        })
                        return Res
                    }).then((res) => {
                        setBroadcastData({ data: res })
                        setisPageLoad('yes')
                        setBreadCrumbdata({ data: BreadCrumbdata_ })
                        document.title = `Event${GlobalContent.pageTitle}`
                    })
                    /* axios.get(`${process.env.REACT_APP_API_URL}livebroadcast/event/calander/${EventIds.join(',')}`).then(json => {
                        json.data.data = json.data.data.filter((e: any) => e.status === 'active')
                        json.data.data.sort((a: any, b: any) => (a.date > b.date) ? 1 : -1)
                        setBroadcastData({ data: json.data.data })

                        setisPageLoad('yes')
                        setBreadCrumbdata({ data: BreadCrumbdata_ })
                        document.title = `Event${GlobalContent.pageTitle}`
                    }) */
                } else {
                    setisPageLoad('yes')
                    setBreadCrumbdata({ data: BreadCrumbdata_ })
                    document.title = `Event${GlobalContent.pageTitle}`
                }

            });
        }
    }, [eventDetailsSlug, eventDateSlug])

    const handleopen = (i: any) => {
        setisOpen(true)
        setphotoIndex(i)
        /* setimageIndex(j) */
    }

    return (
        <>
            {isPageLoad === 'yes' ? <>

                <MetaData keywords={`event, maninagar, swaminarayan, gadi, sansthan`} description={document.title} />
                {/* {JSON.stringify(Lightimages.data)} */}
                {isOpen && (
                    <>
                        <Lightbox

                            /* { image: days_cnt.image, text: eventD.title } */
                            wrapperClassName='image-gallery-popup'
                            mainSrc={Lightimages.data[photoIndex]['images'][imageIndex].imageurl}
                            nextSrc={Lightimages.data[photoIndex]['images'][(imageIndex + 1) % Lightimages.data[photoIndex]['images'].length]}
                            prevSrc={Lightimages.data[photoIndex]['images'][(imageIndex + Lightimages.data[photoIndex]['images'].length - 1) % Lightimages.data[photoIndex]['images'].length]}
                            onCloseRequest={() => setisOpen(false)}
                            onMovePrevRequest={() => setimageIndex((imageIndex + Lightimages.data[photoIndex]['images'].length - 1) % Lightimages.data[photoIndex]['images'].length)}
                            onMoveNextRequest={() => setimageIndex((imageIndex + 1) % Lightimages.data[photoIndex]['images'].length)}
                            imageCaption={`${(imageIndex + 1)} / ${Lightimages.data[photoIndex]['images'].length} :: ${Lightimages.data[photoIndex]['images'][imageIndex].caption}`}
                            imageTitle={Lightimages.data[photoIndex]['name']}
                        /></>
                )}
                <section className="site-contain" style={{ minHeight: '80vh' }}>
                    <ScrollAnimation className="" animateIn="fadeIn" animateOnce={true} delay={100} >
                        <div className="container">
                            <BreadCrumb BreadCrumbdata={BreadCrumbdata.data} />
                            <div className="d-flex flex-row justify-content-between" style={{ alignItems: 'center' }}>
                                {eventDateSlug !== undefined ? <Link to={`${process.env.PUBLIC_URL}/events/${CalendarEventDetailsData.data[0].PrevDateT}${(LangSlug === 'gujarati') ? `/${LangSlug}` : ``}`} className="event-details-nav prev" title={CalendarEventDetailsData.data[0].PrevDate}>{CalendarEventDetailsData.data[0].PrevDate}</Link> : ''}
                                <SectionTitle title={(eventDetailsSlug !== undefined) ? EventDetailsData.data[0].title : CalendarEventDetailsData.data[0].CurDate} type="title-active-color" colClass='col' />
                                {eventDateSlug !== undefined ? <Link to={`${process.env.PUBLIC_URL}/events/${CalendarEventDetailsData.data[0].NextDateT}${(LangSlug === 'gujarati') ? `/${LangSlug}` : ``}`} className="event-details-nav next" title={CalendarEventDetailsData.data[0].NextDate}>{CalendarEventDetailsData.data[0].NextDate}</Link> : ''}
                            </div>

                            {/* {JSON.stringify(GallerySidebarDataTemp.data)} */}

                            <div className="mt-4 event-details">
                                <div className="border-with-bg-radius p-4">
                                    {eventDetailsSlug !== undefined && EventDetailsData.data && EventDetailsData.data.length > 0 && EventDetailsData.data[0].days_cnt.length > 0 ?
                                        <div className="d-flex flex-column event-days-details">
                                            {
                                                EventDetailsData.data[0].days_cnt.map((data: any, i: any) =>
                                                (
                                                    <>
                                                        <div className="row">
                                                            <div className="col-12 col-sm-12 col-md-12 col-lg-3 col-xl-3  d-flex flex-column">
                                                                <span className="event-details-title">{EventDetailsData.data[0].title} Day {data.day}</span>
                                                                {/* <span className="event-details-time">Time: 7:00 AM to 10:00 PM</span> */}
                                                            </div>
                                                            <div className="col-12 col-sm-12 col-md-12 col-lg-9 col-xl-9 event-details-days-desc" dangerouslySetInnerHTML={{ __html: data.desc }}></div>
                                                        </div>
                                                    </>
                                                ))
                                            }</div>
                                        : ''}

                                    {eventDateSlug !== undefined && CalendarEventDetailsData.data ?
                                        <div className="d-flex flex-column event-days-details">
                                            {CalendarEventDetailsData.data[0].event.length > 0 && CalendarEventDetailsData.data[0].event.map((data: any, i: any) =>
                                            (
                                                <>
                                                <style>
                                                    {`.font-family-gujarati *{
                                                        font-family: "Hind Vadodara",sans-serif !important;
                                                    }`}
                                                </style>
                                                    <div className="row">
                                                        <div className="col-12 col-sm-12 col-md-12 col-lg-3 col-xl-3 d-flex flex-row">
                                                            {data.images_data.map((images: any, i: any) => {
                                                                return (
                                                                    <div className="they-say-carousel mt-2 ms-0 me-0"><SayingsImageAndText Image={`${images.image}`} Text={``} imageWidth="300" imageHeight="150" /></div>
                                                                )
                                                            })}
                                                            <span className={`event-details-title ${(LangSlug === 'gujarati' && data.guj_title !== '') ? 'font-family-gujarati' : ''}`}>{(LangSlug === 'gujarati' && data.guj_title !== '') ? data.guj_title : data.title}</span>
                                                            {/* <span className="event-details-time">Time: 7:00 AM to 10:00 PM</span> */}
                                                        </div>
                                                        <div className="col-12 col-sm-12 col-md-12 col-lg-9 col-xl-9 event-details-days-desc">
                                                            <div className={`${(LangSlug === 'gujarati' && data.guj_desc !== '') ? 'font-family-gujarati' : ''}`} dangerouslySetInnerHTML={{ __html: (LangSlug === 'gujarati' && data.guj_desc !== '') ? data.guj_desc : data.desc }}></div>
                                                            {/* <CMSContent cmsPageId={`630c38e4e88110c0b17b5da2`} ContainerClass="mm" firstBlockClass="mt-0" fromPage="eventDetails" ShowSection={['imageGallery']} /> */}
                                                        </div>
                                                    </div>
                                                </>
                                            ))}

                                            {CalendarEventDetailsData.data[0]['yearly'].map((data: any, i: any) =>
                                            (
                                                <>
                                                    <div className="row">
                                                        <div className="col-12 col-sm-12 col-md-12 col-lg-3 col-xl-3  d-flex flex-column">
                                                            <span className="event-details-title">{(LangSlug === 'gujarati') ? data.guj_title : data.title}</span>
                                                        </div>
                                                        <div className="col-12 col-sm-12 col-md-12 col-lg-9 col-xl-9 event-details-days-desc" dangerouslySetInnerHTML={{ __html: (LangSlug === 'gujarati') ? data.guj_desc : data.desc }}></div>
                                                    </div>
                                                </>
                                            ))}

                                            <PreviousBroadCast BroadCastdata={BroadcastData.data} title="Broadcasts and Videos" type="title-active-color" />

                                            {CalendarEventGalleryData.data.length > 0 ? <><div className="mt-3 mb-1 big-separator"></div>
                                                <SectionTitle title={`event Highlights`} type="title-active-color" colClass='col' />
                                                <div className="dashed-separator"></div>
                                                <p className="font-size-24px font-family-futura-bt m-0">Event Gallery</p>
                                                <ScrollAnimation className="col-lg-12 pt-0 pt-lg-0 d-flex flex-column justify-content-center" animateIn="fadeIn" animateOnce={true} delay={100} >
                                                    <Slider className="they-say-carousel pt-0 mt-0 owl-nav-en" {...options}>
                                                        {CalendarEventGalleryData.data.map((Slide: any, i: any) => {
                                                            return (<>
                                                                <SayingsImageAndText Image={`${Slide.image}`} Text={``} handleopen={handleopen} Index={i} />
                                                                <p className={`image-text mt-2 mb-1`}><PrintDesc desc={Slide.name} /></p>
                                                            </>)
                                                        })}

                                                    </Slider>
                                                </ScrollAnimation>
                                            </> : ''}
                                        </div>
                                        : <div className="d-flex flex-row justify-content-center p-5">{/* No event found */}</div>}
                                </div>
                            </div>
                        </div>
                    </ScrollAnimation>
                </section>
            </> : (<PageLoader />)}
        </>
    )
}

export default EventDetails