import React, { useContext, useEffect, useState } from "react";
import ReactPlayer from 'react-player'
import { useSwipeable } from 'react-swipeable';
const DarshanFullView: React.FC<any> = () => {

    document.title = `Darshan`;
    const NEXT = 'NEXT';
    const PREV = 'PREV';
    type Direction = typeof PREV | typeof NEXT;
    const [CurrentIndex, setCurrentIndex] = useState(1)
    const [Mute, setMute] = useState(true)
    const [play, setplay] = useState(false)
    const stream = ['https://stream.swaminarayangadi.com/mharilive/mharilive/playlist.m3u8', 'https://stream.swaminarayangadi.com/mghanshyamlive/mghanshyamlive/playlist.m3u8','https://stream.swaminarayangadi.com/msahajanandlive/msahajanandlive/playlist.m3u8', 'https://stream.swaminarayangadi.com/manilive/manilive/playlist.m3u8']

    const [touchStart, setTouchStart] = useState(null)
    const [touchEnd, setTouchEnd] = useState(null)
    const minSwipeDistance = 50
    const onTouchStart = (e: any) => {
        setTouchEnd(null)
        setTouchStart(e.targetTouches[0].clientX)
    }
    const onTouchMove = (e: any) => setTouchEnd(e.targetTouches[0].clientX)
    const onTouchEnd = () => {
        if (!touchStart || !touchEnd) return
        const distance = touchStart - touchEnd
        const isLeftSwipe = distance > minSwipeDistance
        const isRightSwipe = distance < -minSwipeDistance
        if (isLeftSwipe || isRightSwipe) {
            /* console.log('swipe', isLeftSwipe ? 'left' : 'right') */
            var aa = CurrentIndex
            aa = (isLeftSwipe) ? (CurrentIndex + 1) : (CurrentIndex - 1)
            if (stream.length === aa) {
                aa = 0
            }
            if (aa < 0) {
                aa = (stream.length - 1)
            }
            /* console.log(aa) */
            setCurrentIndex(aa)
        }
    }
    const nextC = (type : any) => {
        var aa = CurrentIndex
        aa = (type === 'next') ? (CurrentIndex + 1) : (CurrentIndex - 1)
        if (stream.length === aa) {
            aa = 0
        }
        if (aa < 0) {
            aa = (stream.length - 1)
        }
        /* console.log(aa) */
        setCurrentIndex(aa)
    }
    useEffect(() => {
        setTimeout(() => {
            setMute(false)
            setplay(true)
        }, 2000);
    }, [])
    return (
        <>
            <style>{`body {padding:0;background:#000;height:100vh} body #root{height:100%}
            .next{
                position: absolute;
                top: 50%;
                transform: translateY(-50%);
                right: 5px;
                height: 30px;
                width: 30px;
                border-radius: 50%;
                border: 1px solid #fff;
                align-items: center;
                display: flex;
                flex-direction: row;
                justify-content: center;
                text-align: center;
                cursor:pointer;
            }
            .prev{
                position: absolute;
                top: 50%;
                transform: translateY(-50%);
                left: 5px;
                height: 30px;
                width: 30px;
                border-radius: 50%;
                border: 1px solid #fff;
                align-items: center;
                display: flex;
                flex-direction: row;
                justify-content: center;
                text-align: center;
                cursor:pointer;
            }
            @media (max-width: 768px) {
                body{height:90vh}
            }`}</style>
            <div className="h-100" onTouchStart={onTouchStart} onTouchMove={onTouchMove} onTouchEnd={onTouchEnd}>
                <ReactPlayer
                    className='h-100'
                    url={stream[CurrentIndex]}
                    width='100%'
                    height='100%'
                    controls={true}
                    playsinline={true}
                    playbackRate={1}
                    playing={true}
                    volume={1}
                    muted={Mute}
                />
                <div className="next d-none d-md-flex" onClick={() => nextC('next')}>
                    <svg width="13" height="7" viewBox="0 0 23 13" fill="none" xmlns="http://www.w3.org/2000/svg" style={{ transform: 'rotate(-90deg)', transition: '0.2s all' }}>
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M21.6525 2.56066L12.1065 12.1066C11.5207 12.6924 10.571 12.6924 9.98519 12.1066L0.439253 2.56066C-0.146534 1.97487 -0.146534 1.02513 0.439253 0.439339C1.02504 -0.146448 1.97479 -0.146448 2.56057 0.439339L11.0459 8.92462L19.5311 0.43934C20.1169 -0.146447 21.0667 -0.146447 21.6525 0.43934C22.2382 1.02513 22.2382 1.97487 21.6525 2.56066Z" fill="#fff" />
                    </svg>
                </div>
                <div className="prev d-none d-md-flex" onClick={() => nextC('prev')}>
                    <svg width="13" height="7" viewBox="0 0 23 13" fill="none" xmlns="http://www.w3.org/2000/svg" style={{ transform: 'rotate(90deg)', transition: '0.2s all' }}>
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M21.6525 2.56066L12.1065 12.1066C11.5207 12.6924 10.571 12.6924 9.98519 12.1066L0.439253 2.56066C-0.146534 1.97487 -0.146534 1.02513 0.439253 0.439339C1.02504 -0.146448 1.97479 -0.146448 2.56057 0.439339L11.0459 8.92462L19.5311 0.43934C20.1169 -0.146447 21.0667 -0.146447 21.6525 0.43934C22.2382 1.02513 22.2382 1.97487 21.6525 2.56066Z" fill="#fff" />
                    </svg>
                </div>
            </div>
        </>
    )
}

export default DarshanFullView