import React from "react"
// @ts-ignore
import ScrollAnimation from 'react-animate-on-scroll'
import { Link } from "react-router-dom"
import { PrintDesc } from "./CommonSections"

const Aboutus = ({ Data }: any) => {
    return (
        <>
            <section className="aboutus pb-2">
                <div className="Flower-top d-none d-md-block">
                    <img src={process.env.PUBLIC_URL + '/img/Flower_Light.svg'} className="img-fluid lazyload" alt="Flower" title="Flower" />
                </div>
                <div className="container " >
                    <div className="row">
                        <ScrollAnimation className="col-lg-6 text-start" animateIn="fadeIn" animateOnce={true} delay={100} >
                            <span className="d-block sub-title d-none">{Data.title}</span>
                            <span className="d-block title mb-3 mb-lg-0"><PrintDesc desc={Data.desc} /></span>
                        </ScrollAnimation>
                        <ScrollAnimation className="col-lg-6 text-start" animateIn="fadeIn" animateOnce={true} delay={100} >
                            <p className="description about-desc">
                                {/* Acharya Shree Jitendriyapriyadasji Swamiji Maharaj is the current spiritual leader of Maninagar Shree Swaminarayan Gadi Sansthan. He is the heir to Acharya Shree Purushottampriyadasji Swamishree Maharaj, and sixth in the lineage of ascetic Acharyas, which descends directly from Lord Shree Swaminarayan. */}
                                <PrintDesc desc={Data.rDesc} isHtml={true} />
                            </p>
                        </ScrollAnimation>
                    </div>
                </div>
            </section>
        </>
    )
}

const AboutUsBlocks = ({ Data }: any) => {
    return (
        <>
            {(Data.image_json && Data.image_json.length > 0) ?
                <>
                    <section className="aboutus pt-0">
                        <div className="container">
                            <ScrollAnimation className="row about-us-blocks justify-content-start justify-content-md-center" animateIn="fadeIn" animateOnce={true} delay={100} >
                                {Data.image_json.map((Slide: any, i: any) => {
                                    return (
                                        <div className="col-lg-3 col-sm-6 col-9 text-center justify-content-center" key={`${i}-aboutUsBlocks`}>
                                            <Link to={Slide.s_l_url} title={Slide.s_title}>
                                                <div className="aboutus-box">

                                                    <img src={Slide.imageurl} className="img-fluid lazyload" alt={Slide.s_i_alt} title={Slide.s_title} />
                                                    <div>
                                                        <span className="d-block title">{Slide.s_title}</span>
                                                        <p className="d-block mb-0 description text-center"><PrintDesc desc={Slide.s_desc} /></p>
                                                    </div>

                                                </div>
                                            </Link>
                                        </div>
                                    )
                                })}
                            </ScrollAnimation>
                        </div>
                    </section>
                </> : ''}
        </>
    )
}

export { Aboutus, AboutUsBlocks }