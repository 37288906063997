import React, { useContext, useEffect, useRef, useState } from "react"
import Banner from './sections/Banner'
import BreadCrumb from "./sections/BreadCrumb"
import { SectionTitle, DownloadTypeFilters, DownloadCategoryFilters, DownloadSections, PageLoader, MetaData } from "./sections/CommonSections";

import GlobalContext from './Core/PageData'
import { Link, useLocation, useNavigate, useParams } from "react-router-dom"
import axios from "axios";
import ScrollAnimation from "react-animate-on-scroll"
// @ts-ignore
import fileDownload from 'js-file-download'

const Download: React.FC = () => {
    let { downloadType, categoryType } = useParams()
    const navigate = useNavigate()
    const { GlobalContent, setGlobalContent } = useContext(GlobalContext as any) as any
    document.title = `Downloads${GlobalContent.pageTitle}`
    const [isPageLoad, setisPageLoad] = useState('no')
    const [isPageLoadMore, setisPageLoadMore] = useState('no')
    const [BreadCrumbdata, setBreadCrumbdata] = useState({ data: [] as any[] })
    const [DownloadsType, setDownloadsType] = useState({ data: [] as any[] })
    const [DownloadsCategoryData, setDownloadsCategoryData] = useState({
        data: [
            {
                image: 'mobile.svg',
                text: 'wallpaper',
                name: 'wallpaper',
                number: '0',
            }, {
                image: 'tablet.svg',
                text: 'IMAGE GALLERY',
                name: 'images',
                number: '0',
            }, {
                image: 'desktop.svg',
                text: 'RINGTONES',
                name: 'ringtone',
                number: '0',
            }, {
                image: 'laptop.svg',
                text: 'RESOURCES',
                name: 'artwork',
                number: '0',
            }
        ] as any[]
    })
    const [Downloads, setDownloads] = useState({ data: [] as any[] })
    const [DownloadsTypeIn, setDownloadsTypeIn] = useState({ data: [] as any[] })
    const [CurrentDownloadCategory, setCurrentDownloadCategory] = useState('')
    const [CurrentDownloadType, setCurrentDownloadType] = useState('')
    const [PaginationData, setPaginationData] = useState({ currentpage: '', nextpage: '', perpage: '', remainingCount: '', total: '', totalpages: '' })
    var sites = GlobalContent.AllSiteData.filter((e: any) => e.is_main === 'yes')
    const myRefq = useRef<HTMLButtonElement>(null);
    useEffect(() => {
        (async () => {
            setisPageLoad('no')
            setGlobalContent({ ...GlobalContent, IsMediaPlayershow: false })
            setBreadCrumbdata({
                data: [
                    {
                        path: `${process.env.PUBLIC_URL}/`,
                        linkName: 'Home',
                        status: '',
                    }, {
                        path: `${process.env.PUBLIC_URL}/download`,
                        linkName: 'Downloads',
                        status: '',
                    }
                ]
            })
            setDownloadsType({
                data: [
                    {
                        image: 'mobile.svg',
                        text: 'Mobile',

                    }, {
                        image: 'tablet.svg',
                        text: 'Tablet',

                    }, {
                        image: 'desktop.svg',
                        text: 'Desktop',

                    }, {
                        image: 'laptop.svg',
                        text: 'Laptop',

                    }, {
                        image: 'app-store.svg',
                        text: 'App Store',

                    }, {
                        image: 'google-store.svg',
                        text: 'Google Store',
                    }
                ]
            })

            let _a_ = [
                {
                    image: 'mobile.svg',
                    text: 'WALLPAPER',
                    name: 'wallpaper',
                    number: '0',
                }, {
                    image: 'tablet.svg',
                    text: 'IMAGE GALLERY',
                    name: 'images',
                    number: '0',
                }, {
                    image: 'desktop.svg',
                    text: 'RINGTONES',
                    name: 'ringtone',
                    number: '0',
                }, {
                    image: 'laptop.svg',
                    text: 'RESOURCES',
                    name: 'artwork',
                    number: '0',
                }
            ]
            /* setDownloadsCategoryData({
                data: _a_
            }) */
            const promises = [] as any;
            await _a_.forEach((dataC: any, j: any) => {
                let filters = '?recordPerPage=1'
                if (categoryType !== undefined /* && dataC.name === downloadType */) {
                    filters += `&category=${categoryType}`
                }
                axios.get(`${process.env.REACT_APP_API_URL}download/${dataC.name}/front${filters}`).then(json => {
                    if(json.data.data.length === 0){
                        navigate(`${process.env.PUBLIC_URL}/404`)
                    }
                    let ___ = _a_
                    ___[j]['number'] = json.data.data.total
                    setDownloadsCategoryData({ data: ___ })
                    return ___[j]
                }).then(tt => {
                    promises.push(tt);
                })
            })
            Promise.all(promises).then(dsa => {
                changeType(downloadType !== '' && downloadType !== undefined ? downloadType : 'wallpaper')
                //setDownloadsCategoryData({ data: ___ })
            })
        })()
    }, [downloadType, categoryType])

    const changeType = (name: any, typeOfLoad = 'first', type = '') => {

        if (downloadType !== name) {
            navigate(`${process.env.PUBLIC_URL}/download${categoryType !== undefined ? `/${categoryType}` : ``}/${name}`, { replace: true })
        }
        setCurrentDownloadCategory(name)
        setCurrentDownloadType(type)
        let filters = '?recordPerPage=12'
        if (typeOfLoad === 'loadmore') {
            filters += `&page=${PaginationData.nextpage}`
            setisPageLoadMore('no')
        } else {
            setisPageLoad('no')
        }

        if (type !== '') {
            filters += `&type=${type}`
        }
        if (name === 'ringtone') {
            filters += `&sortField=title&sortType=asc`
        } else {
            filters += `&sortField=publishOn&sortType=desc`
        }
        if (categoryType !== undefined) {
            filters += `&category=${categoryType}`
        }
        axios.get(`${process.env.REACT_APP_API_URL}download/${name}/front${filters}`).then(json => {
            let temp = [] as any
            if (typeOfLoad === 'loadmore') {
                let dwnTemp = [...Downloads.data]
                dwnTemp = dwnTemp.concat(json.data.data.data)
                setDownloads({ data: dwnTemp })
            } else {
                if(json.data.data.length === 0){
                    navigate(`${process.env.PUBLIC_URL}/404`)
                }
                setDownloads({ data: json.data.data.data })
                temp = [...DownloadsCategoryData.data]
                let Index = temp.findIndex((ell: any) => ell.name === name)
                if (temp[Index] !== undefined) {
                    temp[Index]['number'] = json.data.data.total
                }
            }
            setPaginationData({ currentpage: json.data.data.currentpage, nextpage: json.data.data.nextpage, perpage: json.data.data.perpage, remainingCount: json.data.data.remainingCount, total: json.data.data.total, totalpages: json.data.data.totalpages })
            return { donwload: json.data.data.data, temp: temp }
        }).then(downloadTemp => {
            setGlobalContent({ ...GlobalContent, siteData: sites[0], headerClass:'' })
            if (typeOfLoad === 'first') {
                if (name !== 'artwork' && name !== 'images') {
                    axios.get(`${process.env.REACT_APP_API_URL}download/${name}-type/all/`).then(json => {
                        json.data.data = json.data.data.filter((e: any) => e.status === 'active')
                        setDownloadsTypeIn({ data: json.data.data })
                        setisPageLoad('yes')
                    })
                } else {
                    setisPageLoad('yes')
                }
                /* setDownloadsCategoryData({ data: downloadTemp.temp }) */
                setisPageLoadMore('yes')
            } else {
                setisPageLoadMore('yes')
            }
        }).catch(err => {
            if (typeOfLoad !== 'loadmore') {
                navigate(`${process.env.PUBLIC_URL}/404`)
            }
        })
    }

    const [audioSrc, setaudioSrc] = useState('');
    const myRef = useRef<HTMLAudioElement>(null);

    const startAudio = (src: any, i: any) => {
        setaudioSrc(src)
        setTimeout(() => {
            myRef.current?.play();
            let __ = [...Downloads.data]
            let index = __.findIndex((ell: { audioStatus: boolean; }) => ell.audioStatus === true)
            if (index > -1) {
                __[index]['audioStatus'] = false
            }
            __[i]['audioStatus'] = true
            setDownloads({ data: __ })
        }, 1);
    }

    const pauseAudio = (src: any, i: any) => {
        myRef.current?.pause();
        let __ = [...Downloads.data]
        __[i]['audioStatus'] = false
        setDownloads({ data: __ })
    }

    const onEndedRingtone = () => {
        let __ = [...Downloads.data]
        let index = __.findIndex((ell: { audioStatus: boolean; }) => ell.audioStatus === true)
        if (index > -1) {
            __[index]['audioStatus'] = false
        }
        setDownloads({ data: __ })
    }

    useEffect(() => {
        window.addEventListener('scroll', () => {
            const windowHeight = "innerHeight" in window ? window.innerHeight : document.documentElement.offsetHeight;
            const body = document.body;
            const html = document.documentElement;
            const docHeight = Math.max(body.scrollHeight, body.offsetHeight, html.clientHeight, html.scrollHeight, html.offsetHeight);
            const windowBottom = (windowHeight + ((window.innerWidth < 992) ? 900 : 400)) + window.pageYOffset;
            if (windowBottom >= docHeight) {
                if(!myRefq.current?.disabled){
                    myRefq.current?.click()
                }
            }
        });
    }, []);

    const handleDownload = async (url: string, filename: any, _id: any, i: any) => {
        axios.put(`${process.env.REACT_APP_API_URL}download/${CurrentDownloadCategory}/count/${_id}`, {}).then(async function (response) {
            let _a_ = [...Downloads.data]
            _a_[i]['no_of_download'] = (_a_[i]['no_of_download'] !== undefined) ? _a_[i]['no_of_download'] + 1 : 1
            setDownloads({ data: _a_ })
            let a = document.createElement('a');
            await fetch(url, { method: "GET", headers: {} }).then(async response => await response.blob()).then(async blob => {
                let blobUrl = await window.URL.createObjectURL(blob);
                a.href = blobUrl;
                a.download = url.replace(/^.*[\\\/]/, '');
                document.body.appendChild(a);
                a.click();
                await window.URL.revokeObjectURL(blobUrl);
                a.remove();
            })
        })
    }

    return (
        <>
            <MetaData keywords={`news, maninagar, swaminarayan, gadi, sansthan`} description={`Downloads from Maninagar Shree Swaminarayan Gadi Sansthan`} />
            <section className="site-contain">
                {/* <div className="Flower-top d-none d-md-block">
                    <img src={process.env.PUBLIC_URL + '/img/Flower-top.png'} className="img-fluid lazyload" alt="Flower_Light" title="Flower_Light" />
                </div> */}
                <div className="container">
                    <BreadCrumb BreadCrumbdata={BreadCrumbdata.data} />
                    <SectionTitle title="Downloads" type="type-2" />
                    <DownloadTypeFilters DownloadTypeData={DownloadsTypeIn.data} CurrentDownloadCategory={CurrentDownloadCategory} CurrentDownloadType={CurrentDownloadType} title="" changeType={changeType} className="mt-4 mb-5" />
                    <DownloadCategoryFilters DownloadCategoryData={DownloadsCategoryData.data} title="" CurrentDownloadType={CurrentDownloadCategory} changeType={changeType} className="mt-4 mb-5" />
                    {isPageLoad === 'yes' ? <>
                        <ScrollAnimation className="" animateIn="fadeIn" animateOnce={true} delay={400}>
                            <audio ref={myRef} src={audioSrc} onEnded={onEndedRingtone} />
                            <DownloadSections DownloadSectionsData={Downloads.data} DownloadType={DownloadsTypeIn.data} CurrentDownloadSubType={CurrentDownloadType} CurrentDownloadType={CurrentDownloadCategory} title="" className="mt-4 mb-0" startAudio={startAudio} pauseAudio={pauseAudio} handleDownload={handleDownload} />
                            {PaginationData.nextpage !== '' && PaginationData.nextpage !== null && PaginationData.nextpage !== undefined ?
                                <div className="d-flex flex-row justify-content-center mt-5">
                                    <button ref={myRefq} className={`btn btn-primary ${(isPageLoadMore === 'yes') ? '' : 'disabled'}`} disabled={(isPageLoadMore === 'yes') ? false : true} style={{opacity:'0',height: 0,width: 0,padding: 0}} onClick={() => changeType(CurrentDownloadCategory, 'loadmore', CurrentDownloadType)}>{(isPageLoadMore === 'yes') ? 'Load More' : 'Loading...'}</button>
                                    {(isPageLoadMore === 'yes') ? '' : <span className="text-center active-color font-size-18px font-weight-bold">Loading...</span>}
                                </div>
                                : ''}
                        </ScrollAnimation>
                    </> : (<PageLoader />)}
                </div>
            </section>
        </>
    )
}

export default Download