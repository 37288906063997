import React, { useEffect, useState } from "react";
import { reduxConnect } from "../../../redux/store";
/* import Slider from "@material-ui/core/Slider";
import RepeatIcon from "@material-ui/icons/Repeat";
import RepeatOneIcon from "@material-ui/icons/RepeatOne";
import SkipPreviousIcon from "@material-ui/icons/SkipPrevious";
import SkipNextIcon from "@material-ui/icons/SkipNext";
import PlayArrowIcon from "@material-ui/icons/PlayArrow";
import PauseIcon from "@material-ui/icons/Pause";
import VolumeUpIcon from "@material-ui/icons/VolumeUp";
import VolumeOffIcon from "@material-ui/icons/VolumeOff";
import { Shuffle } from "@material-ui/icons";
import Tooltip from "@material-ui/core/Tooltip";
import Grid from "@material-ui/core/Grid"; */
// @ts-ignore
import Slider from "@material-ui/core/Slider";
import CacheManager from "../../../service/CacheManager";
/* import { ArrowDropDown, DragHandle } from "@material-ui/icons"; */
import { useNavigate } from "react-router-dom";
import AudioPlayerUi from "./AudioPlayerUi";
// @ts-ignore
import { DragDropContext } from "react-beautiful-dnd";
// @ts-ignore
import { Droppable } from "react-beautiful-dnd";
// @ts-ignore
import { Draggable } from "react-beautiful-dnd";

/* import { setPlayerList } from "../../../redux/slices/playerList/playerListSlice"; */

{/* <Tooltip open={open} enterTouchDelay={0} placement="top" title={value}>
      {children}
    </Tooltip> */}
/* function ValueLabelComponent(props : any) {
  const { children, open, value } = props;

  return (
    <>{children}</>
  );
} */
const AudioManage = (props: any) => {
  const player = props.player;
  const path = "/assets/img";
  const navigate = useNavigate();
  const [characters, updateCharacters] = useState(props.playerList?.list);
  const current_path = props.playerList.list[props.playerList?.current];
  const [volumeVal, setvolumeVal] = useState(false);
  const [bgcolor, setbgcolor] = useState("white");
  const [AudioData, setAudioData] = useState(0);
  /* setGlobalContent({ ...GlobalContent, siteAccess: '' }) */
  useEffect(() => {
    updateCharacters(props.playerList.list);
  }, [props.playerList.list.length]);

  var toHHMMSS = (secs: any) => {
    var sec_num = parseInt(secs, 10);
    var hours = Math.floor(sec_num / 3600);
    var minutes = Math.floor(sec_num / 60) % 60;
    var seconds = sec_num % 60;

    return [hours, minutes, seconds]
      .map((v) => (v < 10 ? "0" + v : v))
      .filter((v, i) => v !== "00" || i > 0)
      .join(":");
  };
  useEffect(() => {
    if (volumeVal) {
      props.setPlayer({ volume: 0 });
    } else {
      let volume = CacheManager.get("volume") || 0.5;
      props.setPlayer({ volume });
    }
  }, [volumeVal]);
  useEffect(() => {
    if (props.player.isRepeat) {
      setbgcolor("#f94f20");
    } else {
      setbgcolor("#323232");
    }
  }, [props.player.isRepeat]);
  const handlePrevious = () => {
    /* console.log('prev', props)
    let old_current = props.playerList.current;
    if (old_current > 0) {
      props.setPlayerList({ current: old_current - 1 });
      props.setPlayer({ playing: false, TrackLoaded: false });
    } else {
      props.setPlayer({ playing: false, TrackLoaded: false });
    } */
    let old_current = props.playerList.current;
    if (old_current > 0) {
      props.setPlayerList({ current: old_current - 1 });
      props.setPlayer({ playing: false, TrackLoaded: false });
    }

  }
  const handleRepeat = () => {
    props.setPlayer({ isRepeat: !props.player.isRepeat });
  }
  const handleNext = () => {
    let old_current = props.playerList.current;
    if (props.playerList.list.length > old_current + 1) {
      props.setPlayerList({ current: old_current + 1 });
      props.setPlayer({ playing: false,TrackLoaded: false });
    }
    /* console.log('next', props)
    let old_current = props.playerList.current;
    if (props.playerList.list.length - 1 > old_current) {
      props.setPlayerList({ current: old_current + 1 });
      props.setPlayer({ playing: false, TrackLoaded: false });
    } else {
      props.setPlayer({ playing: false, TrackLoaded: false });
    } */

  };
  const clearQueue = () => {
    if (window.confirm("do you want to clear this list?")) {
      props.setPlayerList({ list: [] });
      CacheManager.set({ key: "list", value: [] });
      navigate(`${process.env.PUBLIC_URL}/`);
      props.setPlayer({ show: true, playing: false });
    }
  };
  const navigateBack = () => {
    props.setPlayer({ show: true });
    navigate(`${process.env.PUBLIC_URL}/`);
  };
  const itemPlay = (ele: any) => {
    const old_current = props.playerList.list.findIndex((obj: any) => obj.id == ele);
    props.setPlayerList({ current: old_current });
    props.setPlayer({ playing: false });
  };
  const removerItem = (itemId: any) => {
    const newList = props.playerList?.list.filter((item: any) => item.id != itemId);
    props.setPlayerList({ list: newList });
    CacheManager.set({ key: "list", value: newList });
  };

  function handleOnDragEnd(result: any) {
    if (!result.destination) return;
    let dndCurrent = result.destination.index; //destination
    let dummyListIndex: any = null;
    let appTime = props.player.appTime;
    let playing = props.player.playing;
    // if (props.playerList.current === dndCurrent) {
    dummyListIndex = props.playerList.list[props.playerList.current]; // list =  redux list

    // }
    const items = Array.from(characters);
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);

    updateCharacters(items);
    props.setPlayerList({ list: items });
    // dnd list array update code

    // if (dummyListIndex && Object.keys(dummyListIndex).length > 0) {
    let current = items.findIndex((obj: any) => obj.id === dummyListIndex.id);

    props.setPlayerList({ current });
    props.setPlayer({ playing: false, seekTime: appTime });
    setTimeout(() => {

      props.setPlayer({ playing: playing });
    }, 1);
    // return;
    // }
  }

  const handleShuffle = () => {
    /* let appTime = props.player.appTime;
    let unshuffled = props.playerList.list;
    let dummyListIndex = props.playerList.list[props.playerList.current]; // list =  redux list
    let shuffled = unshuffled
      .map((value: any) => ({ value, sort: Math.random() }))
      .sort((a: any, b: any) => a.sort - b.sort)
      .map(({ value }: any) => value);
    props.setPlayerList({ list: shuffled });
    let current = shuffled.findIndex((obj: any) => obj.id === dummyListIndex.id);
    props.setPlayerList({ current });
    props.setPlayer({ playing: false, seekTime: appTime });
    setTimeout(() => {
      props.setPlayer({ playing: true });
    }, 1); */
  };
  return (
    <section className="manager">
      <div className="container">
        <div className="audio-manager-container">
          <div className="d-flex audio-manager-container-inner flex-column flex-md-column flex-lg-row ">
            <div className="col-lg-5">
              <div className="manager-container d-flex flex-column">
                <div className="container-title">{current_path?.name}</div>
                <div className="container-subtitle">
                  {current_path?.author_name}
                </div>
                <div className="image-container">
                  <img
                    src={current_path?.img}
                    alt={current_path}
                    title={current_path}
                  />
                  {/* {AudioData} */}
                </div>
                <div className="timer">
                  <p>
                    <span style={{ paddingRight: "10px" }}>
                      {toHHMMSS(player.appTime)}
                    </span>
                  </p>
                  <Slider
                    /* ValueLabelComponent={ValueLabelComponent} */
                    aria-label="time-indicator"
                    min={0}
                    max={Math.round(player.duration)}
                    step={1}
                    value={Math.round(player.appTime)}
                    className="slider"
                    onChange={(e: any, new_value: any) => {
                      props.setPlayer({ seekTime: Math.round(new_value) });
                    }}
                  />
                  <p>
                    <span style={{ paddingLeft: "10px" }}>
                      {toHHMMSS(player.duration)}
                    </span>
                  </p>
                </div>
                {/* <div className="manager-different-controls">
                  <div className="manager-repeat">
                    <span
                      onClick={handleRepeat}
                      style={{ color: bgcolor }}
                    >RepeatIcon</span>
                  </div>
                  <div className="manager-controls">
                    <span
                      onClick={handlePrevious}
                      className="manager-controls-icon"
                    >PreviousIcon</span>
                    {player.playing ? (
                      <span
                        onClick={() => props.setPlayer({ playing: false })}
                        className="manager-controls-icon">
                        Pause
                      </span>
                    ) : (
                      <span
                        onClick={() => props.setPlayer({ playing: true })}
                        className="manager-controls-icon"
                      >PlayArrowIcon</span>
                    )}
                    <span
                      onClick={handleNext}
                      className="manager-controls-icon"
                    >SkipNextIcon</span>
                    <span onClick={handleShuffle} >Shuffle</span>
                  </div>
                  <div className="manager-volume">
                    <div className="d-flex flex-row">
                      <div className="d-flex" >
                        <Slider
                          aria-labelledby="continuous-slider"
                          onChange={(e: any, new_value: any) => {
                            
                            CacheManager.set({
                              key: "volume",
                              value: new_value / 100,
                            });
                            props.setPlayer({ volume: new_value / 100 });
                          }}
                          value={player.volume * 100}
                        />
                      </div>
                      <div className="d-flex">
                        {volumeVal == true ? (
                          <span
                            onClick={() => {
                              setvolumeVal(false);
                            }}
                          >
                            <i>VolumeOffIcon</i>
                          </span>
                        ) : (
                          <span
                            onClick={() => {
                              setvolumeVal(true);
                            }}
                          >
                            <i>VolumeUpIcon</i>
                          </span>
                        )}
                      </div>
                    </div>
                  </div>
                </div> */}

                <div className="player-controls">
                  <span
                    className="repeat-icon"
                    onClick={handleRepeat}
                    style={{ color: bgcolor, cursor: 'pointer' }}
                    title="Repeat Track"
                  >
                    <svg width="30" height="28" viewBox="0 0 30 28" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M2.95562 11.1666C2.95562 8.94193 5.12359 6.91557 7.50397 6.91557L21.0643 6.91585V10.9966L29.2261 5.49878L21.0643 0.000976562V4.082H7.50397C3.57188 4.082 0.121338 7.3905 0.121338 11.1666V16.1259L2.95526 16.1261L2.95562 11.1666Z" fill={bgcolor} />
                      <path d="M27.0445 16.8333C27.0445 19.0579 24.8765 21.0843 22.4962 21.0843H8.97821V17.0035L0.830811 22.5013L8.97821 27.9989V23.9182H22.4962C26.4282 23.9182 29.8781 20.6097 29.8781 16.8333V11.874H27.0441L27.0445 16.8333Z" fill={bgcolor} />
                    </svg>
                  </span>
                  <span onClick={handlePrevious} title="Previous Track" style={{ cursor: 'pointer' }}>
                    <svg width="24" height="26" viewBox="0 0 24 26" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M1.76647 0.405273C1.3317 0.405273 0.979198 0.757773 0.979198 1.19255V24.8088C0.979198 25.2435 1.3317 25.596 1.76647 25.596H3.34068C3.77545 25.596 4.12795 25.2435 4.12795 24.8088V1.19255C4.12795 0.757773 3.77545 0.405273 3.34068 0.405273H1.76647ZM23.021 24.7803C23.021 25.4341 22.2699 25.8028 21.7525 25.403L6.50919 13.624C6.10152 13.3089 6.10152 12.6933 6.50919 12.3783L21.7525 0.599292C22.2699 0.199423 23.021 0.568126 23.021 1.22201V24.7803Z" fill="#323232" />
                    </svg>
                  </span>
                  {player.playing ? (
                    <span onClick={() => props.setPlayer({ playing: false })} title="Pause Track" className="play-pause-button" style={{ cursor: 'pointer', opacity: (player.TrackLoaded) ? '1' : '0.5' }}>
                      <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="#fff"><path d="M10 24h-6v-24h6v24zm10-24h-6v24h6v-24z" /></svg>
                    </span>
                  ) : (
                    <span onClick={() => props.setPlayer({ playing: true })} title="Play Track" className="play-pause-button" style={{ paddingLeft: '5px', cursor: 'pointer', opacity: (player.TrackLoaded) ? '1' : '0.5' }}>
                      <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="#fff"><path d="M2 24v-24l20 12-20 12z" /></svg>
                    </span>
                  )}
                  <span onClick={handleNext} title="Next Track" style={{ cursor: 'pointer' }}>
                    <svg width="24" height="26" viewBox="0 0 24 26" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M22.2335 0.405273C22.6683 0.405273 23.0208 0.757773 23.0208 1.19255V24.8088C23.0208 25.2435 22.6683 25.596 22.2335 25.596H20.6593C20.2245 25.596 19.872 25.2435 19.872 24.8088V1.19255C19.872 0.757773 20.2245 0.405273 20.6593 0.405273H22.2335ZM0.979004 24.7803C0.979004 25.4341 1.73013 25.8028 2.24746 25.403L17.4908 13.624C17.8985 13.3089 17.8985 12.6933 17.4908 12.3783L2.24746 0.599292C1.73009 0.199423 0.979004 0.568126 0.979004 1.22201V24.7803Z" fill="#323232" />
                    </svg>
                  </span>
                  <span
                    className="repeat-icon"
                    onClick={handleShuffle} title="Shuffle Track" style={{ cursor: 'pointer' }}
                  >
                    <svg width="30" height="24" viewBox="0 0 30 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M24.3515 17.9514H20.8951C19.8382 17.9514 18.8598 17.4729 18.2107 16.6387L15.5241 13.1846L13.3701 15.9542L15.5266 18.7264C16.8242 20.3951 18.781 21.3524 20.8954 21.3524H24.3515V23.0528L29.4527 19.6521L24.3515 16.2513V17.9514Z" fill="#323232" />
                      <path d="M24.3515 2.64878H20.8951C18.781 2.64878 16.8243 3.60584 15.5263 5.27482L12.4492 9.23132L9.37182 5.27482C8.07424 3.60613 6.11746 2.64878 4.00299 2.64878H0.546631V6.04956H4.00328C5.06023 6.04956 6.03862 6.5281 6.68769 7.36229L10.2949 12.0007L6.68769 16.6394C6.03889 17.4736 5.06051 17.9521 4.00328 17.9521H0.546631V21.3529L1.39684 21.3526H4.00328C6.11739 21.3526 8.07417 20.3955 9.37211 18.7266L18.211 7.36215C18.8598 6.52795 19.8382 6.04942 20.8954 6.04942H24.3518V7.74956L24.9894 7.3246L28.9218 4.7033L29.453 4.34902L24.3518 0.948242L24.3515 2.64878Z" fill="#323232" />
                    </svg>

                  </span>
                  {/* <div className="time-show">
                    <p> {toHHMMSS(player.appTime)} </p> /{" "}
                    <p> {toHHMMSS(player.duration)}</p>
                  </div> */}

                </div>
              </div>
            </div>
            <div className="col-lg-7 ps-lg-5 mt-3 mt-lg-0">
              <DragDropContext onDragEnd={handleOnDragEnd}>
                <Droppable droppableId="droppable-1">
                  {(provided: any, snapshot: any) => (
                    <div
                      className="list-names d-flex flex-column"
                      ref={provided.innerRef}
                      {...provided.droppableProps}
                    >
                      <div className="list_container ">
                        <h4 className="m-0">Queue</h4>
                        <div className="buttons">
                          <button className="btn btn-4 p-2 ps-4 pe-4" onClick={clearQueue} style={{ fontSize: '14px' }}>
                            Clear
                          </button>
                          <div className="arrow-icon">
                            {
                              <span
                                onClick={navigateBack}
                              >
                                <svg width="20" height="12" viewBox="0 0 20 12" fill="none" xmlns="http://www.w3.org/2000/svg" style={{ transform: 'rotate(180deg)' }}>
                                  <path d="M8.86719 0.462283C9.14448 0.184996 9.561 4.17924e-07 9.9771 4.36113e-07C10.3932 4.54302e-07 10.8094 0.184986 11.087 0.462283L19.5492 8.92443C20.1503 9.52554 20.1503 10.5428 19.5492 11.1439C18.9481 11.7449 17.9308 11.745 17.3297 11.1439L9.97671 3.8382L2.67065 11.1442C2.34683 11.4681 1.97685 11.6065 1.56074 11.6065C1.14462 11.6065 0.728491 11.4677 0.450833 11.1442C-0.150278 10.5431 -0.150278 9.52591 0.450833 8.92482L8.86719 0.462283Z" fill="black" fill-opacity="0.8" />
                                </svg>
                              </span>
                            }
                          </div>
                        </div>
                      </div>

                      {props.playerList?.list.map((ele: any, ind: any) => {
                        return (
                          <Draggable
                            key={ele.id}
                            draggableId={"draggable-" + ele.id}
                            index={ind}
                          >
                            {(provided: any, snapshot: any) => (
                              <div className="list" key={ind}>
                                <div
                                  ref={provided.innerRef}
                                  {...provided.draggableProps}
                                >
                                  <span {...provided.dragHandleProps}>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="#f57700" style={{ transform: 'rotate(90deg)' }}><path d="M10 24h-6v-24h6v24zm10-24h-6v24h6v-24z" /></svg>
                                  </span>
                                  <p className="track-number m-0 d-none d-lg-flex">{ind + 1}</p>
                                  <img
                                    src={ele.img}
                                    title={ele.img}
                                    alt={ele.name}
                                    onClick={() => itemPlay(ele.id)}
                                  />
                                  <div className="list_details">
                                    <h5 className={`title-name m-0 text-truncate ${current_path?.id === ele.id ? 'active' : ''}`}>{ele.name}</h5>
                                    <p className={`author-name m-0 text-truncate ${current_path?.id === ele.id ? 'active' : ''}`}>{ele.author_name}</p>
                                  </div>
                                  <div className="remove-items">
                                    {current_path?.id === ele.id ? null : (
                                      <span
                                        className="cross-icon"
                                        onClick={() => removerItem(ele.id)} style={{ cursor: 'pointer' }} title="Remove Track from Queue"
                                      >X</span>
                                    )}
                                    <p className="text-duration m-0">{ele.duration}</p>
                                  </div>
                                </div>
                              </div>
                            )}
                          </Draggable>
                        );
                      })}
                    </div>
                  )}
                </Droppable>
              </DragDropContext>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};
export default reduxConnect(["player", "playerList"])(AudioManage);
