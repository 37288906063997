import React, { useEffect, useState } from "react";
/* import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css'; */
// @ts-ignore
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
// @ts-ignore
/* import ScrollAnimation from 'react-animate-on-scroll' */
import { LazyImage, SectionTitle } from "./CommonSections";
import axios from "axios";
import { Link } from "react-router-dom";

const options = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 6,
    slidesToScroll: 1,
    responsive: [{
        breakpoint: 1400,
        settings: {
            slidesToShow: 4,
        }
    }, {
        breakpoint: 1000,
        settings: {
            slidesToShow: 3
        }
    }, {
        breakpoint: 991,
        settings: {
            slidesToShow: 2
        }
    }, {
        breakpoint: 600,
        settings: {
            slidesToShow: 1
        }
    }, {
        breakpoint: 0,
        settings: {
            slidesToShow: 1
        }
    }]
};
const Our_websites = ({ siteAccess, data }: any) => {
    const [Website, setWebsite] = useState({ data: [] as any[] });
    useEffect(() => {
        axios.get(`${process.env.REACT_APP_API_URL}global/site/all`).then(json => {
            var main = json.data.data
            var childSite = json.data.data.filter((el: any) => el.status === 'active' && el.parent_site === siteAccess && el.parent_site !== '')
            if (childSite.length > 0) {
                setWebsite({ data: childSite })
            } else {
                setWebsite({ data: main.filter((el: any) => el.status === 'active' && el.is_parent === 'yes' && el.is_main !== 'yes') })
            }
        })
    }, [])
    return (
        <>
            {(Website.data && Website.data.length > 0) ?
                <section className="our-websites">
                    <div className="container " >
                        <div className="row">
                            <SectionTitle title={data.title !== '' && data.title !== undefined && data.title !== 'undefined' ? data.title : `our websites`} />
                            <div className="col-lg-12 pt-0 pt-lg-0 d-flex flex-column justify-content-center" >
                                <Slider className=" website-carousel owl-nav-en" {...options}>
                                    {Website.data.map((Slide: any, i: any) => {
                                        return (
                                            (Slide.status === 'active') ? <div className="website-items" /* animateIn="zoomIn" animateOnce={true} delay={100} */ >
                                                <Link to={`${process.env.PUBLIC_URL}/${(Slide.site_url !== undefined) ? Slide.site_url : ``}`} title={Slide.name}>
                                                    <div className="website-items-data">
                                                        {Slide.site_img !== '' && Slide.site_img !== undefined ?
                                                            <LazyImage
                                                                loadingSrc={`${process.env.PUBLIC_URL}/img/placeholder.png`}
                                                                actualSrc={`${Slide.site_img}`}
                                                                errorSrc={`${process.env.PUBLIC_URL}/img/placeholder.png`}
                                                                width={`100%`}
                                                                style={{ position: 'absolute', top: 0, left: 0, objectFit: 'cover', height: '100%', objectPosition: 'top' }}
                                                                imageWidth='236'
                                                                imageHeight='163'
                                                                ContainerClass="border-radius-15px"
                                                                title={Slide.name}
                                                                alt={Slide.name}
                                                            />
                                                            : <div style={{ width: '100%', paddingBottom: 'calc(163 / 236 * 100%)', background: '#ffddbf', borderRadius: '15px' }}></div>}
                                                        <div className="website-text">
                                                            <p className="description">{Slide.name}</p>
                                                        </div>
                                                    </div>
                                                </Link>
                                            </div> : ''
                                        )
                                    })}
                                </Slider>
                            </div>
                        </div>
                    </div>
                </section>
                : ''}
        </>
    )
}

export default Our_websites