import React, { useContext, useEffect, useState } from "react"
import BreadCrumb from "./sections/BreadCrumb"
import { SectionTitle, ImageTextRow, Text100OR50, IMGDesc, TabPanel } from "./sections/CommonSections";
import GlobalContext from './Core/PageData'

const CmsMainFile: React.FC = () => {
    const { GlobalContent, setGlobalContent } = useContext(GlobalContext as any) as any
    document.title = `Introduction${GlobalContent.pageTitle}`;
    const [BreadCrumbdata, setBreadCrumbdata] = useState({ data: [] as any[] });
    const [CmsData, setCmsData] = useState({ data: [] as any[] });
    const [CmsDataTimeline, setCmsDataTimeline] = useState({ data: [] as any[] });
    
    useEffect(() => {
        setGlobalContent({ ...GlobalContent, IsMediaPlayershow: false ,siteAccess: '' })
        setBreadCrumbdata({
            data: [
                {
                    path: `${process.env.PUBLIC_URL}`,
                    linkName: 'Home',
                    status: '',
                }, {
                    path: `${process.env.PUBLIC_URL}`,
                    linkName: 'Introduction',
                    status: '',
                }
            ]
        })
        setCmsData({
            data: [
                {
                    type: 'image-text-row',
                    image: 'lord-swaminarayan 2.png',
                    desc: 'In the Vikram year 1837, Chaitra Sud 9th, (based on the Vikram Lunar calendar), 2nd April 1781 A.D., Lord Swaminarayan manifested in Chhapaiya, a town in the holy land of Northern India, to dispel evil and superstitious practices, to preach non-violence and universal brotherhood, methods of self realisation and ultimately to purify the lost souls and reveal to them His supreme abode Akshardham.'
                }, /* {
                    type: 'cnt_100',
                    title: "Text",
                    desc: "<p>The Sants and disciples express and demonstrate their love and devotion unto the Lord through their varied worship of this pratima form of the supreme Lord Shree Swaminarayan. Lord Shree Swaminarayan says that the worship of God should be appropriate to and vary according to the times of the day and the seasons. For example, in cold weather, the Lord is adorned with warm clothes and heavy gold and silver ornaments. Hot, warming food and drink are offered to the Lord. In the hot seasons, God is offered more refreshing food and dressed in much lighter clothing. Flowery and fragrant garlands are offered.</p><p>At this time of year, the temperatures in Gujarat can reach over 40°C. Therefore, the highly talented Sants in demonstrate their devotion unto Lord Shree Swaminarayan by adorning Him with clothing made of sandalwood paste. This highly fragrant sandalwood has a very cooling and soothing effect. The Sants artistically mould beautiful regal clothes and ornaments out of the paste. This sandalwood is subsequently removed and formed into lozenges, which is then used throughout the year for the impression of the tilaks by the Sants and disciples.</p><p>The start of the Chandan darshan commences on the auspiscious day of Akha Trij, Vaishakh Sud 3. This year, this day fell on Tuesday 3rd May&nbsp;2022.</p>",
                    class: 'cms-roboto-slab-desc'
                }, */ {
                    type: 'cnt_p50',
                    title: "Forecasts of Lord Swaminarayan's Arrival",
                    desc: "<p>The Sants and disciples express and demonstrate their love and devotion unto the Lord through their varied worship of this pratima form of the supreme Lord Shree Swaminarayan. Lord Shree Swaminarayan says that the worship of God should be appropriate to and vary according to the times of the day and the seasons. For example, in cold weather, the Lord is adorned with warm clothes and heavy gold and silver ornaments. Hot, warming food and drink are offered to the Lord. In the hot seasons, God is offered more refreshing food and dressed in much lighter clothing. Flowery and fragrant garlands are offered.</p><p>At this time of year, the temperatures in Gujarat can reach over 40°C. Therefore, the highly talented Sants in demonstrate their devotion unto Lord Shree Swaminarayan by adorning Him with clothing made of sandalwood paste. This highly fragrant sandalwood has a very cooling and soothing effect. The Sants artistically mould beautiful regal clothes and ornaments out of the paste. This sandalwood is subsequently removed and formed into lozenges, which is then used throughout the year for the impression of the tilaks by the Sants and disciples.</p><p>The start of the Chandan darshan commences on the auspiscious day of Akha Trij, Vaishakh Sud 3. This year, this day fell on Tuesday 3rd May&nbsp;2022.</p>",
                    rDesc: "<p>The Sants and disciples express and demonstrate their love and devotion unto the Lord through their varied worship of this pratima form of the supreme Lord Shree Swaminarayan. Lord Shree Swaminarayan says that the worship of God should be appropriate to and vary according to the times of the day and the seasons. For example, in cold weather, the Lord is adorned with warm clothes and heavy gold and silver ornaments. Hot, warming food and drink are offered to the Lord. In the hot seasons, God is offered more refreshing food and dressed in much lighter clothing. Flowery and fragrant garlands are offered.</p><p>At this time of year, the temperatures in Gujarat can reach over 40°C. Therefore, the highly talented Sants in demonstrate their devotion unto Lord Shree Swaminarayan by adorning Him with clothing made of sandalwood paste. This highly fragrant sandalwood has a very cooling and soothing effect. The Sants artistically mould beautiful regal clothes and ornaments out of the paste. This sandalwood is subsequently removed and formed into lozenges, which is then used throughout the year for the impression of the tilaks by the Sants and disciples.</p><p>The start of the Chandan darshan commences on the auspiscious day of Akha Trij, Vaishakh Sud 3. This year, this day fell on Tuesday 3rd May&nbsp;2022.</p>",
                }, {
                    type: 'cnt_p50',
                    title: "The ancient Hindu scripture, the Brahmand-Puran, has forecasted:",
                    desc: "<p class='font-family-noto-sans-devnagari text-center font-size-30px dark-active-color'>दत्तात्रेयः कृतयुगे त्रेतायां रघुनन्दनः |  <br/>द्वापरे वासुदेवः स्यात् कलौ स्वामि वृषात्मजः ||</p><p class='text-center font-family-noto-sans-devnagari font-size-22px'>Dattatreyah Krutayuge, Tretaayaam Raghunandanah, Dwapare Vaasudevah syaat, Kalau Swami Vrushaatmajah.</p>",
                    rDesc: "<p class='font-size-22px font-family-roboto-slab'>In the age of Sat-yug, Lord Dattatreya will manifest. In Treta-yug, Lord Raghunandan alias Ramchandraji will manifest. The Vasudev, alias Lord Shree Krishna will manifest in Dwapar-yug, and in the age of Kali-yug, the son of Dharmadev, Lord Shree Swaminarayan will manifest.</p>",
                    class: 'shadow-bg-with-design',
                    titleClass: 'small-title'
                }, {
                    type: 'cnt_p50',
                    title: "In the Holy Scripture, the Padma-Puran, it is said:",
                    desc: "<p class='font-family-noto-sans-devnagari text-center font-size-30px dark-active-color'>पाखण्डबहुले लोके स्वामिनाम्ना हरिः स्वयम् |<br/>पापपङ्कनिमग्नं तज्जगदुद्धारयिष्यति ||</p><p class='text-center font-family-noto-sans-devnagari font-size-22px'>Paakhanda-bahule loke Swami-naamnaa Harihi swayam, paap-pank-nimagnam taj-jagad-uddhaarayishyati.</p>",
                    rDesc: "<p class='font-size-22px font-family-roboto-slab'>\"When dishonesty has spread throughout society and the world has become immersed in a mire of sin, Lord Shree Swaminarayan will manifest in order to liberate all.\"</p>",
                    class: 'shadow-bg',
                    titleClass: 'small-title'
                }, {
                    type: 'cnt_p50',
                    title: "The Vishnu-Dharmottar scripture states:",
                    desc: "<p class='font-family-noto-sans-devnagari text-center font-size-30px dark-active-color'>महाधर्मात्यये पुण्ये नाम्ना पापविनाशके |<br/>हरिप्रसादविप्रस्य स्वामिनाम्ना हरिः स्वयम् ||</p><p class='text-center font-family-noto-sans-devnagari font-size-22px'>Mahaa-dharmaatyaye punye naamnaa paap-vinaashake, Hariprasaad-viprasya Swami-naamnaa Harihi swayam.</p>",
                    rDesc: "<p class='font-size-22px font-family-roboto-slab'>\"When the principles of the elite religion are being destroyed, Lord Swaminarayan will manifest at the home of the Brahmin Hariprasad. By the mere utterance of His name, sinners will be purified.\"</p>",
                    class: 'white-bg-with-design-and-shadow',
                    titleClass: 'small-title'
                }, {
                    type: 'cnt_p50',
                    title: "Fulfilling the forecasts of these scriptures, Purna-Purushottam Lord Shree Swaminarayan manifested to:",
                    desc: `<ul>
                        <li>Eradicate irreligion and establish the true religion (Bhagwat-dharma).</li>
                        <li>Abolish the practise of animal sacrificial ceremonies and instead, to advocate the ideology of no violence under any circumstances (ahimsa).</li>
                        <li>Establish loving devotion (prem-lakshana-bhakti) unto the Lord.</li>
                        <li>Rescue all those who seek salvation from their internal enemies such as lust, anger etc. and to ensure that they attain eternal salvation (aatyantik moksh).</li>
                        <li>Establish and disseminate, His eminence as the sarvavatari (incarnator of all incarnations), His supreme knowledge and His true upasana.</li>
                        <li>Enlighten the previous incarnations and their devotees with His supreme upasana and then dispatch them to His Akshardham.</li>
                    </ul>`,
                    rDesc: `<ul>
                        <li>Eradicate irreligion and establish the true religion (Bhagwat-dharma).</li>
                        <li>Abolish the practise of animal sacrificial ceremonies and instead, to advocate the ideology of no violence under any circumstances (ahimsa).</li>
                        <li>Establish loving devotion (prem-lakshana-bhakti) unto the Lord.</li>
                        <li>Rescue all those who seek salvation from their internal enemies such as lust, anger etc. and to ensure that they attain eternal salvation (aatyantik moksh).</li>
                        <li>Establish and disseminate, His eminence as the sarvavatari (incarnator of all incarnations), His supreme knowledge and His true upasana.</li>
                        <li>Enlighten the previous incarnations and their devotees with His supreme upasana and then dispatch them to His Akshardham.</li>
                    </ul>`,
                    class: 'mt-5 white-bg-with-borders',
                    titleClass: 'title-with-bg-active-color'
                }, {
                    type: 'cnt_100',
                    title: "Lord Shree Swaminarayan's Manifestation upon the Earth",
                    desc: "<p class='cms-text'>The Lord is never born, although He may show that He is going through the process of human birth. He never ages and never dies. Such a powerful Lord manifested at the home of Murti (Bhakti) and Dharmadev. He shows His body and actions to be just like those of all others. Those who understand His deeds to be divine, and understand that He never ages and is immortal, will be released from the cycle of birth and death.</p><p class='quote-by'>Lord Shree Swaminarayan, Vachanamrut Ahmedabad 4</p>",
                    class: 'no-bg-with-design-2',
                    titleClass: 'title-active-color',
                    PlaceOfDesign: 'content',
                }, {
                    type: 'img_cnt',
                    title: "",
                    desc: "<p class='font-size-22px font-family-roboto-slab'>On one occasion, Lord Shree Swaminarayan was seated amongst a vast assembly of sants and devotees. He asked them all a question. \"God incarnates on the earth for the salvation of souls. Is He unable to bestow salvation whilst remaining in His abode, without incarnating on this earth? God is able to confer salvation in whichever manner He wills. In that case, what is the purpose for God to assume a human form? If God can only bestow salvation after incarnating, but is otherwise unable to confer beatitude, this constitutes a deficiency in God. The fact is that God is able to grant salvation to souls, with or without incarnating on the earth. So what is the purpose for God to incarnate? That is the question.\"</p>",
                    image: `${process.env.PUBLIC_URL}/img/akshardham-sabha 1.png`,
                    image_alt: 'Lord Shree Swaminarayan presides upon Shree Swaminarayan Gadi',
                    class: '',
                    titleClass: 'title-active-color',
                    PlaceOfDesign: 'content',
                }, {
                    type: 'cnt_100',
                    title: "",
                    desc: "<p class='cms-text'>The Lord is never born, although He may show that He is going through the process of human birth. He never ages and never dies. Such a powerful Lord manifested at the home of Murti (Bhakti) and Dharmadev. He shows His body and actions to be just like those of all others. Those who understand His deeds to be divine, and understand that He never ages and is immortal, will be released from the cycle of birth and death.</p><p class='quote-by'>Lord Shree Swaminarayan, Vachanamrut Ahmedabad 4</p>",
                    class: 'white-bg-with-design-and-shadow-quote',
                    titleClass: 'title-active-color',
                    PlaceOfDesign: 'content',
                }, {
                    type: 'cnt_100',
                    title: "",
                    desc: "<p class='font-family-roboto-slab'>All the senior and eminent sants answered the question in accordance to their understanding. However, Shreejimaharaj's remained  unanswered because none of the answers were satisfactory. All the munis joined their hands and prayed, \"Oh Maharaj! The answer to that question can only be attained from You.\" So Shreejimaharaj said, \"The only purpose for God to assume an incarnation is that He surrenders Himself to the devotion of the devotees who have an intense love for Him. The Lord incarnates Himself and assumes a form in accordance to the devotee's spiritual desires in order to grant them His divine bliss. He then fulfils the spiritual desires of His devotees. As the devotees are corporeal and have human bodies, God also shows Himself in this manner in the world and assumes a human body. He showers affection upon His devotees. </p>",
                    class: 'mt-5 mb-5',
                    titleClass: '',
                    PlaceOfDesign: '',
                }, {
                    type: 'tab_panel',
                    title: '',
                    tabs: [{
                        tab_title: 'Jeevanpran Shree Muktajeevan Swamibapa',
                        type: 'img_right_desc',
                        title: "Jeevanpran Shree Muktajeevan Swamibapa",
                        desc: "<p class='font-size-24px font-family-roboto-slab'>On one occasion, Lord Shree Swaminarayan was seated amongst a vast assembly of sants and devotees. He asked them all a question. \"God incarnates on the earth for the salvation of souls. Is He unable to bestow salvation whilst remaining in His abode, without incarnating on this earth? God is able to confer salvation in whichever manner He wills. In that case, what is the purpose for God to assume a human form? If God can only bestow salvation after incarnating, but is otherwise unable to confer beatitude, this constitutes a deficiency in God. The fact is that God is able to grant salvation to souls, with or without incarnating on the earth. So what is the purpose for God to incarnate? That is the question.\"</p>",
                        image: 'akshardham-sabha 1.png',
                        image_alt: 'Lord Shree Swaminarayan presides upon Shree Swaminarayan Gadi',
                        class: '',
                        titleClass: 'title-active-color',
                        PlaceOfDesign: 'content',
                    }, {
                        tab_title: 'The Eminence of Gurudev Jeevanpran Swamibapa',
                        type: 'img_right_desc',
                        title: "The Eminence of Gurudev Jeevanpran Swamibapa",
                        desc: "<p class='font-size-22px font-family-roboto-slab'>On one occasion, Lord Shree Swaminarayan was seated amongst a vast assembly of sants and devotees. He asked them all a question. \"God incarnates on the earth for the salvation of souls. Is He unable to bestow salvation whilst remaining in His abode, without incarnating on this earth? God is able to confer salvation in whichever manner He wills. In that case, what is the purpose for God to assume a human form? If God can only bestow salvation after incarnating, but is otherwise unable to confer beatitude, this constitutes a deficiency in God. The fact is that God is able to grant salvation to souls, with or without incarnating on the earth. So what is the purpose for God to incarnate? That is the question.\"</p>",
                        image: 'akshardham-sabha 1.png',
                        image_alt: 'Lord Shree Swaminarayan presides upon Shree Swaminarayan Gadi',
                        class: '',
                        titleClass: 'title-active-color',
                        PlaceOfDesign: 'content',
                    }, {
                        tab_title: 'Gurudev Jeevanpran Swamibapa',
                        type: 'img_right_desc',
                        title: "Gurudev Jeevanpran Swamibapa",
                        desc: "<p class='font-size-22px font-family-roboto-slab'>On one occasion, Lord Shree Swaminarayan was seated amongst a vast assembly of sants and devotees. He asked them all a question. \"God incarnates on the earth for the salvation of souls. Is He unable to bestow salvation whilst remaining in His abode, without incarnating on this earth? God is able to confer salvation in whichever manner He wills. In that case, what is the purpose for God to assume a human form? If God can only bestow salvation after incarnating, but is otherwise unable to confer beatitude, this constitutes a deficiency in God. The fact is that God is able to grant salvation to souls, with or without incarnating on the earth. So what is the purpose for God to incarnate? That is the question.\"</p>",
                        image: 'akshardham-sabha 1.png',
                        image_alt: 'Lord Shree Swaminarayan presides upon Shree Swaminarayan Gadi',
                        class: '',
                        titleClass: 'title-active-color',
                        PlaceOfDesign: 'content',
                    }]
                }
            ]
        })
        setCmsDataTimeline({
            data: [
                {
                    type: 'img_right_desc',
                    month: 'Feb 2020',
                    date: '26',
                    gujarati_year: 'Samvat 2076',
                    gujarati_date: 'Fagan Sud 3',
                    title: '76th Patotsav Of Shree Swaminarayan Mandir Maninagar',
                    desc: `<div class="row mt-3"><div class="col-12 col-sm-12 col-md-12 col-lg-4 col-xl-4"><img src="${process.env.PUBLIC_URL}/img/imgresizer (27) 1.png" class="border-radius-15px img-fluid lazyload w-100" alt="" /></div><div class="col-12 col-sm-12 col-md-12 col-lg-8 col-xl-8"><p class="description ">On Fagun Sud 3, 26th February 1944 Jeevanpran Swamibapa ceremonially installed the divine Murti of Lord Shree Swaminarayan and established the headquarters of Shree Swaminarayan Gadi Sansthan. On the same day 76 years later, 26th February 2020, the 76th anniversary of the divine installation was celebrated at Shree Swaminarayan Mandir Maninagar.</p></div></div>`,
                    image: 'imgresizer (27) 1.png',
                    color: 'default'
                },
                {
                    month: 'Feb 2020',
                    date: '26',
                    gujarati_year: 'Samvat 2076',
                    gujarati_date: 'Fagan Sud 3',
                    title: '76th Patotsav Of Shree Swaminarayan Mandir Maninagar',
                    desc: '<p class="description ">Board your overnight flight to Dubai today</p>',
                    color: 'amethyst'
                },
                {
                    month: 'Feb 2020',
                    date: '26',
                    gujarati_year: 'Samvat 2076',
                    gujarati_date: 'Fagan Sud 3',
                    title: '76th Patotsav Of Shree Swaminarayan Mandir Maninagar',
                    desc: '<p class="description ">Board your overnight flight to Dubai today</p>',
                    color: 'electric-purple'
                }, {
                    month: 'Feb 2020',
                    date: '26',
                    gujarati_year: 'Samvat 2076',
                    gujarati_date: 'Fagan Sud 3',
                    title: '76th Patotsav Of Shree Swaminarayan Mandir Maninagar',
                    desc: '<p class="description ">Board your overnight flight to Dubai today</p>',
                    color: 'tangerine'
                }, {
                    month: 'Feb 2020',
                    date: '26',
                    gujarati_year: 'Samvat 2076',
                    gujarati_date: 'Fagan Sud 3',
                    title: '76th Patotsav Of Shree Swaminarayan Mandir Maninagar',
                    desc: '<p class="description ">Board your overnight flight to Dubai today</p>',
                    color: 'alizarin'
                }, {
                    month: 'Feb 2020',
                    date: '26',
                    gujarati_year: 'Samvat 2076',
                    gujarati_date: 'Fagan Sud 3',
                    title: '76th Patotsav Of Shree Swaminarayan Mandir Maninagar',
                    desc: '<p class="description ">Board your overnight flight to Dubai today</p>',
                    color: 'golden-poppy'
                }, {
                    month: 'Feb 2020',
                    date: '26',
                    gujarati_year: 'Samvat 2076',
                    gujarati_date: 'Fagan Sud 3',
                    title: '76th Patotsav Of Shree Swaminarayan Mandir Maninagar',
                    desc: '<p class="description ">Board your overnight flight to Dubai today</p>',
                    color: 'citrus'
                }, {
                    month: 'Feb 2020',
                    date: '26',
                    gujarati_year: 'Samvat 2076',
                    gujarati_date: 'Fagan Sud 3',
                    title: '76th Patotsav Of Shree Swaminarayan Mandir Maninagar',
                    desc: '<p class="description ">Board your overnight flight to Dubai today</p>',
                    color: 'shamrock'
                }, {
                    month: 'Feb 2020',
                    date: '26',
                    gujarati_year: 'Samvat 2076',
                    gujarati_date: 'Fagan Sud 3',
                    title: '76th Patotsav Of Shree Swaminarayan Mandir Maninagar',
                    desc: '<p class="description ">Board your overnight flight to Dubai today</p>',
                    color: 'is-green'
                }, {
                    month: 'Feb 2020',
                    date: '26',
                    gujarati_year: 'Samvat 2076',
                    gujarati_date: 'Fagan Sud 3',
                    title: '76th Patotsav Of Shree Swaminarayan Mandir Maninagar',
                    desc: '<p class="description ">Board your overnight flight to Dubai today</p>',
                    color: 'pacific-blue'
                }, {
                    month: 'Feb 2020',
                    date: '26',
                    gujarati_year: 'Samvat 2076',
                    gujarati_date: 'Fagan Sud 3',
                    title: '76th Patotsav Of Shree Swaminarayan Mandir Maninagar',
                    desc: '<p class="description ">Board your overnight flight to Dubai today</p>',
                    color: 'navy-blue'
                }, {
                    month: 'Feb 2020',
                    date: '26',
                    gujarati_year: 'Samvat 2076',
                    gujarati_date: 'Fagan Sud 3',
                    title: '76th Patotsav Of Shree Swaminarayan Mandir Maninagar',
                    desc: '<p class="description ">Board your overnight flight to Dubai today</p>',
                    color: 'egyptian-blue'
                }, {
                    month: 'Feb 2020',
                    date: '26',
                    gujarati_year: 'Samvat 2076',
                    gujarati_date: 'Fagan Sud 3',
                    title: '76th Patotsav Of Shree Swaminarayan Mandir Maninagar',
                    desc: '<p class="description ">Board your overnight flight to Dubai today</p>',
                    color: 'purple-heart'
                }, {
                    month: 'Feb 2020',
                    date: '26',
                    gujarati_year: 'Samvat 2076',
                    gujarati_date: 'Fagan Sud 3',
                    title: '76th Patotsav Of Shree Swaminarayan Mandir Maninagar',
                    desc: '<p class="description ">Board your overnight flight to Dubai today</p>',
                    color: 'dark-magenta'
                }, {
                    month: 'Feb 2020',
                    date: '26',
                    gujarati_year: 'Samvat 2076',
                    gujarati_date: 'Fagan Sud 3',
                    title: '76th Patotsav Of Shree Swaminarayan Mandir Maninagar',
                    desc: '<p class="description ">Board your overnight flight to Dubai today</p>',
                    color: 'old-rose'
                }

            ]
        })
    }, []);
    return (
        <>
            <section className="site-contain pb-5">
                <div className="container">
                    <BreadCrumb BreadCrumbdata={BreadCrumbdata.data} />
                    <SectionTitle title="Purna-Purushottam Lord Shree Swaminarayan" type="type-2" />
                    {CmsData.data && CmsData.data.length > 0 && CmsData.data.map((data: any, i: any) =>
                    (
                        <>
                            {(data.type === 'image-text-row') ?
                                (
                                    <><ImageTextRow Data={data} /></>
                                ) : <></>}

                            {(data.type === 'cnt_p50' || data.type === 'cnt_100') ?
                                (
                                    <><Text100OR50 Data={data} title={data.title} className={data.class} titleClass={data.titleClass} PlaceOfDesign={data.PlaceOfDesign} /></>
                                ) : <></>}

                            {(data.type === 'img_cnt' || data.type === 'cnt_img') ?
                                (
                                    <><IMGDesc Data={data} title={data.title} className={data.class} titleClass={data.titleClass} PlaceOfDesign={data.PlaceOfDesign} /></>
                                ) : <></>}

                            {(data.type === 'tab_panel') ?
                                (
                                    <><TabPanel Data={data} /></>
                                ) : <></>}
                        </>
                    ))}

                    <div className="itinerary-section">
                        <div className="timeline">
                            {/* <div className="line text-muted">
                            </div> */}
                            <div aria-multiselectable="true" className="panel-group" id="accordion" role="tablist">
                                {CmsDataTimeline.data && CmsDataTimeline.data.length > 0 && CmsDataTimeline.data.map((data: any, i: any) =>
                                (
                                    <>
                                        <div className={`panel-group-contain ${data.color}`}>
                                            <div className="panel panel-default">
                                                <div className="day-text d-flex flex-column">
                                                    <div className="day-text-month">{data.month}</div>
                                                    <div className="day-text-day">{data.date}</div>
                                                    <div className="day-text-gujarati">
                                                        <span className="d-block">{data.gujarati_year}</span>
                                                        <span className="d-block">{data.gujarati_date}</span>
                                                    </div>
                                                </div>
                                                <div className="panel-heading" id="iti_heading0" role="tab">
                                                    <h4 className="panel-title">
                                                        {data.title}
                                                    </h4>
                                                </div>
                                                <div className="panel-collapse collapse in show">
                                                    <div className="panel-body" dangerouslySetInnerHTML={{ __html: data.desc }}></div>
                                                </div>
                                            </div>
                                        </div>
                                    </>
                                ))}

                            </div>
                        </div>
                    </div>
                </div>
            </section>

        </>
    )
}

export default CmsMainFile