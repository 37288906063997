import React, { useContext, useEffect, useState } from "react"
import Banner from './sections/Banner'
import BreadCrumb from "./sections/BreadCrumb"
import { SectionTitle, PopularNews, SayingsImageAndText, GallerySidebar, OnlyTitleBanner, OnlySocialWithDate, ImageWithText, PageLoader, monthNames, ImageTextRow, Text100OR50, IMGDesc, TabPanel, ModulePlusApiName, GetSiteUrl, getKeyword, MetaData } from "./sections/CommonSections"
import GlobalContext from './Core/PageData'
import { Link, useNavigate, useParams } from "react-router-dom"
import axios from "axios"
import ScrollAnimation from "react-animate-on-scroll"
import CMSContent from "./CMSContent"
// @ts-ignore
import moment from 'moment'

const NewsDetails: React.FC = () => {
    let { newsDetailsSlug, siteSlug, locationSlug, YearSlug } = useParams();
    const { GlobalContent, setGlobalContent } = useContext(GlobalContext as any) as any
    //document.title = `News${GlobalContent.pageTitle}`;
    const [isPageLoad, setisPageLoad] = useState('no')
    const [BreadCrumbdata, setBreadCrumbdata] = useState({ data: [] as any[] })
    const [NewsDetailsData, setNewsDetailsData] = useState({ data: [] as any[] })
    const [NewsDetailsContentData, setNewsDetailsContentData] = useState({ data: [] as any[] })
    const [PopularNewsList, setPopularNewsList] = useState({ data: [] as any[] })
    const [GallerySidebarDataTemp, setGallerySidebarDataTemp] = useState({ data: [] as any[] })
    const [GallerySidebarData, setGallerySidebarData] = useState({ data: [] as any[] })
    const [NewsImage, setNewsImage] = useState({ data: [] as any[] })
    const [HalfImages, setHalfImages] = useState({ data: [] as any[] })
    const [MetaKeyword, setMetaKeyword] = useState('');
    const navigate = useNavigate()
    useEffect(() => {
        setGallerySidebarData({ data: [] as any[] })
        setNewsDetailsContentData({ data: [] as any[] })
        setisPageLoad('no')
        setGlobalContent({ ...GlobalContent, IsMediaPlayershow: false })
        setNewsImage({
            data: [
                {
                    image: 'imgresizer_news_1.png',
                    text: 'Fuldolotsav and Ved Ratna Acharya Swamiji Maharaj 60th Diksha Jayanti',

                }, {
                    image: 'Rectangle81.png',
                    text: 'Maninagardham Patotsav',

                }, {
                    image: 'image65.png',
                    text: 'Community Peace Prayer Vigil to pray for Ukraine',

                }, {
                    image: '2004_president_kalam_06.png',
                    text: 'Fuldolotsav and Ved Ratna Acharya Swamiji Maharaj 60th Diksha Jayanti',

                }, {
                    image: 'image66.png',
                    text: 'Maninagardham Patotsav',

                }, {
                    image: 'imgresizer_news_3.png',
                    text: 'Community Peace Prayer Vigil to pray for Ukraine',

                }
            ]
        })

        axios.get(`${process.env.REACT_APP_API_URL}news/front?sortField=noOfView&sortType=desc&recordPerPage=5`).then(json => {
            setPopularNewsList({ data: json.data.data.data })
        })
        var APINEWS = `${process.env.REACT_APP_API_URL}${locationSlug}/news/${YearSlug}/${newsDetailsSlug}`
        if (locationSlug === undefined) {
            APINEWS = `${process.env.REACT_APP_API_URL}news/slug/${newsDetailsSlug}`
        }
        axios.get(`${APINEWS}`).then(async json => {
            if (json.data.data.length === 0/*  || json.data.data[0].status === 'inactive' */) {
                navigate(`${process.env.PUBLIC_URL}/${process.env.REACT_APP_NEWS_SLUG}`, { replace: true })
            }
            if (json.data.data.length > 0 && json.data.data[0].metaKeyword !== undefined && json.data.data[0].metaKeyword !== '') {
                setMetaKeyword(await getKeyword(json.data.data[0].metaKeyword) as any)
            }
            let d = moment(json.data.data[0].publishOn.split('T')[0])
            json.data.data[0]['date'] = `${d.format('DD')} ${d.format('MMMM')} ${d.format('YYYY')}`
            json.data.data[0]['event_date'] = ''
            if (json.data.data[0].eventDate !== undefined && json.data.data[0].eventDate !== '' && json.data.data[0].eventDate !== null) {
                d = moment(json.data.data[0].eventDate.split('T')[0])
                json.data.data[0]['event_date'] = `${d.format('DD')} ${d.format('MMMM')} ${d.format('YYYY')}`
            }
            setNewsDetailsData({ data: json.data.data })
            //document.title = `${json.data.data[0].title}${GlobalContent.pageTitle}`;

            axios.put(`${process.env.REACT_APP_API_URL}news/viewcouter/${json.data.data[0]._id}`, {}).then(async function (response) {
            })
            return json.data.data
        }).then(NewsDetailsTemp => {
            /* let aa: any[] = [] */
            setGallerySidebarDataTemp({ data: [{ image_json: [] }] })
            setHalfImages({ data: [] })
            var NewsSiteAccess = NewsDetailsTemp[0].siteAccess.split(',')
            if (NewsSiteAccess.length > 0) {
                var site = GlobalContent.AllSiteData.filter((e: any) => NewsSiteAccess[0] === e._id)
            } else {
                var site = GlobalContent.AllSiteData.filter((e: any) => e.is_main === 'yes')
            }
            if (siteSlug !== undefined && siteSlug !== null) {
                site = GlobalContent.AllSiteData.filter((e: any) => siteSlug === e.site_url)
                if (site.length === 0) {
                    site = GlobalContent.AllSiteData.filter((e: any) => siteSlug === e._site_url_)
                }
            }
            setGlobalContent({ ...GlobalContent, siteData: site[0], headerClass: '' })

            axios.get(`${process.env.REACT_APP_API_URL}cms/cms-page-content/level1/${NewsDetailsTemp[0]._id}`).then(async json => {

                let aa: any[] = []
                const promises = [] as any;
                const HalfImagesPromises = [] as any;
                const SidebarGalleryPromises = [] as any;
                var jk = 0;
                await json.data.data.forEach((ContentData: any, i: any) => {
                    if (ContentData.status === 'active') {
                        if (ContentData.type === 'tModule' /* && ContentData.tagType === 'imageGallery' */) {

                            /* if (ContentData.tagType !== '') {
                                json.data.data[i]['references'] = []
                                const References = ContentData.referenceLink.split(',')
                                References.forEach((ReferencesID: any, j: any) => {
                                    if (ReferencesID !== '') {
                                        const promise = axios.get(`${process.env.REACT_APP_API_URL}${ModulePlusApiName[0][ContentData.tagType].apiUrl}${ReferencesID}`).then(Referencesjson => {
                                            let Image = ''
                                            let Title = ''
                                            let URL = `${process.env.PUBLIC_URL}/${ModulePlusApiName[0][ContentData.tagType].siteUrl}`
                                            if (ContentData.tagType === 'imageGallery') {
                                                if (Referencesjson.data.data[0].image_json.length > 0) {
                                                    Title = Referencesjson.data.data[0].name
                                                    Image = (Referencesjson.data.data[0].image_json.length > 0) ? (Referencesjson.data.data[0].image_json.find((ell: { image_feature: string; }) => ell.image_feature === 'yes' || ell.image_feature === '1') !== undefined) ? Referencesjson.data.data[0].image_json.find((ell: { image_feature: string; }) => ell.image_feature === 'yes' || ell.image_feature === '1').imageurl : Referencesjson.data.data[0].image_json[0].imageurl : ''
                                                    URL += `${Referencesjson.data.data[0].slug}`
                                                    /* aa.push(Referencesjson.data.data[0]);
                                                    setGallerySidebarDataTemp({ data: aa }) *
                                                }
    
                                            } else if (ContentData.tagType === 'pBook' || ContentData.tagType === 'pVideo') {
                                                Title = Referencesjson.data.data[0].title
                                                Image = Referencesjson.data.data[0].banner_image
                                                URL += ''
                                            } else if (ContentData.tagType === 'news') {
                                                Title = Referencesjson.data.data[0].title
                                                Image = Referencesjson.data.data[0].image
                                                URL += `${Referencesjson.data.data[0].slug}`
                                            }
                                            if (Title !== '') {
                                                /* return json.data.data[i]['references'].push({
                                                    image: Image,
                                                    text: Title,
                                                    URL: URL,
                                                    type: ContentData.tagType
                                                }) *
                                                return json.data.data[i]['references'][j] = {
                                                    image: Image,
                                                    text: Title,
                                                    URL: URL,
                                                    type: ContentData.tagType
                                                }
    
                                            }
                                        })
                                        promises.push(promise);
                                    }
                                })
                            } */

                            if (ContentData.tagType === 'imageGallery') {
                                const ImgGallery = ContentData.referenceLink.split(',')
                                ImgGallery.forEach(async (ImgGalleryID: any, j: any) => {
                                    if (ImgGalleryID !== '') {
                                        var gR = axios.get(`${process.env.REACT_APP_API_URL}publication/image-gallery/${ImgGalleryID}`).then(json => {
                                            /* aa[jk] = json.data.data[0]
                                            jk = jk + 1 */
                                            return json.data.data[0]
                                        }) as any
                                        SidebarGalleryPromises.push(gR)
                                    }
                                })
                            }
                        } else if (ContentData.type === 'img_img') {
                            if (ContentData.image !== '' && ContentData.rImage !== '') {
                                if (ContentData.image !== undefined && ContentData.image !== '') {
                                    HalfImagesPromises.push({ image: ContentData.image })
                                }
                                if (ContentData.rImage !== undefined && ContentData.rImage !== '') {
                                    HalfImagesPromises.push({ image: ContentData.rImage })
                                }
                            }
                        } else if (ContentData.type === 'tModule' && ContentData.tagType === 'pVideo') {
                            /* if ([...HalfImages.data].length === 0) {
                                if (ContentData.image !== '' && ContentData.rImage !== '') {
                                    setHalfImages({ data: [{ image: ContentData.image }, { image: ContentData.rImage }] })
                                }
                            } */
                        }
                    }
                })
                Promise.all(promises).then(dsa => {
                    setNewsDetailsContentData({ data: json.data.data })
                })
                Promise.all(HalfImagesPromises).then(dsa => {
                    setHalfImages({ data: dsa })
                })
                Promise.all(SidebarGalleryPromises).then(dsa => {
                    setGallerySidebarDataTemp({ data: dsa })
                })

                return { NSingleD: NewsDetailsTemp, NSingleDC: json.data.data, ImageGalleryData: GallerySidebarDataTemp.data }
            }).then(_News_ => {
                let BreadCrumbdata_ = [
                    {
                        path: `${process.env.PUBLIC_URL}${GetSiteUrl(site[0]?._id/* _News_.NSingleD[0].siteAccess */, GlobalContent.AllSiteData)}/`,
                        linkName: 'Home',
                        status: '',
                    }, {
                        path: `${process.env.PUBLIC_URL}${GetSiteUrl(site[0]?._id/* _News_.NSingleD[0].siteAccess */, GlobalContent.AllSiteData)}/${process.env.REACT_APP_NEWS_SLUG}`,
                        linkName: 'News',
                        status: '',
                    }]
                /* if (window.innerWidth > 768) {
                    BreadCrumbdata_.push({
                        path: `${process.env.PUBLIC_URL}${GetSiteUrl(site[0]?._id/* _News_.NSingleD[0].siteAccess *, GlobalContent.AllSiteData)}/${process.env.REACT_APP_NEWS_SLUG}${_News_.NSingleD[0].slug}`,
                        linkName: _News_.NSingleD[0].title,
                        status: '',
                    })
                } */
                setBreadCrumbdata({ data: BreadCrumbdata_ })
                _News_.ImageGalleryData.forEach((Temp: any, i: any) => {
                    let aa = [...GallerySidebarData.data] as any
                    //aa.push(Temp)
                    //setGallerySidebarData({ data: aa })
                })
                /* setisPageLoad('yes') */
                document.title = `${_News_.NSingleD[0].title}${GlobalContent.pageTitle}`
            })
        }).catch(err => {
            navigate(`${process.env.PUBLIC_URL}/404`)
        })
    }, [newsDetailsSlug])

    useEffect(() => {
        let bb = [] as any
        let availSpace: number = 6

        /* GallerySidebarDataTemp.data.forEach((Temp: any, i: any) => {
            if (Temp !== null) {
                if (Temp.image_json.length > 0) {
                    Temp.image_json.forEach((image_json_temp: any, j: any) => {
                        if (bb.length < availSpace && (image_json_temp['image_feature'] === 'yes') || image_json_temp['image_feature'] === '1')) {
                            image_json_temp['URL'] = `${process.env.PUBLIC_URL}${GetSiteUrl(Temp.siteAccess,GlobalContent.AllSiteData)}/${process.env.REACT_APP_IMAGE_GALLERY_SLUG}${Temp.slug}`
                            bb.push(image_json_temp)
                        }
                    })
                }
            }
        }) */

        /* if (bb.length < availSpace) { */
        var eachAvail: number = 0
        if (GallerySidebarDataTemp.data.length < 6) {
            eachAvail = Math.ceil(availSpace / GallerySidebarDataTemp.data.length)
        } else {
            eachAvail = Math.ceil(GallerySidebarDataTemp.data.length / availSpace)
        }

        GallerySidebarDataTemp.data.forEach((Temp: any, i: any) => {
            if (Temp !== null && Temp !== undefined) {
                if (Temp.image_json.length > 0 && Temp.status === 'active') {
                    let eachAvailT = 0
                    Temp.image_json.forEach((image_json_temp: any, j: any) => {
                        if (eachAvailT < eachAvail) {
                            if (bb.length < availSpace && (image_json_temp['image_feature'] !== 'yes' || image_json_temp['image_feature'] !== '1')) {
                                image_json_temp['URL'] = `${process.env.PUBLIC_URL}${GetSiteUrl(Temp.siteAccess, GlobalContent.AllSiteData)}/${process.env.REACT_APP_IMAGE_GALLERY_SLUG}${Temp.slug}`
                                bb.push(image_json_temp)
                                eachAvailT = eachAvailT + 1
                            }
                        }
                    })
                }
            }
        })
        /* } */
        setGallerySidebarData({ data: bb })

        //var image = (gR.image_json.length > 0) ? (gR.image_json.find((ell: { image_feature: string; }) => ell.image_feature === 'yes' || ell.image_feature === '1') !== undefined) ? gR.image_json.find((ell: { image_feature: string; }) => ell.image_feature === 'yes' || ell.image_feature === '1').imageurl : gR.image_json[0].imageurl : ''
        //HalfImagesPromises.push({ image: image });
    }, [GallerySidebarDataTemp])
    return (
        <>
            <div className={`${(isPageLoad === 'no') ? 'd-none' : ''}`}>
                {/* <Banner BannerImage="Shreejibapa_1920.png" /> */}
                <MetaData keywords={MetaKeyword} description={(NewsDetailsData.data[0]?.metaDescription !== undefined) ? NewsDetailsData.data[0]?.metaDescription : ''} />
                <section className="site-contain">
                    {/* <ScrollAnimation className="" animateIn="fadeIn" animateOnce={true} delay={100} > */}
                    <div className="Flower-top d-none d-md-block">
                        <img src={process.env.PUBLIC_URL + '/img/Flower-top.png'} className="img-fluid lazyload" alt="Flower" title="Flower" />
                    </div>
                    <div className="container">
                        <BreadCrumb BreadCrumbdata={BreadCrumbdata.data} />
                        {/* <div className="">
                                <SectionTitle title="News" />
                            </div> */}
                        <OnlyTitleBanner Text={NewsDetailsData.data[0]?.title} className="mt-4 mb-2" />
                        {HalfImages.data.length > 0 && HalfImages.data[0]?.image !== undefined /* && HalfImages.data[1]?.image !== undefined */ ? <div className="row mt-4 mb-4 they-say-carousel">
                            <div className="col-md-6">
                                <SayingsImageAndText Image={`${HalfImages.data[0]?.image}`} Text="" imageWidth='360' imageHeight='180' />
                            </div>
                            {HalfImages.data[1]?.image !== undefined ?
                                <div className="col-md-6 mt-3 mt-md-0 d-none d-md-block">
                                    <SayingsImageAndText Image={`${HalfImages.data[1]?.image}`} Text="" imageWidth='360' imageHeight='180' />
                                </div> :
                                <div className="col-md-6 mt-3 mt-md-0 d-none d-md-block">
                                    <SayingsImageAndText Image={`${GallerySidebarData.data[0]?.imageurl}`} /* URL={`${process.env.PUBLIC_URL}${GallerySidebarData.data[0]?.URL}`} */ Text="" imageWidth='360' imageHeight='180' />
                                </div>
                            }
                        </div> : ``}

                        {HalfImages.data.length === 0 && GallerySidebarData.data.length > 0 /* && GallerySidebarData.data[0]?.image_json.length > 0 */ ? <div className="row mt-4 mb-4 they-say-carousel">
                            {GallerySidebarData.data[0] !== undefined ?
                                <div className="col-md-6">
                                    <SayingsImageAndText Image={`${GallerySidebarData.data[0]?.imageurl}`} /* URL={`${process.env.PUBLIC_URL}${GallerySidebarData.data[0]?.URL}`} */ Text="" imageWidth='360' imageHeight='180' />
                                </div> : ''}
                            {GallerySidebarData.data[1] !== undefined ?
                                <div className="col-md-6 mt-3 mt-md-0 d-none d-md-block">
                                    <SayingsImageAndText Image={`${GallerySidebarData.data[1]?.imageurl}`} /* URL={`${process.env.PUBLIC_URL}${GallerySidebarData.data[1]?.URL}`} */ Text="" imageWidth='360' imageHeight='180' />
                                </div> : ''}
                        </div> : ``}
                        <OnlySocialWithDate Date={`Event: ${(NewsDetailsData.data[0]?.event_date === '') ? NewsDetailsData.data[0]?.date : NewsDetailsData.data[0]?.event_date} | Published: ${NewsDetailsData.data[0]?.date}`} url={window.location.href} image="" title={NewsDetailsData.data[0]?.title} className="pb-3" />
                    </div>

                    <CMSContent cmsPageId={NewsDetailsData.data[0]?._id} /* ContainerClass="" */ firstBlockClass="mt-0" /* NewsData={{ title: 'Popular News', data: PopularNewsList.data, type: 'sidebar' }} */ SiteSlug={siteSlug} newsDetailsSlug={newsDetailsSlug} locationSlug={locationSlug} YearSlug={((YearSlug === undefined)) ? moment(NewsDetailsData.data[0]?.publishOn.split('T')[0]).format('YYYY') : YearSlug} setisPageLoadFromMain={setisPageLoad} />
                    {/* </ScrollAnimation> */}
                </section>
            </div>
            {isPageLoad === 'no' ? <PageLoader /> : ''}
        </>
    )
}

export default NewsDetails