import React from "react";
import { Link } from 'react-router-dom'
// @ts-ignore
import ScrollAnimation from 'react-animate-on-scroll'
/* BreadCrumbdata = [
 {
    path : '',
    linkName: '',
    status : '',
 }
]; */
const BreadCrumb: React.FC<any> = ({ BreadCrumbdata }: any) => {
    var Bread = [/* BreadCrumbdata[0],BreadCrumbdata[BreadCrumbdata.length - 1] */] as any
    BreadCrumbdata.map((e:any,index:any) => {
        if((BreadCrumbdata.length -1) !== index){
            Bread.push(e)
        }
    })
    return (
        <>
            {(BreadCrumbdata.length > 0) ?
                (<>{/* <ScrollAnimation animateIn="fadeIn" animateOnce={true} delay={100} > */}
                    <nav aria-label="breadcrumb" className="d-flex flex-row justify-content-end position-relative" key={`divbreadcrumbitem`}>
                        <ol className="breadcrumb breadcrumb-site mb-0 mb-md-2 mb-lg-2">
                            {BreadCrumbdata && BreadCrumbdata.length > 0 && Bread.map((data: any, i: any) => (
                                <li className={`breadcrumb-item`} key={`${i}-breadcrumbitem`}>
                                    <Link to={data.path} title={data.linkName}>{data.linkName}</Link>
                                </li>
                            ))}
                        </ol>
                    </nav>
                    {/* </ScrollAnimation> */}</>) : <></>
            }
        </>
    )
}

export default BreadCrumb