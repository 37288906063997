import moment from 'moment';
import React, { useContext } from 'react';
import { Link } from 'react-router-dom'
import GlobalContext from './Core/PageData'
const Footer = () => {
    const { GlobalContent, setGlobalContent } = useContext(GlobalContext as any) as any
    let acharya_maharaj_name = GlobalContent.AllSiteData.filter((e: any) => e.is_main === 'yes')[0].acharya_maharaj_name
    let mainSite = GlobalContent.siteData
    if (GlobalContent.siteData?.main_footer !== undefined && GlobalContent.siteData?.main_footer === 'yes') {
        mainSite = GlobalContent.AllSiteData.filter((e: any) => e.is_main === 'yes')[0]
    }
    return (
        <>
            {/*<!-- ======= Footer ======= -->*/}
            <footer id="footer">
                {/* <div className="Bottom_Flower">
                    <img src={process.env.PUBLIC_URL + '/img/Bottom_Flower.svg'}  className="img-fluid lazyload" alt="Flower_Light" title="Flower_Light" />
                </div> */}
                {/* <div className="footer_bg"> */}
                {/*<!-- <div className="bottom_curve">
                        <img src={process.env.PUBLIC_URL + '/img/bottom_curve.png'} className="img-fluid lazyload" alt="Flower_Light" title="Flower_Light"  />
                    </div> -->*/ }
                {/* </div> */}
                {/* {JSON.stringify(GlobalContent)} */}
                <div className="footer-top">
                    <div className="container">
                        <div className="row justify-content-center">
                            <div className="col-lg-4 col-md-12 col-12 footer-links text-center d-block d-lg-none">
                                {mainSite !== undefined && mainSite.footer_logo !== undefined && mainSite.footer_logo !== '' ?
                                    <>
                                        <Link to={`${process.env.PUBLIC_URL}`}><img src={mainSite.footer_logo} className="img-fluid col-md-2 col-4" alt={mainSite.footer_logo_alt} title={mainSite.footer_logo_alt} /></Link>
                                        <span className="description d-block text-center mt-2 text-capitalize" dangerouslySetInnerHTML={{ __html: mainSite.footer_logo_desc }}></span>
                                    </> : <>
                                        <Link to={`${process.env.PUBLIC_URL}`}><img src={process.env.PUBLIC_URL + '/img/favicon.png'} className="img-fluid col-md-2 col-4" alt="logo" title="logo" /></Link>
                                        <span className="description d-block text-center mt-2 text-capitalize">Maninagar<br /><span className="fw-bold">Shree Swaminarayan Gadi</span><br />Sansthan</span>
                                    </>}
                            </div>
                            {/* <div className="col-lg-4 col-md-6 footer-links">
                                <h4>Keep up-to-date with Gadi Sansthan</h4>
                                <form action="" method="post" className="footer-newsletter-form">
                                    <input type="email" name="email" placeholder="Enter your email address" /><input type="submit" value="Subscribe" />
                                </form>
                            </div> */}
                        </div>
                        <div className="row justify-content-center">
                            {mainSite !== undefined ?
                                <>{mainSite.footer_about !== undefined && mainSite.footer_about !== '' && mainSite.footer_about_desc !== undefined && mainSite.footer_about_desc !== '' ?
                                    <div className="col-lg-4 col-md-6 footer-links">
                                        <span className="d-block sub-title-alt">{mainSite.footer_about}</span>
                                        <p className="description" dangerouslySetInnerHTML={{ __html: `${acharya_maharaj_name} ${mainSite.footer_about_desc} ${(mainSite.is_main === 'yes') ? ''/* '<br/><br/><b>Trust Name:</b> Maninagar Shree Swaminarayan Gadi Sansthan Shreeji Sankalpa Murti A Trust' */ : ''}` }}></p>
                                        {/* {(mainSite.is_main === 'yes') ? <Link className='btn btn-7' to={`${process.env.PUBLIC_URL}donate`}>Donate Us</Link> : ''} */}
                                        {/* {(mainSite.is_main === 'yes') ? <div className='d-flex'><Link to={`${process.env.PUBLIC_URL}donate`} className="donate-search-box d-flex flex-row gap-1 justify-content-center align-items-center">

                                            {/* <svg height="24" viewBox="0 0 24 24" width="24" xmlns="http://www.w3.org/2000/svg"><path d="M17.726 13.02 14 16H9v-1h4.065a.5.5 0 0 0 .416-.777l-.888-1.332A1.995 1.995 0 0 0 10.93 12H3a1 1 0 0 0-1 1v6a2 2 0 0 0 2 2h9.639a3 3 0 0 0 2.258-1.024L22 13l-1.452-.484a2.998 2.998 0 0 0-2.822.504zm1.532-5.63c.451-.465.73-1.108.73-1.818s-.279-1.353-.73-1.818A2.447 2.447 0 0 0 17.494 3S16.25 2.997 15 4.286C13.75 2.997 12.506 3 12.506 3a2.45 2.45 0 0 0-1.764.753c-.451.466-.73 1.108-.73 1.818s.279 1.354.73 1.818L15 12l4.258-4.61z"/></svg> * Donate{/*  <svg height="14" viewBox="0 0 24 24" width="14" xmlns="http://www.w3.org/2000/svg"><path d="M20.205 4.791a5.938 5.938 0 0 0-4.209-1.754A5.906 5.906 0 0 0 12 4.595a5.904 5.904 0 0 0-3.996-1.558 5.942 5.942 0 0 0-4.213 1.758c-2.353 2.363-2.352 6.059.002 8.412L12 21.414l8.207-8.207c2.354-2.353 2.355-6.049-.002-8.416z" /></svg> Us *</Link></div> : ''} */}
                                    </div> :
                                    <div className="col-lg-4 col-md-6 footer-links">
                                        <span className="d-block sub-title-alt">{mainSite.footer_about}</span>
                                        <p className="description" dangerouslySetInnerHTML={{ __html: `${acharya_maharaj_name} ${mainSite.footer_about_desc} ${(mainSite.is_main === 'yes') ? '<br/><br/><b>Trust Name:</b> Maninagar Shree Swaminarayan Gadi Sansthan Shreeji Sankalpa Murti A Trust' : ''}` }}></p>
                                    </div>
                                }</>
                                : ''}
                            {mainSite !== undefined && mainSite.footer_logo !== undefined && mainSite.footer_logo !== '' ?
                                <div className="col-lg-4 col-md-6 col-sm-6 col-12 footer-links text-center d-none d-lg-block">
                                    <Link to={`${process.env.PUBLIC_URL}`}><img src={mainSite.footer_logo} className="img-fluid" alt={mainSite.footer_logo_alt} title={mainSite.footer_logo_alt} width={100} /></Link>
                                    <span className="description d-block text-center mt-2 text-capitalize" dangerouslySetInnerHTML={{ __html: mainSite.footer_logo_desc }}></span>
                                </div> : <div className="col-lg-4 col-md-6 col-sm-6 col-12 footer-links text-center d-none d-lg-block">
                                    <Link to={`${process.env.PUBLIC_URL}`}><img src={process.env.PUBLIC_URL + '/img/favicon.png'} className="img-fluid" alt="logo" title="logo" width={100} /></Link>
                                    <span className="description d-block text-center mt-2 text-capitalize">Maninagar<br /><span className="fw-bold">Shree Swaminarayan Gadi</span><br />Sansthan</span>
                                </div>}
                            {(mainSite !== undefined && mainSite.footer_contact_location !== undefined && mainSite.footer_contact_location !== '') || (mainSite !== undefined && mainSite.footer_contact_number !== undefined && mainSite.footer_contact_number !== '') || (mainSite !== undefined && mainSite.footer_contact_number2 !== undefined && mainSite.footer_contact_number2 !== '') || (mainSite !== undefined && mainSite.footer_contact_email !== undefined && mainSite.footer_contact_email !== '') ?
                                <div className="col-lg-4 col-md-6 footer-contact-us">{/* {col-sm-6 col-12} */}
                                    <span className="d-block sub-title-alt">Contact us</span>
                                    {mainSite.footer_contact_location !== undefined && mainSite.footer_contact_location !== '' ?
                                        <p className="description">
                                            <img src={process.env.PUBLIC_URL + '/img/Location.svg'} className="img-fluid footer-contact-icon" alt="icon" title="icon" />
                                            <span dangerouslySetInnerHTML={{ __html: mainSite.footer_contact_location }}></span>
                                        </p> : ''}
                                    {(mainSite.footer_contact_number !== undefined && mainSite.footer_contact_number !== '') || (mainSite.footer_contact_number2 !== undefined && mainSite.footer_contact_number2 !== '') ?
                                        <p className="description">
                                            <img src={process.env.PUBLIC_URL + '/img/Phone.svg'} className="img-fluid footer-contact-icon" alt="icon" title="icon" />
                                            <a title={mainSite.footer_contact_number} href={`tel:${mainSite.footer_contact_number}`}>{mainSite.footer_contact_number}</a> {mainSite.footer_contact_number2 !== '' && mainSite.footer_contact_number2 !== undefined ? <>| <a title={mainSite.footer_contact_number2} href={`tel:${mainSite.footer_contact_number2}`}>{mainSite.footer_contact_number2}</a></> : ''}
                                        </p> : ''}
                                    {(mainSite.footer_contact_email !== undefined && mainSite.footer_contact_email !== '') ?
                                        <p className="description">
                                            <img src={process.env.PUBLIC_URL + '/img/Email.svg'} className="img-fluid footer-contact-icon" alt="icon" title="icon" />
                                            <a title={mainSite.footer_contact_email} href={`mailto:${mainSite.footer_contact_email}`}>{mainSite.footer_contact_email}</a>
                                        </p> : ''}
                                </div> : ''}
                        </div>
                    </div>
                </div>

                <div className="footer-bottom">
                    <div className="container d-flex justify-content-between flex-column flex-md-row">
                        <span>{`Copyright © ${moment().format('YYYY')} Maninagar Shree Swaminarayan Gadi Sansthan`} {mainSite?.footer_tc_link !== '' && mainSite?.footer_tc_link !== undefined && mainSite?.footer_tc_link !== 'undefined' ? <>| <Link to={`${process.env.PUBLIC_URL}/${mainSite?.footer_tc_link}`} title={mainSite.footer_tc_title}>{mainSite.footer_tc_title}</Link></> : ''}</span>
                        <span className='d-flex flex-row follow-us justify-content-center mt-2 mt-md-0'>
                            <span>Follow us</span>
                            {mainSite?.footer_contact_twitter !== '' && mainSite?.footer_contact_twitter !== undefined && mainSite?.footer_contact_twitter !== 'undefined' ?
                                <a title='Twitter' href={`https://twitter.com/${mainSite?.footer_contact_twitter}`} target={`_blank`} className="footer-social twitter"><svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 512 512" fill='#fff'><path d="M389.2 48h70.6L305.6 224.2 487 464H345L233.7 318.6 106.5 464H35.8L200.7 275.5 26.8 48H172.4L272.9 180.9 389.2 48zM364.4 421.8h39.1L151.1 88h-42L364.4 421.8z" /></svg>{/* <i className="bx bxl-twitter"></i> */}</a>
                                : ''}
                            {mainSite?.footer_contact_facebook !== '' && mainSite?.footer_contact_facebook !== undefined && mainSite?.footer_contact_facebook !== 'undefined' ?
                                <a title='facebook' href={`https://www.facebook.com/${mainSite?.footer_contact_facebook}`} target={`_blank`} className="footer-social facebook"><i className="bx bxl-facebook"></i></a>
                                : ''}
                            {mainSite?.footer_contact_instagram !== '' && mainSite?.footer_contact_instagram !== undefined && mainSite?.footer_contact_instagram !== 'undefined' ?
                                <a title='instagram' href={`https://www.instagram.com/${mainSite?.footer_contact_instagram}`} target={`_blank`} className="footer-social instagram"><i className="bx bxl-instagram"></i></a>
                                : ''}
                            {mainSite?.footer_contact_youtube !== '' && mainSite?.footer_contact_youtube !== undefined && mainSite?.footer_contact_youtube !== 'undefined' ?
                                <a title='youtube' href={`https://www.youtube.com/c/${mainSite?.footer_contact_youtube}`} target={`_blank`} className="footer-social google-plus"><i className="bx bxl-youtube"></i></a>
                                : ''}
                            {mainSite?.footer_contact_telegram !== '' && mainSite?.footer_contact_telegram !== undefined && mainSite?.footer_contact_telegram !== 'undefined' ?
                                <a title='telegram' href={`https://t.me/${mainSite?.footer_contact_telegram}`} target={`_blank`} className="footer-social linkedin"><i className="bx bxl-telegram"></i></a>
                                : ''}
                            {mainSite?.footer_contact_tiktok !== '' && mainSite?.footer_contact_tiktok !== undefined && mainSite?.footer_contact_tiktok !== 'undefined' ?
                                <a title='telegram' href={`https://www.tiktok.com/@${mainSite?.footer_contact_tiktok}`} target={`_blank`} className="footer-social tiktok"><svg xmlns="http://www.w3.org/2000/svg"  height="1em" viewBox="0 0 24 24"><path fill="#fff" d="M16.6 5.82s.51.5 0 0A4.28 4.28 0 0 1 15.54 3h-3.09v12.4a2.59 2.59 0 0 1-2.59 2.5c-1.42 0-2.6-1.16-2.6-2.6c0-1.72 1.66-3.01 3.37-2.48V9.66c-3.45-.46-6.47 2.22-6.47 5.64c0 3.33 2.76 5.7 5.69 5.7c3.14 0 5.69-2.55 5.69-5.7V9.01a7.35 7.35 0 0 0 4.3 1.38V7.3s-1.88.09-3.24-1.48"/></svg></a>
                                : ''}
                        </span>
                    </div>
                </div>
                {/* <div className="footer-bottom" style={{padding:'0px 0 15px',fontSize:'13px'}}>
                <div className="container d-block text-left">
                Maninagar Shree Swaminarayan Gadi Sansthan Shreeji Sankalpa Murti A Trust
                </div>
                </div> */}
            </footer>
            <a href="#roots" className="back-to-top"><i className="icofont-simple-up"></i></a>
        </>
    )
}

export default Footer