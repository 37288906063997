import React, { useEffect } from 'react'
import { useLocation } from 'react-router'
import { TransitionGroup, CSSTransition } from "react-transition-group"

const Content: React.FC<{ children: React.ReactNode }> = ({ children }: any) => {
    const location = useLocation()
    useEffect(() => {
        window.scrollTo(0, 0);
    }, [location]);
    const currentKey = location.pathname.split("/")[1] || "/";
    const timeout = { enter: 300, exit: 200 };
    return (
        <>
            <TransitionGroup component="main" className="page-main">
                <CSSTransition
                    key={currentKey}
                    timeout={timeout}
                    classNames="fade"
                    appear
                >
                    <div className='page-main-2'>{children}</div>
                </CSSTransition>
            </TransitionGroup>
        </>
    )
}
export { Content }
