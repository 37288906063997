
import { createSlice } from "@reduxjs/toolkit";

export const counterSlice = createSlice({
  name: "counter",
  initialState: {
    playing: false,
    duration: 0,
    seekTime: 0,
    appTime: 0,
    volume: 0.5,
    show: true,
    isRepeat: false,
    TrackLoaded: false,
    PlayingStatus: false,
    counting: 0
  },
  reducers: {
    setPlayer: (state: { [x: string]: any; }, action: { payload: { [x: string]: any; }; }) => {
      Object.keys(action.payload).forEach((key) => {
        state[key] = action.payload[key];
      });
    },
  },
  extraReducers: {},
});

export const { setPlayer } = counterSlice.actions;

export default counterSlice.reducer;
