import React, { useContext, useEffect, useState } from "react"
import axios from 'axios'
import Banner from './sections/Banner'
import GlobalContext from './Core/PageData'
import { SectionTitle, PageLoader, MetaData } from "./sections/CommonSections"
import { Link } from "react-router-dom"
// @ts-ignore
import ScrollAnimation from 'react-animate-on-scroll'

const Publication: React.FC = () => {
    const { GlobalContent, setGlobalContent } = useContext(GlobalContext as any) as any
    document.title = `Publication${GlobalContent.pageTitle}`;
    var sites = GlobalContent.AllSiteData.filter((e: any) => e.is_main === 'yes')
    /* useEffect(() => {
        setGlobalContent({ ...GlobalContent, IsMediaPlayershow: false })
    }, []); */
    const [publicationData, setpublicationData] = useState({ data: [] as any[] });
    useEffect(() => {
        axios.get(`${process.env.REACT_APP_API_URL}publication/type/all/`).then(json => {
            json.data.data = json.data.data.filter((e:any) => e.status === 'active')
            setpublicationData({ data: json.data.data })
            return json.data.data
        }).then((publicationData1) => {
            setGlobalContent({ ...GlobalContent, siteData: sites[0], headerClass:'' })
            publicationData1.forEach(async (el: any, i: any) => {
                let PubName = el.name.toLowerCase()
                if (PubName === 'books') {
                    PubName = 'book'
                } else if (PubName === 'shree ghanshyam vijay') {
                    PubName = 'gvijay'
                }
                publicationData1[i]['icon'] = `${PubName}.svg`
                await axios.get(`${process.env.REACT_APP_API_URL}publication/${PubName}-category/${(PubName === 'gvijay') ? 'filter?recordPerPage=8&sortField=name&sortType=desc' : 'all/'}`).then(json => {
                    if (PubName === 'gvijay') {
                        publicationData1[i]['data'] = json.data.data.data
                    } else {
                        publicationData1[i]['data'] = json.data.data
                    }
                })
                setpublicationData({ data: publicationData1 })
            });
        });
    }, []);
    return (
        <>
            {publicationData.data && publicationData.data.length > 0 ? <>
                {/* <Banner BannerImage="Shreejibapa_1920.png" /> */}
                <MetaData keywords={`Publications, maninagar, swaminarayan, gadi, sansthan`} description={`Publications from Maninagar Shree Swaminarayan Gadi Sansthan`} />
                <section className="publication-contain">
                    <div className="Flower-top d-none d-md-block">
                        <img src={process.env.PUBLIC_URL + '/img/Flower-top.png'} className="img-fluid lazyload" alt="Flower" title="Flower" />
                    </div>
                    <div className="container">
                        <div className="row">
                            <SectionTitle title="Publications" />

                            <div className="col-lg-12 pt-0 pt-lg-0 d-flex flex-column justify-content-center publications" >
                                {publicationData.data && publicationData.data.length > 0 && publicationData.data.map((data: any, i: any) =>
                                (
                                    <>
                                        {(data.data && data.data.length > 0) ?
                                            <ScrollAnimation className="d-flex flex-column publication" animateIn="fadeIn" animateOnce={true} delay={100} key={`${i}-publication`}>
                                                <div className="d-flex publication-inner flex-column flex-md-column flex-lg-row" key={`${i}-publication-row`}>
                                                    {/* <div className="publication-icon"><img className="img-fluid" src={`${process.env.PUBLIC_URL}/icons/${data.icon}`} alt="icon" title="icon" /></div> */}
                                                    <div className="col-12 col-sm-12 col-md-12 col-lg-4 col-xl-3 publication-title d-flex flex-column justify-content-center text-center" key={`${i}-publication-title`}>
                                                        <div className="publication-icon" key={`${i}-publication-icon`}><img className="img-fluid" src={`${process.env.PUBLIC_URL}/icons/${data.icon}`} alt="icon" title="icon" key={`${i}-publication-icon-image`} /></div>
                                                        <span key={`${i}-publication-name`}>
                                                            <Link title={data.name} className="publication-inner-link" to={`${process.env.PUBLIC_URL}/${process.env.REACT_APP_PUBLICATION_SLUG}${data.slug}`}>{data.name}</Link>
                                                        </span>
                                                    </div>
                                                    <div className="col-12 col-sm-12 col-md-12 col-lg-8 col-xl-9 publication-data d-flex flex-column justify-content-center" key={`${i}-publication-data`}>
                                                        <div className="d-flex publication-data-inner" key={`${i}-publication-data-inner`}>

                                                            {data.data && data.data.length > 0 && data.data.map((buttons: any, buttonsi: any) =>
                                                            (
                                                                <>
                                                                    <Link to={`${process.env.PUBLIC_URL}/${process.env.REACT_APP_PUBLICATION_SLUG}${data.slug}/${buttons.slug}`} className="btn btn-2" key={`${i}-${buttonsi}-publication-data-btn`} title={buttons.name}>{buttons.name}</Link>
                                                                </>
                                                            ))}
                                                            {(data.name === 'Shree Ghanshyam Vijay') ? <Link to={`${process.env.PUBLIC_URL}/${process.env.REACT_APP_PUBLICATION_SLUG}${data.slug}/${data.data[0].slug}`} className="btn btn-2" key={`${i}-${`more`}-publication-data-btn`} title="more">More...</Link> : ''}
                                                        </div>
                                                    </div>
                                                </div>
                                            </ScrollAnimation>
                                            : ''}
                                    </>
                                )
                                )}
                            </div>
                        </div>
                    </div>
                </section>
            </> : (<PageLoader />)}
        </>
    )
}

export default Publication