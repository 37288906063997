import React, { useContext, useEffect, useState } from "react";
import GlobalContext from './Core/PageData'
import { Link, useNavigate } from "react-router-dom";
import ScrollAnimation from "react-animate-on-scroll";
import { SectionTitle } from "./sections/CommonSections";
import CacheManager from "../service/CacheManager";
const DonationThankyou: React.FC<any> = ({ site = '', type = "thankyou" }: any) => {
    const { GlobalContent, setGlobalContent } = useContext(GlobalContext as any) as any
    document.title = (type === 'thankyou') ? `Thank You For Donation ${GlobalContent.pageTitle}` : `Donation Cancelled ${GlobalContent.pageTitle}`;
    const [session, setsession] = useState(CacheManager.get('donationD')) as any
    var sites = GlobalContent.AllSiteData.filter((e: any) => (site === '') ? e.is_main === 'yes' : e.name.includes('Kingsbury'))
    useEffect(() => {
        setGlobalContent({ ...GlobalContent, siteData: sites[0], headerClass: '' })
    }, [])

    return (
        <div className="site-contain">
            <ScrollAnimation className="mform-section pt-5 pb-5 container" animateIn="fadeIn" animateOnce={true} delay={400}>
                {type === 'thankyou' ? <>{site === 'Kingsbury' ? <div className="notfoundrow mb-100 d-flex justify-content-center align-items-center">
                    <div className="justify-content-center align-items-center">
                        <h1 className="active-color font-family-quicksand-title" title={`Thank You!`}>{`Thank You!`}</h1>
                        <p className="description-2">Your donation payment has been received successfully.</p>
                        <p className="description-2">Amount donated: <b>£ {Number(session?.amount).toLocaleString('en-IN')}</b><br />Receipt No.: <b>{session?.ReceiptId}</b></p>
                        <p className="description-2">You will receive a receipt by email to <a href={`mailto:${session?.email}`} title="email">{session?.email}</a>.</p>
                        <p className="description-2">If you have any queries regarding this donation please send an email to <a title="London@SwaminarayanGadi.com" href="mailto:London@SwaminarayanGadi.com">London@SwaminarayanGadi.com</a></p>
                        <p className="description-2">Jay Shree Swaminarayan</p>
                        {/* <p className="description-2">We're sorry, the page you requested cannot be found. <br />You can go back to</p> */}
                        <Link to={`${process.env.PUBLIC_URL}${(site !== '') ? "/" + sites[0].site_url : ''}/`} className="btn btn-primary" title="Homepage">Homepage</Link>
                    </div>
                </div> :
                    <div className="notfoundrow mb-100 d-flex justify-content-center align-items-center" style={{ height: '55vh' }}>
                        <div className="justify-content-center align-items-center">
                            <h3 className="dark-active-color font-family-quicksand-title" title={`Jay Shree Swaminarayan`}>{`Jay Shree Swaminarayan`}<br/>Thank you {session?.name}</h3>
                            <p className="description-2">Your donation payment has been received successfully.</p>
                            <p className="description-2">You will receive a receipt by email soon.</p>
                            <p className="description-2">If you have any queries regarding this donation please send an email to <a title="Maninagar@SwaminarayanGadi.com" href="mailto:Maninagar@SwaminarayanGadi.com">Maninagar@SwaminarayanGadi.com</a></p>
                            <p className="description-2">Thank you!</p>
                            {/* <p className="description">We're sorry, the page you requested cannot be found. <br />You can go back to</p> */}
                            <Link to={`${process.env.PUBLIC_URL}${(site !== '') ? "/" + sites[0].site_url : ''}/`} className="btn btn-primary" title="Homepage">Homepage</Link>
                        </div>
                    </div>}</> :

                    <>{site === 'Kingsbury' ? <div className="notfoundrow mb-100 d-flex justify-content-center align-items-center">
                        <div className="justify-content-center align-items-center">
                            <h1 className="active-color font-family-quicksand-title" title={`Donation Payment Unsuccessful`}>{`Donation Payment Unsuccessful`}</h1>
                            <p className="description-2">Your donation payment was cancelled during the payment process.</p>
                            <p className="description-2">If you think this was cancelled in error, you can make a new donation from the <Link to={`${process.env.PUBLIC_URL}${(site !== '') ? "/" + sites[0].site_url : ''}/donations`} title="Donations page">donations page</Link>.</p>
                            <p className="description-2">Jay Shree Swaminarayan</p>
                            {/* <Link to={`${process.env.PUBLIC_URL}${(site !== '') ? "/" + sites[0].site_url : ''}/`} className="btn btn-primary">Homepage</Link> */}
                        </div>
                    </div> : <div className="notfoundrow mb-100 d-flex justify-content-center align-items-center" style={{ height: '55vh' }}>
                        <div className="justify-content-center align-items-center">
                            <h3 className="dark-active-color font-family-quicksand-title" title={`Jay Shree Swaminarayan`}>{`Jay Shree Swaminarayan`}</h3>
                            <p className="description-2">Your donation payment was cancelled during the payment process.</p>
                            <p className="description-2">If you think this was cancelled in error, you can make a new donation from the <Link to={`${process.env.PUBLIC_URL}${(site !== '') ? "/" + sites[0].site_url : ''}/donations`} title="Donations page">donations page</Link>.</p>
                            <p className="description-2">Thank You!</p>
                            {/* <Link to={`${process.env.PUBLIC_URL}${(site !== '') ? "/" + sites[0].site_url : ''}/`} className="btn btn-primary">Homepage</Link> */}
                        </div>
                    </div>}</>
                }
            </ScrollAnimation>
        </div>
    )
}

export default DonationThankyou