import axios from "axios";
import React, { useContext, useEffect, useState, } from "react"
import GlobalContext from '../Core/PageData'
// @ts-ignore
import ScrollAnimation from 'react-animate-on-scroll'
import { monthNames, SectionTitle, DayNamesShort, LimitDesc, GetGujAndEngDateSameDate } from "./CommonSections";
import moment from "moment";
import { Link } from "react-router-dom";
const Calender = ({ siteAccess = '' }: any) => {
    const { GlobalContent, setGlobalContent } = useContext(GlobalContext as any) as any
    const [EventCategory, setEventCategory] = useState({ data: [] as any[] });
    const [EventCalender, setEventCalender] = useState({ data: [] as any[] });
    const currentDate = moment().format('YYYY-MM-DD')
    const EndDate = moment().add(9, 'months').format('YYYY-MM-DD')
    var CurrentDateTimeStamp = parseInt(moment(`${moment().format('YYYY-MM-DD')}T00:00:00`).format('x'))
    var SiteSlug = ''
    var site = GlobalContent.AllSiteData.filter((e: any) => e._id === siteAccess)
    if (site.length > 0) {
        SiteSlug = '/' + site[0].site_url
    }
    if (siteAccess !== '') {
        siteAccess = siteAccess + '/'
    }

    useEffect(() => {
        axios.get(`${process.env.REACT_APP_API_URL}event/category/all`).then(async json => {
            const PromiseCalender = [] as any
            json.data.data = json.data.data.filter((e: any) => e.status === 'active')
            json.data.data = json.data.data.filter((e: any) => e.name === 'Mahotsav')
            await json.data.data.forEach(async (element: any, i: any) => {
                json.data.data[i]['events'] = []
                const PP = axios.get(`${process.env.REACT_APP_API_URL}calendar/event-category/${siteAccess.trim()}${element._id}/${currentDate}/${EndDate}`).then(Eventjson => {

                    let promises = [] as any;
                    Eventjson.data.data.event.forEach((eventD: any, j: any) => {
                        let date1 = moment(eventD.startDate.split('T')[0]) as any
                        let date2 = moment(eventD.endDate.split('T')[0]) as any
                        var Difference_In_Time = date2.format('x') - date1.format('x');
                        var Difference_In_Days = Difference_In_Time / (1000 * 3600 * 24);
                        if (!Number.isNaN(Difference_In_Days)) {
                            for (var i = 0; i <= Difference_In_Days; i++) {
                                if (eventD.days_cnt[i] !== undefined) {
                                    let startDate: any
                                    if (i === 0) {
                                        startDate = date1
                                    } else {
                                        startDate = date1.add(1, 'days')
                                    }
                                    let CalendarData = Eventjson.data.data.calender.find((ell: { englishDate: string; }) => ell.englishDate === startDate.format('YYYY-MM-DD')) as any

                                    let ___ = Eventjson.data.data.calender.filter((ell: { englishDate: string; }) => ell.englishDate === startDate.format('YYYY-MM-DD'))
                                    let Tithi = GetGujAndEngDateSameDate(___)
                                    CalendarData['indianDate'] = Tithi.english

                                    promises.push({
                                        title: `${eventD.title.trim()}${(eventD.days_cnt.length > 1) ? ` - Day ${i + 1}` : ''}`,
                                        start: startDate.format('YYYY-MM-DD'),
                                        timestamp: parseInt(startDate.format('x')),
                                        classNames: ['yellow'],
                                        url: '/publication',
                                        link: '/publication',
                                        description: eventD.days_cnt[i].desc,
                                        CalendarData: CalendarData,
                                        categoryId: eventD.category,
                                        Dates: {
                                            month: startDate.format("MMM"),
                                            date: startDate.format("DD"),
                                            year: startDate.format("YYYY"),
                                            day: startDate.format("ddd")
                                        }
                                    });
                                }
                                /* } */
                            }
                        }
                    })

                    Eventjson.data.data['yearly-event'].forEach((eventY: any, j: any) => {
                        var eventYear = []
                        if (eventY.calendarType === 'gujarati') {
                            var GujDate = `${eventY.calendarMonth} ${eventY.calendarDay}`
                            eventYear = Eventjson.data.data.calender.filter((e: any) => GujDate === `${e.gujaratiIndianMonth} ${e.gujaratiIndianPeriod} ${e.gujaratiIndianDate}`)
                        } else if (eventY.calendarType === 'english') {
                            var EngDate = `${moment().format('YYYY')}-${eventY.calendarMonth}-${eventY.calendarDay}`
                            eventYear = Eventjson.data.data.calender.filter((e: any) => EngDate === `${e.englishDate}`)
                        }

                        let Tithi = GetGujAndEngDateSameDate(eventYear)
                        eventYear[0]['indianDate'] = Tithi.english

                        if (eventYear.length > 0) {
                            promises.push({
                                title: `${eventY.title.trim()}`,
                                start: moment(eventYear[0].englishDate).format('YYYY-MM-DD'),
                                timestamp: parseInt(moment(eventYear[0].englishDate).format('x')),
                                classNames: ['yellow'],
                                url: '/publication',
                                link: '/publication',
                                description: eventY.desc,
                                CalendarData: eventYear[0],
                                categoryId: eventY.category,
                                Dates: {
                                    month: moment(eventYear[0].englishDate).format("MMM"),
                                    date: moment(eventYear[0].englishDate).format("DD"),
                                    year: moment(eventYear[0].englishDate).format("YYYY"),
                                    day: moment(eventYear[0].englishDate).format("ddd")
                                }
                            });
                        }
                    })

                    promises = promises.filter((e: any) => CurrentDateTimeStamp <= e.timestamp)
                    promises.sort((a: any, b: any) => (a.timestamp >= b.timestamp) ? 1 : -1)

                    json.data.data[i]['events'] = promises
                    return Eventjson.data.data.event
                })
                PromiseCalender.push(PP)
            });
            Promise.all(PromiseCalender).then(async dsa => {
                /* json.data.data.sort((a: any, b: any) => (a.timestamp > b.timestamp) ? 1 : -1) */
                setEventCategory({ data: json.data.data })
            })
            return json.data.data
        }).then(Result => {
            axios.get(`${process.env.REACT_APP_API_URL}calendar/event/${siteAccess.trim()}${currentDate}/${EndDate}`).then(Eventjson => {
                const promises = [] as any;
                Eventjson.data.data.event.forEach((eventD: any, j: any) => {
                    let date1 = moment(eventD.startDate.split('T')[0]) as any
                    let date2 = moment(eventD.endDate.split('T')[0]) as any
                    var Difference_In_Time = date2.format('x') - date1.format('x');
                    var Difference_In_Days = Difference_In_Time / (1000 * 3600 * 24);

                    if (!Number.isNaN(Difference_In_Days)) {
                        for (var i = 0; i <= Difference_In_Days; i++) {
                            if (eventD.days_cnt[i] !== undefined) {
                                let startDate: any
                                if (i === 0) {
                                    startDate = date1
                                } else {
                                    startDate = date1.add(1, 'days')
                                }
                                let CalendarData = Eventjson.data.data.calender.find((ell: { englishDate: string; }) => ell.englishDate === startDate.format('YYYY-MM-DD')) as any

                                let ___ = Eventjson.data.data.calender.filter((ell: { englishDate: string; }) => ell.englishDate === startDate.format('YYYY-MM-DD'))
                                let Tithi = GetGujAndEngDateSameDate(___)
                                CalendarData['indianDate'] = Tithi.english

                                promises.push({
                                    title: `${eventD.title.trim()}${(eventD.days_cnt.length > 1) ? ` - Day ${i + 1}` : ''}`,
                                    start: startDate.format('YYYY-MM-DD'),
                                    timestamp: parseInt(startDate.format('x')),
                                    classNames: ['yellow'],
                                    url: '/publication',
                                    link: '/publication',
                                    description: eventD.days_cnt[i].desc,
                                    CalendarData: CalendarData,
                                    categoryId: eventD.category,
                                    Dates: {
                                        month: startDate.format("MMM"),
                                        date: startDate.format("DD"),
                                        year: startDate.format("YYYY"),
                                        day: startDate.format("ddd")
                                    }
                                });
                            }
                            /* } */
                        }
                    }
                })

                Eventjson.data.data['yearly-event'].forEach((eventY: any, j: any) => {
                    var eventYear = []
                    if (eventY.calendarType === 'gujarati') {
                        var GujDate = `${eventY.calendarMonth} ${eventY.calendarDay}`
                        eventYear = Eventjson.data.data.calender.filter((e: any) => GujDate === `${e.gujaratiIndianMonth} ${e.gujaratiIndianPeriod} ${e.gujaratiIndianDate}`)
                    } else if (eventY.calendarType === 'english') {
                        var EngDate = `${moment().format('YYYY')}-${eventY.calendarMonth}-${eventY.calendarDay}`
                        eventYear = Eventjson.data.data.calender.filter((e: any) => EngDate === `${e.englishDate}`)
                    }

                    let Tithi = GetGujAndEngDateSameDate(eventYear)
                    eventYear[0]['indianDate'] = Tithi.english

                    if (eventYear.length > 0) {
                        promises.push({
                            title: `${eventY.title.trim()}`,
                            start: moment(eventYear[0].englishDate).format('YYYY-MM-DD'),
                            timestamp: parseInt(moment(eventYear[0].englishDate).format('x')),
                            classNames: ['yellow'],
                            url: '/publication',
                            link: '/publication',
                            description: eventY.desc,
                            CalendarData: eventYear[0],
                            categoryId: eventY.category,
                            Dates: {
                                month: moment(eventYear[0].englishDate).format("MMM"),
                                date: moment(eventYear[0].englishDate).format("DD"),
                                year: moment(eventYear[0].englishDate).format("YYYY"),
                                day: moment(eventYear[0].englishDate).format("ddd")
                            }
                        });
                    }
                })

                Promise.all(promises).then(event => {
                    event = event.filter((e: any) => CurrentDateTimeStamp <= e.timestamp)
                    event.sort((a: any, b: any) => (a.timestamp >= b.timestamp) ? 1 : -1)
                    setEventCalender({ data: event })
                })
            })
        })
    }, [])
    return (
        <>
            {EventCalender.data.length > 0 ?
                <section className="calender pt-4">
                    <div className="container " >
                        <div className="row">
                            <SectionTitle title={`Upcoming`} />
                            <div className="col-lg-12 pt-3 d-flex flex-column justify-content-center" data-aos="fade-up" data-aos-delay="100">
                                <div className="calender-nav">
                                    <ul className="nav nav-tabs justify-content-center" role="tablist">
                                        <li className="nav-item">
                                            <a className="nav-link active" title="Events" data-toggle="tab" href="#calender-upcoming" role="tab">Events</a>
                                        </li>
                                        {EventCategory.data.map((Category: any, i: any) => {
                                            return (
                                                (Category.events.length > 0) ? <li className="nav-item" key={`${i}-eventCategory`}>
                                                    <a className="nav-link" data-toggle="tab" href={`#calender-${Category.slug}`} role="tab" title={Category.name}>{Category.name}</a>
                                                </li> : ''
                                            )
                                        })}
                                    </ul>
                                </div>
                                <div className="tab-content calender-tab-content text-center ">
                                    <div className="tab-pane active" id="calender-upcoming" role="tabpanel">
                                        <div className="row">
                                            {/* {JSON.stringify(EventCalender)} */}
                                            {EventCalender.data.map((data: any, i: any) => {
                                                return (
                                                    (i < 6) ? <div className="col-lg-4 col-sm-6" title={data.title} key={`${i}-EventCalender`}>
                                                        <ScrollAnimation className="calender-box d-flex justify-content-center pt-4" animateIn="fadeIn" offset={0} animateOnce={true} delay={100} /* initiallyVisible={true} */ >
                                                            <div className="calender-dates col-4">
                                                                <span className="calender-dates-1 d-block">{data.Dates.year}</span>
                                                                <span className="calender-dates-2 d-block">{data.Dates.month}</span>
                                                                <span className="calender-dates-3 d-block">{data.Dates.day} {data.Dates.date}</span>
                                                            </div>
                                                            <div className="col-8 justify-content-center d-flex flex-column flex-wrap">
                                                                <div className="calender-text d-flex flex-column justify-content-between">
                                                                    {(data.CalendarData !== undefined) ? <span className="calender-tithi d-block">Samvat {data.CalendarData.indianYear} {data.CalendarData.indianDate}</span> : ''}
                                                                    <span className="calender-desc d-block"><LimitDesc desc={data.title} limit="-1" /></span>
                                                                    {/* <a className="calender-icon" href="#roots">
                                                                        <img src={process.env.PUBLIC_URL + '/img/calender_icon.svg'} className="img-fluid lazyload" alt="calender_icon" title="Calendar" />
                                                                    </a> */}
                                                                </div>
                                                            </div>
                                                        </ScrollAnimation>
                                                    </div> : ''
                                                )
                                            })}
                                        </div>
                                        <ScrollAnimation className="col-lg-12 text-center justify-content-center d-flex" animateIn="fadeIn" animateOnce={true} delay={100} >
                                            <Link className="btn btn-primary mt-5" to={`${process.env.PUBLIC_URL}${SiteSlug}/calendar`} title="View Calendar">View Calendar</Link>
                                        </ScrollAnimation>
                                    </div>
                                    {EventCategory.data.map((Category: any, i: any) => {
                                        return (
                                            (Category.events.length > 0) ?
                                                <div className="tab-pane" id={`calender-${Category.slug}`} role="tabpanel" key={`${i}-EventCategory3`}>
                                                    <div className="row">
                                                        {Category.events.map((data: any, j: any) => {
                                                            return (
                                                                (j < 6) ? <div className="col-lg-4 col-sm-6" title={data.title} key={`${j}-EventCategory2`}>
                                                                    <ScrollAnimation className="calender-box d-flex justify-content-center pt-4" animateIn="fadeIn" animateOnce={true} delay={100} offset={0} initiallyVisible={true} >
                                                                        <div className="calender-dates col-4">
                                                                            <span className="calender-dates-1 d-block">{data.Dates.year}</span>
                                                                            <span className="calender-dates-2 d-block">{data.Dates.month}</span>
                                                                            <span className="calender-dates-3 d-block">{data.Dates.day} {data.Dates.date}</span>
                                                                        </div>
                                                                        <div className="col-8 justify-content-center d-flex flex-column flex-wrap">
                                                                            <div className="calender-text d-flex flex-column justify-content-between">
                                                                                {(data.CalendarData !== undefined) ? <span className="calender-tithi d-block">Samvat {data.CalendarData.indianYear} {data.CalendarData.indianDate}</span> : ''}
                                                                                <span className="calender-desc d-block"><LimitDesc desc={data.title} /* limit="30" */ limit="-1" /></span>
                                                                                {/* <a className="calender-icon" href="#roots">
                                                                                    <img src={process.env.PUBLIC_URL + '/img/calender_icon.svg'} className="img-fluid lazyload" alt="calender_icon" title="Calendar" />
                                                                                </a> */}
                                                                            </div>
                                                                        </div>
                                                                    </ScrollAnimation>
                                                                </div> : ''
                                                            )
                                                        })}
                                                    </div>
                                                    <div className="col-lg-12 text-center justify-content-center d-flex" >
                                                        <Link className="btn btn-primary mt-5" to={`${process.env.PUBLIC_URL}/upcoming-mahotsavs`} title="View Details">View Details</Link>
                                                    </div>
                                                </div>
                                                : ''
                                        )
                                    })}
                                </div>
                            </div>
                            {/* <ScrollAnimation className="col-lg-12 text-center justify-content-center d-flex" animateIn="fadeIn" animateOnce={true} delay={100} >
                                <Link className="btn btn-primary mt-5" to={`${process.env.PUBLIC_URL}/calendar`} title="View Calendar">View Calendar</Link>
                            </ScrollAnimation> */}
                        </div>
                    </div>
                </section>
                : ''}
        </>
    )
}

export default Calender