import React, { useContext, useEffect, useState } from "react"
// @ts-ignore
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Link } from "react-router-dom";
import axios from "axios";
import GlobalContext from '../Core/PageData'
import moment from "moment";
import { checkBroadCast, getMyTimeBroadCast } from "./CommonSections";

const options = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 2,
    slidesToScroll: 1,
    draggable: false,
    responsive: [{
        breakpoint: 1400,
        settings: {
            slidesToShow: 2
        }
    }, {
        breakpoint: 1000,
        settings: {
            slidesToShow: 2
        }
    }, {
        breakpoint: 991,
        settings: {
            slidesToShow: 2
        }
    }, {
        breakpoint: 600,
        settings: {
            slidesToShow: 1
        }
    }, {
        breakpoint: 0,
        settings: {
            slidesToShow: 1
        }
    }]
};

const optionsLive = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false
}

const Live_darshan = ({ Data }: any) => {

    const { GlobalContent, setGlobalContent } = useContext(GlobalContext as any) as any
    const [Broadcast, setBroadcast] = useState({ data: [] as any[] })
    const [AllBroadType, setAllBroadType] = useState({ data: [] as any[] })
    const [Loading, setLoading] = useState(false)
    const [MobileDClass, setMobileDClass] = useState('col-lg-12 col-sm-12 col-12 width-fit-content')
    const [StyleDclass, SetStyleDclass] = useState({})
    /* console.log(GlobalContent.MyTimeCode,moment().tz(GlobalContent.MyTimeZone).format('DD MMM YYYY H:mm:ss')) */
    useEffect(() => {
        axios.get(`${process.env.REACT_APP_API_URL}livebroadcast/type/all`).then(json => {
            setAllBroadType({ data: json.data.data })
        }).then(() => {
            axios.get(`${process.env.REACT_APP_API_URL}livebroadcast/event/front?sortField=publishOn&sortType=desc&recordPerPage=100&siteAccess=${GlobalContent?.siteData?._id}`).then(json => {
                json.data.data.data = json.data.data.data.filter((e: any) => e.status === 'active' /* && e.broadcast === 'active' */)
                json.data.data.data = checkBroadCast(json.data.data.data, GlobalContent, json.data.data.date)
                json.data.data.data = json.data.data.data.filter((e: any) => e.status === 'active' /* && e.broadcast === 'active' */)
                json.data.data.data = json.data.data.data.sort((a: any, b: any) => (moment(a.publishOn.split('T')[0] + 'T' + a.startTime).format('X') > moment(b.publishOn.split('T')[0] + 'T' + b.startTime).format('X')) ? 1 : -1)
                json.data.data.data = json.data.data.data.slice(0, 5)
                setBroadcast({ data: json.data.data.data })

                var BroadCastLength = (json.data.data.data.length === 0) ? 0 : 1

                if ((Data.image_json.length + BroadCastLength) > 1) {
                    setMobileDClass('col-lg-3 col-sm-5 col-8')
                }
                if ((Data.image_json.length + BroadCastLength) == 2) {
                    setMobileDClass('col-lg-3 col-sm-6 col-8')
                }
                if (json.data.data.data.length > 0) {
                    if (Data.image_json.length === 1) {
                        options.slidesToShow = 2
                        options.responsive.forEach(async (trackPdf: any, k: any) => {
                            options.responsive[k].settings.slidesToShow = options.slidesToShow
                        })
                    } else if (Data.image_json.length === 2) {
                        options.slidesToShow = 2
                        options.responsive.forEach(async (trackPdf: any, k: any) => {
                            options.responsive[k].settings.slidesToShow = options.slidesToShow
                        })
                    } else if (Data.image_json.length === 3) {
                        options.slidesToShow = 2
                        options.responsive.forEach(async (trackPdf: any, k: any) => {
                            options.responsive[k].settings.slidesToShow = options.slidesToShow
                        })
                    }
                } else {
                    if (Data.image_json.length === 1) {
                        options.slidesToShow = 1
                        options.responsive.forEach(async (trackPdf: any, k: any) => {
                            options.responsive[k].settings.slidesToShow = options.slidesToShow
                        })
                    } else if (Data.image_json.length === 2) {
                        options.slidesToShow = 2
                        options.responsive.forEach(async (trackPdf: any, k: any) => {
                            options.responsive[k].settings.slidesToShow = options.slidesToShow
                        })
                    } else if (Data.image_json.length === 3) {
                        options.slidesToShow = 2
                        options.responsive.forEach(async (trackPdf: any, k: any) => {
                            options.responsive[k].settings.slidesToShow = options.slidesToShow
                        })
                    }
                }
                setLoading(true)
            }).catch(() => {
                if (Data.image_json.length === 1) {
                    options.slidesToShow = 1
                    options.responsive.forEach(async (trackPdf: any, k: any) => {
                        options.responsive[k].settings.slidesToShow = options.slidesToShow
                    })
                } else if (Data.image_json.length === 2) {
                    options.slidesToShow = 2
                    options.responsive.forEach(async (trackPdf: any, k: any) => {
                        options.responsive[k].settings.slidesToShow = options.slidesToShow
                    })
                } else if (Data.image_json.length === 3) {
                    options.slidesToShow = 2
                    options.responsive.forEach(async (trackPdf: any, k: any) => {
                        options.responsive[k].settings.slidesToShow = options.slidesToShow
                    })
                }
                setLoading(true)
            })
        })
    }, [])

    return (
        <>
            {(Data.image_json && Data.image_json.length > 0 && Loading) ?
                <>
                    <section className="live-darshan pb-0 pt-5">
                        <div className="Flower-top d-none d-md-block">
                            <img src={process.env.PUBLIC_URL + '/img/Flower-top.png'} className="img-fluid lazyload" alt="Flower" title="Flower" />
                        </div>
                        <div className="container" style={{ zIndex: 1, position: 'relative' }}>
                            {(window.innerWidth < 992/* 576 */) ?
                                <div className=" row live-darshan-box-container bg-white-shadow-r flex-nowrap overflow-auto">

                                    {Broadcast.data && Broadcast.data.length > 0 ?
                                        <><div className={`${MobileDClass} text-center justify-content-center live-darshan-box d-block`} key={`live-liveDarshan`}>
                                            <Slider className={`w-100`} {...optionsLive} key={`live-liveDarshan`}>
                                                {Broadcast.data.map((data: any, i: any) => {
                                                    var site = GlobalContent.AllSiteData.filter((e: any) => data.siteAccess.split(',').includes(e._id))[0]
                                                    var siteUrl = ''
                                                    if (GlobalContent.AllSiteData.length > 0 && site?.is_main !== 'yes') {
                                                        siteUrl = '/' + site?.site_url
                                                    }
                                                    var typeUrl = AllBroadType.data.filter((e: any) => e._id === data.livePage)[0]?.slug

                                                    const d = moment(data.publishOn.split('T')[0])
                                                    data['day'] = d.format('DD')
                                                    data['month'] = d.format('MMM')
                                                    data['year'] = d.format('YYYY')

                                                    const dT = data.startTime.split(':')
                                                    data['e_hour'] = dT[0]
                                                    data['e_minutes'] = dT[1]
                                                    data['event_times'] = ''
                                                    data['__myTime__'] = ''
                                                    data = getMyTimeBroadCast(data, GlobalContent)

                                                    var __Print__ = <>
                                                        <div className="col-5 col-sm-12 col-md-4 col-lg-12">
                                                            <img src={`${(data.image !== '' && data.image !== undefined) ? data.image : `${process.env.PUBLIC_URL}/img/Live_broadcast.svg`}`} className="img-fluid lazyload" alt={data.s_i_alt} title={data.s_i_alt} />
                                                        </div>
                                                        <div className="col-7 col-sm-12 col-md-8 col-lg-12">
                                                            <span className="d-block title text-start pt-2 pb-3">Live Broadcast</span>
                                                        </div>
                                                        <div className="col-12">
                                                            <span className="d-block sub-title">{data.name}</span>
                                                            <p className="d-block mb-0 description" dangerouslySetInnerHTML={{ __html: `Event Time: ${data.day} ${data.month} ${data.year} ${data.e_hour}:${data.e_minutes} ${data.event_times} ${(data['__myTime__'] !== '') ? '<br/>' + data['__myTime__'] : ''}` }}></p>
                                                            {data.broadcast === 'active' ? <span className="btn btn-red mt-2" title="Live Now">Live Now</span> : <span className="btn disabled btn-grey mt-2" title="Live Now">Live Now</span>}
                                                        </div>
                                                    </>

                                                    return (
                                                        <>
                                                            {data.broadcast === 'active' ?
                                                                <Link to={`${process.env.PUBLIC_URL}${siteUrl}/${typeUrl}/${data._slug}`} className="row" title={data.name}>
                                                                    {__Print__}
                                                                </Link>
                                                                : <>
                                                                    <div className="row">
                                                                        {__Print__}
                                                                    </div>
                                                                </>}
                                                        </>
                                                    )
                                                })}

                                            </Slider> <div className="divider d-none d-md-flex d-lg-flex flex-column">
                                                <span>&nbsp;</span>
                                                <span>&nbsp;</span>
                                                <span>&nbsp;</span>
                                            </div>
                                        </div></> : ''}

                                    {Data.image_json.map((Slide: any, i: any) => {

                                        var __Print__ = <>
                                            <div className="col-5 col-sm-12 col-md-4 col-lg-12">
                                                <img src={Slide.imageurl} className="img-fluid lazyload" alt={Slide.s_i_alt} title={Slide.s_i_alt} />
                                            </div>
                                            <div className="col-7 col-sm-12 col-md-8 col-lg-12">
                                                <span className="d-block title text-start pt-2 pb-3">{Slide.s_title}</span>
                                            </div>
                                            <div className="col-12">
                                                <span className="d-block sub-title">{Slide.s_header}</span>
                                                <p className="d-block mb-0 description" dangerouslySetInnerHTML={{ __html: Slide.s_desc }}></p>
                                                {/* {Slide.s_l_url !== undefined && Slide.s_l_url !== '' && Slide.s_l_url !== 'undefined' && Slide.s_title === 'Live Darshan' ? <span className="btn btn-red mt-2">View Darshan</span> : ''} */}
                                                {Slide.s_l_url !== undefined && Slide.s_l_url !== '' && Slide.s_l_url !== 'undefined' && Slide.s_l_title !== undefined && Slide.s_l_title !== 'undefined' && Slide.s_l_title !== '' ? <span className="btn btn-red mt-2">{Slide.s_l_title}</span> : ''}


                                            </div>
                                        </>
                                        return (
                                            <div className={`${MobileDClass} text-center justify-content-center live-darshan-box`} key={`${i}-liveDarshan`}>

                                                {Slide.s_l_url !== undefined && Slide.s_l_url !== '' && Slide.s_l_url !== 'undefined' ?
                                                    <Link to={`${process.env.PUBLIC_URL}${Slide.s_l_url}`} className="row" title={Slide.s_header}>
                                                        {__Print__}
                                                    </Link>
                                                    : <>
                                                        <div className="row">
                                                            {__Print__}
                                                        </div>
                                                    </>}

                                                {(Data.image_json.length !== i + 1) ? <div className="divider d-none d-md-flex d-lg-flex flex-column">
                                                    <span>&nbsp;</span>
                                                    <span>&nbsp;</span>
                                                    <span>&nbsp;</span>
                                                </div> : ''}
                                            </div>
                                        )
                                    })}
                                </div> :
                                <Slider className=" row live-darshan-box-container bg-white-shadow-r" {...options}>

                                    {Broadcast.data && Broadcast.data.length > 0 ?
                                        <><div className={`col-lg-3 col-sm-6 col-8 text-center justify-content-center live-darshan-box w-100 d-inline-block`} key={`live-liveDarshan`}>
                                            <Slider className={`w-100`} {...optionsLive} key={`live-liveDarshan`}>
                                                {Broadcast.data.map((data: any, i: any) => {
                                                    data['mandir_name'] = ''
                                                    var site = GlobalContent.AllSiteData.filter((e: any) => data.siteAccess.split(',').includes(e._id))[0]
                                                    var siteUrl = ''
                                                    if (GlobalContent.AllSiteData.length > 0 && site?.is_main !== 'yes') {
                                                        siteUrl = '/' + site?.site_url
                                                    }

                                                    if (site !== undefined) {
                                                        data['mandir_name'] = site.name
                                                    }

                                                    var typeUrl = AllBroadType.data.filter((e: any) => e._id === data.livePage)[0]?.slug

                                                    const d = moment(data.publishOn.split('T')[0])
                                                    data['day'] = d.format('DD')
                                                    data['month'] = d.format('MMM')
                                                    data['year'] = d.format('YYYY')

                                                    const dT = data.startTime.split(':')
                                                    data['e_hour'] = dT[0]
                                                    data['e_minutes'] = dT[1]
                                                    data['event_times'] = ''
                                                    data['__myTime__'] = ''
                                                    data = getMyTimeBroadCast(data, GlobalContent)

                                                    var __Print__ = <>
                                                        <div className="d-flex justify-content-start flex-column col-6 col-sm-3 col-md-3 col-lg-3 col-xl-2">
                                                            <img src={`${(data.image !== '' && data.image !== undefined) ? data.image : `${process.env.PUBLIC_URL}/img/Live_broadcast.svg`}`} className="img-fluid lazyload" alt={data.s_i_alt} title={data.s_i_alt} />
                                                            {data.broadcast === 'active' ? <span className="btn btn-red mt-2" title="Live Now">Live Now</span> : <span className="btn disabled btn-grey mt-2" title="Live Now">Live Now</span>}
                                                        </div>
                                                        <div className="col-6 col-sm-9 col-md-9 col-lg-9 col-xl-10 position-relative">
                                                            <span className="d-block title text-start pt-0 pb-3">Live Broadcast</span>
                                                            <span className="d-block sub-title text-start mt-2">{data.name}</span>
                                                            {data.mandir_name ? <p className="d-block mb-0 description text-start">{data.mandir_name}</p> : ''}
                                                            <p className="d-block mb-0 description text-start" dangerouslySetInnerHTML={{ __html: `Event Time: ${data.day} ${data.month} ${data.year} ${data.e_hour}:${data.e_minutes} ${data.event_times} ${(data['__myTime__'] !== '') ? '| ' + data['__myTime__'] : ''}` }}></p>
                                                            {/* | Your Time: ${data.ist_day} ${data.ist_month} ${data.ist_year} ${data.ist_hour}:${data.ist_minutes} IST */}
                                                        </div>
                                                    </>

                                                    return (
                                                        <>
                                                            {data.broadcast === 'active' ?
                                                                <Link to={`${process.env.PUBLIC_URL}${siteUrl}/${typeUrl}/${data._slug}`} className="row" title={data.name}>
                                                                    {__Print__}
                                                                </Link>
                                                                : <>
                                                                    <div className="row">
                                                                        {__Print__}
                                                                    </div>
                                                                </>}
                                                        </>
                                                    )
                                                })}
                                            </Slider> <div className="divider d-none d-lg-flex flex-column">
                                                <span>&nbsp;</span>
                                                <span>&nbsp;</span>
                                                <span>&nbsp;</span>
                                                <span>&nbsp;</span>
                                            </div>
                                        </div></> : ''}
                                    {Data.image_json.map((Slide: any, i: any) => {
                                        var DarshanClass = 'col-lg-3 col-sm-6 col-8'
                                        if (Data.image_json.length === 1) {
                                            DarshanClass = 'col-lg-12 col-sm-12 col-12'
                                        } else if (Data.image_json.length === 2) {
                                            DarshanClass = 'col-lg-6 col-sm-6 col-8'
                                        } else if (Data.image_json.length === 3) {
                                            DarshanClass = 'col-lg-4 col-sm-6 col-8'
                                        }

                                        var __Print__ = <>
                                            <div className="d-flex justify-content-start flex-column col-6 col-sm-3 col-md-3 col-lg-3 col-xl-2">
                                                <img src={Slide.imageurl} className="img-fluid lazyload" alt={Slide.s_i_alt} title={Slide.s_i_alt} />
                                                {/* {Slide.s_l_url !== undefined && Slide.s_l_url !== '' && Slide.s_l_url !== 'undefined' && Slide.s_title === 'Live Darshan' ? <span className="btn btn-red mt-2">View Darshan</span> : ''} */}

                                                {Slide.s_l_url !== undefined && Slide.s_l_url !== '' && Slide.s_l_url !== 'undefined' && Slide.s_l_title !== undefined && Slide.s_l_title !== 'undefined' && Slide.s_l_title !== '' ? <span className="btn btn-red mt-2">{Slide.s_l_title}</span> : ''}
                                            </div>
                                            <div className="col-6 col-sm-9 col-md-9 col-lg-9 col-xl-10 position-relative">
                                                <span className="d-block title text-start pt-0 pb-3">{Slide.s_title}</span>
                                                <span className="d-block sub-title text-start mt-2">{Slide.s_header}</span>
                                                <p className="d-block mb-0 description text-start" dangerouslySetInnerHTML={{ __html: Slide.s_desc }}></p>
                                            </div>
                                        </>
                                        return (
                                            <div className={`col-lg-3 col-sm-6 col-8 text-center justify-content-center live-darshan-box`} key={`${i}-liveDarshan`}>
                                                {Slide.s_l_url !== undefined && Slide.s_l_url !== '' && Slide.s_l_url !== 'undefined' ?
                                                    <Link to={`${process.env.PUBLIC_URL}${Slide.s_l_url}`} className="row" title={Slide.s_title}>
                                                        {__Print__}
                                                    </Link>
                                                    : <div className="row">
                                                        {__Print__}
                                                    </div>}

                                                {(Data.image_json.length !== i + 1) ? <div className="divider d-none d-lg-flex flex-column">
                                                    <span>&nbsp;</span>
                                                    <span>&nbsp;</span>
                                                    <span>&nbsp;</span>
                                                    <span>&nbsp;</span>
                                                </div> : ''}
                                            </div>
                                        )
                                    })}
                                </Slider>}
                        </div>
                    </section>
                </>
                : ''}
        </>
    )
}

export default Live_darshan