import React, { useContext, useEffect, useState } from "react"
import BreadCrumb from "./sections/BreadCrumb"
import { SectionTitle, PageLoader, TabPanel, UpcomingBlock, MetaData } from "./sections/CommonSections";
import GlobalContext from './Core/PageData'
import { useParams } from "react-router-dom"
import axios from "axios";

const UpcomingEvents: React.FC<any> = (): any => {
    const { GlobalContent, setGlobalContent } = useContext(GlobalContext as any) as any
    document.title = `Pipe Band${GlobalContent.pageTitle}`;
    let { BandSlug } = useParams();
    const [isPageLoad, setisPageLoad] = useState('no')
    const [BreadCrumbdata, setBreadCrumbdata] = useState({ data: [] as any[] });

    const [AllPipeBand, setAllPipeBand] = useState({ data: [] as any[] });
    const [TabData, setTabData] = useState({ tab_json: [] as any[] });

    /* var NewsCategory = [
        { title: 'Bolton', id: '62e0cf26bd4256f61830d32b' },
        { title: 'London', id: '62e0cf26bd4256f61830d32b' },
        { title: 'Maninagar', id: '62e0cf26bd4256f61830d32b' },
        { title: 'Nairobi', id: '62e0cf26bd4256f61830d32b' },
        { title: 'USA', id: '62e0cf26bd4256f61830d32b' },
    ] */
    useEffect(() => {
        (async () => {

            setisPageLoad('no')
            setGlobalContent({ ...GlobalContent, IsMediaPlayershow: false })

            /* setSomeFacts({ data: [{ title: `Members`, count: `33`, desc: `Members` }, { title: `Members`, count: `33`, desc: `Members` }, { title: `Members`, count: `33`, desc: `Members` }, { title: `Members`, count: `33`, desc: `Members` }, { title: `Members`, count: `33`, desc: `Members5` }] }) */

            axios.get(`${process.env.REACT_APP_API_URL}pipeband/bands/all`).then(json => {
                setAllPipeBand({ data: json.data.data })
                var site = GlobalContent.AllSiteData.filter((e: any) => e.site_url === 'pipeband')
                setGlobalContent({ ...GlobalContent, siteData: site[0],headerClass:'' })
                return json.data.data
            }).then(async AllPipeBandTemp => {
                var _TabData_ = [] as any
                var AllPerformance = await axios.get(`${process.env.REACT_APP_API_URL}pipeband/performances/filter?sortField=publishOn&sortType=desc&recordPerPage=20`).then(json => {
                    let Content = [] as any
                    json.data.data.data = json.data.data.data.filter((e:any) => e.status === 'active')
                    json.data.data.data.forEach(async (dd: any, k: any) => {
                        Content.push(<UpcomingBlock data={dd} key={`${dd._id}-NewsListBlock`} searchText={``} type="horizontal" />)
                    })
                    
                    return { cmsPageId: 'a', tabName: 'All', desc: <div className="news d-flex flex-column ms-4 ms-4" style={{gap:'15px'}}>{Content}</div> }
                })
                _TabData_.push(AllPerformance)
                var Apis = [] as any
                AllPipeBandTemp.forEach(async (dd: any, k: any) => {
                    Apis.push({name : dd.band, api : `${process.env.REACT_APP_API_URL}pipeband/performances/filter?sortField=publishOn&sortType=desc&recordPerPage=20&bands=${dd._id}`})
                })

                await Promise.all(Apis.map((endpoint: any) => axios.get(endpoint.api))).then((json) => {
                    json.forEach(async (dd: any, k: any) => {
                        let Content = [] as any
                        dd.data.data.data = dd.data.data.data.filter((e:any) => e.status === 'active')
                        dd.data.data.data.forEach(async (ddd: any, k: any) => {
                            Content.push(<UpcomingBlock data={ddd} key={`${dd._id}-NewsListBlock`} searchText={``} type="horizontal" />)
                        })
                        _TabData_.push({cmsPageId: 'a', tabName: Apis[k].name, desc: <div className="news d-flex flex-column ms-4 ms-4" style={{gap:'15px'}}>{Content}</div>})
                    })
                })

                setTabData({ tab_json: _TabData_ })

                let BreadCrumbdata_ = [
                    {
                        path: `${process.env.PUBLIC_URL}/pipeband`,
                        linkName: 'Home',
                        status: '',
                    }, {
                        path: `${process.env.PUBLIC_URL}/pipeband`,
                        linkName: 'Upcoming Performance',
                        status: '',
                    }]
                setBreadCrumbdata({ data: BreadCrumbdata_ })
                setisPageLoad('yes')
            })
        })()
    }, [BandSlug]);

    return (
        <>
            {isPageLoad === 'yes' ? <>
                {/* <Banner BannerImage="Shreejibapa_1920.png" /> */}
                <MetaData keywords={`Pipe Band, maninagar, swaminarayan, gadi, sansthan`} description={document.title} />
                <section className="site-contain">
                    <div className="Flower-top d-none d-md-block">
                        <img src={process.env.PUBLIC_URL + '/img/Flower-top.png'} className="img-fluid lazyload" alt="Flower" title="Flower" />
                    </div>

                    <div className="container">
                        <BreadCrumb BreadCrumbdata={BreadCrumbdata.data} />
                        <div className="">
                            <SectionTitle title={`Upcoming Events`} />
                        </div>
                        <div className="tab-panel-container"><TabPanel Data={TabData} Pagetype="element" /></div>
                    </div>
                </section>
            </> : (<PageLoader />)
            }
        </>
    )
}

export default UpcomingEvents