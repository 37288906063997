import React, { useContext, useEffect, useState } from "react";
import GlobalContext from './Core/PageData'
import axios from "axios";
import moment from "moment";
// @ts-ignore
import { saveAs } from "file-saver";
import { createEvents } from "ics";
import * as fs from 'fs';

const CalendarIcs: React.FC<any> = ({ type }: any) => {
    
    document.title = `ICS Download`;
    const [currentData, setcurrentData] = useState({ year: parseInt(moment().format('YYYY')), month: moment().format('MM'), date: moment().format('DD') })
    useEffect(() => {
        downloadIcs(type)
    }, [])

    const downloadIcs = (type: any) => {
        var icsData = [] as any
        if (type === 'currentMonth') {

            axios.get(`${process.env.REACT_APP_API_URL}calendar/icsfiledata/month/${currentData.month}/${currentData.year}`).then((json) => {
                json.data.data[0].currentMonth.map((value: any, index: any) => {
                    var date_start = moment(value.startDate); var date_end = moment(value.endDate);
                    var startYear = parseInt(date_start.format('YYYY')); var startMonth = parseInt(date_start.format('MM')); var startDate = parseInt(date_start.format('DD'));
                    var endYear = parseInt(date_end.format('YYYY')); var endMonth = parseInt(date_end.format('MM')); var endDate = parseInt(date_end.format('DD'));
                    icsData.push({
                        start: [startYear, startMonth, startDate],
                        end: [endYear, endMonth, endDate],
                        uid: `{${startYear}${startMonth}${startDate}-${index}@swaminarayangadi.com}`,
                        title: value.title,
                        description: ((value.desc && value.desc.replace(/(<([^>]+)>)/ig, '').length > 1) ? value.desc.replace(/(<([^>]+)>)/ig, '') : '').replace(/\n|\r/g, ""),
                        calName:'Maninagar Shree Swaminarayan Gadi Calendar',
                        url:`${process.env.REACT_APP_SITE_URL}calendar/ics/all`,
                        categories:['SwaminarayanGadi.com'],
                        sequence: (value.sequence == undefined || value.sequence == '') ? 1 : value.sequence,
                        productId:'-//Shree Swaminarayan Gadi Calendar//NONSGML SGadiCal 1.0//EN'
                    })
                })

                console.log(icsData)
                const { error, value }: any = createEvents(icsData)
                const blob1 = new Blob([value], { type: "text/plain;charset=utf-8" });
                saveAs(blob1, "calendar-data-month.ics");
            })
        } else if (type === 'currentYear') {
            axios.get(`${process.env.REACT_APP_API_URL}calendar/icsfiledata/year/${currentData.year}`).then((json) => {
                json.data.data[0].currentYear.map((value: any, index: any) => {
                    var date_start = moment(value.startDate); var date_end = moment(value.endDate);
                    var startYear = parseInt(date_start.format('YYYY')); var startMonth = parseInt(date_start.format('MM')); var startDate = parseInt(date_start.format('DD'));
                    var endYear = parseInt(date_end.format('YYYY')); var endMonth = parseInt(date_end.format('MM')); var endDate = parseInt(date_end.format('DD'));
                    icsData.push({
                        start: [startYear, startMonth, startDate],
                        end: [endYear, endMonth, endDate],
                        uid: `{${startYear}${startMonth}${startDate}-${index}@swaminarayangadi.com}`,
                        title: value.title,
                        description: (value.desc && value.desc.replace(/(<([^>]+)>)/ig, '').length > 1) ? value.desc.replace(/(<([^>]+)>)/ig, '') : '',
                        calName:'Maninagar Shree Swaminarayan Gadi Calendar',
                        url:`${process.env.REACT_APP_SITE_URL}calendar/ics/all`,
                        categories:['SwaminarayanGadi.com'],
                        sequence:3,
                        productId:'-//Shree Swaminarayan Gadi Calendar//NONSGML SGadiCal 1.0//EN'
                    })
                })
                const { error, value }: any = createEvents(icsData)
                const blob1 = new Blob([value], { type: "text/plain;charset=utf-8" });
                saveAs(blob1, "calendar-data-year.ics");
            })
        } else if (type === 'all') {
            axios.get(`${process.env.REACT_APP_API_URL}calendar/icsfiledata/all`).then((json) => {
                json.data.data[0].allData.map((value: any, index: any) => {
                    var date_start = moment(value.startDate); var date_end = moment(value.endDate);
                    var startYear = parseInt(date_start.format('YYYY')); var startMonth = parseInt(date_start.format('MM')); var startDate = parseInt(date_start.format('DD'));
                    var endYear = parseInt(date_end.format('YYYY')); var endMonth = parseInt(date_end.format('MM')); var endDate = parseInt(date_end.format('DD'));

                    
                    icsData.push({
                        start: [startYear, startMonth, startDate],
                        end: [endYear, endMonth, endDate],
                        uid: `{${startYear}${startMonth}${startDate}-${index}@swaminarayangadi.com}`,
                        title: value.title,
                        description: (value.desc && value.desc.replace(/(<([^>]+)>)/ig, '').length > 1) ? value.desc.replace(/(<([^>]+)>)/ig, '') : '',
                        calName:'Maninagar Shree Swaminarayan Gadi Calendar',
                        url:`${process.env.REACT_APP_SITE_URL}calendar/ics/all`,
                        categories:['SwaminarayanGadi.com'],
                        sequence:(index+1),
                        productId:'-//Shree Swaminarayan Gadi Calendar//NONSGML SGadiCal 1.0//EN'
                    })
                })
                const { error, value }: any = createEvents(icsData)
                const blob1 = new Blob([value], { type: "text/plain;charset=utf-8" });
                saveAs(blob1, "calendar-data-all.ics");
            })
        }
    }
    return (
        <></>
    )
}

export default CalendarIcs