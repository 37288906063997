import CacheManager from "../../../service/CacheManager";
import { createSlice } from "@reduxjs/toolkit";
let initialList = CacheManager.get("session") ? CacheManager.get("session") : {sessionData:{},IsSessionIn:'no'} as any;
export const counterSlice = createSlice({
  name: "session",
  initialState: {
    sessionData: initialList.sessionData,
    IsSessionIn: initialList.IsSessionIn,
  },
  reducers: {
    setSession: (state: { [x: string]: any; }, action: { payload: { [x: string]: any; }; }) => {
      Object.keys(action.payload).forEach((key) => {
        state[key] = action.payload[key];
      });
    },
  },
  extraReducers: {},
});

export const { setSession } = counterSlice.actions;

export default counterSlice.reducer;
