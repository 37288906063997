import React from "react";
// @ts-ignore
import ScrollAnimation from 'react-animate-on-scroll'
import { Link } from "react-router-dom";

const Global_network: React.FC<any> = ({ className, Data, ShowWordWide = 'yes', activeRegion = '' }: any) => {
    return (
        <>
            {(Data.image_json && Data.image_json.length > 0) ?
                <>
                    <section className={`global-network ${className}`}>
                        <div className="container">
                            <div className="row global-network-container d-flex justify-content-center flex-row flex-wrap">
                                {(ShowWordWide === 'yes') ?
                                    <div className="global-network-box-o mt-2 mb-3 mb-md-4 mb-lg-0 col-6 col-lg-2 d-flex flex-column justify-content-center">
                                        <div>
                                            <span className="d-block sub-title-alt">{Data.title}{/* Maninagar<br />swaminarayan gadi<br />sansthan */}</span>
                                            <span className="title">worldwide</span>
                                        </div>
                                    </div>
                                    : ''}

                                {Data.image_json.map((Slide: any, i: any) => {
                                    return (
                                        <Link to={`${process.env.PUBLIC_URL}/${process.env.REACT_APP_MANDIR_SLUG}${Slide.s_l_url}`} className={`global-network-box-o mt-2 mb-3 mb-md-4 mb-lg-0 mt-lg-0 col-6 col-lg-2`} key={`${i}-GlobalNetwork`} title={Slide.s_title}>
                                            <ScrollAnimation className={`global-network-box d-flex flex-column justify-content-center ${activeRegion === Slide.s_l_url ? 'active' : ''}`} animateIn="fadeIn" animateOnce={true} delay={100} >
                                                <div className="text-center"><img src={Slide.imageurl} className="img-fluid lazyload" alt={Slide.s_i_alt} title={Slide.s_i_alt} /></div>
                                                <span className="global-network-text">{Slide.s_title}</span>
                                            </ScrollAnimation>
                                        </Link>
                                    )
                                })}
                            </div>
                        </div>
                    </section>
                </> : ''}
        </>
    )
}

export default Global_network